import React from "react";
import { Card } from "react-bootstrap";
import { Pie } from "react-chartjs-2";

const PieChart = ({ title, data }) => {
  // Warna lebih banyak, bisa diperluas jika diperlukan
  const colors = [
    "#007bff",
    "#28a745",
    "#dc3545",
    "#ffc107",
    "#6c757d",
    "#17a2b8",
    "#e83e8c",
    "#fd7e14",
  ];

  const chartData = {
    labels: data.map((item) => item.name),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: colors.slice(0, data.length), // Memotong array warna agar sesuai dengan jumlah data
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "right",
        align: "center",
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <Card className="p-3 bg-white rounded shadow-sm">
      <Card.Header className="bg-white border-0">
        <h5 className="text-center m-0">{title}</h5>
      </Card.Header>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div style={{ width: "100%", maxWidth: "400px", height: "300px" }}>
          <Pie data={chartData} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default PieChart;
