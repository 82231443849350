import React from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import styled from 'styled-components';

import { getDayDiff } from 'utils/date-utils';
import { formatDateToLocal } from 'utils/utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ShortReservation from '../../../reservation/calendar/components/detail-reservation/short-reservation';
import { useCanvasHandler } from 'hooks/useCanvasHandler';
import { IconArrowLeft, IconArrowRight, IconJewishStar, IconJewishStarFilled, IconList, IconPlus } from '@tabler/icons-react';
import ChangeValue from './change-value';
import ChangeRateAvailability from './monthly-setup/change-rate-availability/change-rate-availability';

// Styled components
const CalendarContainer = styled.div`
    table {
        margin-top:0px;
    }
    .fc {
        background-color: #FFFFFF; /* Menghilangkan background default */
    }
    .fc-event {
      background-color: transparent;
      border-width: 0;
      padding: 0;
    }

    .fc-event-title {
        position: relative;
        z-index: 1;  // Ensure the title remains on top
    }

    // style biar event miring sejajar
    .fc-timeline-event-harness {
        top: 0px !important;
    }

    /* Sembunyikan elemen default */
    th[colspan='1'].fc-day-today {
        background-color: rgb(222, 244, 255);
        color: white;
    }
    td.fc-day-today {
        background-color: rgb(222, 244, 255);
        color: white;
    }
    th[colspan='1'].fc-day-future {
        background-color: transparent;
    }
    th[colspan='1'].fc-day-past {
        background-color: transparent;
    }
    span.fc-datagrid-expander.fc-datagrid-expander-placeholder{
        display: none;
    }
    div.fc-datagrid-cell-frame{
        justify-content: center!important;
    }
    span.fc-icon{
        display: none;
    }
    span.fc-datagrid-expander{
        display: none;
    }
    .fc-timeline-events{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        height: 36px !important;
    }
    .fc-timeline-lane-frame{
        height: 36px !important;
    }
    .fc-header-toolbar{
        display: none;
    }
    .fc-timeline-slot-cushion:hover{
        text-decoration: none;
    }
    .fc-resource-timeline-divider{
        width: 0px !important;
    }
    .fc-timeline-slot-frame:not(:has(.fc-sticky)){
        height: 73px !important;
    }
    .fc-timeline-slot-frame:has(.fc-sticky){
        justify-content: start;
    }
`;

// const CustomEvent = styled.div`
//     background-color: ${props => props.color || '#007bff'};
//     color: white;
//     padding: 5px;
//     border-radius: 2px;
//     text-align: center;
//     position: relative;
//     transform: skew(-45deg); /* Efek skew untuk custom event */
//     overflow: hidden; /* Ensure content does not overflow */

//     span {
//         display: inline-block;
//         transform: skew(45deg); /* Balikkan skew */
//         font-size: 12px;
//     }

//     img {
//         margin-top: 5px;
//         transform: skew(45deg); /* Balikkan skew */
//     }
// `;

const CustomEvent = styled.div`
  position: relative;
  height: 30px !important;
  color: white;
  text-align: center;
  border-radius: 5px;
  top: 3px;

  // Adding the pseudo-element for the skew effect
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: ${props => props.left}%;
    width: ${props => props.width}%;
    height: 100%;
    transform: skewX(-25deg);
    transform-origin: top left;
    z-index: -1;  // Make sure the pseudo-element is behind the content
    background-color: ${props => props.color || '#007bff'}; /* Example background color */
    // border-left: 3px solid #fff;  // Add border to the left
  }
`;

const CustomSlotLabel = styled.div`
    position: relative;
    height: 36px !important;
    color: white;
    text-align: center;
    border-radius: 5px;
`;

// const getIcon = (bookedBy) => {
//     switch (bookedBy) {
//         case 'Booking.com':
//             return <img src="/icons/Booking.com_Icon_2022.svg" alt="Booking.com" width={16} height={16} />;
//         case 'Airbnb':
//             return <img src="/icons/airbnb.png" alt="Airbnb" width={16} height={16} />;
//         case 'Expedia':
//             return <img src="/icons/expedia.png" alt="Expedia" width={16} height={16} />;
//         case 'Agoda':
//             return <img src="/icons/agoda.png" alt="Agoda" width={16} height={16} />;
//         default:
//             return null;
//     }
// };

const CalendarBooking = ({ resourcesPayload, eventsPayload, onSelectEvent, firstDay, lastDay, moveNext, moveBack, resetToday, lastClickedEventType, setLastClickedEventType, occupancyPayload }) => {
    const today = new Date();
    const todayFormatted = formatDateToLocal(today);

    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    const handleEventClick = (clickInfo) => {
        const { event } = clickInfo;

        // Tentukan tipe event
        const eventType = event.extendedProps.isRoom ? 'isRoom' : event.extendedProps.type;

        console.log('Event clicked: ' + eventType);

        if (eventType === 'link' || eventType === 'isRoom') {
            // alert("Cannot select link.");
            return;
        }

        // Validasi jika tipe event yang terakhir di klik berbeda
        // if (lastClickedEventType && lastClickedEventType !== eventType) {
        //     alert("Cannot select different event types at the same time.");
        //     return;
        // }

        // Set tipe event terakhir yang di klik
        // setLastClickedEventType(eventType);

        // Ubah warna background dan teks untuk menandakan event dipilih
        // event.setProp('backgroundColor', '#f05151');
        // event.setProp('textColor', 'white');

        // Panggil onSelectEvent untuk mengirim data event ke parent
        const selectedEvent = {
            title: event.title,
            start: event.start,
            additionalData: event.extendedProps // Tambahkan data tambahan dari event
        };

        onSelectEvent({
            type: eventType,
            data: [selectedEvent]
        });

        showCanvasHandler(`Change ${eventType === 'isRoom' ? 'Available' : 'Rate'}`,
            <ChangeValue
                initialData={selectedEvent}
                onSuccess={() => {
                    setLastClickedEventType(null);
                    handleSuccess();
                }}
                onError={handleError}
            />
        );

        // cliclInfo.jsEvent.preventDefault();
        // // alert('Event clicked: ' + cliclInfo.event);
        // // onEventClick(cliclInfo.event);

        // if (cliclInfo.event.extendedProps.booking_id == undefined) return;

        // showCanvasHandler('Detail Booking',
        //     <ShortReservation
        //         bookingId={cliclInfo.event.extendedProps.booking_id}
        //         onSuccess={() => console.log('Success')}
        //         onError={() => console.log('Error')}
        //     />
        // );
    };

    const openRateAvailability = () => {
        showCanvasHandler(`Restrictions & Settings`,
            <ChangeRateAvailability
                onSuccess={(message) => {
                    setLastClickedEventType(null);
                    handleSuccess(true, message);
                }}
                onError={(message) => {
                    handleError(true, message);
                }}
            />
        );
    };

    // const handleDateClick = (dateClickInfo) => {
    //     console.log('Date clicked: ' + dateClickInfo.dateStr);
    //     // alert('Date clicked: ' + dateClickInfo.title);
    //     if (dateClickInfo.title == undefined) return;
    //     onEventClick(dateClickInfo.dateStr);
    // };

    const renderEventContent = (eventInfo) => {
        const bgColor = eventInfo.event.backgroundColor || eventInfo.event.extendedProps.bgColor;
        const textColor = eventInfo.event.textColor || 'black'; // Default teks hitam jika belum diklik
        // console.log();
        if (eventInfo.event.extendedProps.isRoom) {
            return (
                <div className='w-100 h-100 available' style={{ backgroundColor: 'transparent', color: textColor, borderRadius: 0 }}>
                    <div className='text-center d-flex justify-content-center align-items-center pt-2'>
                        <span className='fs-5'>{eventInfo.event.title}</span>
                    </div>
                </div>
            );
        } else {
            const dayDiff = getDayDiff(eventInfo.event.start, eventInfo.event.end);
            const left = 100 / (dayDiff + 1) / 2;
            const width = 100 - left * 2;
            // const dayDiff = getDayDiff(eventInfo.event.start, eventInfo.event.end) > 1 ? getDayDiff(eventInfo.event.start, eventInfo.event.end) + 1 : 1;
            const maxLength = 5;
            const title = eventInfo.event.title;
            const displayTitle = title.length > maxLength ? title.substring(0, (dayDiff * maxLength)) + '...' : title;

            return (
                // <CustomEvent color={eventInfo.event.extendedProps.bgColor} left={left} width={width} dayDiffSlot={dayDiff} className='d-flex align-items-center justify-content-center'>
                //     <img src="/icons/Booking.com_Icon_2022.svg" alt="Icon" width={16} height={16} className='me-1' />
                //     <span>{displayTitle}</span>
                // </CustomEvent>
                <CustomEvent className='w-100 h-100 d-flex align-items-center justify-content-center' style={{ backgroundColor: bgColor, color: textColor }}>
                    <span className='p-2'>{eventInfo.event.title}</span>
                </CustomEvent>
            );
        }
    };

    const renderSlotLabelContent = (arg) => {
        const { date, level, text } = arg;
        const dateFormatted = formatDateToLocal(date);
        // const random = Math.floor(Math.random() * 100);
        const occupancy = occupancyPayload.find(occupancy => {
            return formatDateToLocal(new Date(occupancy.date)) == dateFormatted;
        });
        // const percentage = Math.floor(Math.random() * 100) + '.00%';
        const percentage = Math.floor(occupancy?.occupancy) + '%';
        if (level == 0) {
            return (
                <div className="custom-slot-label ps-2 text-primary">
                    {text}
                </div>
            )
        } else {
            if (dateFormatted == todayFormatted) {
                return (
                    <div className='py-1 px-2 text-black'>
                        {/* <div className='badge bg-secondary text-white rounded-circle my-1'>1</div> */}
                        <div className='fs-5'>{text}</div>
                        <div className='fs-5'>{percentage}</div>
                    </div>
                );
            } else {
                return (
                    <div className='py-1 px-2 text-secondary'>
                        {/* <div className='badge bg-secondary text-white rounded-circle my-1'>1</div> */}
                        <div className='fs-5'>{text}</div>
                        <div className='fs-5'>{percentage}</div>
                    </div>
                )
            }
        }
    };

    const renderResourceLabel = (arg) => {
        const { resource } = arg;
        const { parentId, title } = resource._resource;
        const { type } = resource.extendedProps;
        if (parentId == '') {
            // Resource parent
            return (
                <div className="fc-resource-parent d-inline-block ms-1 fw-bold d-flex justify-content-between">
                    {/* <IconBedFilled size={24} className='me-2' /> */}
                    <span className='text-primary'>{title}</span>
                    <OverlayTrigger
                        placement="right" // Menentukan posisi tooltip di sisi kanan
                        overlay={
                            <Tooltip id="tooltip-right">
                                Availability
                            </Tooltip>
                        }
                    >
                        <span className="fw-bold" style={{ cursor: 'pointer' }}>
                            AVL
                        </span>
                    </OverlayTrigger>
                </div>
            );
        } else {
            // Resource child
            return (
                <div className="fc-resource-child d-flex justify-content-between">
                    {/* <IconHash size={24} className='me-2' /> */}
                    <span className='ms-2'>{title}</span>
                    <OverlayTrigger
                        placement="right" // Menentukan posisi tooltip di sisi kanan
                        overlay={
                            <Tooltip id="tooltip-right">
                                {type === 'rate' ? 'Rate' : 'Link rate'}
                            </Tooltip>
                        }
                    >
                        <span className='fw-normal' style={{ cursor: 'pointer' }}>
                            {type === 'rate' ? 'RATE' : 'LINK'}
                        </span>
                    </OverlayTrigger>
                </div>
            );
        }
    };

    const renderResourceAreaHeaderContent = (arg) => {
        return (
            <div className="d-block">
                <div className='w-100'>
                    <h3 className='fs-2 text-secondary text-center'>Monthly Setup</h3>
                </div>
                <div className='d-flex mt-2 w-100 m-auto'>
                    <button className='btn btn-sm btn-outline-primary btn-pill me-1' onClick={(e) => moveBack(e)}>
                        <IconArrowLeft size={12} />
                    </button>
                    <button className='btn btn-sm btn-outline-primary w-100 btn-pill me-1' onClick={(e) => resetToday(e)}>
                        THIS MONTH
                    </button>
                    <button className='btn btn-sm btn-outline-primary btn-pill me-1' onClick={(e) => moveNext(e)}>
                        <IconArrowRight size={12} />
                    </button>
                </div>
                <div className='d-flex mt-2 w-100 m-auto'>
                    <button className='btn btn-sm btn-outline-primary w-100 btn-pill me-1' onClick={(e) => {
                        e.preventDefault();
                        openRateAvailability();
                    }}>
                        Restrictions & Settings
                    </button>
                </div>
            </div>
        );
    };

    return (
        <CalendarContainer>
            <FullCalendar
                plugins={[resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: '',
                    center: '',
                    right: ''
                }}
                // initialDate={'2024-09-05'}
                visibleRange={() => {
                    return {
                        start: firstDay,
                        end: lastDay
                    };
                }}
                height={'auto'}
                initialView="resourceTimeline"
                editable={true}
                resourceAreaHeaderContent={renderResourceAreaHeaderContent}
                // resources={RoomsAvailable}
                // resources={RoomsResource}
                resources={resourcesPayload}
                // events={EventRooms}
                // events={frontOfficeData}
                events={eventsPayload}
                showNonCurrentDates={true}
                // dateClick={handleDateClick}
                eventClick={handleEventClick}
                slotLabelFormat={[
                    { month: 'long', year: 'numeric' },
                    { day: 'numeric', weekday: 'short' },
                ]}
                // slotLabelDidMount={renderSlotLabelContent}
                slotLabelContent={renderSlotLabelContent}
                eventContent={renderEventContent} // Custom event content
                schedulerLicenseKey='0310067844-fcs-1721029428'
                resourceLabelContent={renderResourceLabel} // Custom resource content
                // nowIndicator={true}
                // nowIndicatorContent={renderNowIndicatorContent}
                // dayCellContent={renderNowIndicatorContent}
                resourceAreaWidth="20%"
            />
        </CalendarContainer>
    );
}

export default CalendarBooking;