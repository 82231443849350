import useCreatePropertyManagementHook from 'hooks/v2/pages/property-setting/useCreatePropertyManagementHook';
import { createContext, useContext } from 'react';

const CreatePropertyManagementContext = createContext();

export const CreatePropertyManagementProvider = ({ children }) => {
    const createPropertyManagement = useCreatePropertyManagementHook();

    const contextValue = {
        ...createPropertyManagement,
    };

    return (
        <CreatePropertyManagementContext.Provider value={contextValue}>
            {children}
        </CreatePropertyManagementContext.Provider>
    );
};

export const useCreatePropertyManagement = () => {
    const context = useContext(CreatePropertyManagementContext);
    if (context === undefined) {
        throw new Error('useCreatePropertyManagement must be used within a CreatePropertyManagementProvider');
    }
    return context;
};