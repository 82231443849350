import { useEffect, useState } from 'react';
import { getRequest, patchRequest, postRequest } from 'services/api';
import { API_VERSION } from 'utils/constants';
import { formatDateToString } from 'utils/dateHelpers';

const useCreateReservationHooks = (initialState) => {
    const [optionState, setOptionState] = useState({
        data: null,
        error: null,
        isLoading: false,
    });

    const [processedData, setProcessedData] = useState({
        data: null,
        error: null,
        isLoading: false,
    });

    const handleCreateReservation = async () => {
        console.log('create reservation');
        const { invoiceForm, rooms, guestDetailsForm, commentsForm, getRoomTotal, getAllRoomsTotal, getTotalOccupancyAdults, getTotalOccupancyChildren } = initialState;

        // New Payload
        const payloadRequest = {
            customer_name: invoiceForm.first_name || "",
            customer_surname: invoiceForm.last_name || "",
            customer_email: invoiceForm.email || "",
            currency: invoiceForm.currency || "IDR",
            amount: getAllRoomsTotal() || "0",
            occupancy_children: getTotalOccupancyChildren() || "0",
            occupancy_adults: getTotalOccupancyAdults() || "0",
            occupancy_infants: "0",
            arrival_date: rooms[0].arrival_date || "",
            arrival_hour: guestDetailsForm.arrival_time || "00:00",
            departure_date: rooms[0].departure_date || "",
            // Add other customer details here
            customer_phone: guestDetailsForm.phone || "",
            customer_address: guestDetailsForm.address || "",
            customer_country: guestDetailsForm.country || "",
            customer_state: guestDetailsForm.state || "",
            customer_birth_date:
                guestDetailsForm.birthday_month &&
                    guestDetailsForm.birthday_day &&
                    guestDetailsForm.birthday_year
                    ? formatDateToString(
                        new Date(
                            `${guestDetailsForm.birthday_month} ${guestDetailsForm.birthday_day}, ${guestDetailsForm.birthday_year}`
                        )
                    )
                    : "",
            customer_identity_number: guestDetailsForm.id_number || "",
            agent_id: guestDetailsForm.agent_id || "",
            mark: invoiceForm.mark || "",
            status: invoiceForm.status || "confirmed",
            invoice_number: invoiceForm.custom_invoice_nb || "",
            booking_date: invoiceForm.booking_date || "",
            notes: commentsForm.public_notes || "",
            private_notes: commentsForm.private_notes || "",
            bookingroom: rooms.map((room, index) => ({
                payment_type: invoiceForm.payment_type || "",
                customer_name: invoiceForm.first_name || "",
                customer_surname: invoiceForm.last_name || "",
                customer_email: invoiceForm.email || "",
                room_type_id: room.room_type_id,
                room_id: room.room_id,
                rate_plan_id: room.rate_plan_id,
                amount: getRoomTotal(index) || "0",
                occupancy_adults: room.occupancy_adults,
                occupancy_children: room.occupancy_children,
                checkin_date: room.arrival_date || "",
                checkout_date: room.departure_date || "",
                day: room.day.data.map((d) => ({
                    date: formatDateToString(new Date(d.date)) || "",
                    amount: d.rate,
                })),
            })),
        };

        console.log('Payload Request:', payloadRequest);

        setProcessedData((prevState) => ({ ...prevState, isLoading: true }));
        try {
            const response = await postRequest(`/${API_VERSION}/booking`, payloadRequest);
            if (response.success) {
                setProcessedData((prevState) => ({ ...prevState, data: response.data, error: null, isLoading: false }));
                return { success: true, message: 'Reservation created successfully' };
            }
        } catch (error) {
            setProcessedData((prevState) => ({ ...prevState, error, isLoading: false }));
            return { success: false, message: 'Failed to create reservation' };
        }
    };

    return {
        optionState,
        setOptionState,
        processedData,
        handleCreateReservation,
    };
};

export default useCreateReservationHooks;