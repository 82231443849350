import { IconArrowsExchange, IconBell, IconBook, IconBox, IconBrandBooking, IconCalculator, IconCalendar, IconCalendarCheck, IconCalendarEvent, IconCash, IconCashRegister, IconChartLine, IconChecklist, IconClipboard, IconClipboardList, IconClock, IconCloudDataConnection, IconCreditCard, IconCurrency, IconCurrencyDollar, IconDashboard, IconDeviceDesktopAnalytics, IconDiscount, IconFileInvoice, IconGlobe, IconHome, IconHomeCheck, IconLayoutDashboardFilled, IconListCheck, IconListDetails, IconLock, IconLockAccess, IconPackage, IconReport, IconReportMoney, icons, IconSettings, IconSettings2, IconSettingsAutomation, IconSettingsCheck, IconShield, IconShieldLock, IconUser, IconUserCog, IconUserDollar, IconUsers, IconWallet, IconWashMachine, IconWorldCog } from '@tabler/icons-react';

export const API_VERSION = "v2";
export const API_URL = "https://api.bedshero.com/api";
// export const API_URL = "https://pms-api.gotra.my.id/api";

// Mark as status checked_in, checked_out, no_show, early_checkout, early_checkout_update_availability\
export const MARK_AS = [
    { value: 'checked_in', label: 'Checked In' },
    { value: 'checked_out', label: 'Checked Out' },
    { value: 'no_show', label: 'No Show' },
    { value: 'early_checkout', label: 'Early Checkout' },
    { value: 'early_checkout_update_availability', label: 'Early Checkout & Update Availability' }
]

export const URLS = {
    // DASHBOARD
    DASHBOARD: '/',

    // CHANNEL MANAGER
    DASHBOARD_ADMIN_CHANNEL_MANAGER: '/admin/channel-manager',
    DASHBOARD_ADMIN_CHANNEL_MANAGER_MONTHLY_SETUP: '/admin/channel-manager/monthly-setup',
    DASHBOARD_ADMIN_CHANNEL_MANAGER_YEARLY_SETUP: '/admin/channel-manager/yearly-setup',
    DASHBOARD_ADMIN_CHANNEL_MANAGER_MAPPING: '/admin/channel-manager/mapping',
    DASHBOARD_ADMIN_CHANNEL_MANAGER_CHANNEL_SETUP: '/admin/channel-manager/channel-setup',
    DASHBOARD_ADMIN_CHANNEL_MANAGER_REPORTS: '/admin/channel-manager/reports',

    // RESERVATION
    DASHBOARD_ADMIN_RESERVATION_DASHBOARD: '/admin/reservation/dashboard',
    DASHBOARD_ADMIN_RESERVATION: '/admin/reservation',
    DASHBOARD_ADMIN_RESERVATION_CALENDAR: '/admin/reservation/calendar',
    DASHBOARD_ADMIN_RESERVATION_AGENT_SETTINGS: '/admin/reservation/agent-settings',
    DASHBOARD_ADMIN_RESERVATION_ACCOUNT_EXTRAS: '/admin/reservation/account-extras',
    DASHBOARD_ADMIN_RESERVATION_HOUSEKEEPING: '/admin/reservation/housekeeping',
    DASHBOARD_ADMIN_RESERVATION_STOCK_INVENTORY: '/admin/reservation/stock-inventory',
    DASHBOARD_ADMIN_RESERVATION_REPORTS: '/admin/reservation/reports',
    DASHBOARD_ADMIN_RESERVATION_REPORTS_OCCUPANCY: '/admin/reservation/reports/occupancy',
    DASHBOARD_ADMIN_RESERVATION_REPORTS_AGENT: '/admin/reservation/reports/agent',
    DASHBOARD_ADMIN_RESERVATION_REPORTS_REVENUE: '/admin/reservation/reports/revenue',
    DASHBOARD_ADMIN_RESERVATION_REPORTS_SALES: '/admin/reservation/reports/sales',

    // PROPERTY SETTINGS
    DASHBOARD_ADMIN_PROPERTY_SETTINGS: '/admin/property-settings',
    DASHBOARD_ADMIN_PROPERTY_SETTINGS_PROPERTY_MANAGEMENT: '/admin/property-settings/property-management',
    DASHBOARD_ADMIN_PROPERTY_SETTINGS_RATE: '/admin/property-settings/rate',

    // DASHBOARD PROFILE
    DASHBOARD_PROFILE: '/profile',
    DASHBOARD_CHANGE_PASSWORD: '/change-password',
    DASHBOARD_MY_PROPERTY: '/my-property',

    // USER SETTINGS
    DASHBOARD_ADMIN_SETTINGS_USER_MANAGEMENT: '/admin/settings/user-management',
    DASHBOARD_ADMIN_SETTINGS_ROLES: '/admin/settings/roles',
    DASHBOARD_ADMIN_SETTINGS_PERMISSIONS: '/admin/settings/permissions',
    // DASHBOARD_ADMIN_USER_SETTINGS_LIST: '/admin/user-settings/list',
    // DASHBOARD_ADMIN_USER_SETTINGS_ROLE: '/admin/user-settings/role',
    // DASHBOARD_ADMIN_USER_SETTINGS_ROLE_SYNC_PERMISSIONS: '/admin/user-settings/role/sync-permissions',
    // DASHBOARD_ADMIN_USER_SETTINGS_PERMISSION: '/admin/user-settings/permission',

    // AUTH
    LOGIN: '/login',
    SIGN_UP: '/sign-up',
};

export const NAV_LINKS = [
    { name: 'Dashboard', href: URLS.DASHBOARD, icon: IconLayoutDashboardFilled },
    {
        name: 'PMS',
        href: URLS.DASHBOARD_ADMIN_RESERVATION,
        icon: IconCalendarEvent,
        child: [
            { name: 'Dashboard', href: URLS.DASHBOARD_ADMIN_RESERVATION_DASHBOARD, icon: IconDashboard },
            { name: 'Booking List', href: URLS.DASHBOARD_ADMIN_RESERVATION, icon: IconClipboardList },
            { name: 'Calendar', href: URLS.DASHBOARD_ADMIN_RESERVATION_CALENDAR, icon: IconCalendar },
            { name: 'Agent Settings', href: URLS.DASHBOARD_ADMIN_RESERVATION_AGENT_SETTINGS, icon: IconCreditCard },
            // { name: 'Account & Extras', href: URLS.DASHBOARD_ADMIN_RESERVATION_ACCOUNT_EXTRAS, icon: IconWallet },
            // { name: 'Housekeeping', href: URLS.DASHBOARD_ADMIN_RESERVATION_HOUSEKEEPING, icon: IconWashMachine },
            // { name: 'Stock Inventory', href: URLS.DASHBOARD_ADMIN_RESERVATION_STOCK_INVENTORY, icon: IconBox },
            { name: 'Reports', href: URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS, icon: IconReport },
        ]
    },
    {
        name: 'Channel Manager',
        href: URLS.DASHBOARD_ADMIN_CHANNEL_MANAGER,
        icon: IconGlobe,
        child: [
            { name: 'Monthly Setup', href: URLS.DASHBOARD_ADMIN_CHANNEL_MANAGER_MONTHLY_SETUP, icon: IconSettings },
            // { name: 'Yearly Setup', href: URLS.DASHBOARD_ADMIN_CHANNEL_MANAGER_YEARLY_SETUP, icon: IconBook },
            // { name: 'Channel Manager Mapping', href: URLS.DASHBOARD_ADMIN_CHANNEL_MANAGER_MAPPING, icon: IconListDetails },
            { name: 'Channel Setup', href: URLS.DASHBOARD_ADMIN_CHANNEL_MANAGER_CHANNEL_SETUP, icon: IconWorldCog },
            { name: 'Room & Rate Settings', href: URLS.DASHBOARD_ADMIN_PROPERTY_SETTINGS_RATE, icon: IconCurrencyDollar },
            { name: 'Reports', href: URLS.DASHBOARD_ADMIN_CHANNEL_MANAGER_REPORTS, icon: IconReport }
        ]
    },
    // {
    //     name: 'Booking Engine',
    //     href: URLS.DASHBOARD_ADMIN_BOOKING_ENGINE,
    //     icon: IconBrandBooking, // Ganti dengan ikon yang sesuai
    //     child: [
    //         { name: 'Booking Rules', href: URLS.DASHBOARD_ADMIN_BOOKING_RULES, icon: IconListCheck }, // Ganti dengan ikon yang sesuai
    //         { name: 'Online Booking Integration', href: URLS.DASHBOARD_ADMIN_ONLINE_BOOKING_INTEGRATION, icon: IconCloudDataConnection }, // Ganti dengan ikon yang sesuai
    //         { name: 'Direct Booking Setup', href: URLS.DASHBOARD_ADMIN_DIRECT_BOOKING_SETUP, icon: IconSettingsCheck } // Ganti dengan ikon yang sesuai
    //     ]
    // },
    // {
    //     name: 'Point of Sale',
    //     href: URLS.DASHBOARD_ADMIN_POINT_OF_SALE,
    //     icon: IconDeviceDesktopAnalytics,
    //     child: [
    //         { name: 'Sales', href: URLS.DASHBOARD_ADMIN_POINT_OF_SALE, icon: IconCash },
    //         { name: 'Inventory', href: URLS.DASHBOARD_ADMIN_POINT_OF_SALE_CREATE, icon: IconPackage },
    //         { name: 'Cash Register', href: URLS.DASHBOARD_ADMIN_POINT_OF_SALE_CREATE, icon: IconCashRegister }
    //     ]
    // },
    {
        name: 'Property Settings',
        href: URLS.DASHBOARD_ADMIN_PROPERTY_SETTINGS,
        icon: IconSettings,
        child: [
            // { name: 'General Settings', href: URLS.DASHBOARD_ADMIN_PROPERTY_SETTINGS, icon: IconSettings },
            { name: 'Property Management', href: URLS.DASHBOARD_ADMIN_PROPERTY_SETTINGS_PROPERTY_MANAGEMENT, icon: IconHome },
        ]
    },
    // {
    //     name: 'Housekeeping',
    //     href: URLS.DASHBOARD_ADMIN_HOUSEKEEPING,
    //     icons: IconLockAccess,
    //     child: [
    //         { name: 'Task Scheduling', href: URLS.DASHBOARD_ADMIN_HOUSEKEEPING_TASK_SCHEDULING, icon: IconClock },
    //         { name: 'Room Status', href: URLS.DASHBOARD_ADMIN_HOUSEKEEPING_ROOM_STATUS, icon: IconHomeCheck },
    //         { name: 'Inventory for Housekeeping', href: URLS.DASHBOARD_ADMIN_HOUSEKEEPING_INVENTORY, icon: IconUsers }
    //     ]
    // },
    // {
    //     name: 'Staff Management',
    //     href: URLS.DASHBOARD_ADMIN_STAFF_MANAGEMENT,
    //     icon: IconUserCog, // Ganti dengan ikon yang sesuai
    //     child: [
    //         { name: 'Employee Profiles', href: URLS.DASHBOARD_ADMIN_EMPLOYEE_PROFILES, icon: IconUser },
    //         { name: 'Task Assignment', href: URLS.DASHBOARD_ADMIN_TASK_ASSIGNMENT, icon: IconChecklist },
    //         { name: 'Performance Tracking', href: URLS.DASHBOARD_ADMIN_PERFORMANCE_TRACKING, icon: IconChartLine }
    //     ]
    // },
    // {
    //     name: 'Inventory Management',
    //     href: URLS.DASHBOARD_ADMIN_INVENTORY_MANAGEMENT,
    //     icon: IconBox, // Ganti dengan ikon yang sesuai
    //     child: [
    //         { name: 'Stock Control', href: URLS.DASHBOARD_ADMIN_STOCK_CONTROL, icon: IconBox },
    //         { name: 'Low Stock Alerts', href: URLS.DASHBOARD_ADMIN_LOW_STOCK_ALERTS, icon: IconBell },
    //         { name: 'Inventory Tracking', href: URLS.DASHBOARD_ADMIN_INVENTORY_TRACKING, icon: IconClipboard }
    //     ]
    // },
    {
        name: 'Settings',
        href: URLS.DASHBOARD_ADMIN_SETTINGS,
        icon: IconSettings, // Ganti dengan ikon yang sesuai
        child: [
            { name: 'User Management', href: URLS.DASHBOARD_ADMIN_SETTINGS_USER_MANAGEMENT, icon: IconUsers },
            { name: 'Roles', href: URLS.DASHBOARD_ADMIN_SETTINGS_ROLES, icon: IconShield },
            { name: 'Permissions', href: URLS.DASHBOARD_ADMIN_SETTINGS_PERMISSIONS, icon: IconLock },
            { name: 'System Settings', href: URLS.DASHBOARD_ADMIN_SYSTEM_SETTINGS, icon: IconSettings2 }
        ]

    }
    // {
    //     name: 'Reports',
    //     href: URLS.DASHBOARD_ADMIN_REPORTS,
    //     icon: IconReport,
    //     child: [
    //         { name: 'Financial Report', href: URLS.DASHBOARD_ADMIN_REPORTS, icon: IconReportMoney },
    //         { name: 'Reservation Report', href: URLS.DASHBOARD_ADMIN_REPORTS, icon: IconFileInvoice },
    //     ]
    // },
    // {
    //     name: 'Accounting',
    //     href: URLS.DASHBOARD_ADMIN_ACCOUNTING,
    //     icon: IconCalculator,
    //     child: [
    //         { name: 'Transactions', href: URLS.DASHBOARD_ADMIN_ACCOUNTING, icon: IconArrowsExchange },
    //         { name: 'Ledger', href: URLS.DASHBOARD_ADMIN_ACCOUNTING, icon: IconBook },
    //         { name: 'Invoices', href: URLS.DASHBOARD_ADMIN_ACCOUNTING, icon: IconFileInvoice }
    //     ]
    // },
];