import { IconCheck, IconExclamationCircle, IconX } from '@tabler/icons-react';
import clsx from 'clsx';
import { useRoomRate } from 'contexts/v2/pages/room-rate-setting/RoomRateContext';
import React, { useEffect, useRef, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { toast } from 'react-toastify';

const EditRoom = ({ show, handleClose, onSuccess, onError }) => {
    return (
        <Offcanvas show={show} onHide={handleClose} placement='end' style={{ width: '45%' }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Create Roomtype</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <EditRoomForm
                    onSuccess={onSuccess}
                    onError={onError}
                />
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default EditRoom;

const EditRoomForm = ({ onSuccess, onError }) => {
    const { updateDefaultData, updateDefaultLoading, fetchDefaultUpdateData, updateDefaultRoom, roomIdWillUpdate, updateAvailableRoom } = useRoomRate();

    // state handling data and error
    const [hasError, setHasError] = useState(false);

    // Form state
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        no_of_beds: null,
        cut_of_days: null,
        is_free_allotment: false,
        is_auto_assign: false,
        is_dynamic_pricing: false,
        adults: null,
        children: null,
        room_number: [],
    });
    // Form error state handling
    const [formError, setFormError] = useState(null);

    // Form submission state handling
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Loading state for fetching default data
    const [isLoading, setIsLoading] = useState(true);

    // Room count state handling for room number input
    const [roomCount, setRoomCount] = useState(null);
    // State to check if room number is changed
    const [isRoomNumberChanged, setIsRoomNumberChanged] = useState(false);
    // State to store default rooms data from API
    const [defaultRooms, setDefaultRooms] = useState([]);
    // State to store room available status loading state
    const [roomAvailableIsLoading, setRoomAvailableIsLoading] = useState([]);
    const [roomAvailableErrors, setRoomAvailableErrors] = useState([]);
    // Refs for room number inputs
    const roomNumberRefs = useRef([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const { room_number, adults, children, ...rest } = formData;
            const formDataPayload = {
                ...rest,
                occ_adults: adults,
                occ_children: children,
                ...(room_number.some((room) => typeof room === "string") && {
                    room_number: room_number.filter((room) => typeof room === "string"),
                }),
            };


            const response = await updateDefaultRoom(roomIdWillUpdate, formDataPayload);
            if (response.success) {
                onSuccess(response.message);
            } else {
                setFormError((prevErrors) => ({
                    ...prevErrors,
                    ...response.formError,
                }));
                onError(response.message);
            }
        } catch (error) { } finally {
            setIsSubmitting(false);
        }
    };

    // Handle room count and input focus
    const handleRoomAvailableChange = (e) => {
        const roomCount = parseInt(e.target.value, 10);
        setIsRoomNumberChanged(true);
        setRoomCount(roomCount);
        setFormError((prevErrors) => ({
            ...prevErrors,
            room_number: null,
        }));
    };

    // Handle room number change
    const handleRoomNumberChange = (e, index) => {
        const { value } = e.target;
        setFormData((prevFormData) => {
            const updatedRoomNumbers = [...(prevFormData.room_number || [])];
            updatedRoomNumbers[index] = value;

            return {
                ...prevFormData,
                room_number: updatedRoomNumbers,
            };
        });
    };

    const handleSetRoomNumber = () => {
        setIsRoomNumberChanged(false);

        if (!isNaN(roomCount)) {
            setFormData((prevFormData) => {
                const currentRoomNumbers = defaultRooms || [];

                // Validasi: Jika roomCount kurang dari panjang room_number, set error
                if (roomCount < currentRoomNumbers.length) {
                    setFormError((prevErrors) => ({
                        ...prevErrors,
                        room_number: ['You cannot set room count less than the previous room numbers.'],
                    }));
                    return prevFormData; // Tidak melakukan update jika validasi gagal
                }

                // Jika validasi sukses, reset error
                setFormError((prevErrors) => ({
                    ...prevErrors,
                    room_number: null,
                }));

                const currentLength = currentRoomNumbers.length;

                // Jika roomCount lebih besar, tambahkan item baru
                if (roomCount => currentLength) {
                    const newRoomNumbers = [
                        ...currentRoomNumbers,
                        ...Array.from(
                            { length: roomCount - currentLength },
                            (_, index) => `10${currentLength + index + 1}`
                        ),
                    ];
                    return {
                        ...prevFormData,
                        room_number: newRoomNumbers,
                    };
                }

                return prevFormData;
            });
        } else {
            setFormError((prevErrors) => ({
                ...prevErrors,
                room_number: ['Invalid room count.'],
            }));
        }
    };

    const setDefaultFormData = async () => {
        setIsLoading(true);
        try {
            const response = await fetchDefaultUpdateData(roomIdWillUpdate);
            if (response.success) {
                const { data } = response;
                setFormData({
                    name: data.name,
                    description: data.description,
                    no_of_beds: data.no_of_beds,
                    cut_of_days: data.cut_of_days,
                    is_free_allotment: data.is_free_allotment,
                    is_auto_assign: data.is_auto_assign,
                    is_dynamic_pricing: data.is_dynamic_pricing,
                    adults: data.occ_adults,
                    children: data.occ_children,
                    room_number: data.rooms,
                });
                setDefaultRooms(data.rooms);
                setRoomCount(data.total_rooms);
            } else {
                setHasError(true);
            }
        } catch (error) {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAvailableRoom = async (status, roomId, roomNumber) => {
        setRoomAvailableIsLoading((prev) => [...prev, roomId]);
        try {
            const response = await updateAvailableRoom(roomId, { status, room_number: roomNumber });
            if (response.success) {
                try {
                    const innerResponse = await fetchDefaultUpdateData(roomIdWillUpdate);
                    if (innerResponse.success) {
                        const { data } = innerResponse;
                        setFormData({
                            name: data.name,
                            description: data.description,
                            no_of_beds: data.no_of_beds,
                            cut_of_days: data.cut_of_days,
                            is_free_allotment: data.is_free_allotment,
                            is_auto_assign: data.is_auto_assign,
                            is_dynamic_pricing: data.is_dynamic_pricing,
                            adults: data.occ_adults,
                            children: data.occ_children,
                            room_number: data.rooms,
                        });
                        setDefaultRooms(data.rooms);
                        setRoomCount(data.total_rooms);
                        setRoomAvailableErrors([]);
                    } else {
                        setRoomAvailableErrors((prev) => [...prev, { roomId, message: response.message }]);
                    }
                } catch (error) {
                    setRoomAvailableErrors((prev) => [...prev, { roomId, message: error.message }]);
                }
            } else {
                setRoomAvailableErrors((prev) => [...prev, { roomId, message: response.message }]);
            }
        } catch (error) {
            setRoomAvailableErrors((prev) => [...prev, { roomId, message: error.message }]);
        } finally {
            setRoomAvailableIsLoading((prev) => prev.filter((id) => id !== roomId));
        }
    };

    useEffect(() => {
        if (roomIdWillUpdate) {
            setDefaultFormData();
        }
    }, [roomIdWillUpdate]);

    if (hasError) {
        return (
            <div style={styles.errorContainer}>
                <IconExclamationCircle style={styles.icon} size={72} />
                <p style={styles.errorText}>Failed to load data. Please try again later.</p>
            </div>
        );
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                <div className="row mb-3 align-items-center">
                    {isLoading ? (
                        <div className='col-sm-8 ms-auto'>
                            <div className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: '35px', display: 'block' }}></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label htmlFor="name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Name: </label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    className={clsx("form-control app-form-control rounded-1", formError?.name && 'is-invalid')}
                                    id="name"
                                    placeholder="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, name: e.target.value }))}
                                    disabled={isSubmitting}
                                    style={{ fontSize: '14px', height: '35px' }}
                                />
                                {formError?.name && <div className="invalid-feedback">{formError.name}</div>}
                            </div>
                        </>
                    )}
                </div>

                <div className="row mb-3 align-items-center">
                    {isLoading ? (
                        <div className='col-sm-8 ms-auto'>
                            <div className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: '70px', display: 'block' }}></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label htmlFor="description" className="col-sm-4 col-form-label d-flex justify-content-end">Description: </label>
                            <div className="col-sm-8">
                                <textarea
                                    className={clsx("form-control app-form-control rounded-1", formError?.description && 'is-invalid')}
                                    name="description"
                                    id="description"
                                    placeholder="Enter rate plan description"
                                    value={formData.description}
                                    onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, description: e.target.value }))}
                                />
                                {formError?.description && <div className="invalid-feedback">{formError.description}</div>}
                            </div>
                        </>
                    )}
                </div>

                <h3 className="mt-6 border-bottom fw-normal">Room Settings</h3>
                <div className="row mb-3 align-items-center">
                    {isLoading ? (
                        <div className='col-sm-8 ms-auto'>
                            <div className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: '35px', display: 'block' }}></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label htmlFor="noOfRooms" className="col-sm-4 col-form-label d-flex justify-content-end">No. of Beds</label>
                            <div className="col-sm-8">
                                <select
                                    name="no_of_beds"
                                    className={clsx("form-control app-form-control rounded-1", formError?.no_of_beds && 'is-invalid')}
                                    id="noOfRooms"
                                    value={formData.no_of_beds}
                                    onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, no_of_beds: parseInt(e.target.value, 10) }))}
                                >
                                    <option value="0">Select no. of beds</option>
                                    <option value="1">1 bed</option>
                                    <option value="2">2 beds</option>
                                    <option value="3">3 beds</option>
                                </select>
                                {formError?.no_of_beds && <div className="invalid-feedback">{formError.no_of_beds}</div>}
                            </div>
                        </>
                    )}
                </div>
                <div className="row mb-3 align-items-center">
                    {isLoading ? (
                        <div className='col-sm-8 ms-auto'>
                            <div className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: '35px', display: 'block' }}></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label htmlFor="cutOff" className="col-sm-4 col-form-label d-flex justify-content-end">Cut Off: </label>
                            <div className='col-sm-8'>
                                <select
                                    name="cut_of_days"
                                    className={clsx("form-control app-form-control rounded-1", formError?.cut_of_days && 'is-invalid')}
                                    id="cutOff"
                                    value={formData.cut_of_days}
                                    onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, cut_of_days: parseInt(e.target.value, 10) }))}
                                >
                                    <option value="0">Select cut off</option>
                                    <option value="1">1 Day</option>
                                    <option value="2">2 Days</option>
                                    <option value="3">3 Days</option>
                                </select>
                                {formError?.cut_of_days && <div className="invalid-feedback">{formError.cut_of_days}</div>}
                            </div>
                        </>
                    )}
                </div>
                <div className="row mb-3 align-items-center">
                    {isLoading ? (
                        <div className='col-sm-8 ms-auto mb-3'>
                            <div className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: '35px', display: 'block' }}></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label className="col-sm-4 col-form-label d-flex justify-content-end" htmlFor="enableFreeAllotment">Enable Free Allotment:</label>
                            <div className="col-sm-8">
                                <input
                                    type="checkbox"
                                    name="is_free_allotment"
                                    className={clsx("form-check-input rounded-1", formError?.is_free_allotment && 'is-invalid')}
                                    id="enableFreeAllotment"
                                    checked={formData.is_free_allotment}
                                    onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, is_free_allotment: e.target.checked }))}
                                />
                                {formError?.is_free_allotment && <div className="invalid-feedback">{formError.is_free_allotment}</div>}
                            </div>
                        </>
                    )}
                    {isLoading ? (
                        <div className='col-sm-8 ms-auto mb-3'>
                            <div className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: '35px', display: 'block' }}></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label className="col-sm-4 col-form-label d-flex justify-content-end" htmlFor="enableAutoAssign">Enable Auto Assign Feature:</label>
                            <div className="col-sm-8">
                                <input
                                    type="checkbox"
                                    name="is_auto_assign"
                                    className={clsx("form-check-input rounded-1", formError?.is_auto_assign && 'is-invalid')}
                                    id="enableAutoAssign"
                                    checked={formData.is_auto_assign}
                                    onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, is_auto_assign: e.target.checked }))}
                                />
                                {formError?.is_auto_assign && <div className="invalid-feedback">{formError.is_auto_assign}</div>}
                            </div>
                        </>
                    )}
                    {isLoading ? (
                        <div className='col-sm-8 ms-auto mb-3'>
                            <div className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: '35px', display: 'block' }}></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label className="col-sm-4 col-form-label d-flex justify-content-end" htmlFor="enableDynamicPricing">Enable Dynamic Pricing</label>
                            <div className="col-sm-8">
                                <input
                                    type="checkbox"
                                    name="is_dynamic_pricing"
                                    className={clsx("form-check-input rounded-1", formError?.is_dynamic_pricing && 'is-invalid')}
                                    id="enableDynamicPricing"
                                    checked={formData.is_dynamic_pricing}
                                    onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, is_dynamic_pricing: e.target.checked }))}
                                />
                                {formError?.is_dynamic_pricing && <div className="invalid-feedback">{formError.is_dynamic_pricing}</div>}
                            </div>
                        </>
                    )}
                </div>

                {/* Add Adults input */}
                <div className="row mb-3 align-items-center">
                    {isLoading ? (
                        <div className='col-sm-8 ms-auto'>
                            <div className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: '35px', display: 'block' }}></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label htmlFor="adults" className="col-sm-4 col-form-label d-flex justify-content-end">Occupancy Adults</label>
                            <div className="col-sm-8">
                                <input
                                    type="number"
                                    className={clsx("form-control app-form-control rounded-1", formError?.adults && 'is-invalid')}
                                    id="adults"
                                    placeholder="Enter no. of adults"
                                    value={formData.adults}
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            adults: parseInt(e.target.value, 10),
                                        }));
                                    }}
                                />
                                {formError?.adults && <div className="invalid-feedback">{formError.adults}</div>}
                            </div>
                        </>
                    )}
                </div>

                {/* Add children input */}
                <div className="row mb-3 align-items-center">
                    {isLoading ? (
                        <div className='col-sm-8 ms-auto'>
                            <div className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: '35px', display: 'block' }}></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label htmlFor="children" className="col-sm-4 col-form-label d-flex justify-content-end">Occupancy Children</label>
                            <div className="col-sm-8">
                                <input
                                    type="number"
                                    className={clsx("form-control app-form-control rounded-1", formError?.children && 'is-invalid')}
                                    id="children"
                                    placeholder="Enter no. of children"
                                    value={formData.children}
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            children: parseInt(e.target.value, 10),
                                        }));
                                    }}
                                />
                                {formError?.children && <div className="invalid-feedback">{formError.children}</div>}
                            </div>
                        </>
                    )}
                </div>
                <div className="row mb-3 align-items-center">
                    {isLoading ? (
                        <div className='col-sm-8 ms-auto'>
                            <div className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: '35px', display: 'block' }}></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label htmlFor="roomAvailable" className="col-sm-4 col-form-label d-flex justify-content-end">Set the no. room Available</label>
                            <div className="col-sm-8">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <input
                                        type="number"
                                        className={clsx("form-control app-form-control rounded-1", formError?.room_number && 'border-red')}
                                        id="roomAvailable"
                                        placeholder="Enter no. room available"
                                        value={roomCount}
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                        onChange={handleRoomAvailableChange}
                                    />
                                    {isRoomNumberChanged && (
                                        <button className='btn btn-outline-success btn-sm ms-2' onClick={handleSetRoomNumber}>
                                            <IconCheck size={16} />
                                        </button>
                                    )}
                                </div>
                                {formError?.room_number && <div className="invalid-feedback d-block">{formError.room_number}</div>}
                            </div>
                        </>
                    )}
                </div>
                {/* Room Number Inputs */}
                {formData.room_number.map((room, index) => (
                    <div className="row mb-3 align-items-center" key={index}>
                        {/* Label */}
                        <label
                            htmlFor={`roomNumber_${index}`}
                            className="col-sm-4 col-form-label d-flex justify-content-end"
                        >
                            Room {index + 1} Name
                        </label>

                        {/* Input dan Button */}
                        <div className="col-sm-8">
                            <div className="d-flex justify-content-between align-items-center">
                                {/* Input */}
                                <input
                                    type="text"
                                    className="form-control me-2" // Tambahkan margin-end (me-2) untuk jarak ke button
                                    id={`roomNumber_${index}`}
                                    placeholder={`Enter name for room ${index + 1}`}
                                    value={formData.room_number ? formData.room_number[index].room_number : ''}
                                    onChange={(e) => handleRoomNumberChange(e, index)}
                                    ref={(el) => (roomNumberRefs.current[index] = el)}
                                />

                                <RoomStatusButton room={room} handleAvailableRoom={handleAvailableRoom} roomAvailableIsLoading={roomAvailableIsLoading} />
                            </div>
                            {roomAvailableErrors.find((error) => error.roomId === room.id) && (
                                <div className="invalid-feedback d-block">
                                    {roomAvailableErrors.find((error) => error.roomId === room.id).message?.room_number || roomAvailableErrors.find((error) => error.roomId === room.id).message || 'Failed to update room status.'}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div >

            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    {isSubmitting ? 'Updating...' : 'Update Room Type'}
                </button>
            </div>
        </form >
    );
};

const RoomStatusButton = ({ room, handleAvailableRoom, roomAvailableIsLoading }) => {
    // Fungsi untuk merender tombol berdasarkan status
    const renderButton = (status) => {
        if (status === "available") {
            return (
                <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => handleAvailableRoom('closed', room.id, room.room_number)}
                >
                    <IconX size={16} />
                </button>
            );
        }

        if (status === "closed") {
            return (
                <button
                    className="btn btn-sm btn-outline-success"
                    onClick={() => handleAvailableRoom('available', room.id, room.room_number)}
                >
                    <IconCheck size={16} />
                </button>
            );
        }

        return null; // Tidak ada tombol jika status tidak cocok
    };

    return (
        <div>
            {roomAvailableIsLoading.includes(room.id) ? (
                <div className="spinner-border" role="status" aria-hidden="true"></div>
            ) : (
                renderButton(room.status)
            )}
        </div>
    );
};

const styles = {
    errorContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%", // Sesuaikan agar memuat penuh area Offcanvas
        color: "#6c757d",
    },
    icon: {
        fontSize: "4rem",
        marginBottom: "1rem",
    },
    errorText: {
        fontSize: "1.2rem",
        fontWeight: "bold",
    },
};