import { debounce, get } from "lodash";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "services/api";

const useUser = () => {
    const [userAssigned, setUserAssigned] = useState({
        form: {
            users: [],
        },
        data: [],
        loading: false,
        success: null,
        message: null,
    });

    const fetchUser = async (query = '') => {
        try {
            const response = await getRequest(`/user?search=${query}`);

            if (response.success) {
                return { success: true, data: response.data };
            } else {
                return { success: false, message: response.message };
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    const loadOptionsUser = debounce(async (inputValue, callback) => {
        if (!inputValue) return callback([]);

        try {
            const response = await fetchUser(inputValue);
            if (response.success) {
                const data = response.data;
                const options = data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
                callback(options);
            } else {
                callback([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            callback([]);
        }
    }, 500);

    const requestAssignUser = async (propertyId, userIds) => {
        try {
            const response = await postRequest(`/property/${propertyId}/sync`, {
                users: userIds.map((item) => item.value),
            });

            if (response.success) {
                return { success: true, message: response.message };
            } else {
                return { success: false, message: response.message };
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                setUserAssigned((prevState) => ({
                    ...prevState,
                    loading: true,
                }));
                const data = await fetchUser();
                if (data.success) {
                    setUserAssigned((prevState) => ({
                        ...prevState,
                        data: data.data.map((item) => ({
                            value: item.id,
                            label: item.name,
                        })),
                        loading: false,
                    }));
                } else {
                    setUserAssigned((prevState) => ({
                        ...prevState,
                        loading: false,
                    }));
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
                setUserAssigned((prevState) => ({
                    ...prevState,
                    loading: false,
                }));
            }
        };
        fetchInitialData();
    }, []);

    return {
        userAssigned,
        setUserAssigned,
        loadOptionsUser,
        requestAssignUser,
    };
};

export default useUser;