import { useEffect, useMemo, useState } from 'react';
import { getRequest } from 'services/api';
import { API_VERSION } from 'utils/constants';
import { convertDateToYYYYMMDD, formatToISO8601, get31DaysFromToday, get3DaysFromToday } from 'utils/date-utils';

const defaultCalendarStatus = {
    isLoading: false,
    error: null
};

const defaultCalendarSettings = {
    todayDate: get3DaysFromToday(),
    lastDate: get31DaysFromToday(),
    unsigned: false,
    fullCalendarFirstDay: get3DaysFromToday(),
    fullCalendarLastDay: get31DaysFromToday()
};

const useCalendarResourceHooks = () => {
    const [calendarSettings, setCalendarSettings] = useState(defaultCalendarSettings);

    const [calendarStatus, setCalendarStatus] = useState(defaultCalendarStatus);
    const [roomResource, setRoomResource] = useState([]);
    const [occupancyResource, setOccupancyResource] = useState([]);
    const [reservationResource, setReservationResource] = useState([]);
    const [unsignedList, setUnsignedList] = useState([]);

    const fetchRoomResource = async () => {
        try {
            const response = await getRequest(`/${API_VERSION}/room-resource?start_date=${calendarSettings.todayDate}&end_date=${calendarSettings.lastDate}`);
            if (response.success) {
                setRoomResource([...response.data]);
                setOccupancyResource([...response.occupancy]);
                setCalendarStatus((prevState) => ({
                    ...prevState,
                    error: null
                }));
            }
        } catch (error) {
            setRoomResource([]);
            setCalendarStatus((prevState) => ({
                ...prevState,
                error
            }));
        }
    };

    const resourcesPayload = useMemo(() => {
        return roomResource?.map((room, index) => {
            const mapResource = {
                id: room.id.toString(),
                title: room.title,
                children: [
                    ...room.children,
                    ...(calendarSettings.unsigned ? [{ id: `unsigned ${index}`, title: 'Unsigned Rooms' }] : [])
                ].filter(Boolean)
            }
            return mapResource;
        }) || [];
    }, [roomResource]);

    const occupancyPayload = useMemo(() => {
        const occupancyEvents = occupancyResource?.map(occupancy => {
            const mappedOccupancy = {
                occupancy: occupancy.occupancy,
                date: occupancy.date,
            }
            return mappedOccupancy;
        }) || [];
        return occupancyEvents;
    }, [occupancyResource]);

    const fetchReservationResource = async () => {
        try {
            const response = await getRequest(`/${API_VERSION}/booking-resource?start_date=${calendarSettings.todayDate}&end_date=${calendarSettings.lastDate}`);
            if (response.success) {
                setReservationResource([...response.data]);
                setCalendarStatus((prevState) => ({
                    ...prevState,
                    error: null
                }));
            }
        } catch (error) {
            setReservationResource([]);
            setCalendarStatus((prevState) => ({
                ...prevState,
                error
            }));
        }
    };

    const eventsPayload = useMemo(() => {
        return [
            ...reservationResource?.map(book => ({
                ...book,
                start: formatToISO8601(book.start),
                end: formatToISO8601(book.end),
            })) || [], // Tambahkan data frontOfficeData jika ada
            ...(roomResource?.flatMap(room =>
                room.available?.map(avail => ({
                    resourceId: room.id.toString(),
                    title: avail.available,
                    start: avail.date,
                    end: avail.date,
                    isRoom: true,
                    bgColor: '#67c17b',
                })) || []
            ) || []) // Fallback jika RoomsResource atau room.available undefined
        ];
    }, [roomResource, reservationResource]);

    const fetchUnsignedList = async () => {
        try {
            const response = await getRequest(`/${API_VERSION}/unassigned-room?from=${calendarSettings.todayDate}&to=${calendarSettings.lastDate}`);
            if (response.success) {
                setUnsignedList([...response.data]);
                setCalendarStatus((prevState) => ({
                    ...prevState,
                    error: null
                }));
            }
        } catch (error) {
            setUnsignedList([]);
            setCalendarStatus((prevState) => ({
                ...prevState,
                error
            }));
        }
    };

    const handleBackToToday = () => {
        setCalendarSettings((prevState) => ({
            ...prevState,
            todayDate: get3DaysFromToday(),
            lastDate: get31DaysFromToday()
        }));
    };

    const handlePrevMonth = () => {
        const today = new Date(calendarSettings.todayDate);
        today.setMonth(today.getMonth() - 1);

        const last = new Date(calendarSettings.lastDate);
        last.setMonth(last.getMonth() - 1);

        setCalendarSettings((prevState) => ({
            ...prevState,
            todayDate: convertDateToYYYYMMDD(today),
            lastDate: convertDateToYYYYMMDD(last)
        }));
    };

    const handleNextMonth = () => {
        const today = new Date(calendarSettings.todayDate);
        today.setMonth(today.getMonth() + 1);

        const last = new Date(calendarSettings.lastDate);
        last.setMonth(last.getMonth() + 1);

        setCalendarSettings((prevState) => ({
            ...prevState,
            todayDate: convertDateToYYYYMMDD(today),
            lastDate: convertDateToYYYYMMDD(last)
        }));
    };

    // Show/Hide unsigned rooms
    const handleToggleUnsigned = () => {
        setCalendarSettings((prevState) => ({
            ...prevState,
            unsigned: !prevState.unsigned
        }));
    };

    const refetchCalendarResource = () => {
        setCalendarStatus((prevState) => ({
            ...prevState,
            isLoading: true
        }));
        try {
            fetchRoomResource();
            fetchReservationResource();
            fetchUnsignedList();
        } catch (error) {
            setCalendarStatus((prevState) => ({
                ...prevState,
                error
            }));
        } finally {
            setCalendarStatus((prevState) => ({
                ...prevState,
                isLoading: false
            }));
        }
    };

    const expandCalendarByBooking = (reservation) => {
        const { start, end } = reservation;

        // Fungsi untuk menghapus jam dari tanggal
        const stripTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

        // Konversi tanggal dari reservation ke Date object (jika diperlukan)
        const reservationStart = stripTime(new Date(start));
        const reservationEnd = stripTime(new Date(end));

        // Dapatkan tanggal awal dan akhir dari calendarSettings
        const calendarStart = new Date(calendarSettings.fullCalendarFirstDay);
        const calendarEnd = new Date(calendarSettings.fullCalendarLastDay);

        // Periksa apakah reservation.start lebih kecil dari calendarStart
        if (reservationStart < calendarStart) {
            console.log('Expanding calendar start date to:', reservationStart);
            // calendarSettings.firstDay = reservationStart.toISOString().split('T')[0];
            setCalendarSettings((prevState) => ({
                ...prevState,
                fullCalendarFirstDay: reservationStart.toISOString().split('T')[0]
            }));
        }

        // Periksa apakah reservation.end lebih besar dari calendarEnd
        if (reservationEnd > calendarEnd) {
            // Menambahkan 1 hari ke reservationEnd
            reservationEnd.setDate(reservationEnd.getDate() + 1);

            console.log('Expanding calendar end date to:', reservationEnd);
            // calendarSettings.lastDay = reservationEnd.toISOString().split('T')[0];
            setCalendarSettings((prevState) => ({
                ...prevState,
                fullCalendarLastDay: reservationEnd.toISOString().split('T')[0]
            }));
        }
    };

    useEffect(() => {
        refetchCalendarResource();
    }, [calendarSettings.todayDate, calendarSettings.lastDate]);

    // useEffect(() => {
    //     if (reservationResource) {
    //         reservationResource.map((event) => {
    //             expandCalendarByBooking(event);
    //         });
    //     }
    // }, [reservationResource]);

    return {
        calendarSettings,
        setCalendarSettings,
        calendarStatus,
        setCalendarStatus,
        roomResource,
        setRoomResource,
        reservationResource,
        setReservationResource,
        unsignedList,
        setUnsignedList,
        refetchCalendarResource,
        resourcesPayload,
        occupancyPayload,
        eventsPayload,
        handleBackToToday,
        handlePrevMonth,
        handleNextMonth,
        handleToggleUnsigned
    };
};

export default useCalendarResourceHooks;