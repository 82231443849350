import { deleteRequest } from 'services/api';

const useRemoveRateplan = () => {
    const removeRateplan = async (rateplanId) => {
        try {
            const response = await deleteRequest(`/rate-plan/${rateplanId}`);
            if (response.success) {
                return { success: true, message: response.message };
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    return {
        removeRateplan,
    };
};

export default useRemoveRateplan;