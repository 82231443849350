import { IconExclamationCircle } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import clsx from 'clsx';
import Select from 'react-select';
import CurrencyInput from 'components/form/currency-input';
import { useRoomRate } from 'contexts/v2/pages/room-rate-setting/RoomRateContext';
import { toast } from 'react-toastify';

const CreateRate = ({ show, handleClose, onError, onSuccess }) => {
    return (
        <Offcanvas show={show} onHide={handleClose} placement='end' style={{ width: '45%' }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Create Rateplan</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <CreateRateForm onSuccess={onSuccess} onError={onError} />
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CreateRate;

const CreateRateForm = ({ onError, onSuccess }) => {
    const { roomIdWillCreateRateplan, createRateplan, getRoomTypeForRateplan } = useRoomRate();

    const [hasError, setHasError] = useState(false); // State error

    const [formData, setFormData] = useState({
        room_type: [],
        name: '',
        description: '',
        max_night: '',
        min_night: '',
        guest_include: '',
        guest_max: '',
        extra_adult: '',
        extra_child: '',
        extra_adult_rate: '',
        extra_child_rate: '',
        rate: '',
        min_rate: ''
    }); // State form data
    const [formError, setFormError] = useState(null); // State form error
    const [isSubmitting, setIsSubmitting] = useState(false); // State submitting

    const [roomTypeOptions, setRoomTypeOptions] = useState([]); // State room type options
    const [roomTypeOptionsLoading, setRoomTypeOptionsLoading] = useState(false); // State room type options loading

    const handleSetRoomTypeOptions = async () => {
        console.log('Fetching room type options...');
        setRoomTypeOptionsLoading(true);

        try {
            const response = await getRoomTypeForRateplan();
            console.log(response);
            if (response.success) {
                setRoomTypeOptions(response.data.map((roomType) => ({
                    value: roomType.id,
                    label: roomType.name
                })));
            } else {
                setFormError((prev) => ({ ...prev, room_type: response.message || 'Failed to get data room type' }));
            }
        } catch (error) {
            setFormError((prev) => ({ ...prev, room_type: error.message || 'Failed to get data room type' }));
        } finally {
            setRoomTypeOptionsLoading(false);
        }
    } // Function set room type options

    const handleRate = (value) => {
        // Jika min_rate kosong, tampilkan error hanya jika rate sudah diisi
        if (!formData.min_rate && value !== '') {
            setFormError((prev) => ({ ...prev, rate: 'Min Rate must be filled first' }));
            setFormData((prev) => ({ ...prev, rate: value })); // Update rate di sini
            return;
        }

        // Jika min_rate lebih besar dari rate, tampilkan error
        if (value && parseInt(value) < parseInt(formData.min_rate)) {
            setFormError((prev) => ({ ...prev, rate: 'Rate must be greater than or equal to Min Rate' }));
        } else {
            // Bersihkan error jika validasi berhasil
            setFormError((prev) => ({ ...prev, rate: null }));
        }

        // Selalu update rate setelah validasi
        setFormData((prev) => ({ ...prev, rate: value }));
    };

    useEffect(() => {
        // Validasi jika min_rate lebih besar dari rate
        if (formData.min_rate && formData.rate && parseInt(formData.min_rate) > parseInt(formData.rate)) {
            setFormError((prev) => ({ ...prev, rate: 'Rate must be greater than or equal to Min Rate' }));
        } else {
            setFormError((prev) => ({ ...prev, rate: null }));
        }
    }, [formData.min_rate, formData.rate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            // validasi jika min_rate lebih besar dari rate
            if (formData.min_rate && formData.rate && parseInt(formData.min_rate) > parseInt(formData.rate)) {
                // Add delay
                await new Promise((resolve) => setTimeout(resolve, 1000));
                setFormError((prev) => ({ ...prev, rate: 'Rate must be greater than or equal to Min Rate' }));
                setIsSubmitting(false);
                return;
            }

            const response = await createRateplan(formData);
            if (response.success) {
                onSuccess(response.message);
            } else {
                if (response.formError) {
                    toast.error(response.message || 'Validation error, please check your input');
                    setFormError((prev) => ({ ...prev, ...response.formError }));
                } else {
                    onError(response.message || 'Something went wrong, internal server error');
                }
            }
        } catch (error) {
            onError(error.message || 'Something went wrong, internal server error');
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (roomIdWillCreateRateplan) {
            setFormData((prev) => ({ ...prev, room_type: [roomIdWillCreateRateplan] }));
            handleSetRoomTypeOptions();
        } else {
            setHasError(true);
        }
    }, [roomIdWillCreateRateplan]);

    if (hasError) {
        return (
            <div style={styles.errorContainer}>
                <IconExclamationCircle style={styles.icon} size={72} />
                <p style={styles.errorText}>Failed to fetch room data</p>
            </div>
        )
    };

    return (
        <form onSubmit={(e) => handleSubmit(e)} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                {/* Room Type Multiple */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="room_type" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Room Type: </label>
                    <div className="col-sm-8">
                        {/* Select Multiple */}
                        <Select
                            id="room_type"
                            name="room_type"
                            options={roomTypeOptions}
                            value={roomTypeOptions.filter((option) => formData.room_type.includes(option.value))}
                            onChange={(selectedOption) => setFormData({
                                ...formData, room_type: [
                                    ...selectedOption.map((option) => option.value)
                                ]
                            })}
                            isMulti
                            isLoading={roomTypeOptionsLoading}
                            isDisabled={isSubmitting}
                        />
                    </div>
                </div>
                {/* Name */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Name: </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.name && 'is-invalid')}
                            id="name"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.name && <div className="invalid-feedback">{formError.name}</div>}
                    </div>
                </div>

                {/* Description */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="description" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Description: </label>
                    <div className="col-sm-8">
                        <textarea
                            className={clsx("form-control app-form-control rounded-1", formError?.description && 'is-invalid')}
                            id="description"
                            placeholder="Description"
                            name="description"
                            value={formData.description}
                            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px' }}
                        />
                        {formError?.description && <div className="invalid-feedback">{formError.description}</div>}
                    </div>
                </div>

                <h3 className="mt-6 border-bottom fw-normal">Rate Settings</h3>
                {/* Max Night */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="max_night" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Max Night: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.max_night && 'is-invalid')}
                            id="max_night"
                            placeholder="Max Night"
                            name="max_night"
                            value={formData.max_night}
                            onChange={(e) => setFormData({ ...formData, max_night: e.target.value })}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.max_night && <div className="invalid-feedback">{formError.max_night}</div>}
                    </div>
                </div>

                {/* Min Night */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="min_night" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Min Night: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.min_night && 'is-invalid')}
                            id="min_night"
                            placeholder="Min Night"
                            name="min_night"
                            value={formData.min_night}
                            onChange={(e) => setFormData({ ...formData, min_night: e.target.value })}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.min_night && <div className="invalid-feedback">{formError.min_night}</div>}
                    </div>
                </div>

                {/* Guest Include */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="guest_include" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Guest Include: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.guest_include && 'is-invalid')}
                            id="guest_include"
                            placeholder="Guest Include"
                            name="guest_include"
                            value={formData.guest_include}
                            onChange={(e) => setFormData({ ...formData, guest_include: e.target.value })}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.guest_include && <div className="invalid-feedback">{formError.guest_include}</div>}
                    </div>
                </div>

                {/* Guest Max */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="guest_max" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Guest Max: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.guest_max && 'is-invalid')}
                            id="guest_max"
                            placeholder="Guest Max"
                            name="guest_max"
                            value={formData.guest_max}
                            onChange={(e) => setFormData({ ...formData, guest_max: e.target.value })}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.guest_max && <div className="invalid-feedback">{formError.guest_max}</div>}
                    </div>
                </div>

                {/* Extra Adult */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_adult" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Adult: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.extra_adult && 'is-invalid')}
                            id="extra_adult"
                            placeholder="Extra Adult"
                            name="extra_adult"
                            value={formData.extra_adult}
                            onChange={(e) => setFormData({ ...formData, extra_adult: e.target.value })}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.extra_adult && <div className="invalid-feedback">{formError.extra_adult}</div>}
                    </div>
                </div>

                {/* Extra Child */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_child" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Child: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.extra_child && 'is-invalid')}
                            id="extra_child"
                            placeholder="Extra Child"
                            name="extra_child"
                            value={formData.extra_child}
                            onChange={(e) => setFormData({ ...formData, extra_child: e.target.value })}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.extra_child && <div className="invalid-feedback">{formError.extra_child}</div>}
                    </div>
                </div>

                <h3 className="mt-6 border-bottom fw-normal">Rate Price Settings</h3>
                {/* Extra Adult Rate */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_adult_rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Adult Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="extra_adult_rate"
                            className={clsx("app-form-control rounded-1", formError?.extra_adult_rate && 'is-invalid')}
                            value={formData.extra_adult_rate} // Bind value ke formData
                            onChange={(e, rawValue) => setFormData((prev) => ({ ...prev, extra_adult_rate: rawValue }))} // Simpan rawValue
                            error={formError?.extra_adult_rate} // Menampilkan error jika ada
                            placeholder="Rp. 0" // Placeholder Rupiah default
                        />
                    </div>
                </div>

                {/* Extra Child Rate */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_child_rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Child Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="extra_child_rate"
                            className={clsx("app-form-control rounded-1", formError?.extra_child_rate && 'is-invalid')}
                            value={formData.extra_child_rate} // Bind value ke formData
                            onChange={(e, rawValue) => setFormData((prev) => ({ ...prev, extra_child_rate: rawValue }))} // Simpan rawValue
                            error={formError?.extra_child_rate} // Menampilkan error jika ada
                            placeholder="Rp. 0" // Placeholder Rupiah default
                        />
                    </div>
                </div>

                {/* Min Rate */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="min_rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Min Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="min_rate"
                            className={clsx("app-form-control rounded-1", formError?.min_rate && 'is-invalid')}
                            value={formData.min_rate} // Bind value ke formData
                            onChange={(e, rawValue) => setFormData((prev) => ({ ...prev, min_rate: rawValue }))} // Simpan rawValue
                            error={formError?.min_rate} // Menampilkan error jika ada
                            placeholder="Rp. 0" // Placeholder Rupiah default
                        />
                    </div>
                </div>

                {/* Rate */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="rate"
                            className={clsx("app-form-control rounded-1", formError?.rate && 'is-invalid')}
                            value={formData.rate} // Bind value ke formData
                            onChange={(e, rawValue) => {
                                handleRate(rawValue);
                            }} // Simpan rawValue
                            error={formError?.rate} // Menampilkan error jika ada
                            placeholder="Rp. 0" // Placeholder Rupiah default
                        />
                    </div>
                </div>
            </div>

            {/* Submit Button */}
            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    {isSubmitting ? 'Creating...' : 'Create Rateplan'}
                </button>
            </div>
        </form>
    );
};

const styles = {
    errorContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%", // Sesuaikan agar memuat penuh area Offcanvas
        color: "#6c757d",
    },
    icon: {
        fontSize: "4rem",
        marginBottom: "1rem",
    },
    errorText: {
        fontSize: "1.2rem",
        fontWeight: "bold",
    },
};