import { useState } from 'react';
import { getRequest, patchRequest } from 'services/api';

const useUpdateRate = () => {
    const [rateIdWillUpdate, setRateIdWillUpdate] = useState(null);

    const updateRateplan = async (rateId, data) => {
        try {
            const response = await patchRequest(`/rate-plan/${rateId}`, data);
            if (response.success) {
                return { success: true, message: response.message };
            }
        } catch (error) {
            if (error.code === 422) {
                return { success: false, message: error.message, formError: error.errors };
            } else {
                return { success: false, message: error.message };
            }
        }
    };

    const fetchRoomTypesforUpdateRate = async () => {
        try {
            const response = await getRequest(`/room-type`);
            if (response.success) {
                return { success: true, data: response.data, message: response.message };
            } else {
                return { success: false, message: response.message || 'Failed to fetch room types' };
            }
        } catch (error) {
            return { success: false, message: error.message || 'Failed to fetch room types' };
        }
    };

    const fetchDefaultRateData = async (rateId) => {
        try {
            const response = await getRequest(`/rate-plan/${rateId}`);
            if (response.success) {
                return { success: true, data: response.data, message: response.message };
            } else {
                return { success: false, message: response.message || 'Failed to fetch default rate data' };
            }
        } catch (error) {
            return { success: false, message: error.message || 'Failed to fetch default rate data' };
        }
    };

    return {
        rateIdWillUpdate,
        setRateIdWillUpdate,
        updateRateplan,
        fetchDefaultRateData,
        fetchRoomTypesforUpdateRate
    };
};

export default useUpdateRate;