import useUpdatePropertyManagementHook from 'hooks/v2/pages/property-setting/useUpdatePropertyManagementHook';
import { createContext, useContext } from 'react';

const UpdatePropertyManagementContext = createContext();

export const UpdatePropertyManagementProvider = ({ children }) => {
    const UpdatePropertyManagement = useUpdatePropertyManagementHook();

    const contextValue = {
        ...UpdatePropertyManagement,
    };

    return (
        <UpdatePropertyManagementContext.Provider value={contextValue}>
            {children}
        </UpdatePropertyManagementContext.Provider>
    );
};

export const useUpdatePropertyManagement = () => {
    const context = useContext(UpdatePropertyManagementContext);
    if (context === undefined) {
        throw new Error('useUpdatePropertyManagement must be used within a UpdatePropertyManagementProvider');
    }
    return context;
};