import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getRequest } from 'services/api';
import clsx from 'clsx';

const PrimaryPhoneSelectComponent = ({ errorMessage, defaultValue, form, isSubmitting, handleChange }) => {
    const [options, setOptions] = useState([]);
    const [loadState, setLoadState] = useState({
        loading: false,
        success: false,
        message: errorMessage || null,
    });

    useEffect(() => {
        if (errorMessage) {
            setLoadState((prevState) => ({ ...prevState, message: errorMessage }));
        }
    }, [errorMessage]);

    useEffect(() => {
        const fetchOptions = async () => {
            setLoadState((prevState) => ({ ...prevState, loading: true }));
            try {
                const response = await getRequest('/dialcode-list');
                if (response.success && response.data) {
                    const formattedOptions = response.data.map((dialcode) => ({
                        value: dialcode.value,
                        label: dialcode.text,
                    }));
                    setOptions(formattedOptions);
                    setLoadState((prevState) => ({
                        ...prevState,
                        loading: false,
                        success: true,
                    }));
                } else {
                    setLoadState((prevState) => ({
                        ...prevState,
                        loading: false,
                        success: false,
                        message: response.message || 'Failed to load data.',
                    }));
                }
            } catch (error) {
                setLoadState((prevState) => ({
                    ...prevState,
                    loading: false,
                    success: false,
                    message: error.message || 'Failed to fetch data.',
                }));
            }
        };

        fetchOptions();
    }, []);

    const selectChange = (selectedOption) => {
        setLoadState((prevState) => ({ ...prevState, message: null }));
        handleChange({
            target: {
                name: 'primary_phone_country_code',
                value: selectedOption?.value || null,
            },
        });
    };

    const { loading, message, success } = loadState;

    return (
        <div className="row mb-3 align-items-center">
            <label
                htmlFor='primary_phone_country_code'
                className="col-sm-4 col-form-label d-flex justify-content-end"
            >
                Primary Phone
            </label>
            <div className="col-sm-4">
                <Select
                    options={options}
                    isLoading={loading}
                    onChange={selectChange}
                    value={options.find((option) => option.value === defaultValue)}
                    isClearable
                    placeholder="+62"
                />
            </div>
            <div className="col-sm-4">
                <input
                    type='text'
                    className={clsx("form-control app-form-control rounded-1", message && "is-invalid")}
                    id="primary_phone"
                    placeholder='81234567890'
                    name='primary_phone'
                    value={form.primary_phone}
                    onChange={handleChange}
                    disabled={isSubmitting}
                />
                {message && (
                    <small className="text-danger">{message}</small>
                )}
            </div>
        </div>
    );
}

export default PrimaryPhoneSelectComponent;