import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import { useCreateData, useUpdateData } from "hooks/useApiHooks";
import SimpleSelect from "components/form/simple-select";

const CreatePropertyPage = ({ initialData, onSuccess, onError }) => {
  const API_URL = "/property";

  // State untuk menyimpan form data. Jika initialData tersedia, isi form untuk update
  const [formData, setFormData] = useState({
    name: "",
    accommodation_type: "",
    preferred_currency: "",
    time_zone: "",
    primary_email: "",
    cc_emails: "",
    accounting_email: "",
    mobile_number_country_code: "",
    mobile_number: "",
    primary_phone_country_code: "",
    primary_phone: "",
    website: "",
    country: "",
    state: "",
    city: "",
    street_address: "",
    postal_code: "",
    prefix_booking_number: "",
    hotel_tax_number: "",
    enable_cvv: false,
  });

  const [selectedProvince, setSelectedProvince] = useState(null);

  const isUpdateMode = !!initialData; // Cek apakah ini update mode atau create mode

  // API hook untuk create dan update
  const { createData: createData, loading: creatingData } =
    useCreateData(API_URL);
  const { updateData: updateData, loading: updatingData } = useUpdateData(
    `${API_URL}/${initialData?.id}`
  );

  // Gunakan hook untuk menangani perubahan form
  const handleChange = useFormChangeHandler(setFormData);

  // Gunakan hook untuk menangani pengiriman form
  const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
    isUpdateMode ? updateData : createData, // Gunakan updateData jika dalam mode update
    onSuccess,
    onError
  );

  // Jika initialData ada, isi ulang form dengan data dari initialData
  useEffect(() => {
    if (initialData) {
      console.log(initialData);
      setFormData({
        name: initialData.name || "",
        accommodation_type: initialData.accommodation_type || "",
        accommodation_type_label: initialData.accommodation_type_label || "",
        // Transformasi menjadi format { value, label }
        // accommodation_type: initialData.accommodation_type
        //   ? { value: initialData.accommodation_type, label: initialData.accommodation_type_label } 
        //   : "",
        preferred_currency: initialData.preferred_currency || "",
        time_zone: initialData.time_zone || "",
        primary_email: initialData.primary_email || "",
        cc_emails: initialData.cc_emails || "",
        accounting_email: initialData.accounting_email || "",
        mobile_number_country_code:
          initialData.mobile_number_country_code || "",
        mobile_number: initialData.mobile_number || "",
        primary_phone_country_code:
          initialData.primary_phone_country_code || "",
        primary_phone: initialData.primary_phone || "",
        website: initialData.website || "",
        country: initialData.country || "",
        state: initialData.state || "",
        city: initialData.city || "",
        street_address: initialData.street_address || "",
        postal_code: initialData.postal_code || "",
        prefix_booking_number: initialData.prefix_booking_number || "",
        hotel_tax_number: initialData.hotel_tax_number || "",
        enable_cvv: initialData.enable_cvv || false,
      });
    //   console.log("formData",formData);
    }
  }, [initialData]);

  const HandleBeforeSubmit = (e) => {
    e.preventDefault();

    const payload = {
      name: formData?.name,
      accommodation_type: formData?.accommodation_type?.value,
      preferred_currency: formData?.preferred_currency?.value,
      time_zone: formData?.time_zone?.value,
      primary_email: formData?.primary_email,
      cc_emails: formData?.cc_emails,
      accounting_email: formData?.accounting_email,
      mobile_number_country_code: formData?.mobile_number_country_code?.value,
      mobile_number: formData?.mobile_number,
      primary_phone_country_code: formData?.primary_phone_country_code?.value,
      primary_phone: formData?.primary_phone,
      website: formData?.website,
      country: formData?.country?.label,
      state: formData?.state?.label,
      city: formData?.city?.label,
      street_address: formData?.street_address,
      postal_code: formData?.postal_code,
      prefix_booking_number: formData?.prefix_booking_number,
      hotel_tax_number: formData?.hotel_tax_number,
      enable_cvv: formData?.enable_cvv,
    };

    handleSubmit(payload, e);
  };

  return (
    <form
      onSubmit={(e) => HandleBeforeSubmit(e)}
      className="d-flex flex-column"
      style={{ height: "100%" }}
    >
      <div className="action-sheet-content flex-grow-1">
        {/* Name */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="name"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Name:{" "}
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className={clsx(
                "form-control app-form-control rounded-1",
                formError?.extra_child && "is-invalid"
              )}
              id="name"
              placeholder="Property Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {formError?.name && (
              <div className="invalid-feedback">{formError.name}</div>
            )}
          </div>
        </div>

        {/* Accommodation Type */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="accommodation_type"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Accommodation Type:{" "}
          </label>
          <div className="col-sm-8">
            <SimpleSelect
              url="/accommodation-list" // URL to fetch options
              value={formData.accommodation_type} // Selected value
              onChange={(selectedOption) =>
                setFormData({ ...formData, accommodation_type: selectedOption })
              }
              
              placeholder={formData.accommodation_type_label ? formData.accommodation_type_label : "Select Accommodation Type"}
              reload={false} // Set to true if you need to reload data
            />
            {formError?.accommodation_type && (
              <div className="invalid-feedback">
                {formError.accommodation_type}
              </div>
            )}
          </div>
        </div>

        {/* Preferred Currency */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="preferred_currency"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Preferred Currency:{" "}
          </label>
          <div className="col-sm-8">
            <SimpleSelect
              url="/currency-list" // URL to fetch options
              value={formData.preferred_currency} // Selected value
              onChange={(selectedOption) =>
                setFormData({ ...formData, preferred_currency: selectedOption })
              }
              valueKey="value" // Sesuaikan dengan format data
              labelKey="text" // Sesuaikan dengan format data
              placeholder="Select Currency"
              reload={false} // Set to true if you need to reload data
              isSearchable={true} // Enable search functionality
            />
            {formError?.preferred_currency && (
              <div className="invalid-feedback">
                {formError.preferred_currency}
              </div>
            )}
          </div>
        </div>

        {/* Time Zone */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="time_zone"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Time Zone:{" "}
          </label>
          <div className="col-sm-8">
            <SimpleSelect
              url="/timezone-list" // URL to fetch options
              value={formData.time_zone} // Selected value
              onChange={(selectedOption) =>
                setFormData({ ...formData, time_zone: selectedOption })
              }
              valueKey="value" // Sesuaikan dengan format data
              labelKey="text" // Sesuaikan dengan format data
              placeholder="Select Time Zone"
              reload={false} // Set to true if you need to reload data
              isSearchable={true} // Enable search functionality
              isDisabled={isSubmitting} // Disable select input
            />
            {formError?.time_zone && (
              <div className="invalid-feedback">{formError.time_zone}</div>
            )}
          </div>
        </div>

        <h3 className="mt-6 border-bottom fw-normal">Contacts Settings</h3>
        {/* Primary Email */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="primary_email"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Primary Email:{" "}
          </label>
          <div className="col-sm-8">
            <input
              type="email"
              className={clsx(
                "form-control",
                formError?.primary_email && "is-invalid"
              )}
              id="primary_email"
              placeholder="Primary Email"
              name="primary_email"
              value={formData.primary_email}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {formError?.primary_email && (
              <div className="invalid-feedback">{formError.primary_email}</div>
            )}
          </div>
        </div>

        {/* CC Emails */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="cc_emails"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            CC Emails:{" "}
          </label>
          <div className="col-sm-8">
            <input
              type="email"
              className={clsx(
                "form-control",
                formError?.cc_emails && "is-invalid"
              )}
              id="cc_emails"
              placeholder="CC Emails"
              name="cc_emails"
              value={formData.cc_emails}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {formError?.cc_emails && (
              <div className="invalid-feedback">{formError.cc_emails}</div>
            )}
          </div>
        </div>

        {/* Accounting Email */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="accounting_email"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Accounting Email:{" "}
          </label>
          <div className="col-sm-8">
            <input
              type="email"
              className={clsx(
                "form-control",
                formError?.accounting_email && "is-invalid"
              )}
              id="accounting_email"
              placeholder="Accounting Email"
              name="accounting_email"
              value={formData.accounting_email}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {formError?.accounting_email && (
              <div className="invalid-feedback">
                {formError.accounting_email}
              </div>
            )}
          </div>
        </div>

        {/* Mobile Number */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="mobile_number_country_code"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Mobile Number:{" "}
          </label>
          <div className="col-sm-4">
            <SimpleSelect
              url="/dialcode-list" // URL to fetch options
              value={formData.mobile_number_country_code} // Selected value
              onChange={(selectedOption) =>
                setFormData({
                  ...formData,
                  mobile_number_country_code: selectedOption,
                })
              }
              valueKey="value" // Sesuaikan dengan format data
              labelKey="text" // Sesuaikan dengan format data
              placeholder="+00"
              reload={false} // Set to true if you need to reload data
              isSearchable={true} // Enable search functionality
              isDisabled={isSubmitting} // Disable select input
            />
            {formError?.mobile_number_country_code && (
              <div className="invalid-feedback">
                {formError.mobile_number_country_code}
              </div>
            )}
          </div>
          <div className="col-sm-4">
            <input
              type="text"
              className={clsx(
                "form-control",
                formError?.mobile_number && "is-invalid"
              )}
              id="mobile_number"
              placeholder="Mobile Number"
              name="mobile_number"
              value={formData.mobile_number}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {formError?.mobile_number && (
              <div className="invalid-feedback">{formError.mobile_number}</div>
            )}
          </div>
        </div>

        {/* Primary Phone */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="primary_phone"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Primary Phone:{" "}
          </label>
          <div className="col-sm-4">
            <SimpleSelect
              url="/dialcode-list" // URL to fetch options
              value={formData.primary_phone_country_code} // Selected value
              onChange={(selectedOption) =>
                setFormData({
                  ...formData,
                  primary_phone_country_code: selectedOption,
                })
              }
              valueKey="value" // Sesuaikan dengan format data
              labelKey="text" // Sesuaikan dengan format data
              placeholder="+00"
              reload={false} // Set to true if you need to reload data
              isSearchable={true} // Enable search functionality
              isDisabled={isSubmitting} // Disable select input
            />
            {formError?.primary_phone_country_code && (
              <div className="invalid-feedback">
                {formError.primary_phone_country_code}
              </div>
            )}
          </div>
          <div className="col-sm-4">
            <input
              type="text"
              className={clsx(
                "form-control",
                formError?.primary_phone && "is-invalid"
              )}
              id="primary_phone"
              placeholder="Primary Phone"
              name="primary_phone"
              value={formData.primary_phone}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {formError?.primary_phone && (
              <div className="invalid-feedback">{formError.primary_phone}</div>
            )}
          </div>
        </div>

        {/* Website */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="website"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Website:{" "}
          </label>
          <div className="col-sm-8">
            <input
              type="url"
              className={clsx(
                "form-control",
                formError?.website && "is-invalid"
              )}
              id="website"
              placeholder="Website"
              name="website"
              value={formData.website}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {formError?.website && (
              <div className="invalid-feedback">{formError.website}</div>
            )}
          </div>
        </div>

        <h3 className="mt-6 border-bottom fw-normal">Location Settings</h3>
        {/* Country, State, City, Street Address, Postal Code */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="country"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Country:{" "}
          </label>
          <div className="col-sm-8">
            <SimpleSelect
              url="/country-list" // URL to fetch options
              value={formData.country} // Selected value
              onChange={(selectedOption) =>
                setFormData({ ...formData, country: selectedOption })
              }
              valueKey="value" // Sesuaikan dengan format data
              labelKey="text" // Sesuaikan dengan format data
              placeholder={formData.country ? formData.country : "Select Country"}
              reload={false} // Set to true if you need to reload data
              isSearchable={true} // Enable search functionality
              isDisabled={isSubmitting} // Disable select input
            />
            {formError?.country && (
              <div className="invalid-feedback">{formError.country}</div>
            )}
          </div>
        </div>

        <div className="row mb-3 align-items-center">
          <label
            htmlFor="state"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            State:{" "}
          </label>
          <div className="col-sm-8">
            <SimpleSelect
              url="/provinces" // URL to fetch options
              value={formData.state} // Selected value
              onChange={(selectedOption) => {
                setSelectedProvince(selectedOption);
                setFormData({ ...formData, state: selectedOption });
              }}
              valueKey="id" // Sesuaikan dengan format data
              labelKey="name" // Sesuaikan dengan format data
              placeholder={formData.state ? formData.state : "Select State"}
              reload={false} // Set to true if you need to reload data
              isSearchable={true} // Enable search functionality
              isDisabled={isSubmitting} // Disable select input
            />
            {formError?.state && (
              <div className="invalid-feedback">{formError.state}</div>
            )}
          </div>
        </div>

        <div className="row mb-3 align-items-center">
          <label
            htmlFor="city"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            City:{" "}
          </label>
          <div className="col-sm-8">
            <SimpleSelect
              url={`/regencies/${selectedProvince?.value}`} // URL to fetch options
              value={formData.city} // Selected value
              onChange={(selectedOption) =>
                setFormData({ ...formData, city: selectedOption })
              }
              valueKey="id" // Sesuaikan dengan format data
              labelKey="name" // Sesuaikan dengan format data
              placeholder={formData.city ? formData.city : "Select City"}
              reload={false} // Set to true if you need to reload data
              isSearchable={true} // Enable search functionality
              isDisabled={isSubmitting} // Disable select input
            />
            {formError?.city && (
              <div className="invalid-feedback">{formError.city}</div>
            )}
          </div>
        </div>

        <div className="row mb-3 align-items-center">
          <label
            htmlFor="street_address"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Street Address:{" "}
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className={clsx(
                "form-control",
                formError?.street_address && "is-invalid"
              )}
              id="street_address"
              placeholder="Street Address"
              name="street_address"
              value={formData.street_address}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {formError?.street_address && (
              <div className="invalid-feedback">{formError.street_address}</div>
            )}
          </div>
        </div>

        <div className="row mb-3 align-items-center">
          <label
            htmlFor="postal_code"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Postal Code:{" "}
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className={clsx(
                "form-control",
                formError?.postal_code && "is-invalid"
              )}
              id="postal_code"
              placeholder="Postal Code"
              name="postal_code"
              value={formData.postal_code}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {formError?.postal_code && (
              <div className="invalid-feedback">{formError.postal_code}</div>
            )}
          </div>
        </div>

        {/* Prefix Booking Number */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="prefix_booking_number"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Prefix Booking Number:{" "}
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className={clsx(
                "form-control",
                formError?.prefix_booking_number && "is-invalid"
              )}
              id="prefix_booking_number"
              placeholder="Prefix Booking Number"
              name="prefix_booking_number"
              value={formData.prefix_booking_number}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {formError?.prefix_booking_number && (
              <div className="invalid-feedback">
                {formError.prefix_booking_number}
              </div>
            )}
          </div>
        </div>

        {/* Hotel Tax Number */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="hotel_tax_number"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Hotel Tax Number:{" "}
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className={clsx(
                "form-control",
                formError?.hotel_tax_number && "is-invalid"
              )}
              id="hotel_tax_number"
              placeholder="Hotel Tax Number"
              name="hotel_tax_number"
              value={formData.hotel_tax_number}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {formError?.hotel_tax_number && (
              <div className="invalid-feedback">
                {formError.hotel_tax_number}
              </div>
            )}
          </div>
        </div>

        {/* Enable CVV */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="enable_cvv"
            className="col-sm-4 col-form-label d-flex justify-content-end"
          >
            Enable CVV:{" "}
          </label>
          <div className="col-sm-8 d-flex align-items-center">
            <input
              type="checkbox"
              className="form-check-input"
              id="enable_cvv"
              name="enable_cvv"
              checked={formData.enable_cvv}
              onChange={() =>
                setFormData({ ...formData, enable_cvv: !formData.enable_cvv })
              }
              disabled={isSubmitting}
            />
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <div className="form-group mt-auto d-grid">
        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={isSubmitting}
        >
          {isUpdateMode ? "Update Property" : "Create Property"}
        </button>
      </div>
    </form>
  );
};

export default CreatePropertyPage;
