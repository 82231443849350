import React from "react";
import HeaderFilter from "./components/HeaderFilter";
import AgentTable from "./components/AgentTable";
import ChartsSection from "./components/ChartsSection";
import { Col, Row } from "react-bootstrap";
import PieChart from "./components/PieChart";

const PropertyContent = () => {
  // Data dummy untuk chart
  const roomSoldData = {
    labels: ["BOOKING.COM", "WEBSITE", "PMS", "TIKET.COM", "EXPEDIA"],
    datasets: [
      {
        label: "Number of Room Sold",
        data: [89.9, 3.44, 3.44, 1.83, 1.38],
        backgroundColor: [
          "#007bff",
          "#ffc107",
          "#28a745",
          "#dc3545",
          "#17a2b8",
        ],
      },
    ],
  };

  const revenueData = {
    labels: ["BOOKING.COM", "WEBSITE", "PMS", "TIKET.COM", "EXPEDIA"],
    datasets: [
      {
        label: "Total Revenue",
        data: [90.1, 3.5, 3.4, 1.9, 1.1],
        backgroundColor: [
          "#007bff",
          "#ffc107",
          "#28a745",
          "#dc3545",
          "#17a2b8",
        ],
      },
    ],
  };
  const pieChartsData = [
    {
      title: "Number of Room Sold by Source of Agent",
      data: [
        { name: "Booking.com", value: 65 },
        { name: "website", value: 10 },
        { name: "PMS", value: 5 },
        { name: "Tiket.com", value: 5 },
        { name: "Expedia", value: 5 },
        { name: "Agoda", value: 5 },
        { name: "Airbnb", value: 5 },
      ],
    },
    {
      title: "Total Revenue by Source of Agent",
      data: [
        { name: "Booking.com", value: 88.6 },
        { name: "website", value: 1.3 },
        { name: "PMS", value: 0.7 },
        { name: "Tiket.com", value: 0.7 },
        { name: "Expedia", value: 0.7 },
        { name: "Agoda", value: 0.7 },
        { name: "Airbnb", value: 0.7 },
      ],
    },
  ];
  return (
    <div className="container mt-4">
      <HeaderFilter />
      <h3 className="mt-4">Agents Based</h3>
      <AgentTable />
      {/* <ChartsSection roomSoldData={roomSoldData} revenueData={revenueData} /> */}
      <Row className="mt-3 gy-4">
        {pieChartsData.map((chart, index) => (
          <Col key={index} md={6}>
            <PieChart title={chart.title} data={chart.data} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default PropertyContent;
