import { useEffect, useState, useCallback, useMemo } from "react";
import { getRequest } from "services/api";

const useRoomRateData = () => {
    const [roomRateData, setRoomRateData] = useState({
        data: [],
        loading: true,
        success: null,
        message: null,
        initialTimeLoaded: false,
    });

    // Fetch room rate data
    const fetchRoomRateData = useCallback(async () => {
        try {
            const response = await getRequest('/room-type?all=1');
            if (response.success) {
                return { success: true, data: response.data, message: response.message };
            } else {
                throw new Error(response.message || "Failed to fetch room rates");
            }
        } catch (error) {
            console.error("Error fetching room rate data:", error);
            return { success: false, message: error.message };
        }
    }, []);

    // Set room rate data to state
    const setRoomRateDataState = useCallback(async (isInitialLoad = false) => {
        const response = await fetchRoomRateData();
        setRoomRateData((prev) => ({
            ...prev,
            data: response.success ? response.data : [],
            loading: false,
            success: response.success,
            message: response.message,
            initialTimeLoaded: isInitialLoad ? true : prev.initialTimeLoaded,  // Update only on initial load
        }));
    }, [fetchRoomRateData]);

    // Filtered rooms, memoized for performance
    const filteredRoom = useMemo(() => {
        return (query) => {
            return roomRateData.data.filter((room) =>
                room.name.toLowerCase().includes(query.toLowerCase())
            );
        };
    }, [roomRateData.data]);

    // Fetch room data on initial load only
    useEffect(() => {
        if (!roomRateData.initialTimeLoaded) {
            setRoomRateDataState(true);  // Initial load flag
        }
    }, [roomRateData.initialTimeLoaded, setRoomRateDataState]);

    // Manual data refresh when performing actions like create, update, remove
    const refreshRoomData = async () => {
        setRoomRateData((prev) => ({ ...prev, loading: true }));  // Set loading to true for refresh
        await setRoomRateDataState();  // Fetch and update state
    };

    return {
        roomRateData,
        setRoomRateDataState,
        filteredRoom,
        refreshRoomData,  // Function to trigger data refresh manually
    };
};

export default useRoomRateData;