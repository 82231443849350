import { useCallback, useEffect, useState } from "react";
import { getRequest } from "services/api";
import { formatDateToString } from "utils/dateHelpers";
import { buildQueryString } from "utils/queryHelpers";
import { formatPrice } from "utils/utils";

const defaultOptionsStatus = [
    { value: 'confirmed', label: 'Confirmed' },
    { value: 'pending', label: 'Pending' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'modified', label: 'Modified' },
];

const defaultOptionsMark = [
    { value: 'normal', label: 'Normal' },
    { value: 'vip', label: 'VIP' },
    { value: 'blacklist', label: 'Blacklist' },
];

const defaultOptionsPaymentType = [
    { value: 'hotel_collect', label: 'Hotel Collect' },
    { value: 'channel_collect', label: 'Channel Collect' },
    { value: 'undefined', label: 'Undefined' },
];

const defaultOptionsAdults = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
];

const defaultOptionsChildren = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
];

const defaultRoomValues = {
    title: 'Booking Detail Room 1',
    arrival_date: formatDateToString(new Date()),
    departure_date: formatDateToString(new Date(new Date().setDate(new Date().getDate() + 1))),
    room_type_id: '',
    room_id: '',
    rate_plan_id: '',
    occupancy_adults: '1',
    occupancy_children: '0',
    roomTypeOptions: {
        data: [],
        error: null,
        isLoading: false,
    },
    roomOptions: {
        data: [],
        error: null,
    },
    rateplanOptions: {
        data: [],
        error: null,
        isLoading: false,
    },
    day: {
        data: [],
        error: null,
        isLoading: false,
    },
    error: null,
    show: false,
};

const defaultInvoiceForm = {
    custom_invoice_nb: '',
    invoice_date: '',
    new_guest: false,
    guest_no: '',
    booking_date: formatDateToString(new Date()),
    status: 'confirmed',
    mark: 'normal',
    payment_type: 'hotel_collect',
    first_name: '',
    last_name: '',
    email: '',
}

const useInvoiceTab = () => {
    const [invoiceForm, setInvoiceForm] = useState(defaultInvoiceForm);

    const [optionsStatus, setOptionsStatus] = useState(defaultOptionsStatus);
    const [optionsMark, setOptionsMark] = useState(defaultOptionsMark);
    const [optionsPaymentType, setOptionsPaymentType] = useState(defaultOptionsPaymentType);
    const [optionsAdults, setOptionsAdults] = useState(defaultOptionsAdults);
    const [optionsChildren, setOptionsChildren] = useState(defaultOptionsChildren);

    const { arrival_date, departure_date, ...restRoomValues } = defaultRoomValues;

    const [rooms, setRooms] = useState(
        invoiceForm.custom_invoice_nb ? [restRoomValues] : [defaultRoomValues]
    );

    const clearInvoiceTab = (booking_id = null) => {
        setInvoiceForm(defaultInvoiceForm);
        setRooms(booking_id ? [restRoomValues] : [defaultRoomValues]);
    };

    const defaultStatusOption = () => {
        const status = optionsStatus.find((option) => option.value === invoiceForm.status);
        return status;
    };

    const defaultMarkOption = () => {
        const mark = optionsMark.find((option) => option.value === invoiceForm.mark);
        return mark;
    };

    const defaultPaymentTypeOption = () => {
        const paymentType = optionsPaymentType.find((option) => option.value === invoiceForm.payment_type);
        return paymentType;
    }

    const defaultAdultsOption = (index) => {
        const adults = optionsAdults.find((option) => option.value === rooms[index].occupancy_adults);
        return adults;
    }

    const defaultChildrenOption = (index) => {
        const children = optionsChildren.find((option) => option.value === rooms[index].occupancy_children);
        return children;
    }

    const addRoom = () => {
        setRooms((prevRooms) => [
            ...prevRooms,
            {
                ...defaultRoomValues,
                title: `Booking Detail Room ${prevRooms.length + 1}`,
                arrival_date: prevRooms[0].arrival_date,
                departure_date: prevRooms[0].departure_date,
            },
        ]);
    }

    const removeRoom = (index) => {
        setRooms((prevRooms) => {
            const newRooms = [...prevRooms];
            newRooms.splice(index, 1);
            return newRooms;
        });
    }

    const resetRoomsByIndex = (index) => {
        setRooms((prevRooms) => {
            const newRooms = [...prevRooms];
            newRooms[index] = {
                ...defaultRoomValues,
                title: `Booking Detail Room ${index + 1}`,
                arrival_date: prevRooms[index].arrival_date,
                departure_date: prevRooms[index].departure_date,
                occupancy_adults: prevRooms[index].occupancy_adults,
                occupancy_children: prevRooms[index].occupancy_children,
            };
            return newRooms;
        });
    };

    const fetchRoomTypes = useCallback(
        async (index) => {
            console.log('FetchRoomTypesIndex:', index);
            if (index === undefined || index === null) {
                return;
            }
            if (
                rooms[index].arrival_date &&
                rooms[index].departure_date &&
                rooms[index].occupancy_adults &&
                rooms[index].occupancy_children
            ) {
                resetRoomsByIndex(index);

                const queryStringRoomTypes = buildQueryString({
                    checkin: rooms[index].arrival_date,
                    checkout: rooms[index].departure_date,
                    adult: rooms[index].occupancy_adults,
                    children: rooms[index].occupancy_children,
                });

                setRooms((prevRooms) => {
                    const newRooms = [...prevRooms];
                    newRooms[index].roomTypeOptions.isLoading = true;
                    return newRooms;
                });

                try {
                    const response = await getRequest(`/v2/check-room-rate${queryStringRoomTypes}`);

                    if (response.success) {
                        setRooms((prevRooms) => {
                            const newRooms = [...prevRooms];
                            newRooms[index].roomTypeOptions.data = response.data.map((roomType) => ({
                                value: roomType.id,
                                label: roomType.name,
                                originalData: roomType,
                            }));
                            newRooms[index].roomTypeOptions.isLoading = false;
                            return newRooms;
                        });
                    }
                } catch (error) {
                    setRooms((prevRooms) => {
                        const newRooms = [...prevRooms];
                        newRooms[index].roomTypeOptions.error = error;
                        newRooms[index].roomTypeOptions.isLoading = false;
                        return newRooms;
                    });
                }
            }
        },
        [rooms, setRooms]
    );

    const fetchRoomTypesInUpdate = useCallback(
        async (index, booking_id) => {
            if (index === undefined || index === null) {
                console.error('Index is missing');
                return;
            }
            if (
                rooms[index].arrival_date &&
                rooms[index].departure_date &&
                rooms[index].occupancy_adults &&
                rooms[index].occupancy_children
            ) {
                const queryStringRoomTypes = buildQueryString({
                    checkin: rooms[index].arrival_date,
                    checkout: rooms[index].departure_date,
                    adult: rooms[index].occupancy_adults,
                    children: rooms[index].occupancy_children,
                    booking_id: booking_id
                });

                setRooms((prevRooms) => {
                    const newRooms = [...prevRooms];
                    newRooms[index].roomTypeOptions.isLoading = true;
                    return newRooms;
                });

                try {
                    const response = await getRequest(`/v2/check-room-rate${queryStringRoomTypes}`);

                    if (response.success) {
                        const selectedRoomType = response.data.find((roomType) => roomType.id === rooms[index].room_type_id);
                        console.log('SelectedRoomType:', selectedRoomType);

                        if (selectedRoomType) {
                            setRooms((prevRooms) => {
                                const newRooms = [...prevRooms];
                                newRooms[index].roomTypeOptions.data = response.data.map((roomType) => ({
                                    value: roomType.id,
                                    label: roomType.name,
                                    originalData: roomType,
                                }));
                                newRooms[index].roomTypeOptions.isLoading = false;
                                newRooms[index].roomOptions.data = optionsForRoomNumberByRoomType(selectedRoomType);
                                newRooms[index].rateplanOptions.data = filterRateplanByRoomTypeId(selectedRoomType);
                                return newRooms;
                            });
                        }
                    }
                } catch (error) {
                    setRooms((prevRooms) => {
                        const newRooms = [...prevRooms];
                        newRooms[index].roomTypeOptions.error = error;
                        newRooms[index].roomTypeOptions.isLoading = false;
                        return newRooms;
                    });
                }
            }
        },
        [rooms, setRooms]
    );

    const defaultRoomTypeOption = (index) => {
        const roomType = rooms[index].roomTypeOptions.data.find((option) => option.value === rooms[index].room_type_id);
        return roomType || null;
    }

    const optionsForRoomNumberByRoomType = (room_type_original_data) => {
        if (room_type_original_data.rooms.length === 0) {
            return [{ value: '', label: 'Please unassign your room manually later.', originalData: null }];
        }
        return room_type_original_data.rooms.map((room) => ({
            value: room.id,
            label: room.room_number,
            originalData: room,
        }));
    };

    const defaultRoomNumberOption = (index) => {
        const roomNumber = rooms[index].roomOptions.data.find((option) => option.value === rooms[index].room_id);
        return roomNumber || null;
    };

    const filterRateplanByRoomTypeId = (room_type_original_data) => {
        return room_type_original_data.rate_plans.map((rateplan) => ({
            value: rateplan.id,
            label: rateplan.name,
            originalData: rateplan,
        }));
    };

    const selectRoomType = (index, selectedOption) => {
        setRooms((prevRooms) => {
            const newRooms = [...prevRooms];
            newRooms[index].room_type_id = selectedOption.value;
            newRooms[index].room_id = '';
            newRooms[index].rate_plan_id = '';
            newRooms[index].roomOptions.data = optionsForRoomNumberByRoomType(selectedOption.originalData);
            newRooms[index].rateplanOptions.data = filterRateplanByRoomTypeId(selectedOption.originalData);
            newRooms[index].day = {
                data: [],
                error: null,
                isLoading: false,
            };
            return newRooms;
        });
    };

    const selectRoomNumber = (index, selectedOption) => {
        // Cek apakah room_id sudah dipilih di kamar lain
        const isRoomIdAlreadySelected = rooms.some(
            (room, i) => i !== index && room.room_id === selectedOption.value
        );

        if (isRoomIdAlreadySelected) {
            setRooms((prevRooms) => {
                const newRooms = [...prevRooms];
                newRooms[index] = {
                    ...newRooms[index], // Salin objek di index tersebut
                    roomOptions: {
                        ...newRooms[index].roomOptions, // Salin objek roomOptions
                        error: `Room number ${selectedOption.originalData.room_number} already selected in another room.`,
                    },
                };
                return newRooms;
            });
            return;
        }

        setRooms((prevRooms) => {
            const newRooms = [...prevRooms];
            newRooms[index] = {
                ...newRooms[index], // Salin objek di index tersebut
                room_id: selectedOption.value,
                roomOptions: {
                    ...newRooms[index].roomOptions, // Salin objek roomOptions
                    error: null,
                },
            };
            return newRooms;
        });
    };

    const fetchRateplansDays = useCallback(
        async (index) => {
            // Periksa apakah rate_plan_id valid
            if (!rooms[index]?.rate_plan_id) {
                console.error(`Rate plan ID is missing for room at index ${index}`);
                return;
            }

            setRooms((prevRooms) => {
                const newRooms = [...prevRooms];
                newRooms[index].day.isLoading = true;
                return newRooms;
            });

            const queryStringRateplans = buildQueryString({
                checkin: rooms[index].arrival_date,
                checkout: rooms[index].departure_date,
                rate_plan_id: rooms[index].rate_plan_id,
            });

            try {
                const response = await getRequest(`/v2/show-rate${queryStringRateplans}`);

                if (response.success) {
                    setRooms((prevRooms) => {
                        const newRooms = [...prevRooms];
                        newRooms[index].day.data = response.data.map((day) => ({
                            date: day.date,
                            rate: day.rate,
                        }));
                        newRooms[index].day.isLoading = false;
                        return newRooms;
                    });
                }
            } catch (error) {
                setRooms((prevRooms) => {
                    const newRooms = [...prevRooms];
                    newRooms[index].day.error = error;
                    newRooms[index].day.isLoading = false;
                    return newRooms;
                });
            }
        },
        [rooms, setRooms] // Dependencies untuk menjaga stabilitas fungsi
    );

    const defaultRateplanOption = (index) => {
        const rateplan = rooms[index].rateplanOptions.data.find((option) => option.value === rooms[index].rate_plan_id);
        return rateplan;
    };

    const getRoomTotal = (index) => {
        return rooms[index].day.data.reduce((total, day) => total + Number(day.rate), 0);
    };

    const getAllRoomsTotal = () => {
        // return rooms.reduce((total, room) => total + Number(room.day.reduce((total, day) => total + Number(day.rate), 0)), 0);
        return rooms.reduce((total, room, index) => total + getRoomTotal(index), 0);
    };

    const getTotalOccupancyAdults = () => {
        return rooms.reduce((total, room) => total + Number(room.occupancy_adults), 0);
    };

    const getTotalOccupancyChildren = () => {
        return rooms.reduce((total, room) => total + Number(room.occupancy_children), 0);
    };

    const handleDateRangeChange = (index, start_date, end_date) => {
        setRooms((prevRooms) => {
            const newRooms = [...prevRooms];
            newRooms[index] = {
                ...newRooms[index],
                arrival_date: start_date,
                departure_date: end_date,
            };
            return newRooms;
        });
    };

    const handleDateClickChange = (index, date) => {
        const nextDay = new Date(date);
        nextDay.setDate(nextDay.getDate() + 1);

        setRooms((prevRooms) => {
            const newRooms = [...prevRooms];
            newRooms[index] = {
                ...newRooms[index],
                arrival_date: date,
                departure_date: formatDateToString(nextDay),
            };
            return newRooms;
        });
    };

    return {
        clearInvoiceTab,
        invoiceForm,
        setInvoiceForm,
        optionsStatus,
        defaultStatusOption,
        optionsMark,
        defaultMarkOption,
        rooms,
        setRooms,
        addRoom,
        removeRoom,
        optionsPaymentType,
        defaultPaymentTypeOption,
        defaultRoomTypeOption,
        selectRoomType,
        defaultRoomNumberOption,
        defaultRateplanOption,
        optionsAdults,
        defaultAdultsOption,
        optionsChildren,
        defaultChildrenOption,
        fetchRateplansDays,
        getRoomTotal,
        getAllRoomsTotal,
        getTotalOccupancyAdults,
        getTotalOccupancyChildren,
        optionsForRoomNumberByRoomType,
        selectRoomNumber,
        fetchRoomTypes,
        fetchRoomTypesInUpdate,
        handleDateRangeChange,
        handleDateClickChange,
    };
};

export default useInvoiceTab;