import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useCreateData, useUpdateData } from "hooks/useApiHooks";

const CreateAgentPage = ({ initialData, onSuccess, onError }) => {
    const [formData, setFormData] = useState({
        name: '',
        color: '#000000',
        website: '',
        email: '',
        phone: '',
    });

    const isUpdateMode = !!initialData;

    const { createData: createAgent } = useCreateData('/agent');
    const { updateData: updateAgent } = useUpdateData(`/agent/${initialData?.id}`);

    const handleChange = useFormChangeHandler(setFormData);

    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        isUpdateMode ? updateAgent : createAgent,
        onSuccess,
        onError
    );

    useEffect(() => {
        if (initialData) {
            setFormData({
                name: initialData.name || '',
                color: initialData.color || '#000000', // Set initial color value if provided
                website: initialData.website || '',
                email: initialData.email || '',
                phone: initialData.phone || '',
            });
        }
    }, [initialData]);

    return (
        <form onSubmit={(e) => handleSubmit(formData, e)} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                {/* Name */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Name: </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.name && 'is-invalid')}
                            id="name"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.name && <div className="invalid-feedback">{formError.name}</div>}
                    </div>
                </div>

                {/* Color */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="color" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>
                        Color:
                    </label>
                    <div className="col-sm-8 d-flex align-items-center">
                        {/* Color Picker */}
                        <input
                            type="color"
                            className={clsx("form-control app-form-control rounded-1", formError?.color && 'is-invalid')}
                            id="color"
                            name="color"
                            value={formData.color}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ width: '45px', height: '35px', padding: 0, border: 'none' }}
                        />
                        {/* Text Input for Color */}
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1 ms-2", formError?.color && 'is-invalid')}
                            name="colorText"
                            value={formData.color}
                            onChange={(e) => setFormData((prev) => ({ ...prev, color: e.target.value }))}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px', width: '100px' }}
                        />
                        {formError?.color && <div className="invalid-feedback">{formError.color}</div>}
                    </div>
                </div>

                {/* Website */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="website" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Website: </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.website && 'is-invalid')}
                            id="website"
                            placeholder="Website"
                            name="website"
                            value={formData.website}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.website && <div className="invalid-feedback">{formError.website}</div>}
                    </div>
                </div>

                {/* Email */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="email" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Email: </label>
                    <div className="col-sm-8">
                        <input
                            type="email"
                            className={clsx("form-control app-form-control rounded-1", formError?.email && 'is-invalid')}
                            id="email"
                            placeholder="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.email && <div className="invalid-feedback">{formError.email}</div>}
                    </div>
                </div>

                {/* Phone */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="phone" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Phone: </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.phone && 'is-invalid')}
                            id="phone"
                            placeholder="Phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.phone && <div className="invalid-feedback">{formError.phone}</div>}
                    </div>
                </div>
            </div>

            {/* Submit Button */}
            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    {isUpdateMode ? 'Update Agent' : 'Create Agent'}
                </button>
            </div>
        </form>
    );
};

export default CreateAgentPage;
