import React, { useEffect, useState } from 'react';
import { Dropdown, Offcanvas, Tab, Tabs } from 'react-bootstrap';
import { getRequest } from 'services/api';
import { convertCurrency } from 'utils/utils';
import { useCanvasHandler } from 'hooks/useCanvasHandler';
import AsignRoomPage from './asign-room-page';
import ShortReservation from '../../calendar/components/detail-reservation/short-reservation';
import { useReservation } from 'contexts/v2/pages/reservation/ReservationContext';
import CreateReservationPage from '../v2/create-reservation-page';
import { useUpdateReservation } from 'contexts/v2/pages/reservation/UpdateReservationContext';
import GuestDetails from '../v2/components/edit/guest-details-tab';
import Swal from 'sweetalert2';
import InvoiceTab from '../v2/components/edit/invoice-tab';
import Comments from '../v2/components/edit/comments-tab';
import { formatDateToDayMonthYear } from 'utils/dateHelpers';

const ReservationItems = () => {
    const { badgeOptions, reservation } = useReservation();
    const { handleShowCanvas } = useUpdateReservation();

    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    // Handle assign reservation room
    const handleAssignRoom = (e, data) => {
        e.preventDefault();
        showCanvasHandler('Assign Room',
            <AsignRoomPage
                onSuccess={handleSuccess}
                onError={handleError}
                initialData={data}
            />
        );
    }

    // Handle view reservation
    const handleViewReservation = (e, booking_id) => {
        e.preventDefault();
        showCanvasHandler('View Reservation',
            <ShortReservation bookingId={booking_id} onSuccess={handleSuccess} onError={handleError} />
        );
    }

    const getBadgeColor = (status) => {
        // find badge color if not found use unconfirmed
        const badge = badgeOptions.find((badge) => badge.value === status);
        return badge ? badge.color : 'unconfirmed';
    }

    return (
        <>
            {/* Table */}
            <div className="table-wrapper position-relative">
                {reservation.filterLoading &&
                    (
                        <div class="spinner-overlay table-spinner-overlay active">
                            <div class="spinner-border table-spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )
                }

                <table className="table table-hover table-vcenter">
                    <thead>
                        <tr>
                            <th>Reservation</th>
                            <th>OTA Name</th>
                            <th>Created</th>
                            <th>Arrival</th>
                            <th>Departure</th>
                            <th>Guest Name</th>
                            <th>Room</th>
                            <th>Rateplan</th>
                            <th>Due Amount</th>
                            <th>Deposit Amount</th>
                            <th>Assign Room</th>
                            <th>Status</th>
                            <th className="text-end">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(!reservation.data || reservation.data.length === 0) ? (
                            <tr>
                                <td colSpan="13" className="text-center">No data found</td>
                            </tr>
                        ) : (
                            reservation.data.map((reservation, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td>
                                            <span className={`badge bg-${getBadgeColor(reservation.status)} me-2 text-${getBadgeColor(reservation.status)}`}>#</span>
                                            {`${reservation.invoice_number}`}
                                        </td>
                                        <td>{reservation.ota_name}</td>
                                        <td>{formatDateToDayMonthYear(reservation.booking_date)}</td>
                                        <td>{formatDateToDayMonthYear(reservation.checkin_date)}</td>
                                        <td>{formatDateToDayMonthYear(reservation.checkout_date)}</td>
                                        <td>{`${reservation.customer_name} ${reservation.customer_surname}`}</td>
                                        <td>{reservation.room_type.name}</td>
                                        <td>{reservation.rate_plan.name}</td>
                                        <td>{reservation.currency ? (convertCurrency(reservation.due_payment, reservation.currency)) : reservation.due_payment}</td>
                                        <td>{reservation.currency ? (convertCurrency(reservation.down_payment, reservation.currency)) : reservation.down_payment}</td>
                                        <td>{reservation.room.room_number}</td>
                                        <td>{reservation.status}</td>
                                        <td className="text-end">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="default" id="dropdownMenuButton1">
                                                    Actions
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {/* <Dropdown.Item href="#" onClick={(e) => handleViewReservation(e, reservation.id)}>View</Dropdown.Item> */}
                                                    <Dropdown.Item href="#" onClick={(e) => handleShowCanvas(reservation.id)}>Edit</Dropdown.Item>
                                                    {/* <Dropdown.Item href="#" onClick={(e) => handleAssignRoom(e, reservation)}>Assign Room</Dropdown.Item> */}
                                                    <Dropdown.Item href="#">Remove</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {/* <UpdateReservationProvider>
                <EditReservationPage reservationsCanvas={reservationsCanvas} setReservationsCanvas={setReservationsCanvas} />
            </UpdateReservationProvider> */}
            <CanvasForEditReservation />
        </>
    );
};

export default ReservationItems;

const CanvasForEditReservation = () => {
    const {
        reservationsCanvas,
        setReservationsCanvas,
        guestDetailsForm,
        setGuestDetailsForm,
        countryOptions,
        defaultOptionsCountry,
        currencyOptions,
        defaultOptionsCurrency,
        agentOptions,
        defaultOptionsAgent,
        birthdayOptions,
        defaultOptionsBirthday,
        commentsForm,
        setCommentsForm,
        invoiceForm,
        setInvoiceForm,
        optionsStatus,
        defaultStatusOption,
        optionsMark,
        defaultMarkOption,
        optionsPaymentType,
        defaultPaymentTypeOption,
        rooms,
        setRooms,
        addRoom,
        removeRoom,
        defaultRoomTypeOption,
        selectRoomType,
        defaultRoomNumberOption,
        defaultRateplanOption,
        optionsAdults,
        defaultAdultsOption,
        optionsChildren,
        defaultChildrenOption,
        fetchRateplansDays,
        getRoomTotal,
        handleUpdateReservation,
        selectRoomNumber,
        fetchRoomTypes,
        fetchRoomTypesInUpdate
    } = useUpdateReservation();

    console.log('reservationsCanvas', reservationsCanvas);

    return (
        <Offcanvas show={reservationsCanvas.show} onHide={() => {
            setReservationsCanvas((prevState) => ({ ...prevState, show: false }));
        }} placement='end' style={{ width: '100%' }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Update Reservation</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="pt-3">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdateReservation().then(async (response) => {
                        if (response.success) {
                            setReservationsCanvas((prevState) => ({ ...prevState, show: false }));
                            const result = await Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: 'Reservation updated successfully',
                                showConfirmButton: true,
                                confirmButtonText: 'OK',
                            });
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response.message,
                            });
                            console.error(response.errors);
                        }
                    });
                }}>
                    <Tabs
                        defaultActiveKey='invoice'
                        className="mb-3 text-blue"
                        variant="underline"
                    >
                        <Tab eventKey='invoice' title='Invoice'>
                            <InvoiceTab
                                reservationsCanvas={reservationsCanvas}
                                invoiceForm={invoiceForm}
                                setInvoiceForm={setInvoiceForm}
                                optionsStatus={optionsStatus}
                                defaultStatusOption={defaultStatusOption}
                                optionsMark={optionsMark}
                                defaultMarkOption={defaultMarkOption}
                                optionsPaymentType={optionsPaymentType}
                                defaultPaymentTypeOption={defaultPaymentTypeOption}
                                rooms={rooms}
                                setRooms={setRooms}
                                removeRoom={removeRoom}
                                defaultRoomTypeOption={defaultRoomTypeOption}
                                selectRoomType={selectRoomType}
                                defaultRoomNumberOption={defaultRoomNumberOption}
                                defaultRateplanOption={defaultRateplanOption}
                                optionsAdults={optionsAdults}
                                defaultAdultsOption={defaultAdultsOption}
                                optionsChildren={optionsChildren}
                                defaultChildrenOption={defaultChildrenOption}
                                fetchRateplansDays={fetchRateplansDays}
                                getRoomTotal={getRoomTotal}
                                selectRoomNumber={selectRoomNumber}
                                fetchRoomTypes={fetchRoomTypes}
                                fetchRoomTypesInUpdate={fetchRoomTypesInUpdate}
                            />
                        </Tab>
                        <Tab eventKey='guest_details' title='Guest Details'>
                            <GuestDetails
                                guestDetailsForm={guestDetailsForm}
                                setGuestDetailsForm={setGuestDetailsForm}
                                countryOptions={countryOptions}
                                defaultOptionsCountry={defaultOptionsCountry}
                                currencyOptions={currencyOptions}
                                defaultOptionsCurrency={defaultOptionsCurrency}
                                agentOptions={agentOptions}
                                defaultOptionsAgent={defaultOptionsAgent}
                                birthdayOptions={birthdayOptions}
                                defaultOptionsBirthday={defaultOptionsBirthday}
                            />
                        </Tab>
                        <Tab eventKey='comments' title='Comments'>
                            <Comments commentsForm={commentsForm} setCommentsForm={setCommentsForm} />
                        </Tab>
                    </Tabs>

                    {/* button flex between */}
                    <div className="my-4 border-bottom"></div>
                    <div className="d-flex flex-column justify-content-end gap-2">
                        <div className="d-flex justify-content-between gap-2">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    addRoom();
                                }}
                            >
                                Add Room
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Update Reservation
                            </button>
                        </div>
                    </div>
                </form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};