import { useState } from 'react';
import { getRequest, patchRequest } from 'services/api';

const useUpdateRoom = () => {
    const [updateDefaultData, setUpdateDefaultData] = useState(null);
    const [updateDefaultLoading, setUpdateDefaultLoading] = useState(false);
    const [roomIdWillUpdate, setRoomIdWillUpdate] = useState(null);

    const fetchDefaultUpdateData = async (roomId) => {
        try {
            const response = await getRequest(`/room-type/${roomId}`);
            if (response.success) {
                return { success: true, data: response.data, message: response.message };
            } else {
                return { success: false, message: response.message };
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    }

    const updateDefaultRoom = async (roomId, data) => {
        try {
            const response = await patchRequest(`/room-type/${roomId}`, data);
            if (response.success) {
                return { success: true, message: response.message };
            }
        } catch (error) {
            if (error.code === 422) {
                return { success: false, message: error.message, formError: error.errors };
            } else {
                return { success: false, message: error.message };
            }
        }
    };

    const updateAvailableRoom = async (roomId, data) => {
        try {
            const response = await patchRequest(`/room-status/${roomId}`, data);
            if (response.success) {
                return { success: true, message: response.message };
            }
        } catch (error) {
            return { success: false, message: error.message || error.errors };
        }
    };

    return {
        updateDefaultData,
        updateDefaultLoading,
        fetchDefaultUpdateData,
        updateDefaultRoom,
        roomIdWillUpdate,
        setRoomIdWillUpdate,
        updateAvailableRoom
    };
};

export default useUpdateRoom;