import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getRequest } from 'services/api';

const AccommodationTypeSelectComponent = ({ errorMessage, defaultValue, handleChange }) => {
    const [options, setOptions] = useState([]);
    const [loadState, setLoadState] = useState({
        loading: false,
        success: false,
        message: null,
    });

    useEffect(() => {
        if (errorMessage) {
            setLoadState((prevState) => ({ ...prevState, message: errorMessage }));
        }
    }, [errorMessage]);

    useEffect(() => {
        const fetchOptions = async () => {
            setLoadState((prevState) => ({ ...prevState, loading: true }));
            try {
                const response = await getRequest('/accommodation-list');
                if (response.success && response.data) {
                    const formattedOptions = Object.entries(response.data).map(([key, value]) => ({
                        value: key,
                        label: value,
                    }));
                    setOptions(formattedOptions);
                    setLoadState((prevState) => ({
                        ...prevState,
                        loading: false,
                        success: true,
                    }));
                } else {
                    setLoadState((prevState) => ({
                        ...prevState,
                        loading: false,
                        success: false,
                        message: response.message || 'Failed to load data.',
                    }));
                }
            } catch (error) {
                setLoadState((prevState) => ({
                    ...prevState,
                    loading: false,
                    success: false,
                    message: error.message || 'Failed to fetch data.',
                }));
            }
        };

        fetchOptions();
    }, []);

    const selectChange = (selectedOption) => {
        setLoadState((prevState) => ({ ...prevState, message: null }));
        handleChange({
            target: {
                name: 'accommodation_type',
                value: selectedOption?.value || null,
            },
        });
    };

    const { loading, message, success } = loadState;

    return (
        <div className="row mb-3 align-items-center">
            <label
                htmlFor="accommodation_type"
                className="col-sm-4 col-form-label d-flex justify-content-end"
            >
                Accommodation Type:
            </label>
            <div className="col-sm-8">
                <Select
                    options={options}
                    isLoading={loading}
                    onChange={selectChange}
                    value={options.find((option) => option.value === String(defaultValue))}
                    isClearable
                    placeholder="Select accommodation type"
                    isDisabled={loading}
                />
                {message && (
                    <small className="text-danger">{message}</small>
                )}
            </div>
        </div>
    );
};

export default AccommodationTypeSelectComponent;