import React from "react";
import { Line } from "react-chartjs-2";

const OccupancyChart = () => {
  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Persentase Okupansi",
        data: [30, 40, 35, 50, 55, 60, 70, 65, 80, 85, 90, 95],
        borderColor: "#007bff",
        backgroundColor: "rgba(0, 123, 255, 0.2)",
        borderWidth: 2,
        pointBackgroundColor: "#007bff",
        tension: 0.4, // Membuat garis menjadi lebih halus
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Bulan",
          color: "#333",
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Persentase Okupansi",
          color: "#333",
          font: {
            size: 14,
            weight: "bold",
          },
        },
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        align: "center",
      },
    },
  };

  return (
    <div className="mt-4 p-3 bg-white rounded shadow-sm">
      <h5 className="text-center mb-3">
        Persentase Okupansi (12 Bulan Terakhir)
      </h5>
      <div style={{ height: "400px" }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default OccupancyChart;
