// utils/dateUtils.js
export const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};


// Get first day of the month function
export const getFirstDayOfMonth = (year, month) => {
  return new Date(year, month, 1).getDay();
};

// Get first day of this month function
export const getFirstDayOfThisMonth = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = '01';

  return `${year}-${month}-${day}`;
};


// get last day of the month function
export const getLastDayOfMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDay();
};

// get last day of this month function
export const getLastDayOfThisMonth = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;

  // Get the last day of the current month
  const lastDayOfMonth = new Date(year, month, 0);

  // Add one day to the last day of the month
  const dayAfterLastDay = new Date(lastDayOfMonth);
  dayAfterLastDay.setDate(dayAfterLastDay.getDate() + 1);

  // Format as YYYY-MM-DD
  const nextYear = dayAfterLastDay.getFullYear();
  const nextMonth = String(dayAfterLastDay.getMonth() + 1).padStart(2, '0');
  const nextDay = String(dayAfterLastDay.getDate()).padStart(2, '0');

  return `${nextYear}-${nextMonth}-${nextDay}`;
};

// get 30 days from today function
export const get30DaysFromToday = () => {
  const today = new Date();
  today.setDate(today.getDate() + 30);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

export const getMonthName = (month) => {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  return monthNames[month];
};

export const getDayDiff = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Hitung selisih waktu dalam milidetik
  const timeDiff = endDate - startDate;

  // Konversi selisih waktu dari milidetik ke hari
  const dayDiff = timeDiff / (1000 * 60 * 60 * 24);

  return dayDiff;
}

// getToday function YYYY-MM-DD
export const getToday = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

// get 3 days before today function YYYY-MM-DD
export const get3DaysFromToday = () => {
  const today = new Date();
  today.setDate(today.getDate() - 3);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

// get 31 days from today function YYYY-MM-DD
export const get31DaysFromToday = () => {
  const today = new Date();
  today.setDate(today.getDate() + 31);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

// formatToISO8601 function
export const formatToISO8601 = (dateString) => {
  const [year, month, day] = dateString.split('-');
  const date = new Date(Date.UTC(year, month - 1, day));
  return date.toISOString();
}

// convert new Date() to YYYY-MM-DD format and the function name is convertDateToYYYYMMDD
export const convertDateToYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}