import { createContext, useContext, useEffect, useState } from "react";
import useMoveReservationHooks from "hooks/v2/pages/calendar/useMoveReservationHooks";
import useTooltipReservationHooks from "hooks/v2/pages/calendar/useTooltipReservationHooks";
import useGuestDetailsTab from "hooks/v2/pages/reservation/update/useGuestDetailsTab";
import useCommentsTab from "hooks/v2/pages/reservation/update/useCommentsTab";
import useInvoiceTab from "hooks/v2/pages/reservation/update/useInvoiceTab";
import useUpdateReservationHooks from "hooks/v2/pages/reservation/update/useUpdateReservation";
import useCreateReservationHooks from "hooks/v2/pages/reservation/create/useCreateReservation";
import useCalendarResourceHooks from "hooks/v2/pages/calendar/useCalendarResourceHooks";
import useCalendarBookingResource from "hooks/v2/pages/calendar/useCalendarBookingResource";

const defaultReservationsCanvas = {
    booking_id: null,
    data: [],
    error: null,
    isLoading: false,
    show: false
}

const CalendarContext = createContext();

export const CalendarProvider = ({ children }) => {
    const [reservationsCanvas, setReservationsCanvas] = useState(defaultReservationsCanvas);

    // Hooks Calendar resrouces
    const calendarBookingResourceHooks = useCalendarBookingResource(); // new hooks
    // const calendarResourceHooks = useCalendarResourceHooks(); // old hooks

    const moveReservationHooks = useMoveReservationHooks();
    const tooltipReservationHooks = useTooltipReservationHooks();
    const guestDetailsTabHooks = useGuestDetailsTab();
    const commentsTabHooks = useCommentsTab();
    const invoiceTabHooks = useInvoiceTab();
    const updateReservationHooks = useUpdateReservationHooks({
        ...guestDetailsTabHooks,
        ...commentsTabHooks,
        ...invoiceTabHooks,
        booking_id: reservationsCanvas.booking_id,
    });
    const createReservationHooks = useCreateReservationHooks({
        ...guestDetailsTabHooks,
        ...commentsTabHooks,
        ...invoiceTabHooks
    });


    const handleShowCanvas = (booking_id) => {
        guestDetailsTabHooks.clearGuestDetailsTab();
        commentsTabHooks.clearCommentsTab();
        invoiceTabHooks.clearInvoiceTab(booking_id);

        setReservationsCanvas((prevState) => ({
            ...prevState,
            booking_id,
            show: true,
        }));
    };

    const handleCreateReservationCanvas = () => {
        guestDetailsTabHooks.clearGuestDetailsTab();
        commentsTabHooks.clearCommentsTab();
        invoiceTabHooks.clearInvoiceTab();

        setReservationsCanvas((prevState) => ({
            ...prevState,
            booking_id: null,
            show: true,
        }));
    };

    const contextValue = {
        // ...calendarResourceHooks,
        ...calendarBookingResourceHooks,
        ...moveReservationHooks,
        ...tooltipReservationHooks,
        ...guestDetailsTabHooks,
        ...commentsTabHooks,
        ...invoiceTabHooks,
        ...updateReservationHooks,
        ...createReservationHooks,
        reservationsCanvas,
        setReservationsCanvas,
        handleShowCanvas,
        handleCreateReservationCanvas
    };

    return (
        <CalendarContext.Provider value={contextValue}>
            {children}
        </CalendarContext.Provider>
    );
}

export const useCalendarContext = () => {
    const context = useContext(CalendarContext);
    if (!context) {
        throw new Error('useCalendarContext must be used within a CalendarProvider');
    }
    return context;
}