import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";

const MenuTabs = ({ activeTab, onTabChange }) => {
  const tabs = [
    { label: "Dashboard", key: "dashboard" },
    { label: "Laporan Properti", key: "property" },
    { label: "Laporan Grup", key: "group" },
    { label: "Laporan Pertumbuhan", key: "growth" },
  ];

  return (
    <Row className="justify-content-start gap-2">
      {tabs.map((tab) => (
        <Col key={tab.key} xs="auto">
          <Button
            variant={activeTab === tab.key ? "primary" : "outline-primary"}
            onClick={() => onTabChange(tab.key)}
          >
            {tab.label}
          </Button>
        </Col>
      ))}
    </Row>
  );
};

export default MenuTabs;
