import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

const HeaderFilter = () => {
  return (
    <div>
      <Row>
        <Col md={2}>
          <Form.Select>
            <option>Select Property</option>
            <option>The Mesare Resort</option>
          </Form.Select>
        </Col>
        
        <Col md={4}>
          <Form.Control type="date" />
        </Col>
        <Col md={2}>
          <Form.Select>
            <option>Select Agent</option>
            <option>All Agent</option>
          </Form.Select>
        </Col>
        <Col md={2}>
          <Button variant="warning" className="w-100">Search</Button>
        </Col>
      </Row>
      <Form.Check
        className="mt-3"
        type="radio"
        name="dateFilter"
        label="Created Reservation"
        defaultChecked
      />
      <Form.Check
        className="mt-1"
        type="radio"
        name="dateFilter"
        label="Stay Dates"
      />
    </div>
  );
};

export default HeaderFilter;
