import CurrencyInput from "components/form/currency-input";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useAuth } from "hooks/useAuth";
import { Tabs, Tab, Accordion, Button } from "react-bootstrap";
import { getRequest, postRequest } from "services/api";
import { API_VERSION } from "utils/constants";
import { IconPlus } from "@tabler/icons-react";
import { formatDateToString } from "utils/dateHelpers";

const optionsType = [
    { value: "availability", label: "Availability" },
    { value: "rate", label: "Rate" },
    { value: "close_to_arrival", label: "Close to Arrival" },
    { value: "close_to_departure", label: "Close to Departure" },
    { value: "min_stay", label: "Min Stay" },
    { value: "stop_sell", label: "Stop Sell" },
];

const ChangeRateAvailability = ({ onSuccess, onError }) => {
    const { user } = useAuth();

    const [restrictionsSetup, setRestrictionsSetup] = useState({
        formDatas: [
            {
                type: "availability",
                availability: null,
                date_from: formatDateToString(new Date()),
                date_to: formatDateToString(new Date(new Date().setDate(new Date().getDate() + 1))),
                property_id: user?.active_property_id,
                room_type_id: null,
            },
            // {
            //     type: "rate",
            //     rate: null,
            //     date_from: formatDateToString(new Date()),
            //     date_to: formatDateToString(new Date(new Date().setDate(new Date().getDate() + 1))),
            //     property_id: user?.active_property_id,
            //     rate_plan_id: null,
            // }
        ],
        loading: false,
        error: null,
        activeAccordion: 0,
    });

    const [UISync, setUISync] = useState({
        sync: user?.is_full_sync,
        loading: false,
        error: null,
    });

    const [UIFullSync, setUIFullSync] = useState({
        loading: false,
        error: null,
    });

    const [roomTypes, setRoomTypes] = useState({
        data: [],
        loading: false,
        error: null,
    });

    const fetchRoomType = async () => {
        setRoomTypes((prev) => ({ ...prev, loading: true }));

        try {
            const response = await getRequest('/room-type');
            if (response.success) {
                setRoomTypes((prev) => ({ ...prev, data: response.data.map((type) => ({ label: type.name, value: type.id, originalData: type })), error: null }));
            } else {
                setRoomTypes((prev) => ({ ...prev, error: 'Failed to fetch room type.', loading: false }));
            }
        } catch (error) {
            setRoomTypes((prev) => ({ ...prev, error: 'Something went wrong. Please try again later.', loading: false }));
        } finally {
            setRoomTypes((prev) => ({ ...prev, loading: false }));
        }
    };

    const handleAddRestrictionSetup = () => {
        setRestrictionsSetup((prev) => ({
            ...prev,
            formDatas: [
                ...prev.formDatas,
                {
                    type: "availability",
                    availability: null,
                    date_from: formatDateToString(new Date()),
                    date_to: formatDateToString(new Date(new Date().setDate(new Date().getDate() + 1))),
                    property_id: user?.active_property_id,
                    room_type_id: null,
                },
            ],
            // Set the active accordion to the last index
            activeAccordion: prev.formDatas.length,
        }));
    };

    const handleRemoveRestrictionSetup = (index) => {
        setRestrictionsSetup((prev) => {
            const newFormDatas = [...prev.formDatas];
            newFormDatas.splice(index, 1);
            return { ...prev, formDatas: newFormDatas };
        });
    };

    const handleSubmitRestrictionSetup = async () => {
        setRestrictionsSetup((prev) => ({ ...prev, loading: true, error: null }));
        try {
            const response = await postRequest(`/${API_VERSION}/update-rate-restriction`, {
                values: restrictionsSetup.formDatas,
            });

            if (response.success) {
                console.log('response', response);
                onSuccess(response.message);
            } else {
                throw new Error(response.message || 'Unknown error');
            }
        } catch (error) {
            // Tangani error tipe objek atau Error
            const errorMessage =
                error?.code || error?.errors
                    ? `Validation Error: ${JSON.stringify(error.errors || error.code)}`
                    : error.message || 'Unexpected error occurred';

            setRestrictionsSetup((prev) => ({ ...prev, error: errorMessage }));
            onError(errorMessage);
        } finally {
            setRestrictionsSetup((prev) => ({ ...prev, loading: false }));
        }
    }

    const handleSwitchSync = async (e) => {
        e.preventDefault();
        setUISync((prev) => ({ ...prev, loading: true }));
        try {
            const response = await postRequest(`/${API_VERSION}/setting/enable-full-sync`, {
                is_full_sync: e.target.checked ? 1 : 0,
            })

            if (response.success) {
                console.log('response', response);
                setUISync((prev) => ({ ...prev, sync: response.data.is_full_sync }));
            }
        } catch (error) {
            setUISync((prev) => ({ ...prev, error }));
        } finally {
            setUISync((prev) => ({ ...prev, loading: false }));
        }
    };

    const handleButtonFUllSync = async (e) => {
        e.preventDefault();
        setUIFullSync((prev) => ({ ...prev, loading: true, error: null })); // Reset error sebelumnya
        try {
            const response = await getRequest(`/${API_VERSION}/setting/full-sync`);

            if (response.success) {
                onSuccess(response.message);
            } else {
                throw new Error(response.message || 'Unknown error');
            }
        } catch (error) {
            // Tangani error tipe objek atau Error
            const errorMessage =
                error?.code || error?.errors
                    ? `Validation Error: ${JSON.stringify(error.errors || error.code)}`
                    : error.message || 'Unexpected error occurred';

            setUIFullSync((prev) => ({ ...prev, error: errorMessage }));
            onError(errorMessage);
        } finally {
            setUIFullSync((prev) => ({ ...prev, loading: false }));
        }
    };


    useEffect(() => {
        fetchRoomType();
    }, []);

    return (
        <Tabs
            defaultActiveKey='restrictions'
            className="mb-3 text-blue"
            variant="underline"
        >
            <Tab eventKey='restrictions' title='Restrictions'>
                <form className="d-flex flex-column" style={{ height: '75vh' }}>
                    <div className="action-sheet-content flex-grow-1">
                        {restrictionsSetup.formDatas && restrictionsSetup.formDatas.map((formData, index) => (
                            <Accordion key={index} defaultActiveKey={restrictionsSetup.activeAccordion} flush>
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <span>Restriction Setup #{index + 1}</span>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                className="me-2"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveRestrictionSetup(index);
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <RestrictionsSetup formData={formData} setRestrictionsSetup={setRestrictionsSetup} order={index} roomTypes={roomTypes} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        ))}
                        {/* Add button plus for add new restriction setup, the button will be icon with text and dont have border */}
                        <div className="d-grid mb-3">
                            <button
                                type="button"
                                className="btn btn-outline-primary btn-block"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleAddRestrictionSetup();
                                }}
                            >
                                <IconPlus size={24} strokeWidth={1.5} className="me-2" />
                                Add New Restriction Setup
                            </button>
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="form-group mt-auto d-grid">
                        <button type="submit" className="btn btn-primary btn-block" disabled={restrictionsSetup.loading} onClick={(e) => {
                            e.preventDefault();
                            handleSubmitRestrictionSetup();
                        }}>
                            Change Value
                        </button>
                    </div>
                </form>
            </Tab>
            <Tab eventKey='settings' title='Settings'>
                <div className="d-flex flex-column" style={{ height: '75vh' }}>
                    <div className="action-sheet-content flex-grow-1">
                        {/* alert showing last time sync */}
                        {/* <div className="alert alert-info" role="alert">
                            Last sync was on 2021-08-01 12:00:00
                        </div> */}

                        {/* Make radio switch for sync on/off */}
                        <div className="row mb-3 align-items-center">
                            <label className="col-sm-4 col-form-label d-flex justify-content-end">
                                Sync:
                            </label>
                            <div className="col-sm-8">
                                {UISync.sync !== undefined ? (
                                    <div className="form-check form-switch mb-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                            onChange={handleSwitchSync}
                                            checked={UISync.sync}
                                            disabled={UISync.loading}
                                        />
                                        {UISync.loading && <div className="spinner-border spinner-border-sm ms-2" role="status" />}
                                    </div>
                                ) : (
                                    <span className="text-muted">Loading...</span>
                                )}
                            </div>
                        </div>

                        {/* from & to date input */}
                        {/* <div className="row mb-3 align-items-center">
                            <label className="col-sm-4 col-form-label d-flex justify-content-end">
                                From Date:
                            </label>
                            <div className="col-sm-8">
                                <input
                                    type="date"
                                    className="form-control app-form-control rounded-1"
                                    value={formData.start_date}
                                    onChange={e => setFormData({ ...formData, start_date: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className="row mb-3 align-items-center">
                            <label className="col-sm-4 col-form-label d-flex justify-content-end">
                                To Date:
                            </label>
                            <div className="col-sm-8">
                                <input
                                    type="date"
                                    className="form-control app-form-control rounded-1"
                                    value={formData.end_date}
                                    onChange={e => setFormData({ ...formData, end_date: e.target.value })}
                                />
                            </div>
                        </div> */}
                    </div>

                    {/* Submit Button */}
                    <div className="form-group mt-auto d-grid">
                        <button className="btn btn-primary btn-block" onClick={handleButtonFUllSync} disabled={UIFullSync.loading}>
                            Full Sync
                        </button>
                    </div>
                </div>
            </Tab>
        </Tabs>
    );
};

export default ChangeRateAvailability;

const RestrictionsSetup = ({ formData, setRestrictionsSetup, order, roomTypes }) => {
    const [ratePlans, setRatePlans] = useState({
        data: [],
        loading: false,
        error: null,
        selected: null,
    });

    const [checkAvailability, setCheckAvailability] = useState({
        availability: null,
        loading: false,
        message: null,
        success: null,
        isChange: false,
    });

    const handleTypeChange = (selectedOption, order) => {
        setRestrictionsSetup((prev) => {
            const newFormDatas = [...prev.formDatas]; // Copy the formDatas array
            const updatedFormData = { ...newFormDatas[order], type: selectedOption.value }; // Copy the specific form data at 'order'

            if (selectedOption.value === "availability") {
                // Remove rate_plan_id and rate, add availability field
                delete updatedFormData.rate_plan_id;
                delete updatedFormData.rate;
                updatedFormData.availability = ""; // Provide a default value
            } else if (selectedOption.value === "rate") {
                // Remove availability field, add rate_plan_id and rate
                delete updatedFormData.availability;
                updatedFormData.rate_plan_id = ""; // Provide a default value
                updatedFormData.rate = ""; // Provide a default value
            }

            newFormDatas[order] = updatedFormData; // Update the specific form data
            return { ...prev, formDatas: newFormDatas }; // Return the updated state
        });
    };

    const handleCheckAvailability = async (availability) => {
        setCheckAvailability((prev) => ({ ...prev, loading: true }));

        try {
            setRestrictionsSetup((prev) => {
                const newFormDatas = [...prev.formDatas];
                newFormDatas[order] = { ...newFormDatas[order], availability: availability };
                return { ...prev, formDatas: newFormDatas };
            });

            const response = await postRequest(`/${API_VERSION}/check-total-availability`, {
                availability: availability,
                room_type_id: formData.room_type_id,
                date_from: formData.date_from,
                date_to: formData.date_to,
            });

            if (response.success) {
                setCheckAvailability((prev) => ({ ...prev, message: response.message, success: true }));
            } else {
                setCheckAvailability((prev) => ({ ...prev, message: response.message, success: false }));
            }
        } catch (error) {
            setCheckAvailability((prev) => ({ ...prev, message: 'Something went wrong. Please try again later.', success: false }));
        } finally {
            setCheckAvailability((prev) => ({ ...prev, loading: false, isChange: false }));
        }
    }

    const fetchRatePlan = async (roomTypeId) => {
        setRatePlans((prev) => ({ ...prev, loading: true }));

        try {
            const response = await getRequest(`/rate-plan?room_type_id=${roomTypeId}`);
            if (response.success) {
                setRatePlans((prev) => ({ ...prev, data: response.data.map((plan) => ({ label: plan.name, value: plan.id, originalData: plan })), error: null }));
            } else {
                setRatePlans((prev) => ({ ...prev, error: 'Failed to fetch rate plan.', loading: false }));
            }
        } catch (error) {
            setRatePlans((prev) => ({ ...prev, error: 'Something went wrong. Please try again later.', loading: false }));
        } finally {
            setRatePlans((prev) => ({ ...prev, loading: false }));
        }
    };

    useEffect(() => {
        if (formData.room_type_id) {
            fetchRatePlan(formData.room_type_id);

            // Reset the selected rate plan and update restrictions only if the room_type requires a rate plan
            setRatePlans((prev) => ({ ...prev, selected: null }));

            setRestrictionsSetup((prev) => {
                const newFormDatas = [...prev.formDatas];
                const updatedFormData = { ...newFormDatas[order] };

                if (formData.type !== "availability") {
                    // If the room_type requires a rate plan, reset it
                    updatedFormData.rate_plan_id = ""; // Reset rate plan
                } else {
                    // If the room_type is availability, ensure rate_plan is removed
                    delete updatedFormData.rate_plan_id;
                    setCheckAvailability((prev) => ({ ...prev, availability: null, message: null, success: null, isChange: false }));
                }

                newFormDatas[order] = updatedFormData; // Update the specific form data
                return { ...prev, formDatas: newFormDatas }; // Return updated state
            });
        }
    }, [formData.room_type_id]);

    useEffect(() => {
        if (formData.rate_plan_id) {
            setRatePlans((prev) => ({ ...prev, selected: prev.data.find((plan) => plan.value === formData.rate_plan_id) }));
        }
    }, [formData.rate_plan_id]);

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
        }}>
            <div className="row mb-3 align-items-center">
                <label className="col-sm-4 col-form-label d-flex justify-content-end">
                    Type:
                </label>
                <div className="col-sm-8">
                    {/* <input
                            type="text"
                            className="form-control app-form-control rounded-1"
                            value={formData.type ? 'Availability' : 'Rate'}
                        /> */}
                    {/* <Select
                            value={uiState.type}
                            options={[
                                // { label: 'Availability', value: 'availability' },
                                { label: 'Rate', value: 'rate' }
                            ]}
                            onChange={(selectedOption) => {
                                setUiState({ ...uiState, type: selectedOption });
                            }}
                        /> */}
                    <Select
                        value={optionsType.find(option => option.value === formData.type)}
                        options={optionsType}
                        onChange={(selectedOption) => handleTypeChange(selectedOption, order)}
                    />
                </div>
            </div>
            <div className="row mb-3 align-items-center">
                <label className="col-sm-4 col-form-label d-flex justify-content-end">
                    Room Title:
                </label>
                <div className="col-sm-8">
                    {/* <span className="app-form-control">{formData.room_title}</span> */}
                    {roomTypes.data && roomTypes.data.length > 0 ? (
                        <Select
                            value={roomTypes.data.find(option => option.value === formData.room_type_id)}
                            options={roomTypes.data}
                            onChange={(selectedOption) => {
                                setRestrictionsSetup((prev) => {
                                    const newFormDatas = [...prev.formDatas];
                                    newFormDatas[order] = { ...newFormDatas[order], room_type_id: selectedOption.value };
                                    return { ...prev, formDatas: newFormDatas };
                                });
                            }}
                            isLoading={roomTypes.loading}
                        />
                    ) : (
                        <span className="text-muted">Loading...</span>
                    )}
                </div>
            </div>
            {formData.type === 'availability' ? '' : (
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Rateplan:
                    </label>
                    <div className="col-sm-8">
                        {/* <span className="app-form-control">{formData.rate_plan_name}</span> */}
                        {ratePlans.data && ratePlans.data.length > 0 ? (
                            <Select
                                value={ratePlans.selected}
                                options={ratePlans.data}
                                onChange={(selectedOption) => {
                                    setRestrictionsSetup((prev) => {
                                        const newFormDatas = [...prev.formDatas];
                                        newFormDatas[order] = { ...newFormDatas[order], rate_plan_id: selectedOption.value };
                                        return { ...prev, formDatas: newFormDatas };
                                    });
                                }}
                                isLoading={ratePlans.loading}
                            />
                        ) : (
                            <span className="text-muted">Check your room title selection</span>
                        )}
                    </div>
                </div>
            )}
            <div className="row mb-3 align-items-center">
                <label className="col-sm-4 col-form-label d-flex justify-content-end">
                    From Date:
                </label>
                <div className="col-sm-8">
                    <input
                        type="date"
                        className="form-control app-form-control rounded-1"
                        value={formData.date_from}
                        onChange={e => setRestrictionsSetup((prev) => {
                            const newFormDatas = [...prev.formDatas];
                            newFormDatas[order] = { ...newFormDatas[order], date_from: e.target.value };
                            return { ...prev, formDatas: newFormDatas };
                        })}
                    />
                </div>
            </div>

            <div className="row mb-3 align-items-center">
                <label className="col-sm-4 col-form-label d-flex justify-content-end">
                    To Date:
                </label>
                <div className="col-sm-8">
                    <input
                        type="date"
                        className="form-control app-form-control rounded-1"
                        value={formData.date_to}
                        onChange={e => setRestrictionsSetup((prev) => {
                            const newFormDatas = [...prev.formDatas];
                            newFormDatas[order] = { ...newFormDatas[order], date_to: e.target.value };
                            return { ...prev, formDatas: newFormDatas };
                        })}
                    />
                </div>
            </div>

            <div className="row mb-3 align-items-center">
                <label className="col-sm-4 col-form-label d-flex justify-content-end">
                    Value:
                </label>
                <div className="col-sm-8">
                    {formData.type === 'availability' ? (
                        <>
                            <input
                                type="text"
                                className="form-control app-form-control rounded-1"
                                value={checkAvailability.availability ? checkAvailability.availability : ''}
                                disabled={checkAvailability.loading || formData.room_type_id === null}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setCheckAvailability((prev) => ({ ...prev, availability: e.target.value, message: null, success: null, isChange: true }));
                                }}
                                placeholder="0"
                            />
                            {checkAvailability.isChange && (
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm mt-2"
                                    onClick={() => handleCheckAvailability(checkAvailability.availability)}
                                    disabled={checkAvailability.loading || formData.room_type_id === null}
                                >
                                    Check Availability
                                </button>
                            )}
                            {checkAvailability.success !== null && (
                                <span className={`text-${checkAvailability.success ? 'success' : 'danger'}`}>
                                    {checkAvailability.message}
                                </span>
                            )}
                        </>
                    ) : (
                        <CurrencyInput
                            name="extra_child_rate"
                            className="app-form-control rounded-1"
                            value={formData.rate} // Bind value ke formData
                            onChange={(e, rawValue) => {
                                setRestrictionsSetup((prev) => {
                                    const newFormDatas = [...prev.formDatas];
                                    newFormDatas[order] = { ...newFormDatas[order], rate: rawValue };
                                    return { ...prev, formDatas: newFormDatas };
                                });
                            }}
                            placeholder="Rp. 0" // Placeholder Rupiah default
                        />
                    )}
                </div>
            </div>
        </form>
    );
};
