import { useEffect, useState } from 'react';
import { getRequest, patchRequest } from 'services/api';
import { API_VERSION } from 'utils/constants';
import { formatDateToString } from 'utils/dateHelpers';

const useUpdateReservationHooks = (initialState) => {
    const [optionState, setOptionState] = useState({
        booking_id: null,
        data: null,
        error: null,
        isLoading: false,
    });

    const [processedData, setProcessedData] = useState({
        data: null,
        error: null,
        isLoading: false,
    });

    const mappedDataFromAPI = async (booking_id) => {
        setOptionState((prevState) => ({ ...prevState, isLoading: true }));
        try {
            const response = await getRequest(`/${API_VERSION}/booking/${booking_id}`);
            if (response.success) {
                const data = response.data;

                initialState.setCommentsForm((prevState) => ({
                    ...prevState,
                    public_notes: data.notes,
                    private_notes: data.notes
                }));
                initialState.setGuestDetailsForm((prevState) => ({
                    ...prevState,
                    phone: data.customer?.phone,
                    address: data.customer?.address,
                    country: data.customer?.country,
                    state: data.customer?.state,
                    city: data.customer?.city,
                    postal_code: data.customer?.zip,
                    id_number: '',
                    currency: data.currency,
                    non_refund: false,
                    maintenance: false,
                    agent_id: data.agent_id,
                    arrival_time: data.checkin_time,
                    birthday_day: '',
                    birthday_month: '',
                    birthday_year: '',
                }));
                initialState.setInvoiceForm((prevState) => ({
                    ...prevState,
                    custom_invoice_nb: data.unique_id,
                    invoice_date: formatDateToString(new Date(data.inserted_at)),
                    new_guest: false,
                    guest_no: '',
                    booking_date: formatDateToString(new Date(data.booking_date)),
                    status: data.status,
                    mark: 'normal',
                    payment_type: data.payment_type,
                    first_name: data.customer_name,
                    last_name: data.customer_surname,
                    email: data.customer?.email,
                }));
                console.log('initialstate rooms', initialState.invoiceForm);
                initialState.setRooms((prevState) => ([
                    {
                        ...prevState[0],
                        title: `Booking Detail Room 1`,
                        room_type_id: data.room_type_id,
                        room_id: data.room_id,
                        rate_plan_id: data.rate_plan_id,
                        arrival_date: data.checkin_date,
                        departure_date: data.checkout_date,
                        day: {
                            ...prevState[0].day,
                            data: data.days.map((d) => ({
                                date: d.date,
                                rate: d.amount,
                            })),
                        },
                    }]));
                setOptionState((prevState) => ({ ...prevState, data, error: null, isLoading: false }));
            }
        } catch (error) {
            setOptionState((prevState) => ({ ...prevState, error, isLoading: false }));
        }
    }

    useEffect(() => {
        if (initialState.booking_id) {
            setOptionState((prevState) => ({ ...prevState, booking_id: initialState.booking_id }));
            mappedDataFromAPI(initialState.booking_id);
        }
    }, [initialState.booking_id]);

    useEffect(() => {
        if (optionState.data) {
            initialState.fetchRoomTypesInUpdate(0, optionState.booking_id);
        }
    }, [optionState.data]);

    const handleUpdateReservation = async () => {
        const { invoiceForm, rooms, guestDetailsForm, commentsForm, getRoomTotal, getAllRoomsTotal, getTotalOccupancyAdults, getTotalOccupancyChildren } = initialState;

        // New Payload
        const payloadRequest = {
            customer_name: invoiceForm.first_name || "",
            customer_surname: invoiceForm.last_name || "",
            customer_email: invoiceForm.email || "",
            currency: invoiceForm.currency || "IDR",
            amount: getAllRoomsTotal() || "0",
            occupancy_children: getTotalOccupancyChildren() || "0",
            occupancy_adults: getTotalOccupancyAdults() || "0",
            occupancy_infants: "0",
            arrival_date: rooms[0].arrival_date || "",
            arrival_hour: guestDetailsForm.arrival_time || "00:00",
            departure_date: rooms[0].departure_date || "",
            // Add other customer details here
            customer_phone: guestDetailsForm.phone || "",
            customer_address: guestDetailsForm.address || "",
            customer_country: guestDetailsForm.country || "",
            customer_state: guestDetailsForm.state || "",
            customer_birth_date:
                guestDetailsForm.birthday_month &&
                    guestDetailsForm.birthday_day &&
                    guestDetailsForm.birthday_year
                    ? formatDateToString(
                        new Date(
                            `${guestDetailsForm.birthday_month} ${guestDetailsForm.birthday_day}, ${guestDetailsForm.birthday_year}`
                        )
                    )
                    : "",
            customer_identity_number: guestDetailsForm.id_number || "",
            agent_id: guestDetailsForm.agent_id || "",
            mark: invoiceForm.mark || "",
            status: invoiceForm.status || "confirmed",
            invoice_number: invoiceForm.custom_invoice_nb || "",
            booking_date: invoiceForm.booking_date || "",
            notes: commentsForm.public_notes || "",
            private_notes: commentsForm.private_notes || "",
            bookingroom: rooms.map((room, index) => ({
                payment_type: invoiceForm.payment_type || "",
                customer_name: invoiceForm.first_name || "",
                customer_surname: invoiceForm.last_name || "",
                customer_email: invoiceForm.email || "",
                room_type_id: room.room_type_id,
                room_id: room.room_id,
                rate_plan_id: room.rate_plan_id,
                rate: room.rate,
                amount: getRoomTotal(index) || "0",
                occupancy_adults: room.occupancy_adults,
                occupancy_children: room.occupancy_children,
                checkin_date: room.arrival_date || "",
                checkout_date: room.departure_date || "",
                day: room.day.data.map((d) => ({
                    date: formatDateToString(new Date(d.date)) || "",
                    amount: d.rate,
                })),
            })),
        };

        console.log('Payload Request:', payloadRequest);

        setProcessedData((prevState) => ({ ...prevState, isLoading: true }));
        try {
            const response = await patchRequest(`/${API_VERSION}/booking/${optionState.booking_id}`, payloadRequest);
            if (response.success) {
                setProcessedData((prevState) => ({ ...prevState, data: response.data, error: null, isLoading: false }));
                return { success: true, message: 'Reservation updated successfully' };
            }
        } catch (error) {
            setProcessedData((prevState) => ({ ...prevState, error, isLoading: false }));
            return { success: false, message: 'Failed to update reservation' };
        }
    };

    return {
        optionState,
        setOptionState,
        processedData,
        handleUpdateReservation,
    };
};

export default useUpdateReservationHooks;