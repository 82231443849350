import { useState } from 'react';
import { postRequest } from 'services/api';

const useCreatePropertyManagementHook = () => {
    const [form, setForm] = useState({
        name: "",
        accommodation_type: "",
        preferred_currency: "",
        timezone: "",
        primary_email: "",
        cc_emails: "",
        accounting_email: "",
        mobile_number_country_code: "",
        mobile_number: "",
        primary_phone_country_code: "",
        primary_phone: "",
        website: "",
        country: "",
        state: "",
        city: "",
        street_address: "",
        postal_code: "",
        prefix_booking_number: "",
        hotel_tax_number: "",
        enable_cvv: "",
    });
    const [formErrors, setFormErrors] = useState({
        name: null,
        accommodation_type: null,
        preferred_currency: null,
        timezone: null,
        primary_email: null,
        cc_emails: null,
        accounting_email: null,
        mobile_number_country_code: null,
        mobile_number: null,
        primary_phone_country_code: null,
        primary_phone: null,
        website: null,
        country: null,
        state: null,
        city: null,
        street_address: null,
        postal_code: null,
        prefix_booking_number: null,
        hotel_tax_number: null,
        enable_cvv: null,
    });

    const [rawCcEmails, setRawCcEmails] = useState("");

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [message, setMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateId, setStateId] = useState(null);

    const validateEmails = (emails) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex sederhana untuk email
        return emails.every(email => emailRegex.test(email));
    };


    const requestCreateProperty = async (data) => {
        setIsSubmitting(true);  // Set isSubmitting to true when starting the request

        let result = { success: false }; // Default result

        try {
            // Validasi email sebelum request
            if (data.cc_emails && !validateEmails(data.cc_emails)) {
                const message = "One or more CC Emails are invalid.";
                setFormErrors({ cc_emails: message });
                setMessage(message);
                setSuccess(false);
                return { success: false, message, errors: { cc_emails: message } };
            }

            const response = await postRequest('/property', data);

            if (response.success) {
                setSuccess(true);
                setMessage(response.message);
                result = { success: true, data: response.data, message: response.message };
            } else {
                setSuccess(false);
                setMessage(response.message);
                result = { success: false, message: response.message };
            }
        } catch (error) {
            setSuccess(false);

            if (error.code === 422) {
                setFormErrors(error.errors);
                result = { success: false, message: error.message, errors: error.errors };
            } else {
                setMessage(error.message);
                result = { success: false, message: error.message };
            }
        } finally {
            setIsSubmitting(false);
        }

        return result;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            [name]: null,
        }));
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const parseCcEmails = (rawCcEmails) => {
        // Pisahkan berdasarkan spasi, koma, atau newline
        const emailsArray = rawCcEmails
            .split(/[\s,]+/)  // Memisahkan berdasarkan spasi, koma, atau newline
            .map(email => email.trim()) // Hilangkan spasi ekstra di sekitar email
            .filter(email => email); // Hapus elemen kosong
    
        // Mengembalikan array hasil parsing
        return emailsArray;
    };
    

    const handleCcEmailsChange = (e) => {
        const rawValue = e.target.value;
    
        // Simpan nilai mentah ke rawCcEmails
        setRawCcEmails(rawValue);
    
        // Parsing nilai mentah menjadi array, lalu simpan ke form.cc_emails
        const parsedEmails = parseCcEmails(rawValue);
        setForm(prevForm => ({
            ...prevForm,
            cc_emails: parsedEmails,
        }));
    };    

    return {
        form,
        formErrors,
        loading,
        success,
        message,
        isSubmitting,
        requestCreateProperty,
        handleChange,
        stateId,
        setStateId,
        handleCcEmailsChange,
        rawCcEmails
    };
};

export default useCreatePropertyManagementHook;
