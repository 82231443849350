import { useEffect, useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getRequest, postRequest } from 'services/api';
import Swal from 'sweetalert2';
import { formatDateToString } from 'utils/dateHelpers';
import { useCalendarContext } from 'contexts/v2/pages/calendar/CalendarContext';
import { formatPrice } from 'utils/utils';
import Select from 'react-select';
import CurrencyInput from 'components/form/currency-input';
import { API_VERSION } from 'utils/constants';
import { buildQueryString } from 'utils/queryHelpers';
import { toast } from 'react-toastify';

const defaultDetails = {
    oldRoomType: '',
    oldRoomNumber: '',
    oldCheckIn: '',
    oldCheckOut: '',
    oldRateplan: '',
    oldRoomCharges: [],
    oldRoomTotal: '',
    newRoomType: '',
    newRoomNumber: '',
    newCheckIn: '',
    newCheckOut: '',
    newRateplan: '',
    newRoomCharges: [],
    newRoomTotal: '',
    extendedProps: null,
}

const MoveReservationModal = ({ showModal, onClose, onFailed }) => {
    const { reservationDetails, roomInfo, roomNumberInfo, ratePlanInfo, clearMove, refetchCalendarResource, handleRefreshMoveReservation } = useCalendarContext();
    const [details, setDetails] = useState(defaultDetails);
    const [sameAsOriginal, setSameAsOriginal] = useState(false);
    const [ratePlan, setRatePlan] = useState(null);
    const [checkingRatePlanPrice, setCheckingRatePlanPrice] = useState({
        status: null,
        data: null,
        message: null,
        loading: false,
    });

    useEffect(() => {
        if (showModal.data) {
            const { oldEvent, event } = showModal.data.info;
            const { start: oldStart, end: oldEnd } = oldEvent;
            const { start: newStart, end: newEnd, extendedProps, _def } = event;
            const { resourceIds } = _def;

            setDetails((prevState) => ({
                ...prevState,
                oldCheckIn: formatDateToString(new Date(oldStart)),
                oldCheckOut: formatDateToString(new Date(oldEnd)),
                newCheckIn: formatDateToString(new Date(newStart)),
                newCheckOut: formatDateToString(new Date(newEnd)),
                extendedProps: extendedProps,
                resourceIds: resourceIds,
            }));
        }
    }, [showModal.data]);

    // Fungsi untuk memproses data dan mengubah rate menjadi null jika roomTypeId berbeda
    const processRoomCharges = (days, reservationDetails, roomInfo) => {
        // Periksa apakah roomTypeId cocok
        const isRoomTypeMatched = reservationDetails?.data?.room_type.id === roomInfo.data?.id;

        // Jika roomTypeId tidak cocok, set rate menjadi null
        return days.map((day) => {
            if (!isRoomTypeMatched) {
                return { date: day.date, amount: null }; // Rate menjadi null jika tidak cocok
            }
            return { date: day.date, amount: day.amount }; // Biarkan data tetap jika cocok
        });
    }


    useEffect(() => {
        if (roomInfo.data && ratePlanInfo.oldRatePlan && reservationDetails.data) {
            setSameAsOriginal(true);

            // Check if room type is the same before setting rate plan and details
            if (reservationDetails.data?.room_type.id === roomInfo.data?.id) {
                setRatePlan({ value: ratePlanInfo.oldRatePlan?.id, label: ratePlanInfo.oldRatePlan?.name });
                setSameAsOriginal(true);
            } else {
                setRatePlan(null);
                setSameAsOriginal(false); // Set to false if room types are different
            }

            // Only set details if room types match
            setDetails((prevState) => ({
                ...prevState,
                // old
                oldRoomType: reservationDetails.data?.room_type.name,
                oldRoomNumber: reservationDetails.data?.room.room_number ? reservationDetails.data?.room.room_number : 'Unassigned',
                oldRateplan: ratePlanInfo?.oldRatePlan?.name,
                oldRoomCharges: reservationDetails?.data?.days,
                oldRoomTotal: reservationDetails?.data?.amount,
                // new 
                newRoomType: roomInfo?.data?.name,
                newRoomNumber: roomNumberInfo?.newRoom.data ? roomNumberInfo?.newRoom.data?.room_number : 'Unassigned',
                newRoomCharges: processRoomCharges(reservationDetails?.data?.days, reservationDetails, roomInfo),
                newRoomTotal: reservationDetails?.data?.amount,
                newRateplan: reservationDetails?.data?.room_type.id === roomInfo.data?.id ? ratePlanInfo.oldRatePlan?.id : null,
            }));
        }
    }, [roomInfo.data, roomNumberInfo.oldRoom.data, ratePlanInfo.oldRatePlan, reservationDetails.data]);

    const getNewRoomTotal = () => {
        // Jika sameAsOriginal true dan oldRoomTotal tersedia, atau jika newRoomCharges kosong atau null
        if (sameAsOriginal && details.oldRoomTotal && details.newRoomCharges?.length > 0) {
            return details.oldRoomTotal;
        } else {
            // Jika newRoomCharges tidak ada atau kosong, kembalikan 0
            if (!details.newRoomCharges || details.newRoomCharges.length === 0) {
                return 0;
            }

            // Hitung total jika newRoomCharges ada dan memiliki nilai
            const total = details.newRoomCharges.reduce((acc, curr) => {
                // Pastikan curr.amount adalah angka, jika tidak, anggap 0
                return acc + (Number(curr.amount) || 0);
            }, 0);

            return total;
        }
    };

    const handleSelectNewRatePlan = async (selectedOption) => {
        setCheckingRatePlanPrice((prevState) => ({ ...prevState, loading: true }));

        try {
            const ratePlanId = selectedOption.value;
            const queryString = buildQueryString({
                checkin: details.newCheckIn,
                checkout: details.newCheckOut,
                rate_plan_id: ratePlanId,
            });
            const response = await getRequest(`/${API_VERSION}/show-rate${queryString}`);

            if (response.success) {
                console.log('response', response);
                setRatePlan(selectedOption);
                setDetails((prevState) => ({
                    ...prevState,
                    newRateplan: ratePlanId,
                    newRoomCharges: response.data.map((charge) => ({
                        date: charge.date,
                        amount: charge.rate,
                    })),
                }));
                setCheckingRatePlanPrice((prevState) => ({
                    ...prevState,
                    status: response.success,
                    data: response.data,
                    message: response.message,
                }));
            } else {
                setCheckingRatePlanPrice((prevState) => ({
                    ...prevState,
                    status: response.success,
                    message: response.message,
                }));
            }
        } catch (error) {
            setCheckingRatePlanPrice((prevState) => ({
                ...prevState,
                status: false,
                message: 'Failed to check rate plan price',
            }));
        } finally {
            setCheckingRatePlanPrice((prevState) => ({ ...prevState, loading: false }));
        }
    };

    const handleMoveReservation = async () => {
        try {
            const payload = {
                room_id: details.resourceIds[0].split('|')[1] === 'unassigned' ? null : details.resourceIds[0].split('|')[1],
                room_type_id: roomInfo.data.id,
                booking_room_id: details.extendedProps.booking_room_id,
                rate_plan_id: details.newRateplan,
                day: details.newRoomCharges.map((charge) => ({
                    date: charge.date,
                    amount: charge.amount
                })),
            };

            handleRefreshMoveReservation(details.extendedProps.booking_room_id, payload);

            const response = await postRequest(`/${API_VERSION}/update-room-booking`, payload);

            if (response.success) {
                onClose();
                toast.success('Reservation has been moved successfully');
                setDetails(defaultDetails);
                clearMove();
                refetchCalendarResource();
            }
        } catch (error) {
            toast.error('Failed to move reservation');
            setDetails(defaultDetails);
            clearMove();
            onFailed();
        }
    };

    useEffect(() => {
        if (sameAsOriginal !== true) {
            const total = details.newRoomCharges.reduce((acc, curr) => acc + Number(curr.amount), 0);
            setDetails((prevState) => ({
                ...prevState,
                newRoomTotal: total,
            }));
        }
    }, [details.newRoomCharges]);

    return (
        <Modal show={showModal.show} onHide={onClose} centered size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Move Reservation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {/* FROM Section */}
                    <Col md={6} style={{ backgroundColor: '#F9F9F9' }} className='p-3'>
                        <h5 className='fs-1'>From</h5>
                        <div className='mb-3 d-flex justify-content-between'>
                            <span>Original Room Type</span>
                            <span className='fw-bold'>{details.oldRoomType}</span>
                        </div>
                        <div className='mb-3 d-flex justify-content-between'>
                            <span>Original Room Number</span>
                            <span className='fw-bold'>{details.oldRoomNumber}</span>
                        </div>
                        <div className='mb-3 d-flex justify-content-between'>
                            <span>Original Check-in</span>
                            <span className='fw-bold'>{details.oldCheckIn}</span>
                        </div>
                        <div className='mb-3 d-flex justify-content-between'>
                            <span>Original Check-out</span>
                            <span className='fw-bold'>{details.oldCheckOut}</span>
                        </div>
                        <div className='mb-3 d-flex justify-content-between'>
                            <span>Original Rateplan</span>
                            <span className='fw-bold'>{details.oldRateplan}</span>
                        </div>
                        <div className='mb-3 d-flex justify-content-between'>
                            <span>Room Charges</span>
                        </div>
                        {details.oldRoomCharges.length > 0 && details.oldRoomCharges.map((charge, index) => (
                            <div key={index} className='mb-3 d-flex justify-content-between'>
                                <span>{charge.date}</span>
                                <span className='fw-bold'>{formatPrice(charge.amount)}</span>
                            </div>
                        ))}
                        <div className='mb-3 d-flex justify-content-between'>
                            <span>Original Room Total</span>
                            <span className='fw-bold'>{formatPrice(details.oldRoomTotal)}</span>
                        </div>
                    </Col>
                    {/* TO Section */}
                    <Col md={6} style={{ backgroundColor: '#F0EEEF' }} className='p-3'>
                        <h5 className='fs-1'>To</h5>
                        <form>
                            <div className='row mb-3'>
                                <label htmlFor='newRoomType' className='col-6'>New Room Type</label>
                                <div className='col-6'>
                                    <span id='newRoomType'>{details.newRoomType}</span>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <label htmlFor='newRoomNumber' className='col-6'>New Room Number</label>
                                <div className='col-6'>
                                    <span id='newRoomNumber'>{details.newRoomNumber}</span>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <label htmlFor='newCheckIn' className='col-6'>New Check-in</label>
                                <div className='col-6'>
                                    <span id='newCheckIn'>{details.newCheckIn}</span>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <label htmlFor='newCheckOut' className='col-6'>New Check-out</label>
                                <div className='col-6'>
                                    <span id='newCheckOut'>{details.newCheckOut}</span>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <label htmlFor='newRateplan' className='col-6'>New Rateplan</label>
                                <div className='col-6'>
                                    <Select
                                        id='newRateplan'
                                        className='py-1 rounded-0'
                                        value={ratePlan}
                                        options={ratePlanInfo.data}
                                        onChange={(selectedOption) => {
                                            handleSelectNewRatePlan(selectedOption);
                                        }}
                                        isDisabled={checkingRatePlanPrice.loading || sameAsOriginal}
                                    />
                                    {checkingRatePlanPrice.loading && (
                                        <div className='text-muted small'>Checking rate plan...</div>
                                    )}
                                    {checkingRatePlanPrice.status === false && (
                                        <div className='text-danger small'>{checkingRatePlanPrice.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <label htmlFor='roomCharges' className='col-6'>Room Charges</label>
                                <div className='col-6'>
                                    {reservationDetails.data && reservationDetails.data?.room_type?.id && (
                                        <>
                                            <input id='sameAsOriginal' type='checkbox' checked={sameAsOriginal} className='form-checkbox me-2' onChange={(e) => {
                                                setSameAsOriginal(e.target.checked);
                                                if (e.target.checked === true) {
                                                    setRatePlan({ value: ratePlanInfo.oldRatePlan?.id, label: ratePlanInfo.oldRatePlan?.name });
                                                    setDetails((prevState) => ({
                                                        ...prevState,
                                                        newRateplan: ratePlanInfo.oldRatePlan?.id,
                                                        newRoomCharges: details.oldRoomCharges,
                                                    }));
                                                }
                                            }} />
                                            <label htmlFor='sameAsOriginal'>Same as Original</label>
                                        </>
                                    )}
                                </div>
                            </div>
                            {sameAsOriginal === true && details.oldRoomCharges.length > 0 && details.oldRoomCharges.map((charge, index) => (
                                <div key={index} className='row mb-3'>
                                    <label htmlFor='roomCharges' className='col-6'>{charge.date}</label>
                                    <div className='col-6'>
                                        <input id='roomCharges' type='text' defaultValue={formatPrice(charge.amount)} disabled={sameAsOriginal} className='form-control py-1' />
                                    </div>
                                </div>
                            ))}

                            {sameAsOriginal === false && details.newRoomCharges.length > 0 && details.newRoomCharges.map((charge, index) => (
                                <div key={index} className='row mb-3'>
                                    <label htmlFor='roomCharges' className='col-6'>{charge.date}</label>
                                    <div className='col-6'>
                                        <CurrencyInput
                                            id='roomCharges'
                                            value={charge.amount}
                                            disabled={sameAsOriginal}
                                            className='form-control py-1'
                                            onChange={(e, rawValue) => {
                                                const newRoomCharges = details.newRoomCharges.map((item, idx) => {
                                                    if (idx === index) {
                                                        return { date: item.date, amount: rawValue };
                                                    }
                                                    return item;
                                                });
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    newRoomCharges: newRoomCharges,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className='d-flex justify-content-between'>
                                <span>New Room Total:</span>
                                <span className='fw-bold'>{formatPrice(getNewRoomTotal())}</span>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="success" onClick={handleMoveReservation} disabled={reservationDetails.loading}>Move Reservation</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MoveReservationModal;
