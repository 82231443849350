// komponen
import LayoutDashboard from "layouts/layout-dashboard-collapse";
// import CalendarBooking from "pages/reservation/calendar/components/calendar-booking";
import CalendarBooking from "./components/calendar-booking-2";
import FullLoader from "components/loader/full-loader";
import { Accordion, Card, Offcanvas, Tab, Tabs, useAccordionButton } from "react-bootstrap";
import { useCalendarContext } from "contexts/v2/pages/calendar/CalendarContext";
import GuestDetails from "pages/reservation/reservation/v2/components/edit/guest-details-tab";
import Comments from "pages/reservation/reservation/v2/components/edit/comments-tab";
import InvoiceTab from "pages/reservation/reservation/v2/components/edit/invoice-tab";
import Swal from "sweetalert2";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const bookings = [
    { id: 1, name: 'John Doe', checkin: '2024-11-21', checkout: '2024-11-25', roomType: 'Deluxe Room' },
    { id: 2, name: 'Jane Smith', checkin: '2024-11-22', checkout: '2024-11-26', roomType: 'Standard Room' },
    { id: 3, name: 'Alice Johnson', checkin: '2024-11-23', checkout: '2024-11-27', roomType: 'Suite' },
];

const CalendarPage = () => {
    const { calendarStatus, calendarSettings, setCalendarSettings, filteredUnsignedList, handleMoveToUnsigned } = useCalendarContext();

    if (calendarStatus.isLoading) {
        return <FullLoader />;
    }

    return (
        <LayoutDashboard>
            <div className="page">
                <div className="page-body mt-0">
                    <div className="container-fluid px-0">
                        {filteredUnsignedList.length > 0 && calendarSettings.unsigned === false && (
                            <Accordion>
                                <Card className="border-0 bg-yellow-lt">
                                    <Card.Header>
                                        <CustomToggle eventKey="0" />
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className="pt-0">
                                            <div className="booking-list">
                                                <ul className="list-group">
                                                    {filteredUnsignedList.map((booking) => (
                                                        <li key={booking.id} className="px-0 list-group-item unsigned-list-group-item d-flex justify-content-between align-items-center">
                                                            <div>
                                                                <strong>{booking.customer_name} {booking.customer_surname}</strong>
                                                                <div className="text-muted">
                                                                    {booking.checkin_date} - {booking.checkout_date} | {booking.room_type.name}
                                                                </div>
                                                            </div>
                                                            <button
                                                                className="btn btn-sm btn-primary"
                                                                onClick={() => {
                                                                    handleMoveToUnsigned(booking);
                                                                }}
                                                            >
                                                                Assign Room
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        )}
                        <CalendarBooking />
                    </div>
                </div>
            </div>
            <CanvasForEditReservation />
            <ToastContainer />
        </LayoutDashboard>
    );
};

export default CalendarPage;

const CustomToggle = ({ eventKey }) => {
    const [isOpened, setIsOpened] = useState(false);

    const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpened(!isOpened));

    return (
        <div class="alert alert-warning m-0 p-0 border-0 bg-transparent shadow-none col-5">
            <div class="d-flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 9v4"></path><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"></path><path d="M12 16h.01"></path></svg>
                </div>
                <div>
                    Hello user, you have a reservation need to assign.
                </div>
                <button className="badge badge-outline text-orange ms-2 cursor-pointer" onClick={(e) => {
                    decoratedOnClick();
                }}>{!isOpened ? 'Click to see' : 'Click to hide'}</button>
            </div>
        </div>
    );
}

const CanvasForEditReservation = () => {
    const {
        reservationsCanvas,
        setReservationsCanvas,
        guestDetailsForm,
        setGuestDetailsForm,
        countryOptions,
        defaultOptionsCountry,
        currencyOptions,
        defaultOptionsCurrency,
        agentOptions,
        defaultOptionsAgent,
        birthdayOptions,
        defaultOptionsBirthday,
        commentsForm,
        setCommentsForm,
        invoiceForm,
        setInvoiceForm,
        optionsStatus,
        defaultStatusOption,
        optionsMark,
        defaultMarkOption,
        optionsPaymentType,
        defaultPaymentTypeOption,
        rooms,
        setRooms,
        addRoom,
        removeRoom,
        defaultRoomTypeOption,
        selectRoomType,
        defaultRoomNumberOption,
        defaultRateplanOption,
        optionsAdults,
        defaultAdultsOption,
        optionsChildren,
        defaultChildrenOption,
        fetchRateplansDays,
        getRoomTotal,
        handleUpdateReservation,
        handleCreateReservation,
        selectRoomNumber,
        fetchRoomTypes,
        fetchRoomTypesInUpdate
    } = useCalendarContext();

    return (
        <Offcanvas show={reservationsCanvas.show} onHide={() => {
            setReservationsCanvas((prevState) => ({ ...prevState, show: false }));
        }} placement='end' style={{ width: '100%' }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{reservationsCanvas.booking_id ? 'Update Reservation' : 'Create Reservation'}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="pt-3">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    if (reservationsCanvas.booking_id) {
                        handleUpdateReservation().then(async (response) => {
                            if (response.success) {
                                setReservationsCanvas((prevState) => ({ ...prevState, show: false }));
                                const result = await Swal.fire({
                                    icon: 'success',
                                    title: 'Success',
                                    text: 'Reservation updated successfully',
                                    showConfirmButton: true,
                                    confirmButtonText: 'OK',
                                });
                                if (result.isConfirmed) {
                                    window.location.reload();
                                }
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: response.message,
                                });
                                console.error(response.errors);
                            }
                        });
                    } else {
                        handleCreateReservation().then(async (response) => {
                            if (response.success) {
                                setReservationsCanvas((prevState) => ({ ...prevState, show: false }));
                                const result = await Swal.fire({
                                    icon: 'success',
                                    title: 'Success',
                                    text: 'Reservation created successfully',
                                    showConfirmButton: true,
                                    confirmButtonText: 'OK',
                                });
                                if (result.isConfirmed) {
                                    window.location.reload();
                                }
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: response.message,
                                });
                                console.error(response.errors);
                            }
                        });
                    }
                }}>
                    <Tabs
                        defaultActiveKey='invoice'
                        className="mb-3 text-blue"
                        variant="underline"
                    >
                        <Tab eventKey='invoice' title='Invoice'>
                            <InvoiceTab
                                reservationsCanvas={reservationsCanvas}
                                invoiceForm={invoiceForm}
                                setInvoiceForm={setInvoiceForm}
                                optionsStatus={optionsStatus}
                                defaultStatusOption={defaultStatusOption}
                                optionsMark={optionsMark}
                                defaultMarkOption={defaultMarkOption}
                                optionsPaymentType={optionsPaymentType}
                                defaultPaymentTypeOption={defaultPaymentTypeOption}
                                rooms={rooms}
                                setRooms={setRooms}
                                removeRoom={removeRoom}
                                defaultRoomTypeOption={defaultRoomTypeOption}
                                selectRoomType={selectRoomType}
                                defaultRoomNumberOption={defaultRoomNumberOption}
                                defaultRateplanOption={defaultRateplanOption}
                                optionsAdults={optionsAdults}
                                defaultAdultsOption={defaultAdultsOption}
                                optionsChildren={optionsChildren}
                                defaultChildrenOption={defaultChildrenOption}
                                fetchRateplansDays={fetchRateplansDays}
                                getRoomTotal={getRoomTotal}
                                selectRoomNumber={selectRoomNumber}
                                fetchRoomTypes={fetchRoomTypes}
                                fetchRoomTypesInUpdate={fetchRoomTypesInUpdate}
                            />
                        </Tab>
                        <Tab eventKey='guest_details' title='Guest Details'>
                            <GuestDetails
                                guestDetailsForm={guestDetailsForm}
                                setGuestDetailsForm={setGuestDetailsForm}
                                countryOptions={countryOptions}
                                defaultOptionsCountry={defaultOptionsCountry}
                                currencyOptions={currencyOptions}
                                defaultOptionsCurrency={defaultOptionsCurrency}
                                agentOptions={agentOptions}
                                defaultOptionsAgent={defaultOptionsAgent}
                                birthdayOptions={birthdayOptions}
                                defaultOptionsBirthday={defaultOptionsBirthday}
                            />
                        </Tab>
                        <Tab eventKey='comments' title='Comments'>
                            <Comments commentsForm={commentsForm} setCommentsForm={setCommentsForm} />
                        </Tab>
                    </Tabs>

                    {/* button flex between */}
                    <div className="my-4 border-bottom"></div>
                    <div className="d-flex flex-column justify-content-end gap-2">
                        <div className="d-flex justify-content-between gap-2">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    addRoom();
                                }}
                            >
                                Add Room
                            </button>
                            <button type="submit" className="btn btn-primary">
                                {reservationsCanvas.booking_id ? 'Update Reservation' : 'Create Reservation'}
                            </button>
                        </div>
                    </div>
                </form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};