import React, { useEffect, useState } from "react";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import styles from "./Dashboard.module.css";
import ReportsPage from "../../reports-page";
import { getRequest } from "services/api";
import { getFormattedMonthYear } from "utils/dateHelpers";
import { formatPrice } from "utils/utils";
import { Card } from "react-bootstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const DashboardReport = () => {
  const [data, setData] = useState({
    data: null,
    loading: false,
    error: null,
  });

  const [chartData, setChartData] = useState({
    numberReservationBySource: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
          hoverOffset: 4,
        },
      ],
    },
    revenueReservationBySource: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
          hoverOffset: 4,
        },
      ],
    },
    barChartData: {
      labels: [],
      datasets: [
        {
          label: "Occupancy Percentage",
          data: [],
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    },
  });

  const fetchData = async () => {
    setData({ data: [], loading: true, error: null });

    try {
      const response = await getRequest("/dashboard?month=12&year=2024");
      if (response.success) {
        setData((prev) => ({ ...prev, data: response.data, error: null }));
      }
    } catch (error) {
      setData((prev) => ({ ...prev, error }));
    } finally {
      setData((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.data) {
      setChartData({
        numberReservationBySource: {
          labels: data.data.ota_name || [],
          datasets: [
            {
              data: data.data.number_of_reservation_by_ota || [],
              backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
              hoverOffset: 4,
            },
          ],
        },
        revenueReservationBySource: {
          labels: data.data.ota_name || [],
          datasets: [
            {
              data: (data.data.total_sale_by_ota || []).map((value) =>
                parseFloat(value)
              ),
              backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
              hoverOffset: 4,
            },
          ],
        },
        barChartData: {
          labels: data.data.occupancy_months || [],
          datasets: [
            {
              label: "Occupancy Percentage",
              data: data.data.occupancy_percentage || [],
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
      });
    }
  }, [data]);

  return (
    <ReportsPage>
      <div className={styles.container}>
        <div className="row mb-4 align-items-center">
          <div className="col-md-6">
            <label htmlFor="change-date" className="form-label">
              Change Date:
            </label>
            <input
              type="date"
              className="form-control rounded"
              id="change-date"
              defaultValue="2024-10-01"
            />
          </div>
          <div className="col-md-6 text-end">
            <button className="btn btn-primary px-4 py-2">
              Send Report By Email
            </button>
          </div>
        </div>

        <div className="text-center mb-4">
          <h3>
            Transaction Period:{" "}
            {getFormattedMonthYear(data?.data?.month, data?.data?.year) ||
              "Loading..."}
          </h3>
        </div>

        <div className="mb-4">
          <h4 className={styles.cardTitle}>Number Of Reservations</h4>
          <div className="row text-center g-4">
            <div className="col-md-4">
              <div className={`${styles.card} bg-white`}>
                <p className={styles.cardText}>This Month</p>
                <p className={styles.cardValue}>
                  {data?.data?.number_of_reservation_month || "N/A"}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles.card} bg-white`}>
                <p className={styles.cardText}>Compared To Last Month</p>
                <p className={styles.cardValue}>
                  {data?.data?.number_of_reservation_submonth || "N/A"}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles.card} bg-white`}>
                <p className={styles.cardText}>Compared To Last Year</p>
                <p className={styles.cardValue}>
                  {data?.data?.number_of_reservation_last_year || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <h4 className={styles.cardTitle}>Total Sales Based On Stay Date</h4>
          <div className="row text-center g-4">
            <div className="col-md-4">
              <div className={`${styles.card} bg-white`}>
                <p className={styles.cardText}>This Month</p>
                <p className={styles.cardValue}>
                  {formatPrice(data?.data?.total_sale_of_month || 0)}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles.card} bg-white`}>
                <p className={styles.cardText}>Compared To Last Month</p>
                <p className={styles.cardValue}>
                  {formatPrice(data?.data?.total_sale_of_submonth || 0)}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className={`${styles.card} bg-white`}>
                <p className={styles.cardText}>Compared To Last Year</p>
                <p className={styles.cardValue}>
                  {formatPrice(data?.data?.total_sale_of_last_year || 0)}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          {/* Card for Number of Reservations by Source */}
          <div className="col-md-6">
            <Card className="p-3 bg-white rounded shadow-sm">
              <Card.Header className="bg-white border-0 pb-0">
                <h5 className="text-center m-0">
                  Number Of Reservations By Source Of Bookings
                </h5>
              </Card.Header>
              <Card.Body className="p-0 m-0 d-flex justify-content-center align-items-center">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    maxWidth: "500px", // Reduce the max width for smaller chart
                    height: "400px", // Add height to explicitly control chart size
                  }}
                >
                  {/* Pie Chart with Legend */}
                  <div style={{ flex: 2 }}>
                    <Pie
                      data={chartData.numberReservationBySource}
                      options={{
                        plugins: {
                          legend: {
                            position: "right", // Set legend position to the right
                            align: "center", // Center-align the legend
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          {/* Card for Total Revenue by Source */}
          <div className="col-md-6">
            <Card className="p-3 bg-white rounded shadow-sm">
              <Card.Header className="bg-white border-0 pb-0">
                <h5 className="text-center m-0">
                  Total Revenue By Source Of Bookings
                </h5>
              </Card.Header>
              <Card.Body className="p-0 m-0 d-flex justify-content-center align-items-center">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    maxWidth: "500px", // Reduce the max width for smaller chart
                    height: "400px", // Add height to explicitly control chart size
                  }}
                >
                  {/* Pie Chart with Legend */}
                  <div style={{ flex: 2 }}>
                    <Pie
                      data={chartData.revenueReservationBySource}
                      options={{
                        plugins: {
                          legend: {
                            position: "right", // Set legend position to the right
                            align: "center", // Center-align the legend
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>

        {/* <div className="row mt-5">
          <div className="col-md-6">
            <div className={`${styles.card} bg-white`}>
              <h6 className={styles.moduleHeader}>
                Number Of Reservations By Source Of Bookings
              </h6>
              <div className={styles.pieChart}>
                <Pie data={chartData.numberReservationBySource} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`${styles.card} bg-white`}>
              <h6 className={styles.moduleHeader}>
                Total Revenue By Source Of Bookings
              </h6>
              <div className={styles.pieChart}>
                <Pie data={chartData.revenueReservationBySource} />
              </div>
            </div>
          </div>
        </div> */}

        {/* Row 4: Table */}
        <div className={`${styles.card} bg-white mt-5`}>
          <h6 className={styles.moduleHeader}>Bookings Details</h6>
          <div className="table-responsive">
            <table className="table table-bordered text-center">
              <thead>
                <tr>
                  <th>Agent Name</th>
                  <th>Percentage</th>
                  <th>Nb Reservations</th>
                  <th>Nb Nights</th>
                  <th>Avg. Length Of Stay</th>
                  <th>Avg. Earning (IDR)</th>
                  <th>Total Earning (IDR)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Booking.com</td>
                  <td>72%</td>
                  <td>18</td>
                  <td>104</td>
                  <td>5.78</td>
                  <td>2,994,452</td>
                  <td>311,423,044</td>
                </tr>
                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>

        <div className={`${styles.card} bg-white mt-5`}>
          <h6 className={styles.moduleHeader}>
            Occupancy Percentage - Last 12 Months
          </h6>
          <div className={styles.barChart}>
            <Bar data={chartData.barChartData} />
          </div>
        </div>
      </div>
    </ReportsPage>
  );
};

export default DashboardReport;
