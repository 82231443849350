import { useState, useEffect } from 'react';
import { getRequest } from 'services/api';

const defaultGuestDetailsForm = {
    phone: '',
    address: '',
    country: '',
    state: '',
    city: '',
    postal_code: '',
    id_number: '',
    currency: '',
    non_refund: false,
    maintenance: false,
    agent_id: '',
    arrival_time: '',
    birthday_day: '',
    birthday_month: '',
    birthday_year: '',
};

const defaultAgentOptions = {
    data: [],
    error: null,
    isLoading: false,
};

const defaultCountryOptions = {
    data: [],
    error: null,
    isLoading: false,
};

const defaultCurrencyOptions = {
    data: [],
    error: null,
    isLoading: false,
};

const defaultBirthdayOptions = {
    day: Array.from({ length: 31 }, (_, i) => i + 1),
    month: [
        { value: 'january', label: 'January' },
        { value: 'february', label: 'February' },
        { value: 'march', label: 'March' },
        { value: 'april', label: 'April' },
        { value: 'may', label: 'May' },
        { value: 'june', label: 'June' },
        { value: 'july', label: 'July' },
        { value: 'august', label: 'August' },
        { value: 'september', label: 'September' },
        { value: 'october', label: 'October' },
        { value: 'november', label: 'November' },
        { value: 'december', label: 'December' },
    ],
    year: Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i),
};

const useGuestDetailsTab = () => {
    const [guestDetailsForm, setGuestDetailsForm] = useState(defaultGuestDetailsForm);
    const [agentOptions, setAgentOptions] = useState(defaultAgentOptions);
    const [countryOptions, setCountryOptions] = useState(defaultCountryOptions);
    const [currencyOptions, setCurrencyOptions] = useState(defaultCurrencyOptions);
    const [birthdayOptions, setBirthdayOptions] = useState(defaultBirthdayOptions);

    const clearGuestDetailsTab = () => {
        setGuestDetailsForm(defaultGuestDetailsForm);
    };

    const fetchAgentOptions = async () => {
        setAgentOptions((prev) => ({ ...prev, isLoading: true }));
        try {
            const response = await getRequest('/agent?all=1');
            if (response.success) {
                const data = response.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
                setAgentOptions({ data, error: null, isLoading: false });
            }
        } catch (error) {
            setAgentOptions((prev) => ({ ...prev, error, isLoading: false }));
        }
    };

    const fetchCountryOptions = async () => {
        setCountryOptions((prev) => ({ ...prev, isLoading: true }));
        try {
            const response = await getRequest('/country-list');
            if (response.success) {
                const data = response.data.map((item) => ({
                    value: item.value,
                    label: item.text,
                }));
                setCountryOptions({ data, error: null, isLoading: false });
            }
        } catch (error) {
            setCountryOptions((prev) => ({ ...prev, error, isLoading: false }));
        }
    };

    const fetchCurrencyOptions = async () => {
        setCurrencyOptions((prev) => ({ ...prev, isLoading: true }));
        try {
            const response = await getRequest('/currency-list');
            if (response.success) {
                const data = response.data.map((item) => ({
                    value: item.value,
                    label: item.text,
                }));
                setCurrencyOptions({ data, error: null, isLoading: false });
            }
        } catch (error) {
            setCurrencyOptions((prev) => ({ ...prev, error, isLoading: false }));
        }
    }

    const defaultOptionsAgent = () => {
        return agentOptions.data.find((item) => item.value === guestDetailsForm.agent_id);
    }

    const defaultOptionsCountry = () => {
        return countryOptions.data.find((item) => item.value === guestDetailsForm.country);
    }

    const defaultOptionsCurrency = () => {
        return currencyOptions.data.find((item) => item.value === guestDetailsForm.currency);
    }
    
    const defaultOptionsBirthday = () => {
        return {
            day: birthdayOptions.day.find((item) => item === guestDetailsForm.birthday_day),
            month: birthdayOptions.month.find((item) => item.value === guestDetailsForm.birthday_month),
            year: birthdayOptions.year.find((item) => item === guestDetailsForm.birthday_year),
        }
    }

    useEffect(() => {
        fetchAgentOptions();
        fetchCountryOptions();
        fetchCurrencyOptions();
    }, []);

    return {
        clearGuestDetailsTab,
        guestDetailsForm,
        setGuestDetailsForm,
        agentOptions,
        defaultOptionsAgent,
        countryOptions,
        defaultOptionsCountry,
        currencyOptions,
        defaultOptionsCurrency,
        birthdayOptions,
        defaultOptionsBirthday,
    };
};

export default useGuestDetailsTab;