import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FullLoader from "components/loader/full-loader";
import Suspense from "components/suspense/suspense";
import { useLoading } from "contexts/LoadingContext";
import { useReload } from "contexts/ReloadContext";
import { useFetchData } from "hooks/useApiHooks";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import Swal from "sweetalert2";
import MenuTabs from "./components/MenuTabs";
import StatsCard from "./components/StatsCard";
import PieChart from "./components/PieChart";
import ReservationsTable from "./components/ReservationsTable";
import OccupancyChart from "./components/OccupancyChart";
import PropertyContent from "./Property/PropertyContent";
import GroupContent from "./Group/GroupContent";
import GrowthContent from "./Growth/GrowthContent";

const CMReports = () => {
  const { reload } = useReload();
  const { data: channelData, error } = useFetchData(
    "/channex-iframe-url",
    reload,
    "channel-setup"
  );
  const { isLoading } = useLoading();

  if (error) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Failed to fetch channel data",
    });
  }

  const [activeTab, setActiveTab] = useState("dashboard");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const statsData = [
    {
      title: "Number of Reservations (This Month)",
      value: "153",
      subtitle: "November 2024",
      subtitleColor: "orange",
    },
    {
      title: "Compared to Last Month",
      value: "209",
      subtitle: "October 2024",
      subtitleColor: "teal",
    },
    {
      title: "Compared to Last Year",
      value: "104",
      subtitle: "November 2023",
      subtitleColor: "teal",
    },
    {
      title: "Total Sales (This Month)",
      value: "309,845,206 IDR",
      subtitle: "November 2024",
      subtitleColor: "orange",
    },
    {
      title: "Compared to Last Month",
      value: "409,845,206 IDR",
      subtitle: "October 2024",
      subtitleColor: "teal",
    },
    {
      title: "Compared to Last Year",
      value: "209,845,206 IDR",
      subtitle: "November 2023",
      subtitleColor: "teal",
    },
  ];

  const pieChartsData = [
    {
      title: "Number of Reservations by Source of Bookings",
      data: [
        { name: "Booking.com", value: 65 },
        { name: "website", value: 10 },
        { name: "PMS", value: 5 },
        { name: "Tiket.com", value: 5 },
        { name: "Expedia", value: 5 },
        { name: "Agoda", value: 5 },
        { name: "Airbnb", value: 5 },
      ],
    },
    {
      title: "Total Revenue by Source of Bookings",
      data: [
        { name: "Booking.com", value: 88.6 },
        { name: "website", value: 1.3 },
        { name: "PMS", value: 0.7 },
        { name: "Tiket.com", value: 0.7 },
        { name: "Expedia", value: 0.7 },
        { name: "Agoda", value: 0.7 },
        { name: "Airbnb", value: 0.7 },
      ],
    },
  ];

  const columnsReservations = [
    { label: "Nama Agen", key: "agent" },
    { label: "Persentase", key: "percentage" },
    { label: "Jumlah Reservasi", key: "reservations" },
    { label: "Jumlah Malam", key: "nights" },
    { label: "Rata-rata Lama Menginap", key: "avgStay" },
    { label: "Pendapatan Rata-rata (IDR)", key: "avgEarning" },
    { label: "Total Pendapatan (IDR)", key: "totalEarning" },
  ];

  const generateRandomData = () => {
    const agents = [
      "Booking.com",
      "website",
      "PMS",
      "Tiket.com",
      "Expedia",
      "Agoda",
      "Airbnb",
    ];
    const randomAgent = agents[Math.floor(Math.random() * agents.length)];
    const randomPercentage = (Math.random() * 100).toFixed(2) + "%";
    const randomReservations = Math.floor(Math.random() * 200) + 1;
    const randomNights = Math.floor(Math.random() * 365) + 1;
    const randomAvgStay = (Math.random() * 5).toFixed(2);
    const randomAvgEarning = (Math.random() * 1000000).toFixed(0) + " IDR";
    const randomTotalEarning =
      (randomReservations * Math.random() * 10000).toFixed(0) + " IDR";

    return {
      agent: randomAgent,
      percentage: randomPercentage,
      reservations: randomReservations,
      nights: randomNights,
      avgStay: randomAvgStay,
      avgEarning: randomAvgEarning,
      totalEarning: randomTotalEarning,
    };
  };

  const reservationsData = Array.from({ length: 5 }, generateRandomData);

  return (
    <Suspense loader={<FullLoader />} loading={isLoading} type="channel-setup">
      <LayoutDashboard>
        <div className="container">
          <Container className="my-4">
            <MenuTabs activeTab={activeTab} onTabChange={handleTabChange} />
          </Container>

          {activeTab === "dashboard" && (
            <Container fluid className="dashboard-content">
              <Row className="stats-cards">
                {statsData.map((stat, index) => (
                  <Col key={index} md={4} className="">
                    <StatsCard
                      title={stat.title}
                      value={stat.value}
                      subtitle={stat.subtitle}
                      subtitleColor={stat.subtitleColor}
                    />
                  </Col>
                ))}
              </Row>
              <div className="charts-section">
                <Row className="gy-4">
                  {pieChartsData.map((chart, index) => (
                    <Col key={index} md={6}>
                      <PieChart title={chart.title} data={chart.data} />
                    </Col>
                  ))}
                </Row>
              </div>

              <ReservationsTable
                data={reservationsData}
                columns={columnsReservations}
              />
              <OccupancyChart />
            </Container>
          )}
          {activeTab === "property" && <PropertyContent />}
          {activeTab === "group" && <GroupContent />}
          {activeTab === "growth" && <GrowthContent />}
        </div>
      </LayoutDashboard>
    </Suspense>
  );
};

export default CMReports;
