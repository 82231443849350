import { usePaginationWithComponent } from "hooks/usePaginationWithComponent";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { deleteRequest, getRequest } from "services/api";
import { buildQueryString } from "utils/queryHelpers";

const usePropertyManagementHook = () => {
    const [propertyManagement, setPropertyManagement] = useState({
        data: [],
        pagination: null,
        loading: false,
        isInitialLoading: true,
        success: false,
        message: null,
    });

    const [propertyManagementOptions, setPropertyManagementOptions] = useState({
        search: "",
        page: 1,
        limit: 10,
    });

    const queryString = buildQueryString({
        search: propertyManagementOptions.search,
        page: propertyManagementOptions.page,
        limit: propertyManagementOptions.limit,
    });

    const totalPages = propertyManagement.pagination && propertyManagement.pagination?.last_page || 1;

    const Pagination = usePaginationWithComponent({
        currentPage: propertyManagementOptions.page,
        totalPages: totalPages,
        onPageChange: (page) => {
            setPropertyManagementOptions((prevState) => ({ ...prevState, page }));
        },
        visiblePages: 5,
    });

    const fetchPropertyManagement = async (query = '') => {
        try {
            const response = await getRequest(`/property${query}`);

            if (response.success) {
                return { success: true, data: response.data, pagination: response.pagination };
            } else {
                return { success: false, message: response.message };
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    const getPropertyManagement = async (query = '') => {
        setPropertyManagement((prevState) => ({ ...prevState, loading: true }));

        const response = await fetchPropertyManagement(query);

        if (response.success) {
            setPropertyManagement({ data: response.data, pagination: response.pagination, loading: false, success: true });
        } else {
            setPropertyManagement({ data: [], loading: false, success: false, message: response.message });
        }
    };

    const deleteProperty = async (id) => {
        try {
            const response = await deleteRequest(`/property/${id}`);

            if (response.success) {
                return { success: true, data: response.data };
            } else {
                return { success: false, message: response.message };
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    const handleSearch = debounce((term) => {
        setPropertyManagementOptions((prevState) => ({
            ...prevState,
            search: term,
            page: 1,
        }));
    }, 500);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                setPropertyManagement((prevState) => ({
                    ...prevState,
                    isInitialLoading: true,
                }));
                const data = await fetchPropertyManagement(queryString);
                if (data.success) {
                    setPropertyManagement((prevState) => ({
                        ...prevState,
                        data: data.data,
                        pagination: data.pagination,
                        isInitialLoading: false,
                    }));
                } else {
                    setPropertyManagement((prevState) => ({
                        ...prevState,
                        isInitialLoading: false,
                    }));
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
                setPropertyManagement((prevState) => ({
                    ...prevState,
                    isInitialLoading: false,
                }));
            }
        };
        fetchInitialData();
    }, []);

    useEffect(() => {
        if (!propertyManagement.isInitialLoading && queryString) {
            getPropertyManagement(queryString);
        }
    }, [queryString]);

    return {
        propertyManagement,
        propertyManagementOptions,
        setPropertyManagementOptions,
        Pagination,
        handleSearch,
        deleteProperty,
        getPropertyManagement
    };
};

export default usePropertyManagementHook;