import { API_URL } from 'utils/constants';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms)); // untuk test loading state

const MAX_RETRIES = 3; // Jumlah maksimal retry
const BACKOFF_BASE_TIME = 45000; // Waktu backoff dasar dalam ms

const fetchWrapper = async (url, method, data, retries = MAX_RETRIES) => {
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const token = localStorage.getItem('token');

    if (token) {
        options.headers.Authorization = `Bearer ${token}`;
    }

    if (data) {
        options.body = JSON.stringify(data);
    }

    for (let attempt = 0; attempt <= retries; attempt++) {
        try {
            const response = await fetch(`${API_URL}${url}`, options);

            if (!response.ok) {
                if (response.status === 429 && attempt < retries) {
                    // Jika Too Many Requests, tunggu waktu backoff
                    const retryAfter = response.headers.get('Retry-After');
                    const delayTime = retryAfter
                        ? parseInt(retryAfter, 10) * 1000
                        : BACKOFF_BASE_TIME * Math.pow(2, attempt); // Exponential backoff
                    await delay(delayTime);
                    continue; // Coba lagi
                }

                const errorData = response.status === 422 ? await response.json() : null;
                if (response.status === 422) {
                    throw {
                        code: errorData.code,
                        errors: errorData.errors,
                    };
                } else {
                    throw new Error(response.statusText);
                }
            }

            return await response.json(); // Berhasil, kembalikan respons JSON
        } catch (error) {
            if (attempt === retries) {
                // Jika telah mencapai batas retry, lemparkan error
                throw error;
            }
        }
    }
};

export const postRequest = (url, data) => fetchWrapper(url, 'POST', data);
export const patchRequest = (url, data) => fetchWrapper(url, 'PUT', data);
export const deleteRequest = (url) => fetchWrapper(url, 'DELETE');
export const getRequest = (url) => fetchWrapper(url, 'GET');