import React, { useEffect, useState } from 'react';
import ActionDropdown from './action-dropdown';
import { useRoomRate } from 'contexts/v2/pages/room-rate-setting/RoomRateContext';
import Loader from 'components/loader/loader';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

// Child Rates Component
const ChildRates = ({ roomId, refreshKey, setChildRatesVisible, indexToggle, onEditRate, onCreateLinkRate }) => {
    const { getRateDataByRoomId, isLoading, getSuccess, getMessage, removeRateplan, reloadRateDataByRoomId, removeLinkRateplan } = useRoomRate();
    const [rateData, setRateData] = useState([]);
    const [lastRefreshKey, setLastRefreshKey] = useState(refreshKey);

    const handleRateAction = (action, rateId) => {
        alert(`${action} Rate ID: ${rateId}`);
    };

    const handleDetelteRate = (rateId) => {
        const result = Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this rate!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try {
                    const response = await removeRateplan(rateId);
                    if (response.success) {
                        const data = await reloadData(roomId);
                        toast.success(response.message || 'Rate deleted successfully');
                        setRateData(data);
                        setChildRatesVisible((prev) => ({ ...prev, [indexToggle]: false }));
                    } else {
                        toast.error(response.message || 'There was an error deleting the rate');
                    }
                } catch (error) {
                    toast.error(error.message || 'There was an error deleting the rate');
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
            allowEscapeKey: () => !Swal.isLoading(),
        });
    };

    const hanldeDeleteLinkRate = (linkRateId) => {
        const result = Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this link rate!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try {
                    const response = await removeLinkRateplan(linkRateId);
                    if (response.success) {
                        const data = await reloadData(roomId);
                        toast.success(response.message || 'Link rate deleted successfully');
                        setRateData(data);
                        setChildRatesVisible((prev) => ({ ...prev, [indexToggle]: false }));
                    } else {
                        toast.error(response.message || 'There was an error deleting the link rate');
                    }
                } catch (error) {
                    toast.error(error.message || 'There was an error deleting the link rate');
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
            allowEscapeKey: () => !Swal.isLoading(),
        });
    };

    const handleEditRate = (rateId) => {
        onEditRate(rateId);
    };

    const fetchData = async () => {
        const data = await getRateDataByRoomId(roomId);
        setRateData(data);
    };

    const reloadData = async () => {
        const data = await reloadRateDataByRoomId(roomId);
        setRateData(data);
    };

    useEffect(() => {
        if (roomId && rateData.length === 0) {
            fetchData();
        }
    }, [roomId]);

    useEffect(() => {
        if (lastRefreshKey !== refreshKey && rateData.length > 0) {
            reloadData();
            setLastRefreshKey(refreshKey);
        }
    }, [refreshKey]);

    return (
        <>
            {/* Tampilkan LoadingComponent jika loading */}
            {isLoading(roomId) && (
                <tr>
                    <td colSpan="4">
                        <Loader />
                    </td>
                </tr>
            )}

            {/* Tampilkan ErrorComponent jika fetch gagal */}
            {getSuccess(roomId) === false && (
                <tr>
                    <td colSpan="4" className="text-danger text-center">
                        {getMessage(roomId)}
                    </td>
                </tr>
            )}

            {/* Tampilkan SuccessComponent jika fetch berhasil */}
            {getSuccess(roomId) === true && rateData && (
                rateData.map((rate) => (
                    <React.Fragment key={rate.id}>
                        <tr className="child-rate">
                            <td>{rate.name} (Max Guests: {rate.guest_max})</td>
                            <td>—</td>
                            <td>—</td>
                            <td className="text-end">
                                <ActionDropdown
                                    onEdit={() => handleEditRate(rate.id)}
                                    onCreateLink={() => onCreateLinkRate(rate.id)}
                                    onRemove={() => handleDetelteRate(rate.id)}
                                />
                            </td>
                        </tr>
                        {rate.children.map((child) => (
                            <tr key={child.id} className="child-rate">
                                <td style={{ paddingLeft: '90px' }}>
                                    {child.name} (Max Guests: {child.guest_max})
                                </td>
                                <td>—</td>
                                <td>—</td>
                                <td className="text-end">
                                    <ActionDropdown
                                        onRemove={() => hanldeDeleteLinkRate(child.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </React.Fragment>
                ))
            )}
        </>
    );
};

export default ChildRates;