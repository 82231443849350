import CurrencyInput from "components/form/currency-input";
import { useCreateData } from "hooks/useApiHooks";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import React, { useEffect, useState } from "react";
import Select from "react-select";

const optionsType = [
    { value: "rate", label: "Rate" },
    { value: "close_to_arrival", label: "Close to Arrival" },
    { value: "close_to_departure", label: "Close to Departure" },
    { value: "min_stay", label: "Min Stay" },
    { value: "stop_sell", label: "Stop Sell" },
];

const ChangeValue = ({ initialData, onSuccess, onError }) => {
    console.log('initialData', initialData);
    const [formData, setFormData] = useState({
        // roomType: initialData.additionalData.isRoom ? initialData.additionalData.roomDetail.find((value) => value.id === initialData.additionalData.roomId).title : initialData.additionalData.roomDetail.find((value) => value.id === initialData.additionalData.roomId.split("|")[0])?.title,
        roomTitle: '',
        rateplan: '',
        start_date: '',
        end_date: '',
        type: '',
        value: '',
        dummyRoom: '',
        property_id: '',
        room_type_id: '',
        rate_plan_id: '',
        rate_plan_name: '',
    });

    const { handleChange } = useFormChangeHandler(setFormData);

    const getRoomType = (roomData) => {
        if (roomData.isRoom) {
            return roomData.roomTitle;
        } else {
            return roomData.room_type_name;
        }
    };

    const getValue = (roomData) => {
        if (roomData.isRoom) {
            return initialData.title;
        } else {
            return roomData.price;
        }
    };

    useEffect(() => {
        const roomDetail = getRoomType(initialData.additionalData);
        setFormData((prev) => ({
            ...prev,
            roomTitle: roomDetail,
            type: initialData.additionalData.type,
            rate_plan_name: initialData.additionalData?.rate_plan_name,
            property_id: initialData.additionalData?.property_id,
            rate_plan_id: initialData.additionalData?.rate_plan_id,
            start_date: new Date(initialData.start).toISOString().split('T')[0],
            end_date: new Date(initialData.start).toISOString().split('T')[0],
            room_type_id: initialData.additionalData?.room_type_id,
            value: getValue(initialData.additionalData),
            // dummyRoom: roomDetail,
        }));
    }, [initialData]);

    const { createData: updateAvailability } = useCreateData('/set-availability');
    const { createData: updateRate } = useCreateData('/set-rate');

    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        formData.type === 'availability' ? updateAvailability : updateRate,
        onSuccess,
        onError
    );

    const handleBeforeSubmit = (e) => {
        e.preventDefault();

        // const payload = {
        //     date_from: formData.start_date,
        //     date_to: formData.end_date,
        //     property_id: formData.property_id,
        // };

        // if (formData.type) {
        //     payload.availability = formData.value;
        //     payload.room_type_id = formData.room_type_id;
        // } else {
        //     payload.rate = formData.value;
        //     payload.rate_plan_id = formData.rate_plan_id
        // }

        const payload = {
            values: [
                {
                    date_from: formData.start_date,
                    date_to: formData.end_date,
                    property_id: formData.property_id,
                    ...(formData.type === 'availability'
                        ? { availability: Number(formData.value), room_type_id: formData.room_type_id }
                        : { rate: formData.value, rate_plan_id: formData.rate_plan_id })
                }
            ]
        };

        console.log('payload', payload);

        handleSubmit(payload, e);
    }

    // if (initialData.additionalData.isRoom) {
    //     setFormData((prev) => ({
    //         ...prev,
    //         roomId: initialData.resourceId,
    //     }));
    // }

    return (
        <form className="d-flex flex-column" style={{ height: '100%' }} onSubmit={(e) => handleBeforeSubmit(e)}>
            <div className="action-sheet-content flex-grow-1">
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Room Title:
                    </label>
                    <div className="col-sm-8">
                        <span className="app-form-control">{formData.roomTitle}</span>
                    </div>
                </div>
                {formData.type === 'Availability' ? '' : (
                    <div className="row mb-3 align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">
                            Rateplan:
                        </label>
                        <div className="col-sm-8">
                            <span className="app-form-control">{formData.rate_plan_name}</span>
                        </div>
                    </div>
                )}
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        From Date:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="date"
                            className="form-control app-form-control rounded-1"
                            value={formData.start_date}
                            onChange={e => setFormData({ ...formData, start_date: e.target.value })}
                        />
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        To Date:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="date"
                            className="form-control app-form-control rounded-1"
                            value={formData.end_date}
                            onChange={e => setFormData({ ...formData, end_date: e.target.value })}
                        />
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Type:
                    </label>
                    <div className="col-sm-8">
                        {/* <input
                            type="text"
                            className="form-control app-form-control rounded-1"
                            value={formData.type ? 'Availability' : 'Rate'}
                            disabled={true}
                        /> */}
                        {formData.type === 'Availability' ? (
                            <input
                                type="text"
                                className="form-control app-form-control rounded-1"
                                value={formData.type}
                                disabled={true}
                            />
                        ) : (
                            <Select
                                value={optionsType.find(option => option.value === formData.type)}
                                options={optionsType}
                                onChange={(selectedOption) => {
                                    setFormData({ ...formData, type: selectedOption.value });
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Value:
                    </label>
                    <div className="col-sm-8">
                        {formData.type === 'availability' ? (
                            <input
                                type="text"
                                className="form-control app-form-control rounded-1"
                                value={formData.value}
                                onChange={e => setFormData({ ...formData, value: e.target.value })}
                            />
                        ) : (
                            <CurrencyInput
                                name="extra_child_rate"
                                className="app-form-control rounded-1"
                                value={formData.value} // Bind value ke formData
                                onChange={(e, rawValue) => {
                                    e.preventDefault();
                                    setFormData({ ...formData, value: rawValue });
                                }} // Simpan rawValue
                                error={formError?.value} // Menampilkan error jika ada
                                placeholder="Rp. 0" // Placeholder Rupiah default
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* Submit Button */}
            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    Change Value
                </button>
            </div>
        </form>
    );
};

export default ChangeValue;
