import React from "react";
import { Card } from "react-bootstrap";

const StatsCard = ({ title, value, subtitle, subtitleColor }) => {
  return (
    <div className="mb-4">
      <Card>
        <Card.Header>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <Card.Body>
          <h1>{value}</h1>
          <p style={{ color: subtitleColor || "inherit" }}>{subtitle}</p>
        </Card.Body>
      </Card>
    </div>
  );
};


export default StatsCard;
