import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import styled from 'styled-components';

import { formatToISO8601, getDayDiff } from 'utils/date-utils';
import { formatDateToLocal } from 'utils/utils';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import ShortReservation from './detail-reservation/short-reservation';
import { useCanvasHandler } from 'hooks/useCanvasHandler';
import { IconArrowLeft, IconArrowRight, IconJewishStar, IconJewishStarFilled, IconList, IconPlus, IconX } from '@tabler/icons-react';
import FilterCanvas from './filter/filter-canvas';
import CreateReservationPage from 'pages/reservation/reservation/components/create-reservation-page';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { postRequest } from 'services/api';
import MoveReservationModal from '../v2/components/move-reservation-modal';
import { formatDateToString } from 'utils/dateHelpers';
import ReservationTooltip from '../v2/components/reservation-tooltip';
import { useRef } from 'react';
import { Overlay } from 'react-bootstrap';
import Button from 'components/button/button';
import { useCalendarContext } from 'contexts/v2/pages/calendar/CalendarContext';
import { toast } from 'react-toastify';
import { useCanvas } from 'contexts/canvas-context';

// Styled components
const CalendarContainer = styled.div`
    table {
        margin-top:0px;
    }
    .fc {
        background-color: #FFFFFF; /* Menghilangkan background default */
    }
    .fc-event {
      background-color: transparent;
      border-width: 0;
      padding: 0;
    }

    .fc-event-title {
        position: relative;
        z-index: 1;  // Ensure the title remains on top
    }

    // style biar event miring sejajar
    .fc-timeline-event-harness {
        top: 0px !important;
    }

    /* Sembunyikan elemen default */
    th[colspan='1'].fc-day-today {
        background-color: rgb(222, 244, 255);
        color: white;
    }
    td.fc-day-today {
        background-color: rgb(222, 244, 255);
        color: white;
    }
    th[colspan='1'].fc-day-future {
        background-color: transparent;
    }
    th[colspan='1'].fc-day-past {
        background-color: transparent;
    }
    span.fc-datagrid-expander.fc-datagrid-expander-placeholder{
        display: none;
    }
    div.fc-datagrid-cell-frame{
        justify-content: center!important;
    }
    span.fc-icon{
        display: none;
    }
    span.fc-datagrid-expander{
        display: none;
    }
    .fc-timeline-events{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        height: 36px !important;
    }
    .fc-timeline-lane-frame{
        height: 36px !important;
    }
    .fc-header-toolbar{
        display: none;
    }
    .fc-timeline-slot-cushion:hover{
        text-decoration: none;
    }
    .fc-resource-timeline-divider{
        width: 0px !important;
    }
    .fc-timeline-slot-frame:not(:has(.fc-sticky)){
        height: 73px !important;
    }
    .fc-timeline-slot-frame:has(.fc-sticky){
        justify-content: start;
    }
`;

// const CustomEvent = styled.div`
//     background-color: ${props => props.color || '#007bff'};
//     color: white;
//     padding: 5px;
//     border-radius: 2px;
//     text-align: center;
//     position: relative;
//     transform: skew(-45deg); /* Efek skew untuk custom event */
//     overflow: hidden; /* Ensure content does not overflow */

//     span {
//         display: inline-block;
//         transform: skew(45deg); /* Balikkan skew */
//         font-size: 12px;
//     }

//     img {
//         margin-top: 5px;
//         transform: skew(45deg); /* Balikkan skew */
//     }
// `;

const CustomEvent = styled.div`
  position: relative;
  height: 30px !important;
  color: white;
  text-align: center;
  border-radius: 5px;
  top: 3px;

  // Adding the pseudo-element for the skew effect
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: ${props => props.left}%;
    width: ${props => props.width}%;
    height: 100%;
    transform: skewX(-25deg);
    transform-origin: top left;
    z-index: -1;  // Make sure the pseudo-element is behind the content
    background-color: ${props => props.color}; /* Example background color */
    // border-left: 3px solid #fff;  // Add border to the left
  }
`;

const CustomSlotLabel = styled.div`
    position: relative;
    height: 36px !important;
    color: white;
    text-align: center;
    border-radius: 5px;
`;

// const getIcon = (bookedBy) => {
//     switch (bookedBy) {
//         case 'Booking.com':
//             return <img src="/icons/Booking.com_Icon_2022.svg" alt="Booking.com" width={16} height={16} />;
//         case 'Airbnb':
//             return <img src="/icons/airbnb.png" alt="Airbnb" width={16} height={16} />;
//         case 'Expedia':
//             return <img src="/icons/expedia.png" alt="Expedia" width={16} height={16} />;
//         case 'Agoda':
//             return <img src="/icons/agoda.png" alt="Agoda" width={16} height={16} />;
//         default:
//             return null;
//     }
// };

const CalendarBooking = () => {
    const {
        clearMove,
        setReservationDetails,
        setRoomInfo,
        setRoomNumberInfo,
        handleCreateReservationCanvas,
        setInvoiceForm,
        resourcesPayload,
        eventsPayload,
        handleNextMonth: moveNext,
        handlePrevMonth: moveBack,
        handleBackToToday: resetToday,
        calendarSettings,
        setCalendarSettings,
        occupancyPayload,
        handleDateRangeChange,
        handleDateClickChange,
        moveToUnsignedBooking,
        handleRemoveFromUnsigned,
        handleFilterCalendar
    } = useCalendarContext();

    const { hideCanvas } = useCanvas();

    const today = new Date();
    const todayFormatted = formatDateToLocal(today);

    const navigate = useNavigate();

    const [isCanMove, setIsCanMove] = useState(true);
    const [showModal, setShowModal] = useState({
        show: false,
        data: null
    });
    const [dragInfo, setDragInfo] = useState(null);

    const [tooltipShow, setTooltipShow] = useState(false);
    const target = useRef(null);

    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    const handleEventDrop = async (info) => {
        const { event, oldEvent } = info;

        console.log('Event:', event);

        // Access the updated start and end dates
        const newStart = event.start;
        const newEnd = event.end;

        const { _def, extendedProps } = event;
        const { resourceIds } = _def;

        const oldStart = oldEvent.start;

        console.log('Resource IDs:', resourceIds[0].split('|')[1]);
        console.log('booking_id:', extendedProps.booking_room_id);

        console.log('old end:', info.oldEvent.start);

        // alert('Event dropped: ' + event.title);

        console.log('Old start:', oldStart !== newStart);

        console.log('extendedProps:', extendedProps);

        if (formatDateToString(oldStart) !== formatDateToString(newStart)) {
            const response = await Swal.fire({
                icon: 'error',
                title: 'Cannot move reservation to different date',
                text: 'You cannot move reservation to different date',
                confirmButtonText: 'OK',
            });

            if (response.isConfirmed) {
                info.revert();
            }
            return;
        }

        if (isCanMove == false) {
            // Tampilkan SweetAlert dengan pesan sukses
            const result = await Swal.fire({
                icon: 'error',
                title: 'Failed to move reservation',
                text: 'You cannot move reservation to different room type',
                confirmButtonText: 'OK',
            });

            // Jika pengguna menekan tombol OK, lakukan navigasi
            if (result.isConfirmed) {
                info.revert();
            }
            return;
        }

        setDragInfo(info);

        setShowModal({
            show: true,
            data: {
                event: event,
                info: info,
            }
        });
        setReservationDetails((prevState) => ({
            ...prevState,
            id: extendedProps.booking_id,
        }));
        setRoomInfo((prevState) => ({
            ...prevState,
            id: resourceIds[0].split('|')[0],
        }));
        setRoomNumberInfo((prevState) => ({
            ...prevState,
            oldRoom: {
                ...prevState.oldRoom,
                id: oldEvent._def.resourceIds[0].split('|')[1],
            },
            newRoom: {
                ...prevState.newRoom,
                id: event._def.resourceIds[0].split('|')[1],
            }
        }));
    };

    const handleEventClick = (cliclInfo) => {
        cliclInfo.jsEvent.preventDefault();
        // alert('Event clicked: ' + cliclInfo.event);
        // onEventClick(cliclInfo.event);

        if (cliclInfo.event.extendedProps.booking_id == undefined) return;

        // showCanvasHandler('Detail Booking',
        //     <ShortReservation
        //         bookingId={cliclInfo.event.extendedProps.booking_id}
        //         onSuccess={() => console.log('Success')}
        //         onError={() => console.log('Error')}
        //     />
        // );

        setTooltipShow(!tooltipShow);
    };

    const handleDateClick = (dateClickInfo) => {
        // alert('Date clicked: ' + dateClickInfo.dateStr);
        // alert('Date clicked: ' + dateClickInfo.title);
        // if (dateClickInfo.title == undefined) return;
        // onEventClick(dateClickInfo.dateStr);
        console.log('Date clicked: ', dateClickInfo.dateStr);
        const departure_date = new Date(dateClickInfo.dateStr);
        departure_date.setDate(departure_date.getDate() + 1);

        const initialData = {
            arrival_date: dateClickInfo.dateStr,
            departure_date: formatDateToLocal(departure_date),
        };

        // showCanvasHandler('Create Reservation',
        //     <CreateReservationPage
        //         initialData={initialData}
        //         onSuccess={handleSuccess}
        //         onError={handleError}
        //     />
        // );
        handleCreateReservationCanvas();
        handleDateClickChange(0, dateClickInfo.dateStr);
        // setInvoiceForm((prevState) => ({
        //     ...prevState,
        //     arrival_date: dateClickInfo.dateStr,
        //     departure_date: formatDateToLocal(departure_date),
        // }));
    };

    const renderEventContent = (eventInfo) => {
        const bgColor = eventInfo.event.backgroundColor || eventInfo.event.extendedProps.bgColor;
        // console.log('bgColor', bgColor);
        const textColor = eventInfo.event.textColor || 'black'; // Default teks hitam jika belum diklik
        // console.log();

        // console.log('Event Info:', eventInfo.event.extendedProps);

        if (eventInfo.event.extendedProps.isRoom) {
            return (

                // <div className='d-flex justify-content-center align-middle pt-2 text-blue'>
                //     {/* <h5>{eventInfo.event.title}</h5> */}
                //     <span className='badge bg-primary text-white p-2'>{eventInfo.event.title}</span>
                // </div>
                <div className='w-100 h-100 available' style={{ backgroundColor: 'transparent', color: textColor, borderRadius: 0 }}>
                    <div className='text-center d-flex justify-content-center align-items-center pt-2'>
                        <span className='fs-5'>{eventInfo.event.title}</span>
                        {/* <span className='d-block fs-5'>Rp. 192.00</span> */}
                    </div>
                </div>
            );
        } else {
            const dayDiff = getDayDiff(eventInfo.event.start, eventInfo.event.end);
            const left = 100 / (dayDiff + 1) / 2;
            const width = 100 - left * 2;
            // const dayDiff = getDayDiff(eventInfo.event.start, eventInfo.event.end) > 1 ? getDayDiff(eventInfo.event.start, eventInfo.event.end) + 1 : 1;
            const maxLength = 5;
            const title = eventInfo.event.title;
            const displayTitle = title.length > maxLength ? title.substring(0, (dayDiff * maxLength)) + '...' : title;

            return (
                // <CustomEvent color={eventInfo.event.extendedProps.bgColor} left={left} width={width} dayDiffSlot={dayDiff} className='d-flex align-items-center justify-content-center'>
                // <RenderTooltip />
                <RenderTooltip
                    bgColor={bgColor}
                    left={left}
                    width={width}
                    dayDiff={dayDiff}
                    eventInfo={eventInfo}
                    displayTitle={displayTitle}
                />
            );
        }
    };

    const renderSlotLabelContent = (arg) => {
        const { date, level, text } = arg;
        const dateFormatted = formatDateToLocal(date);
        // const random = Math.floor(Math.random() * 100);
        const occupancy = occupancyPayload.find(occupancy => {
            return formatDateToLocal(new Date(occupancy.date)) == dateFormatted;
        });

        // console.log('occupancy:', occupancyPayload.find((occupancy) => {
        //     console.log('occupancy.date:', formatDateToLocal(new Date(occupancy.date)), dateFormatted);
        //     return formatDateToLocal(new Date(occupancy.date)) == dateFormatted;
        // }), dateFormatted);

        // const percentage = Math.floor(Math.random() * 100) + '.00%';
        const percentage = Math.floor(occupancy?.occupancy) + '%';
        if (level == 0) {
            return (
                <div className="custom-slot-label ps-2 text-primary">
                    {text}
                </div>
            )
        } else {
            if (dateFormatted == todayFormatted) {
                return (
                    <div className='py-1 px-2 text-black'>
                        {/* <div className='badge bg-secondary text-white rounded-circle my-1'>1</div> */}
                        <div className='fs-5'>{text}</div>
                        <div className='fs-5'>{percentage}</div>
                    </div>
                );
            } else {
                return (
                    <div className='py-1 px-2 text-secondary'>
                        {/* <div className='badge bg-secondary text-white rounded-circle my-1'>1</div> */}
                        <div className='fs-5'>{text}</div>
                        <div className='fs-5'>{percentage}</div>
                    </div>
                )
            }
        }
    };

    const renderResourceLabel = (arg) => {
        const { resource } = arg;
        const { parentId, title } = resource._resource;
        const { type } = resource.extendedProps;
        const random = Math.random() < 0.5;
        if (parentId == '') {
            // Resource parent
            return (
                <div className="fc-resource-parent d-inline-block ms-1 fw-bold d-flex justify-content-between">
                    {/* <IconBedFilled size={24} className='me-2' /> */}
                    <span className='text-primary'>{title}</span>
                    <OverlayTrigger
                        placement="right" // Menentukan posisi tooltip di sisi kanan
                        overlay={
                            <Tooltip id="tooltip-right">
                                Availability
                            </Tooltip>
                        }
                    >
                        <span className="fw-bold" style={{ cursor: 'pointer' }}>
                            AVL
                        </span>
                    </OverlayTrigger>
                </div>
            );
        } else {
            // Resource child
            if (title === 'Unsigned Rooms') {
                return (
                    <div className="fc-resource-child d-flex justify-content-between">
                        {/* <IconHash size={24} className='me-2' /> */}
                        <span className='ms-2 text-orange'>{title}</span>
                    </div>
                )
            } else {
                return (
                    <div className="fc-resource-child d-flex justify-content-between">
                        {/* <IconHash size={24} className='me-2' /> */}
                        <span className='ms-2'>{title}</span>
                        {/* {random ? (
                            <OverlayTrigger
                                placement="right" // Menentukan posisi tooltip di sisi kanan
                                overlay={
                                    <Tooltip id="tooltip-right">
                                        New Reservation
                                    </Tooltip>
                                }
                            >
                                <span className='text-danger' style={{ cursor: 'pointer' }}>
                                    <IconJewishStarFilled size={8} />
                                </span>
                            </OverlayTrigger>
                        ) : ''} */}
                    </div>
                );
            }
        }
    };

    const handleFilterCanvas = () => {
        showCanvasHandler('Filter Reservation',
            <FilterCanvas
                calendarSettings={calendarSettings}
                handleFilterCalendar={handleFilterCalendar}
                onSameFilter={() => {
                    hideCanvas();
                }}
                onSuccess={(e) => {
                    hideCanvas();
                    toast.success(e);
                }}
                onFailed={(e) => {
                    hideCanvas();
                    toast.error(e);
                }}
            />);
    };

    const renderResourceAreaHeaderContent = (arg) => {
        return (
            <div className="d-block">
                <div className='d-flex justify-content-between w-100'>
                    <h3 className='fs-2 text-secondary'>Reservation Calendar</h3>
                </div>
                <div className='d-flex mt-2 w-100 m-auto'>
                    <button className='btn btn-sm btn-outline-primary btn-pill me-1' onClick={(e) => moveBack(e)}>
                        <IconArrowLeft size={12} />
                    </button>
                    <button className='btn btn-sm btn-outline-primary w-100 btn-pill me-1' onClick={(e) => resetToday(e)}>
                        TODAY
                    </button>
                    <button className='btn btn-sm btn-outline-primary btn-pill me-1' onClick={(e) => moveNext(e)}>
                        <IconArrowRight size={12} />
                    </button>
                </div>
                <div className='d-flex mt-2 w-100 m-auto'>
                    <button className='btn btn-sm btn-outline-primary w-100 btn-pill me-1' onClick={handleFilterCanvas}>
                        FILTER
                    </button>
                </div>
            </div>
        );
    };

    const handleSelect = (info) => {
        handleCreateReservationCanvas();
        handleDateRangeChange(0, info.startStr, info.endStr);
    };

    const handleEventOverlap = (stillEvent, movingEvent) => {
        // toast.error(movingEvent.title + ' cannot be moved to ' + stillEvent.title);
        // Swal.fire({
        //     icon: 'error',
        //     title: 'Failed to move reservation',
        //     text: movingEvent.title + ' cannot be moved to ' + stillEvent.title,
        //     confirmButtonText: 'OK',
        // });
        return false;
    };

    const moveToEvent = () => {
        const calendarApi = target.current.getApi();

        // Find the event by ID
        const event = moveToUnsignedBooking;
        if (event) {
            // Navigate to the event's start date
            calendarApi.gotoDate(formatToISO8601(event.checkin_date));

            // Scroll to the resource row
            setTimeout(() => {
                const resourceRow = document.querySelector(`[data-resource-id="${event.room_type_id}|${event.room_id || 'unassigned'}"]`);
                if (resourceRow) {
                    resourceRow.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }, 100); // Slight delay to ensure the timeline view updates
            handleRemoveFromUnsigned();
        } else {
            toast.error('Unsigned booking not found in the calendar');
        }
    };

    useEffect(() => {
        if (moveToUnsignedBooking) {
            moveToEvent();
        }
    }, [moveToUnsignedBooking]);

    return (
        <CalendarContainer>
            <FullCalendar
                ref={target}
                plugins={[resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: '',
                    center: '',
                    right: ''
                }}
                // initialDate={calendarSettings.firstDay}
                visibleRange={() => {
                    return {
                        start: calendarSettings.todayDate,
                        end: calendarSettings.lastDate
                    };
                }}
                height={'auto'}
                initialView="resourceTimeline"
                editable={false}
                eventResourceEditable={true}
                eventOverlap={(stillEvent, movingEvent) =>
                    handleEventOverlap(stillEvent, movingEvent)
                }
                resourceAreaHeaderContent={renderResourceAreaHeaderContent}
                // resources={RoomsAvailable}
                // resources={RoomsResource}
                resources={resourcesPayload}
                // events={EventRooms}
                // events={frontOfficeData}
                events={eventsPayload}
                showNonCurrentDates={true}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                eventDrop={handleEventDrop}
                selectable={true}
                select={handleSelect}
                slotLabelFormat={[
                    { month: 'long', year: 'numeric' },
                    { day: 'numeric', weekday: 'short' },
                ]}
                // slotLabelDidMount={renderSlotLabelContent}
                slotLabelContent={renderSlotLabelContent}
                eventContent={renderEventContent} // Custom event content
                schedulerLicenseKey='0310067844-fcs-1721029428'
                resourceLabelContent={renderResourceLabel} // Custom resource content
                // nowIndicator={true}
                // nowIndicatorContent={renderNowIndicatorContent}
                // dayCellContent={renderNowIndicatorContent}
                resourceAreaWidth="20%"
            />
            <MoveReservationModal
                showModal={showModal}
                onClose={() => {
                    if (dragInfo) {
                        dragInfo.revert();
                    }
                    setShowModal({ show: false, data: null });
                    clearMove();
                }}
                onFailed={() => {
                    if (dragInfo) {
                        dragInfo.revert();
                    }
                }}
            />
        </CalendarContainer>
    );
}

export default CalendarBooking;

const RenderTooltip = ({ bgColor, left, width, dayDiff, eventInfo, displayTitle }) => {
    const [tooltipShow, setTooltipShow] = useState(false);
    const { reservationTooltip, setReservationTooltip, clearTooltip } = useCalendarContext();

    useEffect(() => {
        console.log('Tooltip Show:', tooltipShow);
    }, [tooltipShow]);

    return (
        <OverlayTrigger
            trigger='click'
            show={tooltipShow}
            // // onToggle={() => setTooltipShow(!tooltipShow)}
            onHide={() => {
                clearTooltip();
                setTooltipShow(false);
            }}
            // key='top'
            placement='auto'
            overlay={
                <Popover id='popover-positioned-top' className='custom-reservation-details'>
                    <Popover.Header as='div' className='d-flex justify-content-between align-items-center'>
                        <h3 className='fs-4 mb-0'>
                            Reservations Information
                        </h3>
                        <button className='p-0 border-0 shadow-none bg-transparent' onClick={() => setTooltipShow(false)}>
                            <IconX size={18} />
                        </button>
                    </Popover.Header>
                    <Popover.Body>
                        <ReservationTooltip setTooltipShow={setTooltipShow} rawValues={eventInfo.event.extendedProps.rawValues} />
                    </Popover.Body>
                </Popover>
            }
        >
            <CustomEvent color={bgColor} left={left} width={width} dayDiffSlot={dayDiff} className='d-flex align-items-center justify-content-center'
                // onClick={(e) => {
                //     e.preventDefault();

                //     setTooltipShow(true);

                //     if (!tooltipShow) {
                //         if (reservationTooltip?.id === null) {
                //             setReservationTooltip({
                //                 id: eventInfo.event.extendedProps.booking_id,
                //             });
                //         } else {
                //             clearTooltip();
                //             setReservationTooltip({
                //                 id: eventInfo.event.extendedProps.booking_id,
                //             });
                //         }
                //     } else {
                //         clearTooltip();
                //     }

                //     // setTooltipShow(!tooltipShow);
                // }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTooltipShow(!tooltipShow);
                }}
            >
                {eventInfo.event.extendedProps.bookedBy === 'BookingCom' && <img src="/icons/Booking.com_Icon_2022.svg" alt="Icon" width={16} height={16} className='me-1' />}
                {/* <img src="/icons/Booking.com_Icon_2022.svg" alt="Icon" width={16} height={16} className='me-1' /> */}
                <span>{displayTitle}</span>
            </CustomEvent>
        </OverlayTrigger>
    );
};