import { useState } from "react";
import { postRequest } from "services/api";

const useCreateRoom = () => {
    const [createRoomStatus, setCreateRoomStatus] = useState({
        isSubmitting: false
    });

    const createRoom = async (data) => {
        try {
            const response = await postRequest("/room-type", data);
            if (response.success) {
                return { success: true, message: response.message };
            }
        } catch (error) {
            if (error.code === 422) {
                return { success: false, message: error.message, formError: error.errors };
            } else {
                return { success: false, message: error.message };
            }
        }
    };

    return {
        createRoom,
        createRoomStatus
    };
};

export default useCreateRoom;