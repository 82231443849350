import Sidebar from "components/navigation-2/sidebar";
import Footer from "components/footer";
import { SidebarProvider } from "contexts/v2/pages/sidebar/SidebarContext";

export default function LayoutDashboard({ children }) {
    return (
        <div className="page">
            <SidebarProvider>
                <Sidebar>
                    <div className="page-wrapper">
                        {children}
                        <Footer />
                    </div>
                </Sidebar>
            </SidebarProvider>
        </div>
    );
}