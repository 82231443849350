import { IconDatabaseOff, IconPlus, IconRefresh } from "@tabler/icons-react";
import React from "react";
import { Tab, Tabs, Row, Col, Card } from "react-bootstrap";

const TabbedDataDisplay = ({
  todayCheckinData,
  inHouseData,
  todayCheckoutData,
}) => {
  const renderTable = (data) => (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Booking ID</th>
            <th>Name</th>
            <th>Rooms</th>
            <th>Check-in</th>
            <th>Check-out</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((activity) => (
              <tr key={activity.id}>
                <td>{activity.unique_id}</td>
                <td>{activity.name}</td>
                <td>{activity.room}</td>
                <td>{activity.checkin}</td>
                <td>{activity.checkout}</td>
                <td>
                  <span
                    className={`badge bg-${
                      activity.status === "confirmed"
                        ? "success-lt text-green"
                        : activity.status === "pending"
                        ? "warning-lt text-orange"
                        : "primary-lt"
                    }`}
                  >
                    {activity.status}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="6"
                className="text-center"
                style={{ padding: "20px", color: "#6c757d" }}
              >
                {/* Ikon Database Off */}
                <IconDatabaseOff size={50} stroke={1.5} color="#6c757d" />

                <div style={{ marginTop: "15px", fontSize: "18px" }}>
                  {/* Teks Pesan Utama */}
                  <p>Tidak ada data hari ini</p>

                  {/* Informasi tambahan */}
                  <p style={{ color: "#6c757d", fontSize: "16px" }}>
                    Anda belum menambahkan data apa pun. Silakan klik tombol di
                    bawah untuk menambahkan data.
                  </p>

                  {/* Tombol Aksi */}
                  {/* <div style={{ marginTop: "20px" }}>
                    <button
                      onClick={() => console.log("Add Data")}
                      className="btn btn-primary"
                      style={{ marginRight: "10px" }}
                    >
                      <IconPlus size={16} stroke={1.5} /> Tambah Data
                    </button>
                    <button
                      onClick={() => console.log("Refresh Page")}
                      className="btn btn-outline-primary"
                    >
                      <IconRefresh size={16} stroke={1.5} /> Refresh
                    </button>
                  </div> */}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Header>
            <h5 className="card-title">Activities</h5>
          </Card.Header>
          <Card.Body>
            <Tabs defaultActiveKey="todayCheckin" id="activities-tabs">
              <Tab eventKey="todayCheckin" title="Today Check-In">
                {renderTable(todayCheckinData)}
              </Tab>
              <Tab eventKey="inHouse" title="In House">
                {renderTable(inHouseData)}
              </Tab>
              <Tab eventKey="todayCheckout" title="Today Check-Out">
                {renderTable(todayCheckoutData)}
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default TabbedDataDisplay;
