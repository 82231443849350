import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import { useUpdateData } from "hooks/useApiHooks";
import { MARK_AS } from "utils/constants";
import Select from "react-select";
import { postRequest } from "services/api";

const StatusCanvas = ({ initialData, onSuccess, onError }) => {
    const [formData, setFormData] = useState({
        mark: initialData?.mark || '', // Status reservasi
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formError, setFormError] = useState({});

    useEffect(() => {
        // Jika data reservasi berubah (untuk update), isi ulang form data
        if (initialData) {
            setFormData({
                status: initialData.mark || ''
            });
        }
    }, [initialData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const request = await postRequest(`/v2/booking/${initialData?.id}/mark-status`, {
                mark_status: formData.mark
            });

            if (request.success) {
                onSuccess('Status updated successfully');
            }

        } catch (error) {
            setFormError(error);
            onError('Failed to update status');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                {/* Detail Reservation */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="reservation_id" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Reservation ID:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control app-form-control rounded-1"
                            id="reservation_id"
                            value={initialData?.unique_id}
                            disabled
                            style={{ fontSize: '14px' }}
                        />
                    </div>
                </div>

                {/* Customer name get from initialData?.customer_name + customer surname */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="customer_name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Customer Name:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control app-form-control rounded-1"
                            id="customer_name"
                            value={initialData?.customer_name + ` ` + initialData?.customer_surname}
                            disabled
                            style={{ fontSize: '14px' }}
                        />
                    </div>
                </div>

                {/* Room Type & number */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="room_type" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Room Type:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control app-form-control rounded-1"
                            id="room_type"
                            value={initialData?.room_type?.name}
                            disabled
                            style={{ fontSize: '14px' }}
                        />
                    </div>
                </div>

                {/* Room Number */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="room_number" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Room Number:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control app-form-control rounded-1"
                            id="room_number"
                            value={initialData?.room?.room_number}
                            disabled
                            style={{ fontSize: '14px' }}
                        />
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <label htmlFor="status" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Status:</label>
                    <div className="col-sm-8">
                        <Select
                            options={MARK_AS}
                            value={MARK_AS.find(option => option.value === formData.mark)}
                            onChange={option => setFormData({ ...formData, mark: option.value })}
                            isDisabled={isSubmitting}
                        />
                        {formError?.mark && <div className="invalid-feedback">{formError.mark}</div>}
                    </div>
                </div>
            </div>

            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    Update Status
                </button>
            </div>
        </form>
    );
};

export default StatusCanvas;
