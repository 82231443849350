const Comments = ({ commentsForm, setCommentsForm }) => {
    return (
        <div className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                <div className="row mb-3 align-items-center">
                    <div className="col-sm-12">
                        <label htmlFor="comment" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>Public Notes:</label>
                        <textarea
                            className="form-control app-form-control rounded-1"
                            id="comment"
                            placeholder="Comment"
                            style={{ fontSize: '14px', height: '100px' }}
                            value={commentsForm.public_notes}
                            onChange={(e) => {
                                setCommentsForm((prevCommentsForm) => ({
                                    ...prevCommentsForm,
                                    public_notes: e.target.value,
                                }))
                            }}
                        />
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <div className="col-sm-12">
                        <label htmlFor="private_comment" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>Private Notes:</label>
                        <textarea
                            className="form-control app-form-control rounded-1"
                            id="private_comment"
                            placeholder="Private Comment"
                            style={{ fontSize: '14px', height: '100px' }}
                            value={commentsForm.private_notes}
                            onChange={(e) => {
                                setCommentsForm((prevCommentsForm) => ({
                                    ...prevCommentsForm,
                                    private_notes: e.target.value,
                                }))
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comments;