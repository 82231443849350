import { useEffect, useState } from 'react';
import { getRequest } from 'services/api';
import { API_VERSION } from 'utils/constants';
import { buildQueryString } from 'utils/queryHelpers';

const defaultReservationDetails = {
    data: null,
    loading: false,
    error: null,
    id: null,
};

const defaultRoomInfo = {
    data: null,
    loading: false,
    error: null,
    id: null,
};

const defaultRoomNumberInfo = {
    oldRoom: {
        data: null,
        id: null,
    },
    newRoom: {
        data: null,
        id: null,
    }
};

const defaultRatePlanInfo = {
    data: [],
    loading: false,
    error: null,
    oldRatePlan: null,
    newRatePlan: null,
};

const defaultNewCharges = [];

const useMoveReservationHooks = () => {
    const [reservationDetails, setReservationDetails] = useState(defaultReservationDetails);
    const [roomInfo, setRoomInfo] = useState(defaultRoomInfo);
    const [roomNumberInfo, setRoomNumberInfo] = useState(defaultRoomNumberInfo);
    const [ratePlanInfo, setRatePlanInfo] = useState(defaultRatePlanInfo);
    const [newCharges, setNewCharges] = useState(defaultNewCharges);

    const clearMove = () => {
        setReservationDetails(defaultReservationDetails);
        setRoomInfo(defaultRoomInfo);
        setRoomNumberInfo(defaultRoomNumberInfo);
        setRatePlanInfo(defaultRatePlanInfo);
        setNewCharges(defaultNewCharges);
    };

    const fetchReservationDetails = async () => {
        try {
            setReservationDetails((prevState) => ({
                ...prevState,
                loading: true,
                error: null,
            }));
            const response = await getRequest(`/${API_VERSION}/booking/${reservationDetails.id}`);
            if (response.success) {
                const data = response.data;
                setReservationDetails((prevState) => ({
                    ...prevState,
                    data,
                    loading: false,
                    error: null,
                }));
            }
        } catch (error) {
            setReservationDetails((prevState) => ({
                ...prevState,
                data: null,
                loading: false,
                error,
            }));
        }
    };

    const fetchRoom = async () => {
        try {
            const response = await getRequest(`/room-type/${roomInfo.id}`);
            if (response.success) {
                const data = response.data;
                setRoomInfo((prevState) => ({
                    ...prevState,
                    data,
                    loading: false,
                    error: null,
                }));
            }
        } catch (error) {
            setRoomInfo((prevState) => ({
                ...prevState,
                data: null,
                loading: false,
                error,
            }));
        }
    };

    const fetchRatePlan = async () => {
        const queryString = buildQueryString({ room_type_id: roomInfo.id });
        try {
            const response = await getRequest(`/rate-plan${queryString}`);
            if (response.success) {
                const data = response.data;
                setRatePlanInfo((prevState) => ({
                    ...prevState,
                    data: data.map(ratePlan => ({ value: ratePlan.id, label: ratePlan.name, originalData: ratePlan })),
                    loading: false,
                    error: null,
                }));
            }
        } catch (error) {
            setRatePlanInfo((prevState) => ({
                ...prevState,
                data: [],
                loading: false,
                error,
            }));
        }
    };

    useEffect(() => {
        if (roomInfo.id && reservationDetails.id) {
            fetchReservationDetails();
            fetchRoom();
            fetchRatePlan();
        }
    }, [roomInfo.id, reservationDetails.id]);

    useEffect(() => {
        if (roomNumberInfo.oldRoom.id && roomNumberInfo.newRoom.id && roomInfo.data) {
            setRoomNumberInfo((prevState) => ({
                ...prevState,
                oldRoom: {
                    ...prevState.oldRoom,
                    data: roomInfo.data.rooms.find(room => room.id === prevState.oldRoom.id),
                },
                newRoom: {
                    ...prevState.newRoom,
                    data: roomInfo.data.rooms.find(room => room.id === prevState.newRoom.id),
                },
            }));
        }
    }, [roomNumberInfo.oldRoom.id, roomNumberInfo.newRoom.id, roomInfo.data]);

    useEffect(() => {
        if (reservationDetails.data) {
            console.log(reservationDetails.data);
            setRatePlanInfo((prevState) => ({
                ...prevState,
                oldRatePlan: reservationDetails.data.rate_plan,
            }));
        }
    }, [reservationDetails.data, roomInfo.id]);

    return {
        clearMove,
        reservationDetails,
        setReservationDetails,
        roomInfo,
        setRoomInfo,
        roomNumberInfo,
        setRoomNumberInfo,
        ratePlanInfo,
        setRatePlanInfo,
        newCharges,
        setNewCharges
    };
};

export default useMoveReservationHooks;