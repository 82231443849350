import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { getRequest } from 'services/api';
import { useReservation } from 'contexts/v2/pages/reservation/ReservationContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SearchComponent from 'pages/reservation/reservation/components/search-component';
import Select from 'react-select';

// Custom Swal with react component support
const MySwal = withReactContent(Swal);

const ReservationFilter = () => {
    const { badgeOptions, filterOption, setFilterOption, agents, dateTypeOptions, propertyOptions, statusOptions, handleFilterChange, handleFilterReset } = useReservation();

    return (
        <>
            {/* multi filter with date, status and agent_id */}
            <Card className="mb-3 shadow-none">
                <Card.Body>
                    <div className="row">
                        <div className='col-md-3'>
                            <div className='form-group'>
                                <label htmlFor='search' className='mb-1'>Guest Name</label>
                                <SearchComponent defaultValue={filterOption.search} onFilter={setFilterOption} />
                            </div>
                        </div>
                        <div className='col-md-3 mt-2 mt-md-0'>
                            <div className='form-group'>
                                <label htmlFor='date_type' className='mb-1'>Date Type</label>
                                <Select
                                    options={dateTypeOptions}
                                    value={dateTypeOptions.find((option) => option.value === filterOption.date_type)}
                                    onChange={(selectedOption) => {
                                        setFilterOption({ ...filterOption, date_type: selectedOption.value });
                                    }}
                                />
                            </div>
                        </div>
                        {/* Property Select multiple */}
                        <div className='col-md-3 mt-2 mt-md-0'>
                            <div className='form-group'>
                                {/* Checkbox Select all property with label property in left of checkbox */}
                                <div className='mb-1 d-flex align-items-center justify-content-between'>
                                    <div className='me-2'>Property</div>
                                    <div className='form-check mb-0 mt-0 d-flex justify-content-start'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            id='property_id'
                                            checked={filterOption.all_properties}
                                            onChange={({ target: { checked } }) =>
                                                setFilterOption({ ...filterOption, all_properties: checked })
                                            }
                                        />
                                        <div className='form-check-label ms-2'>Select All Property</div>
                                    </div>
                                </div>
                                <Select
                                    isMulti
                                    options={propertyOptions}
                                    value={propertyOptions.filter((option) => filterOption.property_ids.includes(option.value))}
                                    onChange={(selectedOption) => {
                                        setFilterOption({ ...filterOption, property_ids: selectedOption.map((option) => option.value) });
                                    }}
                                    isDisabled={filterOption.all_properties}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 mt-2 mt-md-0">
                            <div className="form-group">
                                <label htmlFor="date" className="mb-1">From</label>
                                <input
                                    type="date"
                                    className="form-control app-form-control"
                                    id="date"
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setFilterOption((prev) => ({ ...prev, from: e.target.value }));
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        {/* To Date */}
                        <div className="col-md-3 mt-2 mt-md-0">
                            <div className="form-group">
                                <label htmlFor="date" className="mb-1">To</label>
                                <input
                                    type="date"
                                    className="form-control app-form-control"
                                    id="date"
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setFilterOption((prev) => ({ ...prev, to: e.target.value }));
                                    }} />
                            </div>
                        </div>
                        <div className="col-md-3 mt-2 mt-md-0">
                            <div className="form-group">
                                <label htmlFor="status" className="mb-1">Status</label>
                                <Select
                                    options={statusOptions}
                                    value={statusOptions.find((option) => option.value === filterOption.status)}
                                    onChange={(selectedOption) => {
                                        setFilterOption((prev) => ({ ...prev, status: selectedOption.value }));
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 mt-2 mt-md-0">
                            <div className="form-group">
                                <label htmlFor="agent_id" className="mb-1">Agent</label>
                                {agents.data.length > 0 ? (
                                    <Select
                                        options={agents.data}
                                        value={agents.data.find((agent) => agent.value === filterOption.agent_id)}
                                        onChange={(selectedOption) => {
                                            setFilterOption({ ...filterOption, agent_id: selectedOption.value });
                                        }}
                                    />
                                ) : (
                                    <select className="form-select" id="agent_id" disabled>
                                        <option value="all">All</option>
                                    </select>
                                )}
                            </div>
                        </div>
                        {/* Switch for showing cancel booking */}
                        <div className="col-md-3 mt-2 mt-md-0">
                            <div className="form-group text-start text-md-end">
                                <label htmlFor="show_cancel" className="mb-1">Show Cancel Booking</label>
                                <div className="form-check form-switch mb-0 mt-2 d-flex justify-content-start justify-content-md-end">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="show_cancel"
                                        checked={filterOption.is_cancelled}
                                        onChange={({ target: { checked } }) =>
                                            setFilterOption({ ...filterOption, is_cancelled: checked })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-12 d-flex align-items-center">
                            {badgeOptions.map((badge, index) => (
                                <div key={index} className='d-flex align-items-center me-3'>
                                    <span className={`btn bg-${badge.color} p-2`}></span>
                                    <span className='fs-5 fw-bold ms-1'>{badge.label}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="row mt-3">
                        {/* Reset filter small button */}
                        <div className="col-md-3 mt-2 mt-md-0">
                            <button
                                className="btn btn-secondary btn-sm"
                                onClick={(e) => {
                                    MySwal.fire({
                                        icon: 'warning',
                                        title: 'Filter reset',
                                        showConfirmButton: false,
                                        timer: 1500
                                    });
                                    handleFilterReset(e);
                                }}
                            >
                                Reset Filter
                            </button>
                            <button className="btn btn-primary btn-sm ms-2" onClick={(e) => {
                                MySwal.fire({
                                    icon: 'success',
                                    title: 'Filter applied',
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                                handleFilterChange(e);
                            }
                            }>
                                Apply Filter
                            </button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default ReservationFilter;