import React from "react";
import HeaderFilter from "./components/HeaderFilter";
import AgentTable from "./components/AgentTable";

const GrowthContent = () => {
  return (
    <div className="container mt-4">
    <HeaderFilter />
    <h3 className="mt-4">Growth Content</h3>
    <AgentTable />
    
  </div>
  );
};

export default GrowthContent;
