import useCreateLinkRateplan from 'hooks/v2/pages/room-rate-setting/useCreateLinkRateplan';
import useCreateRateplan from 'hooks/v2/pages/room-rate-setting/useCreateRateplan';
import useCreateRoom from 'hooks/v2/pages/room-rate-setting/useCreateRoom';
import useRateData from 'hooks/v2/pages/room-rate-setting/useRateData';
import useRemoveRateplan from 'hooks/v2/pages/room-rate-setting/useRemoveRateplan';
import useRemoveRoom from 'hooks/v2/pages/room-rate-setting/useRemoveRoom';
import useRoomRateData from 'hooks/v2/pages/room-rate-setting/useRoomRateData';
import useUpdateRate from 'hooks/v2/pages/room-rate-setting/useUpdateRate';
import useUpdateRoom from 'hooks/v2/pages/room-rate-setting/useUpdateRoom';
import useRemoveLinkRateplan from 'hooks/v2/pages/room-rate-setting/useRemoveLinkRateplan';
import { createContext, useContext } from 'react';

const RoomRateContext = createContext();

export const RoomRateProvider = ({ children }) => {
    const roomRateDataHook = useRoomRateData();
    const rateDataHook = useRateData();
    const createRoomHook = useCreateRoom();
    const removeRoomHook = useRemoveRoom();
    const updateRoomHook = useUpdateRoom();
    const createRateplanHook = useCreateRateplan();
    const removeRateplanHook = useRemoveRateplan();
    const updateRateplanHook = useUpdateRate();
    const createLinkRateplanHook = useCreateLinkRateplan();
    const removeLinkRateplanHook = useRemoveLinkRateplan();

    const contextValue = {
        ...roomRateDataHook,
        ...rateDataHook,
        ...createRoomHook,
        ...removeRoomHook,
        ...updateRoomHook,
        ...createRateplanHook,
        ...removeRateplanHook,
        ...updateRateplanHook,
        ...createLinkRateplanHook,
        ...removeLinkRateplanHook
    };

    return (
        <RoomRateContext.Provider value={contextValue}>
            {children}
        </RoomRateContext.Provider>
    );
};

export const useRoomRate = () => {
    const context = useContext(RoomRateContext);
    if (!context) {
        throw new Error('useRoomRate must be used within a RoomRateProvider');
    }
    return context;
};