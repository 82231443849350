import React, { useState } from "react";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDeleteData, useFetchData } from "hooks/useApiHooks";
import Suspense from "components/suspense/suspense";
import { useLoading } from "contexts/LoadingContext";
import FullLoader from "components/loader/full-loader";
import { useCanvasHandler } from "hooks/useCanvasHandler";
import { useDeleteHandler } from "hooks/useDeleteHandler";
import CreateCanvas from "pages/property-settings/property-management/components/v2/create-property-page";
import UpdateCanvas from "pages/property-settings/property-management/components/v2/update-property-page";
import { useReload } from "contexts/ReloadContext";
import { PropertyManagementProvider, usePropertyManagement } from "contexts/v2/pages/property-setting/PropertyManagementContext";
import Swal from "sweetalert2";
import AssignUserProperty from "./components/assign-user-property";
import { CreatePropertyManagementProvider } from "contexts/v2/pages/property-setting/CreatePropertyManagementContext";
import { useCanvas } from "contexts/canvas-context";
import { UpdatePropertyManagementProvider } from "contexts/v2/pages/property-setting/UpdatePropertyManagementContext";

const PropertyManagementPage = () => {
    return (
        <PropertyManagementProvider>
            <PropertyManagementContent />
        </PropertyManagementProvider>
    );
};

export default PropertyManagementPage;

const PropertyManagementContent = () => {
    const { propertyManagement, Pagination, handleSearch, deleteProperty, getPropertyManagement } = usePropertyManagement();
    const { showCanvas, hideCanvas } = useCanvas();

    const [search, setSearch] = useState('');

    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    // Fungsi untuk menampilkan modal create
    const handleCreate = (e) => {
        e.preventDefault();
        showCanvasHandler('Create Property',
            <CreatePropertyManagementProvider>
                <CreateCanvas
                    onSuccess={(message) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: message,
                        });
                        hideCanvas();
                        getPropertyManagement();
                    }}
                    onFormError={(message) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Failed to create property. Please check your form.',
                        });
                    }}
                    onError={(message) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong from server. Please try again later.',
                        });
                    }}
                />
            </CreatePropertyManagementProvider>
        );
    };

    const handleUpdate = (e, data) => {
        e.preventDefault();
        showCanvasHandler('Update Property',
            <UpdatePropertyManagementProvider>
                <UpdateCanvas
                    onSuccess={(message) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: message,
                        });
                        hideCanvas();
                        getPropertyManagement();
                    }}
                    onFormError={(message) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Failed to update property. Please check your form.',
                        });
                    }}
                    onError={(message) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong from server. Please try again later.',
                        });
                    }}
                    id={data.id}
                />
            </UpdatePropertyManagementProvider>
        );
    }

    const handleAssignUser = (property) => {
        showCanvasHandler('Assign User',
            <PropertyManagementProvider>
                <AssignUserProperty
                    onSuccess={(message) => {
                        handleSuccess(message);
                        getPropertyManagement();
                    }}
                    onError={(message) => handleError(message)}
                    initialData={property}
                />
            </PropertyManagementProvider>
        );
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this property!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await deleteProperty(id);
                if (response.success) {
                    Swal.fire('Deleted!', 'Property has been deleted.', 'success');
                    getPropertyManagement();
                } else {
                    Swal.fire('Failed!', 'Failed to delete property.', 'error');
                }
            }
        });
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        handleSearch(e.target.value);
    };

    const getInitials = (fullName) => {
        if (!fullName) return '';

        return fullName
            .split(' ')                 // Pisahkan nama berdasarkan spasi
            .map((name) => name[0])     // Ambil huruf pertama dari setiap nama
            .join('')                  // Gabungkan huruf-huruf menjadi satu string
            .toUpperCase();            // Ubah menjadi huruf kapital
    }

    if (propertyManagement.isInitialLoading) {
        return <FullLoader />;
    };

    return (
        <LayoutDashboard>
            <div className="container-fluid mt-5">
                <div className="table-container">
                    {propertyManagement.loading &&
                        (
                            <div class="spinner-overlay table-spinner-overlay active">
                                <div class="spinner-border table-spinner-border text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )
                    }
                    <div className="search-container mb-3">
                        {/* Search input */}
                        <div className="input-icon">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                onChange={handleSearchChange}
                                value={search}
                            />
                            <span className="input-icon-addon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-search"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="10" cy="10" r="7" />
                                    <line x1="21" y1="21" x2="15" y2="15" />
                                </svg>
                            </span>
                        </div>

                        {/* Create button */}
                        <button className="btn btn-primary" onClick={handleCreate}>Create</button>
                    </div>

                    {/* Table */}
                    <div className="table">
                        <table className="table table-hover table-vcenter">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Location</th>
                                    <th>Users</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {propertyManagement.data && propertyManagement.data.length > 0 ? propertyManagement.data.map((property, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                {property.name}
                                            </td>
                                            <td>{property.street_address}</td>
                                            <td>

                                                <div className="d-flex align-items-center">
                                                    {property.users && property.users.map((user, index) => (
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={<Tooltip id="button-tooltip-2">{user.name}</Tooltip>}
                                                        >
                                                            {({ ref, ...triggerHandler }) => (
                                                                <span
                                                                    className="avatar rounded-circle me-1"
                                                                    ref={ref}
                                                                    {...triggerHandler}
                                                                >{getInitials(user.name)}</span>
                                                            )}
                                                        </OverlayTrigger>
                                                    ))}
                                                </div>
                                            </td>
                                            <td className="text-end">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="default" id="dropdownMenuButton1">
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#" onClick={(e) => handleUpdate(e, property)}>Edit</Dropdown.Item>
                                                        <Dropdown.Item href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            handleAssignUser(property);
                                                        }}>Select User</Dropdown.Item>
                                                        <Dropdown.Item href="#" onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDelete(property.id)
                                                        }}>
                                                            Remove
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )) : (
                                    <tr>
                                        <td colSpan="4" className="text-center">No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination */}
                    {propertyManagement.data && propertyManagement.data.length > 0 && Pagination}
                </div>
            </div>
        </LayoutDashboard>
    );
};
