import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import { useCreateData, useUpdateData } from "hooks/useApiHooks";

const CreateUserPage = ({ initialData, onSuccess, onError }) => {
    const API_URL = '/user';
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
    });

    const isUpdateMode = !!initialData; // Cek apakah ini update mode atau create mode

    // API hook untuk create dan update
    const { createData } = useCreateData(API_URL);
    const { updateData } = useUpdateData(`${API_URL}/${initialData?.id}`);

    // Gunakan hook untuk menangani perubahan form
    const handleChange = useFormChangeHandler(setFormData);

    // Gunakan hook untuk menangani pengiriman form
    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        isUpdateMode ? updateData : createData,
        onSuccess,
        onError
    );

    useEffect(() => {
        // Jika data produk berubah (untuk update), isi ulang form data
        if (initialData) {
            setFormData(initialData); // Sesuaikan dengan struktur initialData
        }
    }, [initialData]);

    return (
        <form onSubmit={(e) => handleSubmit(formData, e)} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                {/* Name */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Name:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.name && 'is-invalid')}
                            id="name"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.name && <div className="invalid-feedback">{formError.name}</div>}
                    </div>
                </div>

                {/* Email */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="email" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Email:</label>
                    <div className="col-sm-8">
                        <input
                            type="email"
                            className={clsx("form-control app-form-control rounded-1", formError?.email && 'is-invalid')}
                            id="email"
                            placeholder="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.email && <div className="invalid-feedback">{formError.email}</div>}
                    </div>
                </div>

                {/* Password */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="password" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Password:</label>
                    <div className="col-sm-8">
                        <input
                            type="password"
                            className={clsx("form-control app-form-control rounded-1", formError?.password && 'is-invalid')}
                            id="password"
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.password && <div className="invalid-feedback">{formError.password}</div>}
                    </div>
                </div>
            </div>

            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    {isUpdateMode ? 'Update User' : 'Create User'}
                </button>
            </div>
        </form>
    );
};

export default CreateUserPage;
