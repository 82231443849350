import React, { useState } from "react";
import { Table, Form } from "react-bootstrap";

const ReservationsTable = ({ columns, data }) => {
  const [search, setSearch] = useState("");

  // Filter data berdasarkan pencarian
  const filteredData = data.filter((row) =>
    columns.some((col) =>
      row[col.key]?.toString().toLowerCase().includes(search.toLowerCase())
    )
  );

  return (
    <div className="table-responsive mt-4">
      {/* <Form.Control
        type="text"
        placeholder="Cari..."
        className="mb-3"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      /> */}
      <Table striped bordered hover>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData && filteredData.length > 0 ? (
            filteredData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>{row[col.key]}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center">
                Tidak ada data yang tersedia
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ReservationsTable;
