import React from "react";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import Breadcrumb from "components/breadcrumb/breadcrumb-with-title";
import { Row, Col, Card } from "react-bootstrap";
import MetricsCard from "./components/MetricsCard";
import OccupancyChart from "./components/OccupancyChart";
import RevenueOverviewChart from "./components/RevenueOverviewChart";
import GuestSatisfactionChart from "./components/GuestSatisfactionChart";
import TodayCheckinTable from "./components/TodayCheckinTable";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useFetchData } from "hooks/useApiHooks";
import FullLoader from "components/loader/full-loader";
import { Bar } from "react-chartjs-2";
import TabbedDataDisplay from "./components/TabbedDataDisplay";

// Register ChartJS components
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

// Fungsi untuk membuat data dummy dengan persentase perubahan dan nilai bulan lalu
const generateData = (currentValue) => {
  const percentageChange = (Math.random() * 20 - 10).toFixed(2); // Persentase acak antara -10% hingga 10%
  const previousValue = (currentValue / (1 + percentageChange / 100)).toFixed(
    2
  );
  const isIncrease = percentageChange > 0;
  return {
    percentageChange,
    previousValue,
    isIncrease,
  };
};

const DashboardPms = () => {
  // All your data and chart configurations remain the same here
  const { data, loading } = useFetchData("/dashboard-general");

  if (loading) {
    return <FullLoader />;
  }

  // if (!data || !data.data) {
  //   return <div>Error: No data found</div>;
  // }

  const {
    total_sale_of_month = 0,
    total_sale_of_submonth = 0,
    number_of_reservation_month = 0,
    number_of_reservation_submonth = 0,
    available_rooms = 0,
    available_rooms_submonth = 0,
    occupancy_weekly = [],
    occupancy_percentage = [],
    ota_name = [],
    number_of_reservation_by_ota = [],
    today_checkin = [],
    today_checkout = [],
    today_inhouse = [],
  } = data?.data || {};

  // console.log("Data Fetch Dashboard general", data?.data);
  // Ensure occupancy_weekly has exactly 30 elements
  const occupancyWeeklyPadded = occupancy_weekly.slice(0, 30);
  while (occupancyWeeklyPadded.length < 30) {
    occupancyWeeklyPadded.push("N/A"); // Pad with "N/A" or any default value
  }

  const occupancyRateData = {
    labels: occupancyWeeklyPadded,
    datasets: [
      {
        label: "Occupancy Rate (%)",
        data: occupancy_percentage.slice(0, 30), // Ensure data length matches labels
        borderColor: "rgba(0, 123, 255, 1)", // Bright blue line
        // backgroundColor: "rgba(0, 123, 255, 0.4)", // Slightly darker blue fill
        fill: true,
        // borderColor: "rgba(0, 123, 255, 1)", // Garis biru cerah
        backgroundColor: "#37a2eb", // Biru lebih pekat untuk batang
        // borderWidth: 1, // Tambahkan garis batas tipis pada batang
        // barPercentage: 0.6, // Sesuaikan lebar batang
      },
    ],
  };

  const revenueData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Room Revenue",
        data: [3000, 4000, 3500, 4500],
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
      {
        label: "POS Revenue",
        data: [1500, 2000, 1800, 2200],
        backgroundColor: "rgba(255, 206, 86, 0.5)",
      },
    ],
  };

  const guestSatisfactionData = {
    labels: ["1 Star", "2 Stars", "3 Stars", "4 Stars", "5 Stars"],
    datasets: [
      {
        data: [5, 10, 20, 25, 40],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const otaReservationsData = {
    labels: ota_name,
    datasets: [
      {
        label: "Number of Reservations",
        data: number_of_reservation_by_ota,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const recentActivitiesData = [];

  const baseId = 4412508165;
  const checkinDate = "2025-08-15";
  const checkoutDate = "2025-08-17";

  for (let i = 0; i < 9; i++) {
    const roomNumber = 101 + i;
    const nameSuffix = i % 3 === 0 ? "" : i % 3 === 1 ? " Jr" : " Sr";

    recentActivitiesData.push({
      id: `BDC-${baseId + i}`,
      name: `Jacobe${nameSuffix}`,
      rooms: `Double Room : ${roomNumber} | Suite Room : ${roomNumber}${
        nameSuffix === "" ? ` | Single Room : ${roomNumber}` : ""
      }`,
      checkin: checkinDate,
      checkout: checkoutDate,
      status: "New",
    });
  }

  console.log(recentActivitiesData);
  const todayCheckin =
    today_checkin.length > 0
      ? today_checkin : 0;
      // : [
      //     {
      //       unique_id: "B001/DUMY",
      //       name: "John Doe",
      //       rooms: 2,
      //       checkin: "2024-11-18",
      //       checkout: "2024-11-20",
      //       status: "Checked-In",
      //     },
      //     {
      //       unique_id: "B002",
      //       name: "Jane Smith",
      //       rooms: 1,
      //       checkin: "2024-11-18",
      //       checkout: "2024-11-19",
      //       status: "Checked-In",
      //     },
      //   ];

  const inHouse =
    today_inhouse.length > 0
      ? today_inhouse : 0;
      // : [
      //     {
      //       unique_id: "B003/DUMY",
      //       name: "Alice Johnson",
      //       rooms: 3,
      //       checkin: "2024-11-16",
      //       checkout: "2024-11-19",
      //       status: "In House",
      //     },
      //   ];

  const todayCheckout =
    today_checkout.length > 0
      ? today_checkout : 0;
      // : [
      //     {
      //       unique_id: "B004/DUMY",
      //       name: "Mark Brown",
      //       rooms: 1,
      //       checkin: "2024-11-15",
      //       checkout: "2024-11-18",
      //       status: "Checked-Out",
      //     },
      //   ];

  return (
    <LayoutDashboard>
      <div className="page vh-100">
        <div className="container-fluid mt-4">
          <Row>
            <Col md={12} lg={6}>
              <MetricsCard
                title="Total Revenue"
                data={total_sale_of_month} //uang
                datalast={total_sale_of_submonth} //uang
              />
            </Col>
            <Col xs={6} md={6} lg={3}>
              <MetricsCard
                title="Total Bookings"
                data={number_of_reservation_month}
                datalast={number_of_reservation_submonth}
              />
            </Col>
            <Col xs={6} md={6} lg={3}>
              <MetricsCard
                title="Available Rooms"
                data={available_rooms}
                datalast={available_rooms_submonth}
              />
            </Col>
          </Row>

          <Row className="d-flex align-items-stretch">
            <Col md={9} className="d-flex">
              {/* <OccupancyChart data={occupancyRateData} /> */}
              <Card
                className="mb-3 shadow-sm flex-fill"
                style={{ borderRadius: "10px", border: "none" }}
              >
                <Card.Body>
                  <Card.Title className="text-muted">Occupancy Rate</Card.Title>
                  {/* <Line data={occupancyRateData} /> */}
                  <Bar data={occupancyRateData} />
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="d-flex flex-column">
              <RevenueOverviewChart data={revenueData} />
              {/* <GuestSatisfactionChart data={guestSatisfactionData} /> */}
              <GuestSatisfactionChart data={otaReservationsData} />
            </Col>
          </Row>
          {/* <Row>
            <Col md={12}>
              <TodayCheckinTable data={today_checkin} />
            </Col>
          </Row> */}
          <Row>
            {/* desain 3 tab */}
            <Col md={12}>
              <TabbedDataDisplay
                todayCheckinData={todayCheckin}
                inHouseData={inHouse}
                todayCheckoutData={todayCheckout}
              />
            </Col>
          </Row>
        </div>
      </div>
    </LayoutDashboard>
  );
};

export default DashboardPms;
