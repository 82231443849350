import React, { createContext, useContext } from 'react';
import useUser from 'hooks/v2/pages/property-setting/useUser';
import usePropertyManagementHook from 'hooks/v2/pages/property-setting/usePropertyManagementHook';

const PropertyManagementContext = createContext();

export const PropertyManagementProvider = ({ children }) => {
    const propertyManagementHook = usePropertyManagementHook();
    const userHook = useUser();

    const contextValue = {
        ...propertyManagementHook,
        ...userHook,
    };

    return (
        <PropertyManagementContext.Provider value={contextValue}>
            {children}
        </PropertyManagementContext.Provider>
    );
};

export const usePropertyManagement = () => {
    const context = useContext(PropertyManagementContext);
    if (context === undefined) {
        throw new Error('usePropertyManagement must be used within a PropertyManagementProvider');
    }
    return context;
};