import CurrencyInput from "components/form/currency-input";
import { IconCircleArrowDown } from "@tabler/icons-react";

const RatesTabForm = ({ index, room, setRooms }) => {

    const handleCopyRate = (e, rate) => {
        e.stopPropagation();
        const newRates = room.day.data.map((row) => ({ ...row, rate }));
        setRooms((prevRooms) => prevRooms.map((prevRoom, i) => (i === index ? {
            ...prevRoom, day: {
                ...prevRoom.day,
                data: newRates
            }
        } : prevRoom)));
    };

    return (
        <div className="d-flex flex-column" style={{ height: "100%" }}>
            <div className="action-sheet-content flex-grow-1">
                <div className="row mb-3 align-items-start">
                    <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                            <thead className="thead-light">
                                <tr>
                                    <th>DATE</th>
                                    <th>RATE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {room.day.data.map((row, row_index) => (
                                    <tr key={row_index}>
                                        <td className="d-flex justify-content-between align-items-center">
                                            <span>{row.date}</span>
                                            {row_index === 0 && (
                                                <button type="button" className="p-0 bg-transparent border-0 text-blue" onClick={(e) => handleCopyRate(e, row.rate)}>
                                                    <IconCircleArrowDown size={20} />
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            <CurrencyInput
                                                className="form-control"
                                                value={row.rate}
                                                onChange={(e, raw_value) => {
                                                    const newRates = room.day.data.map((r, i) => (i === row_index ? { ...r, rate: raw_value } : r));
                                                    setRooms((prevRooms) => {
                                                        const newRooms = [...prevRooms];
                                                        newRooms[index].day.data = newRates;
                                                        return newRooms;
                                                    });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RatesTabForm;