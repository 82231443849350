import { useState } from 'react';
import { getRequest, patchRequest } from 'services/api';

const useUpdatePropertyManagementHook = () => {
    const [form, setForm] = useState({
        name: "",
        accommodation_type: "",
        preferred_currency: "",
        time_zone: "",
        primary_email: "",
        cc_emails: "",
        accounting_email: "",
        mobile_number_country_code: "",
        mobile_number: "",
        primary_phone_country_code: "",
        primary_phone: "",
        website: "",
        country: "",
        state: "",
        city: "",
        street_address: "",
        postal_code: "",
        prefix_booking_number: "",
        hotel_tax_number: "",
        enable_cvv: "",
    });
    const [formErrors, setFormErrors] = useState({
        name: null,
        accommodation_type: null,
        preferred_currency: null,
        time_zone: null,
        primary_email: null,
        cc_emails: null,
        accounting_email: null,
        mobile_number_country_code: null,
        mobile_number: null,
        primary_phone_country_code: null,
        primary_phone: null,
        website: null,
        country: null,
        state: null,
        city: null,
        street_address: null,
        postal_code: null,
        prefix_booking_number: null,
        hotel_tax_number: null,
        enable_cvv: null,
    });

    const [rawCcEmails, setRawCcEmails] = useState("");

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [message, setMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateId, setStateId] = useState(null);

    const parseArrayToRawCcEmails = (emailsArray) => {
        // Gabungkan array menjadi string dengan koma dan newline sebagai pemisah
        return emailsArray.join(', ');  // Menggunakan koma dan spasi sebagai pemisah
    };
    

    const fetchProperty = async (id) => {
        setLoading(true);
        try {
            const response = await getRequest(`/property/${id}`);
            const responseState = await getRequest(`/provinces`);
            if (response.success && response.data && responseState.success && responseState.data) {
                if (response.data.state) {
                    const state = responseState.data.find((state) => state.name === response.data.state);
                    if (state) {
                        setStateId(state.id);
                    }
                }

                if (response.data.cc_emails) {
                    setRawCcEmails(parseArrayToRawCcEmails(response.data.cc_emails));
                }
                setForm((prevstate) => ({
                    ...prevstate,
                    name: response.data.name,
                    accommodation_type: response.data.accommodation_type,
                    preferred_currency: response.data.preferred_currency,
                    time_zone: response.data.time_zone,
                    primary_email: response.data.primary_email,
                    cc_emails: response.data.cc_emails,
                    accounting_email: response.data.accounting_email,
                    mobile_number_country_code: response.data.mobile_number_country_code,
                    mobile_number: response.data.mobile_number,
                    primary_phone_country_code: response.data.primary_phone_country_code,
                    primary_phone: response.data.primary_phone,
                    website: response.data.website,
                    country: response.data.country,
                    state: response.data.state,
                    city: response.data.city,
                    street_address: response.data.street_address,
                    postal_code: response.data.postal_code,
                    prefix_booking_number: response.data.prefix_booking_number,
                    hotel_tax_number: response.data.hotel_tax_number,
                    enable_cvv: response.data.enable_cvv,
                }));
                setLoading(false);
            } else {
                setLoading(false);
                setSuccess(false);
                setMessage(response.message || 'Failed to load data.');
            }
        } catch (error) {
            setLoading(false);
            setSuccess(false);
            setMessage(error.message || 'Failed to fetch data.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: null,
        }));
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const parseCcEmails = (rawCcEmails) => {
        // Pisahkan berdasarkan spasi, koma, atau newline
        const emailsArray = rawCcEmails
            .split(/[\s,]+/)  // Memisahkan berdasarkan spasi, koma, atau newline
            .map(email => email.trim()) // Hilangkan spasi ekstra di sekitar email
            .filter(email => email); // Hapus elemen kosong
    
        // Mengembalikan array hasil parsing
        return emailsArray;
    };
    

    const handleCcEmailsChange = (e) => {
        const rawValue = e.target.value;
    
        // Simpan nilai mentah ke rawCcEmails
        setRawCcEmails(rawValue);
    
        // Parsing nilai mentah menjadi array, lalu simpan ke form.cc_emails
        const parsedEmails = parseCcEmails(rawValue);
        setForm(prevForm => ({
            ...prevForm,
            cc_emails: parsedEmails,
        }));
    };

    const validateEmails = (emails) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex sederhana untuk email
        return emails.every(email => emailRegex.test(email));
    };

    const requestUpdateProperty = async (data, id) => {
        setIsSubmitting(true);

        let result = { success: false };

        try {
            // Validasi email sebelum request
            if (data.cc_emails && !validateEmails(data.cc_emails)) {
                const message = "One or more CC Emails are invalid.";
                setFormErrors({ cc_emails: message });
                setMessage(message);
                setSuccess(false);
                return { success: false, message, errors: { cc_emails: message } };
            }

            const response = await patchRequest(`/property/${id}`, data);

            if (response.success) {
                setSuccess(true);
                setMessage(response.message);
                result = { success: true, data: response.data, message: response.message };
            } else {
                setSuccess(false);
                setMessage(response.message);
                result = { success: false, message: response.message };
            }
        } catch (error) {
            setSuccess(false);

            if (error.code === 422) {
                setFormErrors(error.errors);
                result = { success: false, message: error.message, errors: error.errors };
            } else {
                setMessage(error.message);
                result = { success: false, message: error.message };
            }
        } finally {
            setIsSubmitting(false);
        }

        return result;
    };

    return {
        form,
        setForm,
        formErrors,
        setFormErrors,
        loading,
        success,
        message,
        isSubmitting,
        setIsSubmitting,
        fetchProperty,
        handleChange,
        requestUpdateProperty,
        stateId,
        setStateId,
        rawCcEmails,
        handleCcEmailsChange
    };
};

export default useUpdatePropertyManagementHook;