import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import { useCreateData, useFetchData, useUpdateData } from "hooks/useApiHooks";
import CurrencyInput from "components/form/currency-input";
import { getRequest } from "services/api";
import Select from 'react-select';

const RoomType = ({ formData, setFormData }) => {
    const [roomTypes, setRoomTypes] = useState({
        data: [],
        isLoading: true,
        selected: null,
        success: false,
    });

    const fetchRoomTypes = async (url) => {
        try {
            const result = await getRequest(url);
            if (result.success) {
                return { success: true, data: result.data };
            }
        } catch (error) {
            return { success: false, error: error.message };
        }
    };

    useEffect(() => {
        setRoomTypes({
            ...roomTypes,
            isLoading: true,
        });

        fetchRoomTypes(`/room-type?all=1&date=${formData?.arrival_date}`).then((result) => {
            if (result.success) {
                console.log('fetchRooms', result)
                setRoomTypes({
                    data: result.data,
                    isLoading: false,
                    selected: null,
                    success: true,
                });
            } else {
                setRoomTypes({
                    data: [],
                    isLoading: false,
                    selected: null,
                    success: false,
                });
            }
        });
    }, [formData.arrival_date]);


    return (
        formData.room_rate.length > 0 && formData.room_rate.map((room, roomIndex) => (
            <RoomRate key={roomIndex} index={roomIndex} roomTypes={roomTypes} formData={formData} setFormData={setFormData} />
        ))
    );
};

const RatePlan = ({ setSelectedData, selectedData, setFormData, formData }) => {
    const [ratePlans, setRatePlans] = useState({
        data: [],
        isLoading: true,
        selected: null,
        success: false,
    });

    const fetchRoomTypes = async (url) => {
        try {
            const result = await getRequest(url);
            if (result.success) {
                return { success: true, data: result.data };
            }
        } catch (error) {
            return { success: false, error: error.message };
        }
    };

    useEffect(() => {
        setRatePlans({
            ...ratePlans,
            isLoading: true,
        });

        fetchRoomTypes(`/rate-plan?all=1&room_type_id=${selectedData.room_type?.value}`).then((result) => {
            if (result.success) {
                setRatePlans({
                    data: result.data,
                    isLoading: false,
                    selected: null,
                    success: true,
                });
            } else {
                setRatePlans({
                    data: [],
                    isLoading: false,
                    selected: null,
                    success: false,
                });
            }
        });
    }, [selectedData.room_type]);

    return (
        <div className="row mb-3 align-items-center">
            <label htmlFor='rate_plan' className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Rate:</label>
            <div className="col-sm-8">
                {selectedData.room_type ? <Select
                    className="app-form-control rounded-1"
                    id='rate_plan'
                    name='rate_plan'
                    value={ratePlans.selected}
                    onChange={selected => {
                        setRatePlans({ ...ratePlans, selected });
                        setSelectedData((prev) => ({ ...prev, price: prev.price.map((rate) => ({ ...rate, rate: ratePlans.data.find((rateplan) => rateplan.id === selected.value).rate })) }));
                        setFormData((prev) => {
                            const newRoomRate = [...prev.room_rate];
                            newRoomRate[selectedData.index].rate = selected.value;
                            newRoomRate[selectedData.index].rates = selectedData.price.map((rate) => {
                                return {
                                    date: rate.date,
                                    rate: ratePlans.data.find((rateplan) => rateplan.id === selected.value).rate,
                                };
                            });
                            return {
                                ...prev,
                                room_rate: newRoomRate,
                            };
                        });
                    }}
                    options={ratePlans.data.map((ratePlan) => ({ value: ratePlan.id, label: ratePlan.name }))}
                    // disabled={isSubmitting}
                    style={{ fontSize: '14px', height: '35px' }}
                /> : 'Select room type first'}
            </div>
        </div>
    );
};

const RoomRate = ({ roomTypes, index, formData, setFormData }) => {
    const [selectedData, setSelectedData] = useState({
        index: index,
        room_type: null,
        room_number: null,
        price: [],
    });

    const handleChangePriceRate = (event, index) => {
        const { name, value } = event.target;

        setSelectedData(prevState => {
            const newRates = [...prevState.price];
            newRates[index].rate = value;
            return {
                ...prevState,
                price: newRates,
            };
        });
    };

    const getDatesBetween = (startDate, endDate) => {
        const dates = [];
        let currentDate = new Date(startDate);
        const end = new Date(endDate);
        end.setDate(end.getDate() - 1);

        while (currentDate <= end) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    const setupRoomRatePerDate = () => {
        return setSelectedData({
            ...selectedData,
            price: [
                ...getDatesBetween(formData.arrival_date, formData.departure_date).map((date) => {
                    return {
                        date: date.toISOString().split('T')[0],
                        rate: '',
                    };
                }),
            ],
        });
    };

    useEffect(() => {
        setupRoomRatePerDate();
    }, [roomTypes, formData.arrival_date, formData.departure_date]);

    return (
        <>
            <div className="row mb-3 align-items-center">
                <h3 className="mt-2 ms-4 border-bottom fw-normal fs-4 d-flex justify-content-between">Room {index + 1}
                    <button className="btn btn-link text-danger p-0 me-2" onClick={() => setFormData((prev) => {
                        const newRoomRate = [...prev.room_rate];
                        newRoomRate.splice(index, 1);
                        return {
                            ...prev,
                            room_rate: newRoomRate,
                        };
                    })}>Remove</button>
                </h3>
            </div>
            {/* Room Type */}
            <div className="row mb-3 align-items-center">
                <label htmlFor='room_type' className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Room Type:</label>
                <div className="col-sm-8">
                    <Select
                        className="app-form-control rounded-1"
                        id='room_type'
                        name='room_type'
                        value={selectedData.room_type}
                        onChange={selected => {
                            setSelectedData({ ...selectedData, room_type: selected });
                            setFormData((prev) => {
                                const newRoomRate = [...prev.room_rate];
                                newRoomRate[index].room_type_id = selected.value;
                                return {
                                    ...prev,
                                    room_rate: newRoomRate,
                                };
                            });
                        }}
                        options={roomTypes.data.map((roomType) => ({ value: roomType.id, label: roomType.name }))}
                        // disabled={isSubmitting}
                        style={{ fontSize: '14px', height: '35px' }}
                    ></Select>
                </div>
            </div>

            {/* Room Number */}
            <div className="row mb-3 align-items-center">
                <label htmlFor='room_number' className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Room Number:</label>
                <div className="col-sm-8">
                    {selectedData.room_type ? (
                        <Select
                            className="app-form-control rounded-1"
                            id='room_number'
                            name='room_number'
                            value={selectedData.room_number}
                            onChange={selected => {
                                setSelectedData({ ...selectedData, room_number: selected });
                                setFormData((prev) => {
                                    const newRoomRate = [...prev.room_rate];
                                    newRoomRate[index].room_number = selected.value;
                                    return {
                                        ...prev,
                                        room_rate: newRoomRate,
                                    };
                                });
                            }}
                            options={roomTypes.data.find((type) => type.id === selectedData.room_type?.value)?.rooms.map((room) => ({ value: room.id, label: room.room_number }))}
                            // disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                    ) : 'Select room type first'}
                </div>
            </div>

            {/* Room Rate Type */}
            <RatePlan setSelectedData={setSelectedData} selectedData={selectedData} setFormData={setFormData} />

            {selectedData.price.length > 0 && (
                <>
                    <div className="row mb-3 align-items-center">
                        <div className="d-flex justify-content-end">
                            <h3 className="mt-2 ms-4 border-bottom fw-normal fs-4 col-sm-9">Rates</h3>
                        </div>
                    </div>

                    {selectedData.price.map((rate, rateIndex) => (
                        <div key={rateIndex} className="row mb-3 align-items-center">
                            <label htmlFor={`rates-${rateIndex}`} className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>{rate.date}:</label>
                            <div className="col-sm-8">
                                <CurrencyInput
                                    name={`rates-${rateIndex}`}
                                    className="form-control app-form-control rounded-1"
                                    value={rate.rate}
                                    onChange={(e, rawValue) => {
                                        handleChangePriceRate({ target: { name: `rates-${rateIndex}`, value: rawValue } }, rateIndex);
                                        setFormData((prev) => {
                                            const newRoomRate = [...prev.room_rate];
                                            newRoomRate[index].rates[rateIndex].rate = rawValue;
                                            return {
                                                ...prev,
                                                room_rate: newRoomRate,
                                            };
                                        });
                                    }} // Simpan rawValue
                                    // error={formError?.room_rate?.[index]?.rates?.[rateIndex]?.rate}
                                    placeholder="Rp. 0"
                                />
                            </div>
                        </div>
                    ))}
                </>
            )}
        </>
    )
};

const CreateReservationPage = ({ initialData, onSuccess, onError }) => {
    const API_URL = '/reservation';

    // State untuk menyimpan form data. Jika initialData tersedia, isi form untuk update
    const [formData, setFormData] = useState({
        // // Tambahkan inisialisasi untuk semua field yang diperlukan
        // invoice_number: '',
        invoice_date: '',
        booking_date: '',
        arrival_date: '',
        departure_date: '',
        adult: 1,
        children: 1,
        status: '',
        mark: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        // language: 'en',
        country: '',
        city: '',
        // address: '',
        // postal_code: '',
        room_rate: [{
            room_type_id: 0, // Contoh nilai default
            room_number: 0, // Contoh nilai default
            rate: '',
            rates: [],
        }],
        agent: '',
    });

    const isUpdateMode = !!initialData?.id; // Cek apakah ini update mode atau create mode

    // API hook untuk create dan update
    const { data: agents, loading: loadingAgents } = useFetchData('/agent?all=1');
    const { createData: createProduct, loading: creatingProduct } = useCreateData(API_URL);
    const { updateData: updateProduct, loading: updatingProduct } = useUpdateData(`${API_URL}/${initialData?.id}`);

    // Gunakan hook untuk menangani perubahan form
    const handleChange = useFormChangeHandler(setFormData);

    // Gunakan hook untuk menangani pengiriman form
    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        isUpdateMode ? updateProduct : createProduct,
        onSuccess,
        onError
    );

    useEffect(() => {
        // Jika data produk berubah (untuk update), isi ulang form data
        if (initialData) {
            setFormData({
                // Isi form data dengan initialData
                // invoice_number: initialData.invoice_number || '',
                invoice_date: initialData.invoice_date || '',
                booking_date: initialData.booking_date || '',
                arrival_date: initialData.arrival_date || '',
                departure_date: initialData.departure_date || '',
                adult: initialData.adult || 1,
                children: initialData.children || 1,
                status: initialData.status || '',
                mark: initialData.mark || '',
                first_name: initialData.customer_name || '',
                last_name: initialData.customer_surname || '',
                email: initialData.customer_email || '',
                phone: initialData.customer_phone || '',
                // language: initialData.customer_language || 'en',
                country: initialData.customer_country || 'ID',
                city: initialData.customer_city || '',
                // address: initialData.customer_address || '',
                // postal_code: initialData.customer_postal_code || '',
                room_rate: initialData.bookingroom && initialData.bookingroom.map((room) => {
                    return {
                        room_type_id: room.room_type_id,
                        room_number: room.room_id,
                        rate: room.rate_plan_id,
                        check_in: room.checkin_date,
                        check_out: room.checkout_date,
                        rates: room.day.map((rate) => {
                            return {
                                date: rate.date,
                                rate: rate.amount,
                            };
                        }),
                    };
                }) || [{
                    room_type_id: 0, // Contoh nilai default
                    room_number: 0, // Contoh nilai default
                    rate: '',
                    // check_in: '',s
                    // check_out: '',
                    rates: [],
                }],
                agent: initialData.agent_id || '',
            }); // Sesuaikan dengan struktur initialData
        }
    }, [initialData]);

    const handleBeforeSubmit = (e) => {
        e.preventDefault();
        const payload = {
            customer_name: formData.first_name,
            customer_surname: formData.last_name,
            customer_email: formData.email,
            customer_phone: formData.phone,
            // customer_language: formData.language,
            customer_country: formData.country,
            customer_city: formData.city,
            // customer_address: formData.address,
            // customer_postal_code: formData.postal_code,
            status: formData.status,
            currency: "IDR",
            amount: formData.room_rate.reduce((acc, room) => acc + room.rates.reduce((acc, rate) => acc + parseInt(rate.rate), 0), 0),
            occupancy_children: formData.children,
            occupancy_adults: formData.adult,
            occupancy_infants: 0,
            arrival_date: formData.arrival_date,
            departure_date: formData.departure_date,
            notes: formData.notes,
            bookingroom: formData.room_rate.map((room) => {
                return {
                    room_type_id: room.room_type_id,
                    room_id: room.room_number,
                    rate_plan_id: room.rate,
                    amount: room.rates.reduce((acc, rate) => acc + parseInt(rate.rate), 0),
                    occupancy_adults: formData.adult,
                    occupancy_children: formData.children,
                    occupancy_infants: 0,
                    checkin_date: formData.arrival_date,
                    checkout_date: formData.departure_date,
                    day: room.rates.map((rate) => {
                        return {
                            date: rate.date,
                            amount: rate.rate,
                        };
                    }),
                };
            }),
            ...(formData.agent && { agent_id: formData.agent })
        };

        console.log('payload', payload);

        handleSubmit(payload, e);
    }

    return (
        <form onSubmit={(e) => handleBeforeSubmit(e)} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                {/* Invoice Number */}
                {/* <div className="row mb-3 align-items-center">
                    <label htmlFor="invoice_number" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Invoice Number:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.invoice_number && 'is-invalid')}
                            id="invoice_number"
                            placeholder="Invoice Number"
                            name="invoice_number"
                            value={formData.invoice_number}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.invoice_number && <div className="invalid-feedback">{formError.invoice_number}</div>}
                    </div>
                </div> */}

                <div className="row mb-3 align-items-center">
                    <label htmlFor="status" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Status:</label>
                    <div className="col-sm-8">
                        <select
                            className={clsx("form-select app-form-control rounded-1", formError?.status && 'is-invalid')}
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        >
                            <option value="">Select Status</option>
                            <option value="confirmed">Confirmed</option>
                            <option value="pending">Pending</option>
                            <option value="cancelled">Cancelled</option>
                        </select>
                    </div>
                </div>

                {/* Arrival Date */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="arrival_date" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Arrival Date:</label>
                    <div className="col-sm-8">
                        <input
                            type="date"
                            className={clsx("form-control app-form-control rounded-1", formError?.arrival_date && 'is-invalid')}
                            id="arrival_date"
                            name="arrival_date"
                            value={formData.arrival_date}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.arrival_date && <div className="invalid-feedback">{formError.arrival_date}</div>}
                    </div>
                </div>

                {/* Departure Date */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="departure_date" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Departure Date:</label>
                    <div className="col-sm-8">
                        <input
                            type="date"
                            className={clsx("form-control app-form-control rounded-1", formError?.departure_date && 'is-invalid')}
                            id="departure_date"
                            name="departure_date"
                            value={formData.departure_date}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.departure_date && <div className="invalid-feedback">{formError.departure_date}</div>}
                    </div>
                </div>

                {/* Guest Information Section */}
                <h3 className="mt-6 border-bottom fw-normal">Guest Information</h3>

                {/* First Name */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="first_name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>First Name:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.first_name && 'is-invalid')}
                            id="first_name"
                            placeholder="First Name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.first_name && <div className="invalid-feedback">{formError.first_name}</div>}
                    </div>
                </div>

                {/* Last Name */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="last_name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Last Name:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.last_name && 'is-invalid')}
                            id="last_name"
                            placeholder="Last Name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.last_name && <div className="invalid-feedback">{formError.last_name}</div>}
                    </div>
                </div>

                {/* Email */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="email" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Email:</label>
                    <div className="col-sm-8">
                        <input
                            type="email"
                            className={clsx("form-control app-form-control rounded-1", formError?.email && 'is-invalid')}
                            id="email"
                            placeholder="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.email && <div className="invalid-feedback">{formError.email}</div>}
                    </div>
                </div>

                {/* Phone */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="phone" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Phone:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.customer_phone && 'is-invalid')}
                            id="phone"
                            placeholder="Phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.customer_phone && <div className="invalid-feedback">{formError.customer_phone}</div>}
                    </div>
                </div>

                {/* Language */}
                {/* <div className="row mb-3 align-items-center">
                    <label htmlFor="language" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Language:</label>
                    <div className="col-sm-8">
                        <Select
                            options={
                                [
                                    { value: 'en', label: 'English' },
                                    { value: 'id', label: 'Indonesia' },
                                ]
                            }
                            value={{ value: formData.language, label: formData.language === 'en' ? 'English' : 'Indonesia' }}
                            onChange={(selected) => {
                                setFormData({ ...formData, language: selected.value });
                            }}
                        />
                        {formError?.customer_language && <div className="invalid-feedback">{formError.customer_language}</div>}
                    </div>
                </div> */}

                {/* Country */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="country" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Country:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.customer_country && 'is-invalid')}
                            id="country"
                            placeholder="Country"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.customer_country && <div className="invalid-feedback">{formError.customer_country}</div>}
                    </div>
                </div>

                {/* City */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="city" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>City:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.customer_city && 'is-invalid')}
                            id="city"
                            placeholder="City"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.customer_city && <div className="invalid-feedback">{formError.customer_city}</div>}
                    </div>
                </div>

                {/* Address */}
                {/* <div className="row mb-3 align-items-center">
                    <label htmlFor="address" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Address:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.customer_address && 'is-invalid')}
                            id="address"
                            placeholder="Address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.customer_address && <div className="invalid-feedback">{formError.customer_address}</div>}
                    </div>
                </div> */}

                {/* Postal Code */}
                {/* <div className="row mb-3 align-items-center">
                    <label htmlFor="postal_code" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Postal Code:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.customer_postal_code && 'is-invalid')}
                            id="postal_code"
                            placeholder="Postal Code"
                            name="postal_code"
                            value={formData.postal_code}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.customer_postal_code && <div className="invalid-feedback">{formError.customer_postal_code}</div>}
                    </div>
                </div> */}

                {/* Adult Count */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="adult" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Adults:</label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.adult && 'is-invalid')}
                            id="adult"
                            placeholder="Number of Adults"
                            name="adult"
                            value={formData.adult}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.adult && <div className="invalid-feedback">{formError.adult}</div>}
                    </div>
                </div>

                {/* Children Count */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="children" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Children:</label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.children && 'is-invalid')}
                            id="children"
                            placeholder="Number of Children"
                            name="children"
                            value={formData.children}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.children && <div className="invalid-feedback">{formError.children}</div>}
                    </div>
                </div>

                {/* Notes */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="notes" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Notes:</label>
                    <div className="col-sm-8">
                        <textarea
                            className={clsx("form-control app-form-control rounded-1", formError?.notes && 'is-invalid')}
                            id="notes"
                            placeholder="Notes"
                            name="notes"
                            value={formData.notes}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '100px' }}
                        />
                        {formError?.notes && <div className="invalid-feedback">{formError.notes}</div>}
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <label htmlFor={`agent`} className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Agent:</label>
                    <div className="col-sm-8">
                        <select
                            className={clsx("form-select app-form-control rounded-1", formError?.agent && 'is-invalid')}
                            id={`agent`}
                            name={`agent`}
                            value={formData.agent}
                            onChange={e => {
                                setFormData({ ...formData, agent: e.target.value });
                            }}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        >
                            <option value="">Select Agent</option>
                            {/* Loop through roomTypes */}
                            {agents.data && agents.data.map((agent) => (
                                <option key={agent.id} value={agent.id}>{agent.name}</option>
                            ))}
                        </select>
                        {formError?.agents && <div className="invalid-feedback">{formError.agents}</div>}
                    </div>
                </div>

                {/* Room Information Section */}
                <h3 className="mt-6 border-bottom fw-normal">Room Details</h3>
                <RoomType formData={formData} setFormData={setFormData} />

                {/* Add Room Button */}
                <div className="form-group mt-3 mb-3">
                    <span className="d-block text-center text-primary cursor-pointer" onClick={() => setFormData({
                        ...formData,
                        room_rate: [...formData.room_rate, { room_type_id: 0, room_number: 0, rate: '', rates: [] }],
                    })}>Add Room +</span>
                </div>
            </div>

            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    {isUpdateMode ? 'Update Reservation' : 'Create Reservation'}
                </button>
            </div>
        </form>
    );
};

export default CreateReservationPage;
