import React from "react";
import { Table } from "react-bootstrap";

const AgentTable = () => {
  const data = [
    { agent: "BOOKING.COM", percentage: "89.91%", nights: 392, reservations: 185, avgNights: 2.2, revenue: 402122501 },
    { agent: "WEBSITE", percentage: "3.44%", nights: 15, reservations: 7, avgNights: 2.1, revenue: 15541800 },
    // Tambahkan data lainnya
  ];

  return (
    <Table striped bordered hover className="mt-3">
      <thead>
        <tr>
          <th>Agent Name</th>
          <th>Percentage</th>
          <th>Room Nights Sold</th>
          <th>Reservations</th>
          <th>Avg Nights</th>
          <th>Revenue</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, idx) => (
          <tr key={idx}>
            <td>{row.agent}</td>
            <td>{row.percentage}</td>
            <td>{row.nights}</td>
            <td>{row.reservations}</td>
            <td>{row.avgNights}</td>
            <td>{row.revenue.toLocaleString()}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default AgentTable;
