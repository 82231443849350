import { useState } from 'react';
import { getRequest } from 'services/api';

const useRateData = () => {
    const [rateData, setRateData] = useState([]); // List data rates
    const [loadingIds, setLoadingIds] = useState([]); // Room IDs yang sedang loading
    const [successStates, setSuccessStates] = useState({}); // Status success per roomId
    const [messages, setMessages] = useState({}); // Message per roomId

    const isLoading = (roomId) => loadingIds.includes(roomId);
    const getSuccess = (roomId) => successStates[roomId];
    const getMessage = (roomId) => messages[roomId];

    const fetchRateByRoomId = async (roomId) => {
        try {
            const response = await getRequest(`/rate-plan?room_type_id=${roomId}`);
            if (response.success) {
                return { success: true, data: response.data, message: response.message };
            } else {
                return { success: false, message: response.message };
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    const reloadRateDataByRoomId = async (roomId) => {
        // Set loading state untuk roomId ini
        setLoadingIds((prev) => [...prev, roomId]);
        setMessages((prev) => ({ ...prev, [roomId]: null }));
        setSuccessStates((prev) => ({ ...prev, [roomId]: null }));

        try {
            const response = await fetchRateByRoomId(roomId);

            if (response.success) {
                // Tambahkan data yang baru di-fetch ke dalam state
                setRateData((prev) => {
                    const prevData = prev.filter((rate) => rate.room_type_id !== roomId);
                    return [...prevData, ...response.data];
                });
                setSuccessStates((prev) => ({ ...prev, [roomId]: true }));
                setMessages((prev) => ({ ...prev, [roomId]: response.message }));

                // Filter data yang baru di-fetch untuk roomId ini
                return response.data.filter((rate) => rate.room_type_id === roomId);
            } else {
                setSuccessStates((prev) => ({ ...prev, [roomId]: false }));
                setMessages((prev) => ({ ...prev, [roomId]: response.message || 'Failed to fetch rate data' }));
            }
        } catch (error) {
            setSuccessStates((prev) => ({ ...prev, [roomId]: false }));
            setMessages((prev) => ({ ...prev, [roomId]: error.message || 'Failed to fetch rate data' }));
        } finally {
            setLoadingIds((prev) => prev.filter((id) => id !== roomId));
        }
    };

    const getRateDataByRoomId = async (roomId) => {
        // Cari semua data yang memiliki roomId tersebut
        const existingRates = rateData.filter((rate) => rate.room_type_id === roomId);
        if (existingRates.length > 0) {
            return existingRates; // Jika data sudah ada, kembalikan data tersebut
        } else {
            // Set loading state untuk roomId ini
            setLoadingIds((prev) => [...prev, roomId]);
            setMessages((prev) => ({ ...prev, [roomId]: null }));
            setSuccessStates((prev) => ({ ...prev, [roomId]: null }));

            try {
                const response = await fetchRateByRoomId(roomId);

                if (response.success) {
                    // Tambahkan data yang baru di-fetch ke dalam state
                    setRateData((prev) => {
                        const prevData = prev.filter((rate) => rate.room_type_id !== roomId);
                        return [...prevData, ...response.data];
                    });
                    setSuccessStates((prev) => ({ ...prev, [roomId]: true }));
                    setMessages((prev) => ({ ...prev, [roomId]: response.message }));

                    // Filter data yang baru di-fetch untuk roomId ini
                    return response.data.filter((rate) => rate.room_type_id === roomId);
                } else {
                    setSuccessStates((prev) => ({ ...prev, [roomId]: false }));
                    setMessages((prev) => ({ ...prev, [roomId]: response.message || 'Failed to fetch rate data' }));
                }
            } catch (error) {
                setSuccessStates((prev) => ({ ...prev, [roomId]: false }));
                setMessages((prev) => ({ ...prev, [roomId]: error.message || 'Failed to fetch rate data' }));
            } finally {
                setLoadingIds((prev) => prev.filter((id) => id !== roomId));
            }
        }
    };

    return {
        rateData,
        getRateDataByRoomId,
        isLoading,
        getSuccess,
        getMessage,
        reloadRateDataByRoomId,
    };
};

export default useRateData;