import { IconTrash } from "@tabler/icons-react";
import { useEffect } from "react";
import Select from "react-select";
import { formatDateToString } from "utils/dateHelpers";
import { formatPrice } from "utils/utils";
import RatesTabForm from "./rate-tab-form";
// import { useUpdateReservation } from "contexts/v2/pages/reservation/UpdateReservationContext";

const RoomRates = ({
    index,
    room,
    setRooms,
    invoiceForm,
    setInvoiceForm,
    optionsPaymentType,
    defaultPaymentTypeOption,
    removeRoom,
    selectRoomType,
    defaultRoomTypeOption,
    defaultRoomNumberOption,
    defaultRateplanOption,
    optionsAdults,
    defaultAdultsOption,
    optionsChildren,
    defaultChildrenOption,
    fetchRateplansDays,
    getRoomTotal,
    selectRoomNumber,
    fetchRoomTypes,
    fetchRoomTypesInUpdate,
}) => {
    useEffect(() => {
        if (invoiceForm.custom_invoice_nb && index === 0) {
            fetchRoomTypesInUpdate(index, invoiceForm.custom_invoice_nb);
        } else {
            fetchRoomTypes(index);
        }
    }, [room.arrival_date, room.departure_date, room.occupancy_adults, room.occupancy_children]);

    useEffect(() => {
        fetchRateplansDays(index);
    }, [room.rate_plan_id]);

    return (
        <>
            <div className="mt-6 border-bottom d-flex justify-content-between">
                <h3 className="fw-normal">{room.title}</h3>
                {index !== 0 && (
                    <button className="text-danger fw-normal p-0 border-0 bg-transparent d-flex align-items-center" onClick={(e) => {
                        e.stopPropagation();
                        removeRoom(index);
                    }}>
                        <IconTrash size={20} className="me-1" />
                        Remove Room
                    </button>
                )}
            </div>
            <div className="row mb-3 align-items-start">
                <div className="col-sm-4">
                    <div className="col-12">
                        {/* payment type hotel collect or OTA Collect Select */}
                        <label className="col-form-label" htmlFor="payment_type">Payment Type:</label>
                        {index > 0 ? (
                            <span className="form-control app-form-control rounded-1" style={{ fontSize: '14px', height: '35px' }}>{defaultPaymentTypeOption().label}</span>
                        ) : (
                            <Select
                                options={optionsPaymentType}
                                value={defaultPaymentTypeOption()}
                                onChange={(selectedOption) => {
                                    setInvoiceForm((prevInvoiceForm) => ({
                                        ...prevInvoiceForm,
                                        payment_type: selectedOption.value,
                                    }));
                                }}
                            />
                        )}
                    </div>
                    <div className="col-12">
                        {/* Check in */}
                        <label className="col-form-label" htmlFor="check_in">Check In:</label>
                        {index > 0 ? (
                            <span className="form-control app-form-control rounded-1" style={{ fontSize: '14px', height: '35px' }}>{room.arrival_date}</span>
                        ) : (
                            <input
                                type="date"
                                className="form-control app-form-control rounded-1"
                                id="check_in"
                                style={{ fontSize: '14px', height: '35px' }}
                                value={room.arrival_date}
                                onChange={(e) => {
                                    setRooms((prevRooms) => {
                                        const newRooms = [...prevRooms];
                                        newRooms[index].arrival_date = e.target.value;
                                        return newRooms;
                                    });
                                }}
                            />
                        )}
                    </div>
                    <div className="col-12">
                        {/* Check out */}
                        <label className="col-form-label" htmlFor="check_out">Check Out:</label>
                        {index > 0 ? (
                            <span className="form-control app-form-control rounded-1" style={{ fontSize: '14px', height: '35px' }}>{room.departure_date}</span>
                        ) : (
                            <input
                                type="date"
                                className="form-control app-form-control rounded-1"
                                id="check_out"
                                style={{ fontSize: '14px', height: '35px' }}
                                value={room.departure_date}
                                onChange={(e) => {
                                    setRooms((prevRooms) => {
                                        const newRooms = [...prevRooms];
                                        newRooms[index].departure_date = e.target.value;
                                        return newRooms;
                                    });
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="col-sm-4 ps-4">
                    <div className="col-12">
                        {/* First Name */}
                        <label className="col-form-label" htmlFor="first_name">First Name:</label>
                        {index > 0 ? (
                            <span className="form-control app-form-control rounded-1" style={{ fontSize: '14px', height: '35px' }}>{invoiceForm.first_name}</span>
                        ) : (
                            <input
                                type="text"
                                className="form-control app-form-control rounded-1"
                                id="first_name"
                                placeholder="First Name"
                                style={{ fontSize: '14px', height: '35px' }}
                                value={invoiceForm.first_name}
                                onChange={(e) => setInvoiceForm((prevInvoiceForm) => ({
                                    ...prevInvoiceForm,
                                    first_name: e.target.value,
                                }))}
                            />
                        )}
                    </div>
                    <div className="col-12">
                        {/* Last Name */}
                        <label className="col-form-label" htmlFor="last_name">Last Name:</label>
                        {index > 0 ? (
                            <span className="form-control app-form-control rounded-1" style={{ fontSize: '14px', height: '35px' }}>{invoiceForm.last_name}</span>
                        ) : (
                            <input
                                type="text"
                                className="form-control app-form-control rounded-1"
                                id="last_name"
                                placeholder="Last Name"
                                style={{ fontSize: '14px', height: '35px' }}
                                value={invoiceForm.last_name}
                                onChange={(e) => setInvoiceForm((prevInvoiceForm) => ({
                                    ...prevInvoiceForm,
                                    last_name: e.target.value,
                                }))}
                            />
                        )}
                    </div>
                    <div className="col-12">
                        {/* Email */}
                        <label className="col-form-label" htmlFor="email">Email:</label>
                        {index > 0 ? (
                            <span className="form-control app-form-control rounded-1" style={{ fontSize: '14px', height: '35px' }}>{invoiceForm.email}</span>
                        ) : (
                            <input
                                type="email"
                                className="form-control app-form-control rounded-1"
                                id="email"
                                placeholder="Email"
                                style={{ fontSize: '14px', height: '35px' }}
                                value={invoiceForm.email}
                                onChange={(e) => setInvoiceForm((prevInvoiceForm) => ({
                                    ...prevInvoiceForm,
                                    email: e.target.value,
                                }))}
                            />
                        )}
                    </div>
                </div>
                <div className="col-sm-4 ps-4">
                    {/* Room Select */}
                    <div className="col-12">
                        <label className="col-form-label" htmlFor="room">Room:</label>
                        {room.roomTypeOptions.data.length > 0 ? (
                            <Select
                                options={room.roomTypeOptions.data}
                                value={defaultRoomTypeOption(index)}
                                onChange={(selectedOption) => {
                                    selectRoomType(index, selectedOption, 'create');
                                }}
                            />
                        ) : (
                            <span>Must select Check in, Check out date first</span>
                        )}
                    </div>
                    {/* Subroom it will be number of rooms Select */}
                    <div className="col-12">
                        <label className="col-form-label" htmlFor="subroom">Subroom:</label>
                        {room.room_type_id && room.roomOptions.data.length > 0 ? (
                            console.log('room.roomOptions.error', room.roomOptions),
                            <>
                                <Select
                                    className={room.roomOptions.error && 'is-invalid'}
                                    options={room.roomOptions.data}
                                    value={defaultRoomNumberOption(index)}
                                    onChange={(selectedOption) => {
                                        selectRoomNumber(index, selectedOption);
                                    }}
                                />
                                {room.roomOptions.error && <div className="invalid-feedback">{room.roomOptions?.error}</div>}
                            </>
                        ) : (
                            <span>Must select room first</span>
                        )}
                    </div>
                    {/* Rateplans select */}
                    <div className="col-12">
                        <label className="col-form-label" htmlFor="rateplans">Rateplans:</label>
                        {room.room_type_id && room.rateplanOptions.data.length > 0 ? (
                            <Select
                                options={room.rateplanOptions.data}
                                value={defaultRateplanOption(index)}
                                onChange={(selectedOption) => {
                                    setRooms((prevRooms) => {
                                        const newRooms = [...prevRooms];
                                        newRooms[index].rate_plan_id = selectedOption.value;
                                        return newRooms;
                                    });
                                }}
                            />
                        ) : (
                            <span>Must select room first</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="row mb-3 align-items-start">
                <div className="col-sm-4 row">
                    <div className="col-6">
                        {/* Adult Select */}
                        <label className="col-form-label" htmlFor="adult">Adult:</label>
                        <Select
                            options={optionsAdults}
                            value={defaultAdultsOption(index)}
                            onChange={(selected) => {
                                setRooms((prevRooms) => {
                                    const newRooms = [...prevRooms];
                                    newRooms[index].occupancy_adults = selected.value;
                                    return newRooms;
                                });
                            }}
                        />
                    </div>
                    <div className="col-6">
                        {/* Children Select */}
                        <label className="col-form-label" htmlFor="children">Children:</label>
                        <Select
                            options={optionsChildren}
                            value={defaultChildrenOption(index)}
                            onChange={(selected) => {
                                setRooms((prevRooms) => {
                                    const newRooms = [...prevRooms];
                                    newRooms[index].occupancy_children = selected.value;
                                    return newRooms;
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="col-12">
                        <label className="col-form-label" htmlFor="total_accomodation">Total Accomodation:</label>
                        <span className="form-control app-form-control rounded-1" style={{ fontSize: '14px', height: '35px' }}>{
                            formatPrice(getRoomTotal(index))
                        }</span>
                    </div>
                </div>
                {/* <div className="col-sm-4">
                    <div className="col-12">
                        <label className="col-form-label" htmlFor="total_accomodation">Total Accomodation & Extra & Tax:</label>
                        <span className="form-control app-form-control rounded-1" style={{ fontSize: '14px', height: '35px' }}>Rp. 1.000.000</span>
                    </div>
                </div> */}
            </div>
            {room.room_type_id && (
                <div className="row mb-3 align-items-start">
                    <div className="col-sm-4">
                        <button type="button" className="btn btn-primary" onClick={(e) => {
                            e.stopPropagation();
                            // showHideRoom(index);
                            if (room.show) {
                                setRooms((prevRooms) => {
                                    const newRooms = [...prevRooms];
                                    newRooms[index].show = false;
                                    return newRooms;
                                });
                            } else {
                                setRooms((prevRooms) => {
                                    const newRooms = [...prevRooms];
                                    newRooms[index].show = true;
                                    return newRooms;
                                });
                            }
                        }}>{room.show ? 'Hide Rates' : 'Show Rates'}</button>
                    </div>
                </div>
            )}
            {room.error && <div className="alert alert-danger">{room.error}</div>}
            {room.show && <RatesTabForm index={index} room={room} setRooms={setRooms} />}
        </>
    );
};

export default RoomRates;