import React from "react";
import clsx from "clsx";
import AccommodationTypeSelectComponent from "./accomodation-type-select-component";
import { useCreatePropertyManagement } from "contexts/v2/pages/property-setting/CreatePropertyManagementContext";
import PreferredCurrencySelectComponent from "./preferred-currency-select-component";
import TimezoneSelectComponent from "./timezone-select-component";
import MobileNumberSelectComponent from "./mobile-number-select-component";
import PrimaryPhoneSelectComponent from "./primary-phone-select-component";
import CountrySelectComponent from "./country-select-component";
import StateSelectComponent from "./state-select-component";
import CitySelectComponent from "./city-select-component";

const CreatePropertyPage = ({ onSuccess, onFormError, onError }) => {
    const { form, formErrors, isSubmitting, requestCreateProperty, handleChange, stateId, setStateId, handleCcEmailsChange, rawCcEmails } = useCreatePropertyManagement();

    const HandleBeforeSubmit = async (e) => {
        e.preventDefault();

        const filteredForm = Object.fromEntries(
            Object.entries(form).filter(([key, value]) => value !== null && value !== "")
        );

        const result = await requestCreateProperty(filteredForm);
        if (result.success) {
            onSuccess(result.message);
        } else if (result.errors) {
            onFormError(result.message);
        } else {
            onError(result.message);
        }
    };

    return (
        <form
            onSubmit={(e) => HandleBeforeSubmit(e)}
            className="d-flex flex-column"
            style={{ height: "100%" }}
        >
            <div className="action-sheet-content flex-grow-1">
                {/* Name */}
                <div className="row mb-3 align-items-center">
                    <label
                        htmlFor="name"
                        className="col-sm-4 col-form-label d-flex justify-content-end"
                    >
                        Name:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx(
                                "form-control app-form-control rounded-1",
                                formErrors.name && "is-invalid"
                            )}
                            id="name"
                            placeholder="Property Name"
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                            disabled={isSubmitting}
                        />
                        {formErrors.name && (
                            <div className="invalid-feedback">{formErrors.name}</div>
                        )}
                    </div>
                </div>

                {/* Accommodation Type */}
                <AccommodationTypeSelectComponent errorMessage={formErrors.accommodation_type} handleChange={handleChange} />

                {/* Preferred Currency */}
                <PreferredCurrencySelectComponent errorMessage={formErrors.preferred_currency} handleChange={handleChange} />

                {/* Time Zone */}
                <TimezoneSelectComponent errorMessage={formErrors.time_zone} handleChange={handleChange} />

                <h3 className="mt-6 border-bottom fw-normal">Contacts Settings</h3>
                {/* Primary Email */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="primary_email" className="col-sm-4 col-form-label d-flex justify-content-end">
                        Primary Email:
                    </label>

                    <div className="col-sm-8">
                        <input
                            type="email"
                            className={clsx("form-control app-form-control rounded-1", formErrors.primary_email && "is-invalid")}
                            id="primary_email"
                            placeholder="Primary Email"
                            name="primary_email"
                            value={form.primary_email}
                            onChange={handleChange}
                            disabled={isSubmitting}
                        />
                        {formErrors.primary_email && (
                            <div className="invalid-feedback">{formErrors.primary_email}</div>
                        )}
                    </div>
                </div>

                {/* CC Emails */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="cc_emails" className="col-sm-4 col-form-label d-flex justify-content-end">
                        CC Emails:
                    </label>

                    <div className="col-sm-8">
                        <textarea
                            className={clsx("form-control app-form-control rounded-1", formErrors.cc_emails && "is-invalid")}
                            id="cc_emails"
                            placeholder="Enter multiple emails, separated by commas or new lines"
                            value={rawCcEmails} // Gabungkan array menjadi string dengan newline
                            onChange={handleCcEmailsChange} // Gunakan handler khusus
                            disabled={isSubmitting}
                            rows={3}
                        />
                        {formErrors.cc_emails && (
                            <div className="invalid-feedback">{formErrors.cc_emails}</div>
                        )}
                    </div>
                </div>

                {/* Accounting Email */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="accounting_email" className="col-sm-4 col-form-label d-flex justify-content-end">
                        Accounting Email:
                    </label>

                    <div className="col-sm-8">
                        <input
                            type="email"
                            className={clsx("form-control app-form-control rounded-1", formErrors.accounting_email && "is-invalid")}
                            id="accounting_email"
                            placeholder="Accounting Email"
                            name="accounting_email"
                            value={form.accounting_email}
                            onChange={handleChange}
                            disabled={isSubmitting}
                        />
                        {formErrors.accounting_email && (
                            <div className="invalid-feedback">{formErrors.accounting_email}</div>
                        )}
                    </div>
                </div>

                {/* Mobile Number */}
                <MobileNumberSelectComponent errorMessage={formErrors.mobile_number} form={form} isSubmitting={isSubmitting} handleChange={handleChange} />

                {/* Primary Phone */}
                <PrimaryPhoneSelectComponent errorMessage={formErrors.primary_phone} form={form} isSubmitting={isSubmitting} handleChange={handleChange} />

                {/* Website */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="website" className="col-sm-4 col-form-label d-flex justify-content-end">
                        Website:
                    </label>

                    <div className="col-sm-8">
                        <input
                            type="url"
                            className={clsx("form-control app-form-control rounded-1", formErrors.website && "is-invalid")}
                            id="website"
                            placeholder="Website"
                            name="website"
                            value={form.website}
                            onChange={handleChange}
                            disabled={isSubmitting}
                        />
                        {formErrors.website && (
                            <div className="invalid-feedback">{formErrors.website}</div>
                        )}
                    </div>
                </div>

                <h3 className="mt-6 border-bottom fw-normal">Location Settings</h3>
                {/* Country, State, City, Street Address, Postal Code */}
                <CountrySelectComponent errorMessage={formErrors.country} handleChange={handleChange} />

                {form.country === "Indonesia" && (
                    <>
                        <StateSelectComponent errorMessage={formErrors.state} handleChange={handleChange} handleStateId={setStateId} />
                        {stateId && (
                            <CitySelectComponent errorMessage={formErrors.city} stateId={stateId} handleChange={handleChange} />
                        )}
                    </>
                )}

                {/* Street Address */}
                <div className="row mb-3 align-items-center">
                    <label
                        htmlFor="street_address"
                        className="col-sm-4 col-form-label d-flex justify-content-end"
                    >
                        Street Address:
                    </label>
                    <div className="col-sm-8">
                        <textarea
                            className={clsx(
                                "form-control app-form-control rounded-1",
                                formErrors.street_address && "is-invalid"
                            )}
                            id="street_address"
                            placeholder="Street Address"
                            name="street_address"
                            value={form.street_address}
                            onChange={handleChange}
                            disabled={isSubmitting}
                        />
                        {formErrors.street_address && (
                            <div className="invalid-feedback">{formErrors.street_address}</div>
                        )}
                    </div>
                </div>

                {/* Postal Code */}
                <div className="row mb-3 align-items-center">
                    <label
                        htmlFor="postal_code"
                        className="col-sm-4 col-form-label d-flex justify-content-end"
                    >
                        Postal Code:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx(
                                "form-control app-form-control rounded-1",
                                formErrors.postal_code && "is-invalid"
                            )}
                            id="postal_code"
                            placeholder="Postal Code"
                            name="postal_code"
                            value={form.postal_code}
                            onChange={handleChange}
                            disabled={isSubmitting}
                        />
                        {formErrors.postal_code && (
                            <div className="invalid-feedback">{formErrors.postal_code}</div>
                        )}
                    </div>
                </div>

                {/* Prefix Booking Number */}
                <div className="row mb-3 align-items-center">
                    <label
                        htmlFor="prefix_booking_number"
                        className="col-sm-4 col-form-label d-flex justify-content-end"
                    >
                        Prefix Booking Number:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx(
                                "form-control app-form-control rounded-1",
                                formErrors.prefix_booking_number && "is-invalid"
                            )}
                            id="prefix_booking_number"
                            placeholder="Prefix Booking Number"
                            name="prefix_booking_number"
                            value={form.prefix_booking_number}
                            onChange={handleChange}
                            disabled={isSubmitting}
                        />
                        {formErrors.prefix_booking_number && (
                            <div className="invalid-feedback">{formErrors.prefix_booking_number}</div>
                        )}
                    </div>
                </div>

                {/* Hotel Tax Number */}
                <div className="row mb-3 align-items-center">
                    <label
                        htmlFor="hotel_tax_number"
                        className="col-sm-4 col-form-label d-flex justify-content-end"
                    >
                        Hotel Tax Number:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx(
                                "form-control app-form-control rounded-1",
                                formErrors.hotel_tax_number && "is-invalid"
                            )}
                            id="hotel_tax_number"
                            placeholder="Hotel Tax Number"
                            name="hotel_tax_number"
                            value={form.hotel_tax_number}
                            onChange={handleChange}
                            disabled={isSubmitting}
                        />
                        {formErrors.hotel_tax_number && (
                            <div className="invalid-feedback">{formErrors.hotel_tax_number}</div>
                        )}
                    </div>
                </div>

                {/* Enable CVV */}
                <div className="row mb-3 align-items-center">
                    <label
                        htmlFor="enable_cvv"
                        className="col-sm-4 col-form-label d-flex justify-content-end"
                    >
                        Enable CVV:
                    </label>
                    <div className="col-sm-8">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="enable_cvv"
                                name="enable_cvv"
                                checked={form.enable_cvv}
                                onChange={(e) => {
                                    handleChange({
                                        target: {
                                            name: "enable_cvv",
                                            value: e.target.checked,
                                        },
                                    });
                                }}
                                disabled={isSubmitting}
                            />
                            <label className="form-check-label" htmlFor="enable_cvv">
                                Enable CVV
                            </label>
                        </div>
                        {formErrors.enable_cvv && (
                            <div className="invalid-feedback">{formErrors.enable_cvv}</div>
                        )}
                    </div>
                </div>
            </div>

            {/* Submit Button */}
            <div className="form-group mt-auto d-grid">
                <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Submitting..." : "Create Property"}
                </button>
            </div>
        </form>
    );
};

export default CreatePropertyPage;
