import { useState } from "react";
import { getRequest, postRequest } from "services/api";

const useCreateRateplan = () => {
    const [roomIdWillCreateRateplan, setRoomIdWillCreateRateplan] = useState(null);

    const getRoomTypeForRateplan = async () => {
        try {
            const response = await getRequest(`/room-type`);
            if (response.success) {
                return { success: true, data: response.data, message: response.message };
            } else {
                return { success: false, message: response.message };
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    const createRateplan = async (data) => {
        try {
            const response = await postRequest("/rate-plan", data);
            if (response.success) {
                return { success: true, message: response.message };
            }
        } catch (error) {
            if (error.code === 422) {
                return { success: false, message: error.message, formError: error.errors };
            } else {
                return { success: false, message: error.message };
            }
        }
    };

    return {
        roomIdWillCreateRateplan,
        setRoomIdWillCreateRateplan,
        createRateplan,
        getRoomTypeForRateplan
    };
};

export default useCreateRateplan;