import { useEffect, useMemo, useState } from "react";
import { getRequest } from "services/api";
import { API_VERSION } from "utils/constants";
import { convertDateToYYYYMMDD, formatToISO8601, get31DaysFromToday, get3DaysFromToday } from "utils/date-utils";

const useCalendarBookingResource = () => {
    // Old state variables
    const [calendarSettings, setCalendarSettings] = useState({
        todayDate: get3DaysFromToday(),
        lastDate: get31DaysFromToday(),
        unsigned: false,
    });
    const [calendarStatus, setCalendarStatus] = useState({
        isLoading: false,
        error: null
    });
    const [roomResource, setRoomResource] = useState([]);
    const [occupancyResource, setOccupancyResource] = useState([]);
    const [reservationResource, setReservationResource] = useState([]);

    const [unsignedList, setUnsignedList] = useState([]);
    const [filteredUnsignedList, setFilteredUnsignedList] = useState([]);

    const [moveToUnsignedBooking, setMoveToUnsignedBooking] = useState(null);
    const [unsignedRoomTypeIdSelected, setUnsignedRoomTypeIdSelected] = useState(null);

    const fetchUnsignedList = async (from, to) => {
        try {
            const response = await getRequest(`/${API_VERSION}/unassigned-room?start_date=${from}&end_date=${to}`);
            if (response.success) {
                return { success: true, data: response.data, message: response.message };
            }
        } catch (error) {
            return { success: false, data: [], message: error.message };
        }
    };

    const fetchCalendarBookingResource = async () => {
        try {
            const response = await getRequest(`/${API_VERSION}/booking?date_type=arrival&from=${calendarSettings.todayDate}&to=${calendarSettings.lastDate}&all=1`);
            if (response.success) {
                return { success: true, data: response.data, message: response.message };
            }
        }
        catch (error) {
            return { success: false, data: [], message: error.message };
        }
    };

    const fetchCalendarRoomResource = async () => {
        try {
            const response = await getRequest(`/room-type`);
            if (response.success) {
                return { success: true, data: response.data, message: response.message };
            }
        }
        catch (error) {
            return { success: false, data: [], message: error.message };
        }
    };

    const fetchAvailableRooms = async (from, to) => {
        try {
            const response = await getRequest(`/${API_VERSION}/avi-list?start_date=${from}&end_date=${to}`);
            if (response.success) {
                return { success: true, data: response.data, message: response.message };
            }
        }
        catch (error) {
            return { success: false, data: [], message: error.message };
        }
    };

    const fetchOccupancyResource = async (from, to) => {
        try {
            const response = await getRequest(`/${API_VERSION}/room-resource?start_date=${from}&end_date=${to}`);
            if (response.success) {
                return { success: true, data: response.occupancy, message: response.message };
            }
        } catch (error) {
            return { success: false, data: [], message: error.message };
        }
    };

    const formattingRoomResource = (roomtypes, avis) => {
        const roomResource = roomtypes.map((roomType) => ({
            id: roomType.id,
            title: roomType.name,
            total_rooms: roomType.total_rooms,
            children: [
                ...roomType.rooms.map((room) => ({
                    id: `${roomType.id}|${room.id}`,
                    room_id: room.id,
                    room_type_id: roomType.id,
                    title: room.room_number,
                })),
                {
                    id: `${roomType.id}|unassigned`,
                    room_id: null,
                    room_type_id: roomType.id,
                    title: 'unassigned room',
                }
            ],
            available: avis.filter((avi) => avi.room_type_id === roomType.id).map((avi) => ({
                date: avi.date,
                available: avi.available,
            })),
        }));

        return roomResource;
    };

    const formattingBookingResource = (bookings) => {
        return bookings.map((booking) => ({
            title: `${booking.customer_name} ${booking.customer_surname}`,
            start: booking.checkin_date,
            end: booking.checkout_date,
            bookedBy: booking.agent.name || '',
            bgColor: booking.agent.color || '#FF5A5F',
            resourceId: `${booking.room_type_id}|${booking.room_id || 'unassigned'}`,
            booking_id: booking.id,
            source: booking.source,
            room_id: booking.room_id,
            booking_room_id: booking.id,
            status: booking.status,
            mark: booking.mark,
            ota_name: booking.ota_name,
            agent_id: booking.agent_id,
            rawValues: booking,
        }));
    };

    const fetchCalendarConfigResource = async () => {
        try {
            setCalendarStatus((prevState) => ({
                ...prevState,
                isLoading: true,
            }));
            const bookingResource = await fetchCalendarBookingResource();
            const roomResource = await fetchCalendarRoomResource();
            const availableRooms = await fetchAvailableRooms(calendarSettings.todayDate, calendarSettings.lastDate);
            const occupancyResource = await fetchOccupancyResource(calendarSettings.todayDate, calendarSettings.lastDate);
            const unsignedList = await fetchUnsignedList(calendarSettings.todayDate, calendarSettings.lastDate);
            if (bookingResource.success && roomResource.success && availableRooms.success) {
                const formattedRoomResource = formattingRoomResource(roomResource.data, availableRooms.data);
                setRoomResource(formattedRoomResource);

                setOccupancyResource(occupancyResource.data);

                const formattedBookingResource = formattingBookingResource(bookingResource.data);
                setReservationResource(formattedBookingResource);

                setUnsignedList(unsignedList.data);
            }
        } catch (error) {
            setCalendarStatus((prevState) => ({
                ...prevState,
                error: error.message
            }));
            setRoomResource([]);
        } finally {
            setCalendarStatus((prevState) => ({
                ...prevState,
                isLoading: false,
            }));
        }
    };

    const refetchCalendarResource = async () => {
        try {
            const bookingResource = await fetchCalendarBookingResource();
            const roomResource = await fetchCalendarRoomResource();
            const availableRooms = await fetchAvailableRooms(calendarSettings.todayDate, calendarSettings.lastDate);
            const occupancyResource = await fetchOccupancyResource(calendarSettings.todayDate, calendarSettings.lastDate);
            const unsignedList = await fetchUnsignedList(calendarSettings.todayDate, calendarSettings.lastDate);
            if (bookingResource.success && roomResource.success && availableRooms.success) {
                const formattedRoomResource = formattingRoomResource(roomResource.data, availableRooms.data);
                setRoomResource(formattedRoomResource);

                setOccupancyResource(occupancyResource.data);

                const formattedBookingResource = formattingBookingResource(bookingResource.data);
                setReservationResource(formattedBookingResource);

                setUnsignedList(unsignedList.data);
            }
        } catch (error) {
            setCalendarStatus((prevState) => ({
                ...prevState,
                error: error.message
            }));
            setRoomResource([]);
        }
    };

    const resourcesPayload = useMemo(() => {
        return roomResource?.map((room) => {
            const unsignedRooms = room.children.filter((child) =>
                child.title === 'unassigned room' &&
                (calendarSettings.unsigned || child.room_type_id === unsignedRoomTypeIdSelected)
            );

            const mapResource = {
                id: room.id.toString(),
                title: room.title,
                children: [
                    ...room.children.filter((child) => child.title !== 'unassigned room'),
                    ...unsignedRooms,
                ].filter(Boolean)
            };
            return mapResource;
        }) || [];
    }, [roomResource, unsignedRoomTypeIdSelected, calendarSettings.unsigned]);

    const occupancyPayload = useMemo(() => {
        const occupancyEvents = occupancyResource?.map(occupancy => {
            const mappedOccupancy = {
                occupancy: occupancy.occupancy,
                date: occupancy.date,
            }
            return mappedOccupancy;
        }) || [];
        return occupancyEvents;
    }, [occupancyResource]);

    // const eventsPayload = useMemo(() => {
    //     const formattingData = [
    //         ...reservationResource?.map(book => ({
    //             ...book,
    //             start: formatToISO8601(book.start),
    //             end: formatToISO8601(book.end),
    //         })) || [], // Tambahkan data frontOfficeData jika ada
    //         ...(roomResource?.flatMap(room =>
    //             room.available?.map(avail => ({
    //                 resourceId: room.id.toString(),
    //                 title: avail.available,
    //                 start: avail.date,
    //                 end: avail.date,
    //                 isRoom: true,
    //                 bgColor: '#67c17b',
    //             })) || []
    //         ) || [])
    //     ];
    //     return formattingData;
    // }, [roomResource, reservationResource]);

    const eventsPayload = [
        ...reservationResource?.map(book => ({
            ...book,
            start: formatToISO8601(book.start),
            end: formatToISO8601(book.end),
        })) || [], // Tambahkan data frontOfficeData jika ada
        ...(roomResource?.flatMap(room =>
            room.available?.map(avail => ({
                resourceId: room.id.toString(),
                title: avail.available,
                start: avail.date,
                end: avail.date,
                isRoom: true,
                bgColor: '#67c17b',
            })) || []
        ) || [])
    ];


    const handleBackToToday = () => {
        setCalendarSettings((prevState) => ({
            ...prevState,
            todayDate: get3DaysFromToday(),
            lastDate: get31DaysFromToday()
        }));
    };

    const handlePrevMonth = () => {
        const today = new Date(calendarSettings.todayDate);
        today.setMonth(today.getMonth() - 1);

        const last = new Date(calendarSettings.lastDate);
        last.setMonth(last.getMonth() - 1);

        setCalendarSettings((prevState) => ({
            ...prevState,
            todayDate: convertDateToYYYYMMDD(today),
            lastDate: convertDateToYYYYMMDD(last)
        }));
    };

    const handleNextMonth = () => {
        const today = new Date(calendarSettings.todayDate);
        today.setMonth(today.getMonth() + 1);

        const last = new Date(calendarSettings.lastDate);
        last.setMonth(last.getMonth() + 1);

        setCalendarSettings((prevState) => ({
            ...prevState,
            todayDate: convertDateToYYYYMMDD(today),
            lastDate: convertDateToYYYYMMDD(last)
        }));
    };

    // Show/Hide unsigned rooms
    const handleToggleUnsigned = () => {
        setCalendarSettings((prevState) => ({
            ...prevState,
            unsigned: !prevState.unsigned
        }));
    };

    const handleMoveToUnsigned = (booking) => {
        setMoveToUnsignedBooking(booking);
        setUnsignedRoomTypeIdSelected(booking.room_type_id);
    };

    const handleRemoveFromUnsigned = () => {
        setMoveToUnsignedBooking(null);
    }

    const handleRefreshMoveReservation = (booking_room_id, payload) => {
        setReservationResource((prevState) => {
            // Temukan data lama yang akan diubah
            const oldReservationIndex = prevState.findIndex((reservation) => reservation.booking_room_id === booking_room_id);

            // Jika data lama ditemukan, buat data baru
            if (oldReservationIndex !== -1) {
                const updatedReservation = {
                    ...prevState[oldReservationIndex], // Salin data lama
                    resourceId: `${payload.room_type_id}|${payload.room_id || 'unassigned'}`,
                    room_id: payload.room_id,
                    booking_room_id: payload.booking_room_id,
                    rawValues: {
                        ...prevState[oldReservationIndex].rawValues, // Salin rawValues lama
                        rate_plan_id: payload.rate_plan_id,
                        days: payload.day,
                    },
                };

                // Ganti data lama dengan data baru
                const newState = [...prevState];
                newState[oldReservationIndex] = updatedReservation; // Gantikan data lama dengan yang baru

                return newState;
            }

            // Jika data lama tidak ditemukan, kembalikan state lama tanpa perubahan
            return prevState;
        });
    };

    const handleFilterCalendar = (filter) => {
        console.log('Filter:', filter);
        setCalendarSettings((prevState) => ({
            ...prevState,
            unsigned: filter.unsigned_rooms
        }));
    };

    useEffect(() => {
        fetchCalendarConfigResource();
    }, [calendarSettings.todayDate, calendarSettings.lastDate]);

    useEffect(() => {
        const applyFilter = () => {
            const filtered = unsignedList.filter((unsigned) => unsigned.room_type_id !== unsignedRoomTypeIdSelected);
            setFilteredUnsignedList(filtered);
        };
        applyFilter();
    }, [unsignedRoomTypeIdSelected, unsignedList]);

    return {
        resourcesPayload,
        occupancyPayload,
        eventsPayload,
        handleBackToToday,
        handlePrevMonth,
        handleNextMonth,
        handleToggleUnsigned,
        refetchCalendarResource,
        calendarStatus,
        unsignedList,
        calendarSettings,
        handleMoveToUnsigned,
        moveToUnsignedBooking,
        handleRemoveFromUnsigned,
        filteredUnsignedList,
        handleRefreshMoveReservation,
        handleFilterCalendar,
    };
};

export default useCalendarBookingResource;