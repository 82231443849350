import CurrencyInput from "components/form/currency-input";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useRoomRate } from "contexts/v2/pages/room-rate-setting/RoomRateContext";
import { IconExclamationCircle } from "@tabler/icons-react";
import Select from "react-select";
import { toast } from "react-toastify";

const CreateLinkRate = ({ show, handleClose, onSuccess, onError }) => {
    return (
        <Offcanvas show={show} onHide={handleClose} placement="end" style={{ width: '45%' }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Create Link Rate</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {/* Tampilkan FormComponent */}
                <CreateLinkRateForm onSuccess={onSuccess} onError={onError} />
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CreateLinkRate;

const CreateLinkRateForm = ({ onSuccess, onError }) => {
    const { rateplanIdWillLink, getRateplanForLink, createLinkRateplan } = useRoomRate();

    const specificRuleOptions = [
        { value: 'increase', label: 'Increase' },
        { value: 'decrease', label: 'Decrease' },
    ];

    const specificAmountTypeOptions = [
        { value: 'percent', label: 'Percent' },
        { value: 'amount', label: 'Amount' },
    ];

    const [displayRateType, setDisplayRateType] = useState('');
    const [displayRateTypeLoading, setDisplayRateTypeLoading] = useState(true);
    const [displayRateTypeError, setDisplayRateTypeError] = useState(null);
    const [hasError, setHasError] = useState(false);

    const [formData, setFormData] = useState({
        rate_plan_id: null,
        name: '',
        description: '',
        max_night: null,
        min_night: null,
        guest_include: null,
        guest_max: null,
        extra_adult: null,
        extra_child: null,
        extra_adult_rate: null,
        extra_child_rate: null,
        specific_rule: specificRuleOptions[0].value,
        specific_amount_type: specificAmountTypeOptions[0].value,
        specific_value: null,
    });

    const [formError, setFormError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setFormError(null);

        try {
            // validasi jika formData null maka tidak akan dikirim ke api
            const payload = {
                ...formData.rate_plan_id && { rate_plan_id: formData.rate_plan_id },
                ...formData.name && { name: formData.name },
                ...formData.description && { description: formData.description },
                ...formData.max_night && { max_night: parseInt(formData.max_night) },
                ...formData.min_night && { min_night: parseInt(formData.min_night) },
                ...formData.guest_include && { guest_include: parseInt(formData.guest_include) },
                ...formData.guest_max && { guest_max: parseInt(formData.guest_max) },
                ...formData.extra_adult && { extra_adult: parseInt(formData.extra_adult) },
                ...formData.extra_child && { extra_child: parseInt(formData.extra_child) },
                ...formData.extra_adult_rate && { extra_adult_rate: parseInt(formData.extra_adult_rate) },
                ...formData.extra_child_rate && { extra_child_rate: parseInt(formData.extra_child_rate) },
                ...formData.specific_rule && { specific_rule: formData.specific_rule },
                ...formData.specific_amount_type && { specific_amount_type: formData.specific_amount_type },
                ...formData.specific_value && { specific_value: parseInt(formData.specific_value) },
            };

            const response = await createLinkRateplan(payload);
            if (response.success) {
                onSuccess(response.message);
            } else {
                if (response.formError) {
                    toast.error(response.message || 'Validation error, please check your input');
                    setFormError((prev) => ({ ...prev, ...response.formError }));
                } else {
                    onError(response.message || 'Something went wrong, internal server error');
                }
            }
        } catch (error) {
            onError(error.message || 'Something went wrong, internal server error');
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderAmountValueInput = () => {
        // jika specific_amount_type adalah percent
        if (formData.specific_amount_type === 'percent') {
            return (
                <PercentInput
                    value={formData.specific_value}
                    onChange={(e) => setFormData((prev) => ({ ...prev, specific_value: e.target.value }))}
                    disabled={isSubmitting}
                    error={formError?.specific_value}
                />
            );
        } else {
            return (
                <CurrencyInput
                    name="specific_value"
                    value={formData.specific_value}
                    onChange={(e, rawValue) => setFormData((prev) => ({ ...prev, specific_value: rawValue }))}
                    disabled={isSubmitting}
                    error={formError?.specific_value}
                    placeholder='Rp. 0'
                />
            );
        }
        // jika tidak maka tampilkan input currencyInput
    };

    const fetchRateType = async () => {
        try {
            setDisplayRateTypeLoading(true);
            const response = await getRateplanForLink();
            if (response.success) {
                const { data } = response;
                setDisplayRateType(data.find((rate) => rate.id === rateplanIdWillLink)?.name);
                setFormData((prev) => ({ ...prev, rate_plan_id: rateplanIdWillLink }));
            } else {
                setDisplayRateTypeError(response.message || 'Failed to get rateplan source');
            }
        } catch (error) {
            setDisplayRateTypeError(error.message || 'Failed to get rateplan source');
        } finally {
            setDisplayRateTypeLoading(false);
        }
    };

    useEffect(() => {
        if (rateplanIdWillLink) {
            fetchRateType();
        } else {
            setHasError(true);
        }
    }, [rateplanIdWillLink]);

    if (hasError) {
        return (
            <div style={styles.errorContainer}>
                <IconExclamationCircle style={styles.icon} size={72} />
                <p style={styles.errorText}>Failed to load create rateplan link page</p>
            </div>
        )
    };

    return (
        <form onSubmit={(e) => handleSubmit(e)} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                <div className="row mb-3 align-items-center">
                    {displayRateTypeLoading ? (
                        <div className='col-sm-8 ms-auto'>
                            <div className="placeholder-glow">
                                <span className="placeholder col-12" style={{ height: '25px', width: '90px', display: 'block' }}></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <label htmlFor="room_type" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Rateplan Source: </label>
                            <div className="col-sm-8">
                                <span className='badge bg-primary text-light' style={{ fontSize: '14px' }}>{displayRateType}</span>
                                {formError?.rate_plan_id && <div className="invalid-feedback">{formError.rate_plan_id}</div>}
                                {displayRateTypeError && <div className="invalid-feedback">{displayRateTypeError}</div>}
                            </div>
                        </>
                    )}
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Name: </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.name && 'is-invalid')}
                            id="name"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.name && <div className="invalid-feedback">{formError.name}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="description" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Description: </label>
                    <div className="col-sm-8">
                        <textarea
                            className={clsx("form-control app-form-control rounded-1", formError?.description && 'is-invalid')}
                            id="description"
                            placeholder="Description"
                            name="description"
                            value={formData.description}
                            onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px' }}
                        />
                        {formError?.description && <div className="invalid-feedback">{formError.description}</div>}
                    </div>
                </div>
                <h3 className="mt-6 border-bottom fw-normal">Rate Settings</h3>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="max_night" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Max Night: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.max_night && 'is-invalid')}
                            id="max_night"
                            placeholder="Max Night"
                            name="max_night"
                            value={formData.max_night}
                            onChange={(e) => setFormData((prev) => ({ ...prev, max_night: e.target.value }))}
                            disabled={isSubmitting}
                            onWheel={(e) => e.target.blur()}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.max_night && <div className="invalid-feedback">{formError.max_night}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="min_night" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Min Night: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.min_night && 'is-invalid')}
                            id="min_night"
                            placeholder="Min Night"
                            name="min_night"
                            value={formData.min_night}
                            onChange={(e) => setFormData((prev) => ({ ...prev, min_night: e.target.value }))}
                            disabled={isSubmitting}
                            onWheel={(e) => e.target.blur()}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.min_night && <div className="invalid-feedback">{formError.min_night}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="guest_include" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Guest Include: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.guest_include && 'is-invalid')}
                            id="guest_include"
                            placeholder="Guest Include"
                            name="guest_include"
                            value={formData.guest_include}
                            onChange={(e) => setFormData((prev) => ({ ...prev, guest_include: e.target.value }))}
                            disabled={isSubmitting}
                            onWheel={(e) => e.target.blur()}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.guest_include && <div className="invalid-feedback">{formError.guest_include}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="guest_max" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Guest Max: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.guest_max && 'is-invalid')}
                            id="guest_max"
                            placeholder="Guest Max"
                            name="guest_max"
                            value={formData.guest_max}
                            onChange={(e) => setFormData((prev) => ({ ...prev, guest_max: e.target.value }))}
                            disabled={isSubmitting}
                            onWheel={(e) => e.target.blur()}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.guest_max && <div className="invalid-feedback">{formError.guest_max}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_adult" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Adult: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.extra_adult && 'is-invalid')}
                            id="extra_adult"
                            placeholder="Extra Adult"
                            name="extra_adult"
                            value={formData.extra_adult}
                            onChange={(e) => setFormData((prev) => ({ ...prev, extra_adult: e.target.value }))}
                            disabled={isSubmitting}
                            onWheel={(e) => e.target.blur()}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.extra_adult && <div className="invalid-feedback">{formError.extra_adult}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_child" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Child: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.extra_child && 'is-invalid')}
                            id="extra_child"
                            placeholder="Extra Child"
                            name="extra_child"
                            value={formData.extra_child}
                            onChange={(e) => setFormData((prev) => ({ ...prev, extra_child: e.target.value }))}
                            disabled={isSubmitting}
                            onWheel={(e) => e.target.blur()}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.extra_child && <div className="invalid-feedback">{formError.extra_child}</div>}
                    </div>
                </div>
                <h3 className="mt-6 border-bottom fw-normal">Rate Price Settings</h3>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_adult_rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Adult Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="extra_adult_rate"
                            value={formData.extra_adult_rate}
                            onChange={(e, rawValue) => setFormData((prev) => ({ ...prev, extra_adult_rate: rawValue }))}
                            disabled={isSubmitting}
                            error={formError?.extra_adult_rate}
                            placeholder='Rp. 0'
                        />
                        {formError?.extra_adult_rate && <div className="invalid-feedback">{formError.extra_adult_rate}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_child_rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Child Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="extra_child_rate"
                            value={formData.extra_child_rate}
                            onChange={(e, rawValue) => setFormData((prev) => ({ ...prev, extra_child_rate: rawValue }))}
                            disabled={isSubmitting}
                            error={formError?.extra_child_rate}
                            placeholder='Rp. 0'
                        />
                        {formError?.extra_child_rate && <div className="invalid-feedback">{formError.extra_child_rate}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="specific_rule" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Specific Rule: </label>
                    <div className="col-sm-8">
                        <Select
                            options={specificRuleOptions}
                            value={specificRuleOptions.find((option) => option.value === formData.specific_rule)}
                            onChange={(selectedOption) => setFormData((prev) => ({ ...prev, specific_rule: selectedOption.value }))}
                            isDisabled={isSubmitting}
                            className={clsx("app-form-control", formError?.specific_rule && 'is-invalid')}
                        />
                        {formError?.specific_rule && <div className="invalid-feedback">{formError.specific_rule}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="specific_amount_type" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Specific Amount Type: </label>
                    <div className="col-sm-8">
                        <Select
                            options={specificAmountTypeOptions}
                            value={specificAmountTypeOptions.find((option) => option.value === formData.specific_amount_type)}
                            onChange={(selectedOption) => setFormData((prev) => ({ ...prev, specific_amount_type: selectedOption.value }))}
                            isDisabled={isSubmitting}
                            className={clsx("app-form-control", formError?.specific_amount_type && 'is-invalid')}
                        />
                        {formError?.specific_amount_type && <div className="invalid-feedback">{formError.specific_amount_type}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="specific_value" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Specific Value: </label>
                    <div className="col-sm-8">
                        {renderAmountValueInput()}
                    </div>
                </div>
            </div>

            {/* Submit Button */}
            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    {isSubmitting ? 'Creating...' : 'Creating Link Rateplan'}
                </button>
            </div>
        </form>
    );
};

const PercentInput = ({ value, onChange, disabled, error }) => {
    // buat sebuah component input percent dimana terdapat tanda % di belakang input akan tetapi ketika di simpan bisa simpan value raw tanpa tanda %

    return (
        <>
            <div className="input-group">
                <input
                    type="number"
                    className="form-control"
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
                <span className="input-group-text">%</span>
            </div>
            {error && <div className="invalid-feedback">{error}</div>}
        </>
    );
};


const styles = {
    errorContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%", // Sesuaikan agar memuat penuh area Offcanvas
        color: "#6c757d",
    },
    icon: {
        fontSize: "4rem",
        marginBottom: "1rem",
    },
    errorText: {
        fontSize: "1.2rem",
        fontWeight: "bold",
    },
};