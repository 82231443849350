import { createContext, useContext, useState } from "react";
import useGuestDetailsTab from "hooks/v2/pages/reservation/update/useGuestDetailsTab";
import useCommentsTab from "hooks/v2/pages/reservation/update/useCommentsTab";
import useInvoiceTab from "hooks/v2/pages/reservation/update/useInvoiceTab";
import useCreateReservationHooks from "hooks/v2/pages/reservation/create/useCreateReservation";

const defaultReservationsCanvas = {
    booking_id: null,
    data: [],
    error: null,
    isLoading: false,
    show: false
}

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [reservationsCanvas, setReservationsCanvas] = useState(defaultReservationsCanvas);

    const guestDetailsTabHooks = useGuestDetailsTab();
    const commentsTabHooks = useCommentsTab();
    const invoiceTabHooks = useInvoiceTab();
    const createReservationHooks = useCreateReservationHooks({
        ...guestDetailsTabHooks,
        ...commentsTabHooks,
        ...invoiceTabHooks
    });

    const handleCreateReservationCanvas = () => {
        setReservationsCanvas((prevState) => ({
            ...prevState,
            booking_id: null,
            show: true,
        }));
    };



    const contextValue = {
        ...guestDetailsTabHooks,
        ...commentsTabHooks,
        ...invoiceTabHooks,
        ...createReservationHooks,
        reservationsCanvas,
        setReservationsCanvas,
        handleCreateReservationCanvas
    };

    return (
        <SidebarContext.Provider value={contextValue}>
            {children}
        </SidebarContext.Provider>
    );
}

export const useSidebarContext = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebarContext must be used within a SidebarProvider');
    }
    return context;
}