import CommentCanvas from 'pages/reservation/reservation/v2/components/change/comment';
import { useCalendarContext } from 'contexts/v2/pages/calendar/CalendarContext';
import { useCanvasHandler } from 'hooks/useCanvasHandler';
import React from 'react';
import { Tooltip, OverlayTrigger, Button, Table } from 'react-bootstrap';
import { formatDateToDayMonthYear } from 'utils/dateHelpers';
import { formatPrice } from 'utils/utils';
import StatusCanvas from 'pages/reservation/reservation/v2/components/change/status';

const ReservationTooltip = ({ setTooltipShow, rawValues }) => {
    console.log('rawValues', rawValues);
    const { clearTooltip, handleShowCanvas, defaultPaymentTypeOption } = useCalendarContext();

    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip" className="custom-tooltip">
            {text}
        </Tooltip>
    );

    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    const handleOpenChangeCommentCanvas = () => {
        showCanvasHandler('Change Note', <CommentCanvas initialData={rawValues} onSuccess={(message) => {
            handleSuccess(true, message);
        }} onError={(message) => {
            handleError(true, message);
        }} />);
    };

    const handleOpenChangeStatusCanvas = () => {
        showCanvasHandler('Change Status', <StatusCanvas initialData={rawValues} onSuccess={(message) => {
            handleSuccess(true, message);
        }} onError={(message) => {
            handleError(true, message);
        }} />);
    }

    return (
        <div
            className="responsive-tooltip-container d-flex justify-content-between p-3"
            style={{
                width: '100%',
                maxWidth: '800px',
                margin: 'auto',
                backgroundColor: '#f8f9fa',
                borderRadius: '10px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            }}
        >
            {/* Left Section: Reservation Details */}
            <div style={{ flex: 2 }}>
                <Table borderless className='mt-0'>
                    <tbody>
                        <tr>
                            <th>Reservation:</th>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip('Booking Reference Numbers')}
                                >
                                    <span>#{rawValues?.invoice_number}</span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                        <tr>
                            <th>Payment Type</th>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip('Payment Method')}
                                >
                                    <span>{defaultPaymentTypeOption(rawValues?.payment_type).label}</span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                        <tr>
                            <th>Name:</th>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip('Guest Name')}
                                >
                                    <span>{rawValues?.customer_name + rawValues?.customer_surname}</span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                        <tr>
                            <th>Email:</th>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip('Guest Email Address')}
                                >
                                    <a href={`mailto:${rawValues?.customer?.email}`}>
                                        {rawValues?.customer?.email}
                                    </a>
                                </OverlayTrigger>
                            </td>
                        </tr>
                        <tr>
                            <th>Phone:</th>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip('Guest Phone Number')}
                                >
                                    <span>{rawValues?.customer?.phone}</span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                        <tr>
                            <th>Room:</th>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip('Room Number')}
                                >
                                    <span>
                                        {rawValues?.room?.room_number}
                                    </span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                        <tr>
                            <th>Rateplan:</th>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip('Rate Plan Details')}
                                >
                                    <span>
                                        {rawValues?.rate_plan?.name}
                                    </span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                        <tr>
                            <th>Check-in:</th>
                            <td className="text-success">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip('Check-in Date')}
                                >
                                    <span>{formatDateToDayMonthYear(rawValues?.checkin_date)}</span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                        <tr>
                            <th>Check-out:</th>
                            <td className="text-success">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip('Check-out Date')}
                                >
                                    <span>{formatDateToDayMonthYear(rawValues?.checkout_date)}</span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                        <tr>
                            <th>Total:</th>
                            <td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip('Total Payment Amount')}
                                >
                                    <span>{formatPrice(rawValues?.amount)}</span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            {/* Right Section: Action Buttons */}
            <div style={{ flex: 1, textAlign: 'right' }} className='ps-4'>
                <OverlayTrigger
                    placement="left"
                    overlay={renderTooltip('Edit the reservation details')}
                >
                    <Button variant="primary" className="mb-2 w-100" onClick={(e) => {
                        e.preventDefault();
                        setTooltipShow(false);
                        handleShowCanvas(rawValues?.id);
                        clearTooltip();
                    }}>
                        Edit Reservation
                    </Button>
                </OverlayTrigger>
                {/* <OverlayTrigger
                    placement="left"
                    overlay={renderTooltip('Add payment details for this reservation')}
                >
                    <Button variant="info" className="mb-2 w-100 text-white">
                        Add Payment
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="left"
                    overlay={renderTooltip('Add extra items or services')}
                >
                    <Button variant="dark" className="mb-2 w-100">
                        Add Extra
                    </Button>
                </OverlayTrigger> */}
                <OverlayTrigger
                    placement="left"
                    overlay={renderTooltip('Add a comment for internal use')}
                >
                    <Button variant="secondary" className="mb-2 w-100" onClick={(e) => {
                        e.preventDefault();
                        setTooltipShow(false);
                        handleOpenChangeCommentCanvas();
                        clearTooltip();
                    }}>
                        Add Comment
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="left"
                    overlay={renderTooltip('Change the reservation status')}
                >
                    <Button variant="success" className="w-100" onClick={(e) => {
                        e.preventDefault();
                        setTooltipShow(false);
                        handleOpenChangeStatusCanvas();
                        clearTooltip();
                    }}>
                        Change Status
                    </Button>
                </OverlayTrigger>
            </div>
        </div>
    );
};

export default ReservationTooltip;