import { createContext, useContext, useState } from "react";
import useMoveReservationHooks from "hooks/v2/pages/calendar/useMoveReservationHooks";
import useTooltipReservationHooks from "hooks/v2/pages/calendar/useTooltipReservationHooks";
import useGuestDetailsTab from "hooks/v2/pages/reservation/update/useGuestDetailsTab";
import useCommentsTab from "hooks/v2/pages/reservation/update/useCommentsTab";
import useInvoiceTab from "hooks/v2/pages/reservation/update/useInvoiceTab";
import useUpdateReservationHooks from "hooks/v2/pages/reservation/update/useUpdateReservation";
import useCreateReservationHooks from "hooks/v2/pages/reservation/create/useCreateReservation";

const defaultReservationsCanvas = {
    booking_id: null,
    data: [],
    error: null,
    isLoading: false,
    show: false
}

const UpdateReservationContext = createContext();

export const UpdateReservationProvider = ({ children }) => {
    const [reservationsCanvas, setReservationsCanvas] = useState(defaultReservationsCanvas);

    const moveReservationHooks = useMoveReservationHooks();
    const tooltipReservationHooks = useTooltipReservationHooks();
    const guestDetailsTabHooks = useGuestDetailsTab();
    const commentsTabHooks = useCommentsTab();
    const invoiceTabHooks = useInvoiceTab();
    const updateReservationHooks = useUpdateReservationHooks({
        ...guestDetailsTabHooks,
        ...commentsTabHooks,
        ...invoiceTabHooks,
        booking_id: reservationsCanvas.booking_id,
    });
    const createReservationHooks = useCreateReservationHooks({
        ...guestDetailsTabHooks,
        ...commentsTabHooks,
        ...invoiceTabHooks
    });

    const handleShowCanvas = (booking_id) => {
        console.log('Show Canvas:', booking_id);
        setReservationsCanvas((prevState) => ({
            ...prevState,
            booking_id: booking_id,
            show: true,
        }));
    };

    const handleCreateReservationCanvas = () => {
        setReservationsCanvas((prevState) => ({
            ...prevState,
            booking_id: null,
            show: true,
        }));
    };

    const contextValue = {
        ...moveReservationHooks,
        ...tooltipReservationHooks,
        ...guestDetailsTabHooks,
        ...commentsTabHooks,
        ...invoiceTabHooks,
        ...updateReservationHooks,
        ...createReservationHooks,
        reservationsCanvas,
        setReservationsCanvas,
        handleShowCanvas,
        handleCreateReservationCanvas,
    };

    return (
        <UpdateReservationContext.Provider value={contextValue}>
            {children}
        </UpdateReservationContext.Provider>
    );
};

export const useUpdateReservation = () => {
    const context = useContext(UpdateReservationContext);
    if (!context) {
        throw new Error('useUpdateReservation must be used within a UpdateReservationProvider');
    }
    return context;
}