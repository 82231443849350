import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { postRequest } from "services/api";

const CommentCanvas = ({ initialData, onSuccess, onError }) => {
    // State untuk menyimpan form data. Jika initialData tersedia, isi form untuk update
    const [formData, setFormData] = useState({
        notes: initialData?.notes || '',
        private_notes: initialData?.private_notes || '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formError, setFormError] = useState({});

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const request = await postRequest(`/v2/booking/${initialData?.id}/comment`, {
                notes: formData.notes,
                private_notes: formData.private_notes
            });

            if (request.success) {
                onSuccess('Comments updated successfully');
            }

        }
        catch (error) {
            setFormError(error);
            onError('Failed to update status');
        }
        finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        // Jika data produk berubah (untuk update), isi ulang form data
        if (initialData) {
            setFormData({
                notes: initialData.notes || '',
                private_notes: initialData.private_notes || '',
            });
        }
    }, [initialData]);

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                {/* Detail Reservation */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="reservation_id" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Reservation ID:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control app-form-control rounded-1"
                            id="reservation_id"
                            value={initialData?.unique_id}
                            disabled
                            style={{ fontSize: '14px' }}
                        />
                    </div>
                </div>

                {/* Customer name get from initialData?.customer_name + customer surname */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="customer_name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Customer Name:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control app-form-control rounded-1"
                            id="customer_name"
                            value={initialData?.customer_name + ` ` + initialData?.customer_surname}
                            disabled
                            style={{ fontSize: '14px' }}
                        />
                    </div>
                </div>

                {/* Room Type & number */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="room_type" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Room Type:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control app-form-control rounded-1"
                            id="room_type"
                            value={initialData?.room_type?.name}
                            disabled
                            style={{ fontSize: '14px' }}
                        />
                    </div>
                </div>

                {/* Room Number */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="room_number" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Room Number:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control app-form-control rounded-1"
                            id="room_number"
                            value={initialData?.room?.room_number}
                            disabled
                            style={{ fontSize: '14px' }}
                        />
                    </div>
                </div>

                {/* Public Notes */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="public_notes" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Public Notes:</label>
                    <div className="col-sm-8">
                        <textarea
                            className={clsx("form-control app-form-control rounded-1", formError?.notes && 'is-invalid')}
                            id="public_notes"
                            placeholder="Public Notes"
                            name="public_notes"
                            value={formData.notes}
                            onChange={(e) => {
                                setFormData({ ...formData, notes: e.target.value });
                            }}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '100px' }}
                        />
                        {formError?.notes && <div className="invalid-feedback">{formError.notes}</div>}
                    </div>
                </div>

                {/* Private Notes */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="private_notes" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Private Notes:</label>
                    <div className="col-sm-8">
                        <textarea
                            className={clsx("form-control app-form-control rounded-1", formError?.private_notes && 'is-invalid')}
                            id="private_notes"
                            placeholder="Private Notes"
                            name="private_notes"
                            value={formData.private_notes}
                            onChange={(e) => {
                                setFormData({ ...formData, private_notes: e.target.value });
                            }}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '100px' }}
                        />
                        {formError?.private_notes && <div className="invalid-feedback">{formError.private_notes}</div>}
                    </div>
                </div>
            </div>

            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    Update Notes
                </button>
            </div>
        </form>
    );
};

export default CommentCanvas;
