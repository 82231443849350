import { Dropdown } from 'react-bootstrap';

// Action Dropdown Component
const ActionDropdown = ({ onEdit, onRemove, onCreateRate, onCreateLink }) => (
    <Dropdown>
        <Dropdown.Toggle variant="default" id="action-dropdown">
            Actions
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {onEdit && <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>}
            {onCreateRate && <Dropdown.Item onClick={onCreateRate}>Create Rate</Dropdown.Item>}
            {onCreateLink && <Dropdown.Item onClick={onCreateLink}>Create Link Rate</Dropdown.Item>}
            {onRemove && <Dropdown.Item className="text-danger" onClick={onRemove}>Remove</Dropdown.Item>}
        </Dropdown.Menu>
    </Dropdown>
);

export default ActionDropdown;