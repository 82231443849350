import React, { useEffect, useState } from "react";
import FullLoader from "components/loader/full-loader";
import { Accordion, Card, Offcanvas, Tab, Tabs, useAccordionButton } from "react-bootstrap";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useCanvasHandler } from "hooks/useCanvasHandler";
import CreateReservationPage from "./v2/create-reservation-page";
import { IconAdjustmentsHorizontal } from "@tabler/icons-react";
import { usePaginationWithComponent } from "hooks/usePaginationWithComponent";
import { getRequest } from "services/api";
import ReservationItems from "./components/reservations-items";
import SearchComponent from "./components/search-component";
import { useReservation } from "contexts/v2/pages/reservation/ReservationContext";
import { CreateReservationProvider, useCreateReservation } from "contexts/v2/pages/reservation/CreateReservationContext";
import ReservationFilter from "./v2/components/table/reservation-filter";
import { UpdateReservationProvider, useUpdateReservation } from "contexts/v2/pages/reservation/UpdateReservationContext";
import InvoiceTab from "./v2/components/edit/invoice-tab";
import GuestDetails from "./v2/components/edit/guest-details-tab";
import Comments from "./v2/components/edit/comments-tab";

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
        <button className="btn btn-icon me-2" onClick={decoratedOnClick}>
            {children}
        </button>
    );
}

const ReservationPage = () => {
    const { reservation, PaginationComponent } = useReservation();

    const [reservationsCanvas, setReservationsCanvas] = useState({
        data: [],
        error: null,
        isLoading: false,
        show: false
    });

    if (reservation.isLoading) {
        return <FullLoader />;
    }

    return (
        <LayoutDashboard>
            <UpdateReservationProvider>
                <div className="container-fluid mt-5">
                    <div className="table-container">
                        <Accordion defaultActiveKey="0" flush>
                            <div className="search-container mb-3">
                                <div className="d-flex">
                                    <CustomToggle eventKey="0">
                                        <IconAdjustmentsHorizontal className="text-secondary icon" />
                                    </CustomToggle>
                                    <button className="btn btn-success me-2">Export</button>
                                    <button className="btn bg-cyan text-white">Import</button>
                                </div>

                                {/* Create button */}
                                <button className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault();
                                    setReservationsCanvas({ ...reservationsCanvas, show: true });
                                }}>Create</button>
                            </div>
                            <Accordion.Collapse eventKey="0">
                                <ReservationFilter />
                            </Accordion.Collapse>
                        </Accordion>

                        {/* Table */}
                        <ReservationItems />

                        {/* Pagination */}
                        {PaginationComponent}

                        {/* Canvas */}
                        <CanvasForCreateReservation reservationsCanvas={reservationsCanvas} setReservationsCanvas={setReservationsCanvas} />
                    </div>
                </div>
            </UpdateReservationProvider>
        </LayoutDashboard>
    );
};

export default ReservationPage;

const CanvasForCreateReservation = ({ reservationsCanvas, setReservationsCanvas }) => {
    const {
        guestDetailsForm,
        setGuestDetailsForm,
        countryOptions,
        defaultOptionsCountry,
        currencyOptions,
        defaultOptionsCurrency,
        agentOptions,
        defaultOptionsAgent,
        birthdayOptions,
        defaultOptionsBirthday,
        commentsForm,
        setCommentsForm,
        invoiceForm,
        setInvoiceForm,
        optionsStatus,
        defaultStatusOption,
        optionsMark,
        defaultMarkOption,
        optionsPaymentType,
        defaultPaymentTypeOption,
        rooms,
        setRooms,
        addRoom,
        removeRoom,
        defaultRoomTypeOption,
        selectRoomType,
        defaultRoomNumberOption,
        defaultRateplanOption,
        optionsAdults,
        defaultAdultsOption,
        optionsChildren,
        defaultChildrenOption,
        fetchRateplansDays,
        getRoomTotal,
        handleCreateReservation,
        selectRoomNumber,
        fetchRoomTypes,
        fetchRoomTypesInUpdate
    } = useUpdateReservation();

    return (
        <Offcanvas show={reservationsCanvas.show} onHide={() => {
            setReservationsCanvas((prevState) => ({ ...prevState, show: false }));
        }} placement='end' style={{ width: '100%' }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Create Reservation</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="pt-3">
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleCreateReservation().then(async (response) => {
                        if (response.success) {
                            setReservationsCanvas((prevState) => ({ ...prevState, show: false }));
                            const result = await Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: 'Reservation created successfully',
                                showConfirmButton: true,
                                confirmButtonText: 'OK',
                            });
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response.message,
                            });
                            console.error(response.errors);
                        }
                    });
                }}>
                    <Tabs
                        defaultActiveKey='invoice'
                        className="mb-3 text-blue"
                        variant="underline"
                    >
                        <Tab eventKey='invoice' title='Invoice'>
                            <InvoiceTab
                                invoiceForm={invoiceForm}
                                setInvoiceForm={setInvoiceForm}
                                optionsStatus={optionsStatus}
                                defaultStatusOption={defaultStatusOption}
                                optionsMark={optionsMark}
                                defaultMarkOption={defaultMarkOption}
                                optionsPaymentType={optionsPaymentType}
                                defaultPaymentTypeOption={defaultPaymentTypeOption}
                                rooms={rooms}
                                setRooms={setRooms}
                                removeRoom={removeRoom}
                                defaultRoomTypeOption={defaultRoomTypeOption}
                                selectRoomType={selectRoomType}
                                defaultRoomNumberOption={defaultRoomNumberOption}
                                defaultRateplanOption={defaultRateplanOption}
                                optionsAdults={optionsAdults}
                                defaultAdultsOption={defaultAdultsOption}
                                optionsChildren={optionsChildren}
                                defaultChildrenOption={defaultChildrenOption}
                                fetchRateplansDays={fetchRateplansDays}
                                getRoomTotal={getRoomTotal}
                                selectRoomNumber={selectRoomNumber}
                                fetchRoomTypes={fetchRoomTypes}
                                fetchRoomTypesInUpdate={fetchRoomTypesInUpdate}
                            />
                        </Tab>
                        <Tab eventKey='guest_details' title='Guest Details'>
                            <GuestDetails
                                guestDetailsForm={guestDetailsForm}
                                setGuestDetailsForm={setGuestDetailsForm}
                                countryOptions={countryOptions}
                                defaultOptionsCountry={defaultOptionsCountry}
                                currencyOptions={currencyOptions}
                                defaultOptionsCurrency={defaultOptionsCurrency}
                                agentOptions={agentOptions}
                                defaultOptionsAgent={defaultOptionsAgent}
                                birthdayOptions={birthdayOptions}
                                defaultOptionsBirthday={defaultOptionsBirthday}
                            />
                        </Tab>
                        <Tab eventKey='comments' title='Comments'>
                            <Comments commentsForm={commentsForm} setCommentsForm={setCommentsForm} />
                        </Tab>
                    </Tabs>

                    {/* button flex between */}
                    <div className="my-4 border-bottom"></div>
                    <div className="d-flex flex-column justify-content-end gap-2">
                        <div className="d-flex justify-content-between gap-2">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    addRoom();
                                }}
                            >
                                Add Room
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Create Reservation
                            </button>
                        </div>
                    </div>
                </form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};