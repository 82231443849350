import React, { useState } from 'react';
import LayoutDashboard from 'layouts/layout-dashboard-collapse';
import { RoomRateProvider, useRoomRate } from 'contexts/v2/pages/room-rate-setting/RoomRateContext';
import ActionDropdown from './components/action-dropdown';
import FullLoader from 'components/loader/full-loader';
import ChildRates from './components/child-rates';
import CreateRoom from './components/create-room';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import EditRoom from './components/edit-room';
import CreateRate from './components/create-rate';
import EditRate from './components/edit-rate';
import CreateLinkRate from './components/create-link-rate';

// Main RoomRatePage Component
const RoomRatePage = () => {
    return (
        <RoomRateProvider>
            <RoomRate />
        </RoomRateProvider>
    );
};

export default RoomRatePage;

const RoomRate = () => {
    const { roomRateData, filteredRoom, refreshRoomData, removeRoom, setRoomIdWillUpdate, setRoomIdWillCreateRateplan, setRateIdWillUpdate, setRateplanIdWillLink } = useRoomRate();
    const [searchQuery, setSearchQuery] = useState('');
    const [childRatesVisible, setChildRatesVisible] = useState({});

    // Canvas State for Create Page & Edit Page
    const [createPageShow, setCreatePageShow] = useState(false);
    const [editPageShow, setEditPageShow] = useState(false);

    // handler status form
    const [isDeleting, setIsDeleting] = useState(false);

    // Canvas State for Rateplan
    const [refreshKeyForRateplan, setRefreshKeyForRateplan] = useState(new Date().getTime());
    const [createRatePlanShow, setCreateRatePlanShow] = useState(false);
    const [editRatePlanShow, setEditRatePlanShow] = useState(false);
    const [createLinkRateShow, setCreateLinkRateShow] = useState(false);

    // Handlers
    const handleCreateRoom = () => setCreatePageShow(true);
    const handleEditRoom = (roomId) => {
        setEditPageShow(true);
        setRoomIdWillUpdate(roomId);
    };
    const handleDeleteRoom = async (roomId) => {
        // Menampilkan SweetAlert dengan status 'isSubmitting' yang dapat digunakan untuk menonaktifkan tombol
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "This room type will be permanently deleted!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            showLoaderOnConfirm: true, // Menampilkan loader di tombol konfirmasi
            preConfirm: async () => {
                setIsDeleting(true);  // Set status submitting
                try {
                    // Lakukan permintaan penghapusan
                    const response = await removeRoom(roomId);  // Ganti dengan API penghapusan

                    if (response.success) {
                        return response.message;  // Jika berhasil, kembalikan pesan sukses
                    } else {
                        throw new Error(response.message); // Jika gagal, lempar error dengan pesan dari server
                    }
                } catch (error) {
                    // Tangani error dan kembalikan pesan error
                    throw new Error(error.message);  // Lemparkan error agar dapat ditangani di SweetAlert
                } finally {
                    setIsDeleting(false);  // Set status submitting kembali ke false
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),  // Menghentikan klik luar jika sedang loading
            allowEscapeKey: () => !Swal.isLoading(),    // Menghentikan escape key saat loading
        });

        // Jika konfirmasi sukses, tampilkan pesan sukses
        if (result.isConfirmed) {
            toast.success('Room type deleted successfully');
            refreshRoomData();  // Refresh data
        } else if (result.isDismissed) {
            // Menangani pembatalan atau penutupan SweetAlert
            console.log('Deletion was cancelled');
            return;
        }

        // Jika terjadi error, tampilkan pesan error
        if (result.error) {
            toast.error(result.error.message || 'There was an issue deleting the room type.');
        }
    };

    const handleToggleChildRates = (index) => {
        setChildRatesVisible((prev) => ({ ...prev, [index]: !prev[index] }));
    };
    // Handlers for Create and Edit Rateplan
    const handleCreateRate = (roomId) => {
        setCreateRatePlanShow(true);
        setRoomIdWillCreateRateplan(roomId);
    };
    const handleEditRate = (rateId) => {
        setEditRatePlanShow(true);
        setRateIdWillUpdate(rateId);
    };
    const handleCreateLinkRate = (roomId) => {
        setCreateLinkRateShow(true);
        setRateplanIdWillLink(roomId);
    };

    // First Load
    if (roomRateData.loading) {
        <FullLoader />;
    }

    // Error Message
    if (!roomRateData.success) {
        return <div className="alert alert-danger">{roomRateData.message}</div>;
    }

    // Get filtered rooms based on the search query
    const filteredRooms = filteredRoom(searchQuery);

    return (
        <LayoutDashboard>
            <div className="container-fluid mt-5">
                <div className="table-container">
                    {/* Header: Search and Create */}
                    <div className="search-container mb-3">
                        {/* Search input */}
                        <div className="input-icon">
                            <input type="text" className="form-control" placeholder="Search room type..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                            <span className="input-icon-addon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-search"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="10" cy="10" r="7" />
                                    <line x1="21" y1="21" x2="15" y2="15" />
                                </svg>
                            </span>
                        </div>

                        {/* Create button */}
                        <button className="btn btn-primary" onClick={handleCreateRoom}>Create</button>
                    </div>

                    {/* Room Table */}
                    <div className="table-responsive position-relative">
                        {roomRateData.loading &&
                            (
                                <div class="spinner-overlay table-spinner-overlay active">
                                    <div class="spinner-border table-spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )
                        }
                        <table className="table table-hover table-vcenter">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Count of Rooms</th>
                                    <th>Occupancy</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRooms.map((roomType, index) => (
                                    <React.Fragment key={roomType.id}>
                                        <tr>
                                            <td>
                                                <button
                                                    className="btn btn-sm btn-link me-2"
                                                    onClick={() => handleToggleChildRates(index)}
                                                >
                                                    {childRatesVisible[index] ? '−' : '+'}
                                                </button>
                                                {roomType.name}
                                            </td>
                                            <td>{roomType.total_rooms}</td>
                                            <td>
                                                A: {roomType.occ_adults} C: {roomType.occ_children}
                                            </td>
                                            <td className="text-end">
                                                <ActionDropdown
                                                    onEdit={() => handleEditRoom(roomType.id)}
                                                    onCreateRate={() => handleCreateRate(roomType.id)}
                                                    onRemove={() => handleDeleteRoom(roomType.id)}
                                                />
                                            </td>
                                        </tr>
                                        {childRatesVisible[index] && <ChildRates
                                            key={`${roomType.id}-${index}`}
                                            roomId={roomType.id}
                                            refreshKey={refreshKeyForRateplan}
                                            setChildRatesVisible={setChildRatesVisible}
                                            indexToggle={index}
                                            onEditRate={handleEditRate}
                                            onCreateLinkRate={handleCreateLinkRate}
                                        />}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <CreateRoom
                show={createPageShow}
                handleClose={() => setCreatePageShow(false)}
                onSuccess={(message) => {
                    setCreatePageShow(false);
                    toast.success(message);
                    refreshRoomData();
                }}
                onError={(message) => toast.error(message)}
            />
            <EditRoom
                show={editPageShow}
                handleClose={() => {
                    setEditPageShow(false);
                    setRoomIdWillUpdate(null);
                }}
                onSuccess={(message) => {
                    setEditPageShow(false);
                    setRoomIdWillUpdate(null);
                    toast.success(message);
                    refreshRoomData();
                }}
                onError={(message) => toast.error(message || 'Failed to update room type')}
            />
            <CreateRate
                show={createRatePlanShow}
                handleClose={() => setCreateRatePlanShow(false)}
                onSuccess={(message) => {
                    setCreateRatePlanShow(false);
                    toast.success(message || 'Rateplan created successfully');
                    refreshRoomData();
                    setRefreshKeyForRateplan(new Date().getTime());
                }}
                onError={(message) => toast.error(message)}
            />
            <EditRate
                show={editRatePlanShow}
                handleClose={() => setEditRatePlanShow(false)}
                onSuccess={(message) => {
                    setEditRatePlanShow(false);
                    toast.success(message || 'Rateplan updated successfully');
                    refreshRoomData();
                    setRefreshKeyForRateplan(new Date().getTime());
                }}
                onError={(message) => toast.error(message)}
            />
            <CreateLinkRate
                show={createLinkRateShow}
                handleClose={() => setCreateLinkRateShow(false)}
                onSuccess={(message) => {
                    setCreateLinkRateShow(false);
                    toast.success(message || 'Link Rateplan created successfully');
                    refreshRoomData();
                    setRefreshKeyForRateplan(new Date().getTime());
                }}
                onError={(message) => toast.error(message)}
            />
        </LayoutDashboard>
    );
};