import clsx from 'clsx';
import { useRoomRate } from 'contexts/v2/pages/room-rate-setting/RoomRateContext';
import React, { useRef, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';

const CreateRoom = ({ show, handleClose, onSuccess, onError }) => {
    const { createRoom, createRoomStatus } = useRoomRate();

    return (
        <Offcanvas show={show} onHide={handleClose} placement='end' style={{ width: '45%' }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Create Roomtype</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <CreateRoomForm
                    submitData={createRoom}
                    onSuccess={onSuccess}
                    onError={onError}
                />
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CreateRoom;

const CreateRoomForm = ({ submitData, onSuccess, onError }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        no_of_beds: null,
        cut_of_days: null,
        is_free_allotment: false,
        is_auto_assign: false,
        is_dynamic_pricing: false,
        occ_adults: null,
        occ_children: null,
        room_number: [],
    });
    const [formError, setFormError] = useState(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [roomCount, setRoomCount] = useState(null);
    const roomNumberRefs = useRef([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await submitData(formData);

            if (response.success) {
                // Reset form setelah berhasil submit
                setFormData({
                    name: '',
                    description: '',
                    no_of_beds: 0,
                    cut_of_days: 0,
                    is_free_allotment: false,
                    is_auto_assign: false,
                    is_dynamic_pricing: false,
                    occ_adults: 0,
                    occ_children: 0,
                    room_number: [],
                });

                // Callback onSuccess
                onSuccess(response.message);
            } else {
                // Menangani form error atau error umum
                if (response.formError) {
                    setFormError(response.formError);
                } else {
                    onError("Oops! Something went wrong on our end. Please try again later.");
                }
            }
        } catch (error) {
            // Tangani error dari API request
            onError("Sorry, there was an issue with the application. Please try again later.");
        } finally {
            // Pastikan isSubmitting diset ke false setelah proses selesai
            setIsSubmitting(false);
        }
    };

    // Handle room count and input focus
    const handleRoomAvailableChange = (e) => {
        const roomCount = parseInt(e.target.value, 10);
        setRoomCount(roomCount);
        if (!isNaN(roomCount)) {
            setFormData((prevFormData) => {
                const updatedRoomNumbers = Array.from({ length: roomCount }, (_, index) => prevFormData[`room_number_${index}`] || `10${index + 1}`);
                return {
                    ...prevFormData,
                    room_number: updatedRoomNumbers,
                };
            });

            // Focus the first room input
            if (roomNumberRefs.current[0]) {
                roomNumberRefs.current[0].focus();
            }
        }
    };

    // Handle room number change
    const handleRoomNumberChange = (e, index) => {
        const { value } = e.target;
        setFormData((prevFormData) => {
            const updatedRoomNumbers = [...(prevFormData.room_number || [])];
            updatedRoomNumbers[index] = value;

            return {
                ...prevFormData,
                room_number: updatedRoomNumbers,
            };
        });
    };

    return (
        <form onSubmit={(e) => handleSubmit(e)} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                <div className="row mb-3 align-items-center">
                    <label htmlFor="name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Name: </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control app-form-control rounded-1", formError?.name && 'is-invalid')}
                            id="name"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, name: e.target.value }))}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.name && <div className="invalid-feedback">{formError.name}</div>}
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <label htmlFor="description" className="col-sm-4 col-form-label d-flex justify-content-end">Description: </label>
                    <div className="col-sm-8">
                        <textarea
                            className={clsx("form-control app-form-control rounded-1", formError?.description && 'is-invalid')}
                            name="description"
                            id="description"
                            placeholder="Enter rate plan description"
                            value={formData.description}
                            onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, description: e.target.value }))}
                        />
                        {formError?.description && <div className="invalid-feedback">{formError.description}</div>}
                    </div>
                </div>

                <h3 className="mt-6 border-bottom fw-normal">Room Settings</h3>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="noOfRooms" className="col-sm-4 col-form-label d-flex justify-content-end">No. of Beds</label>
                    <div className="col-sm-8">
                        <select
                            name="no_of_beds"
                            className={clsx("form-control app-form-control rounded-1", formError?.no_of_beds && 'is-invalid')}
                            id="noOfRooms"
                            value={formData.no_of_beds}
                            onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, no_of_beds: parseInt(e.target.value, 10) }))}
                        >
                            <option value="0">Select no. of beds</option>
                            <option value="1">1 bed</option>
                            <option value="2">2 beds</option>
                            <option value="3">3 beds</option>
                        </select>
                        {formError?.no_of_beds && <div className="invalid-feedback">{formError.no_of_beds}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="cutOff" className="col-sm-4 col-form-label d-flex justify-content-end">Cut Off: </label>
                    <div className='col-sm-8'>
                        <select
                            name="cut_of_days"
                            className={clsx("form-control app-form-control rounded-1", formError?.cut_of_days && 'is-invalid')}
                            id="cutOff"
                            value={formData.cut_of_days}
                            onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, cut_of_days: parseInt(e.target.value, 10) }))}
                        >
                            <option value="0">Select cut off</option>
                            <option value="1">1 Day</option>
                            <option value="2">2 Days</option>
                            <option value="3">3 Days</option>
                        </select>
                        {formError?.cut_of_days && <div className="invalid-feedback">{formError.cut_of_days}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end" htmlFor="enableFreeAllotment">Enable Free Allotment:</label>
                    <div className="col-sm-8">
                        <input
                            type="checkbox"
                            name="is_free_allotment"
                            className={clsx("form-check-input rounded-1", formError?.is_free_allotment && 'is-invalid')}
                            id="enableFreeAllotment"
                            checked={formData.is_free_allotment}
                            onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, is_free_allotment: e.target.checked }))}
                        />
                        {formError?.is_free_allotment && <div className="invalid-feedback">{formError.is_free_allotment}</div>}
                    </div>
                    <label className="col-sm-4 col-form-label d-flex justify-content-end" htmlFor="enableAutoAssign">Enable Auto Assign Feature:</label>
                    <div className="col-sm-8">
                        <input
                            type="checkbox"
                            name="is_auto_assign"
                            className={clsx("form-check-input rounded-1", formError?.is_auto_assign && 'is-invalid')}
                            id="enableAutoAssign"
                            checked={formData.is_auto_assign}
                            onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, is_auto_assign: e.target.checked }))}
                        />
                        {formError?.is_auto_assign && <div className="invalid-feedback">{formError.is_auto_assign}</div>}
                    </div>
                    <label className="col-sm-4 col-form-label d-flex justify-content-end" htmlFor="enableDynamicPricing">Enable Dynamic Pricing</label>
                    <div className="col-sm-8">
                        <input
                            type="checkbox"
                            name="is_dynamic_pricing"
                            className={clsx("form-check-input rounded-1", formError?.is_dynamic_pricing && 'is-invalid')}
                            id="enableDynamicPricing"
                            checked={formData.is_dynamic_pricing}
                            onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, is_dynamic_pricing: e.target.checked }))}
                        />
                        {formError?.is_dynamic_pricing && <div className="invalid-feedback">{formError.is_dynamic_pricing}</div>}
                    </div>
                </div>

                {/* Add Adults input */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="adults" className="col-sm-4 col-form-label d-flex justify-content-end">Occupancy Adults</label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.occ_adults && 'is-invalid')}
                            id="adults"
                            placeholder="Enter no. of adults"
                            value={formData.occ_adults}
                            min={0}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                                setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    occ_adults: parseInt(e.target.value, 10),
                                }));
                            }}
                        />
                        {formError?.occ_adults && <div className="invalid-feedback">{formError.occ_adults}</div>}
                    </div>
                </div>

                {/* Add children input */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="children" className="col-sm-4 col-form-label d-flex justify-content-end">Occupancy Children</label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.occ_children && 'is-invalid')}
                            id="children"
                            placeholder="Enter no. of children"
                            value={formData.occ_children}
                            min={0}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                                setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    occ_children: parseInt(e.target.value, 10),
                                }));
                            }}
                        />
                        {formError?.occ_children && <div className="invalid-feedback">{formError.occ_children}</div>}
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label htmlFor="roomAvailable" className="col-sm-4 col-form-label d-flex justify-content-end">Set the no. room Available</label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control app-form-control rounded-1", formError?.room_number && 'is-invalid')}
                            id="roomAvailable"
                            placeholder="Enter no. room available"
                            value={roomCount}
                            min={0}
                            onWheel={(e) => e.target.blur()}
                            onChange={handleRoomAvailableChange}
                        />
                        {formError?.room_number && <div className="invalid-feedback">{formError.room_number}</div>}
                    </div>
                </div>
                {/* Room Number Inputs */}
                {Array.from({ length: roomCount }, (_, index) => (
                    <div className="row mb-3 align-items-center" key={index}>
                        <label htmlFor={`roomNumber_${index}`} className="col-sm-4 col-form-label d-flex justify-content-end">Room {index + 1} Name</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                className="form-control"
                                id={`roomNumber_${index}`}
                                placeholder={`Enter name for room ${index + 1}`}
                                value={formData.room_number ? formData.room_number[index] : ''}
                                onChange={(e) => handleRoomNumberChange(e, index)}
                                ref={(el) => (roomNumberRefs.current[index] = el)}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    {isSubmitting ? 'Creating...' : 'Create Room Type'}
                </button>
            </div>
        </form>
    );
};