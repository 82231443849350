import Select from "react-select";
import RoomRates from "./room-rates";

const InvoiceTab = ({
    reservationsCanvas,
    invoiceForm,
    setInvoiceForm,
    optionsStatus,
    defaultStatusOption,
    optionsMark,
    defaultMarkOption,
    optionsPaymentType,
    defaultPaymentTypeOption,
    rooms,
    setRooms,
    removeRoom,
    defaultRoomTypeOption,
    selectRoomType,
    defaultRoomNumberOption,
    defaultRateplanOption,
    optionsAdults,
    defaultAdultsOption,
    optionsChildren,
    defaultChildrenOption,
    fetchRateplansDays,
    getRoomTotal,
    selectRoomNumber,
    fetchRoomTypes,
    fetchRoomTypesInUpdate
}) => {
    return (
        <div className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                <div className="row mb-3 align-items-start">
                    <div className="col-sm-4 row">
                        {reservationsCanvas?.booking_id && (
                            <>
                                <img
                                    src="/3592fb7a9746fd4cf510c54b0d7ef017.png"
                                    alt="Booking com"
                                    style={{ height: '50px', width: 'auto', objectFit: 'contain' }}
                                />
                                <span className="text-success fw-bold cursor-pointer">
                                    <a href={`https://pms-api.gotra.my.id/api/ota-invoice/55491f8d-4ed9-4d7a-9cb0-971662d3482a`} className="text-reset text-decoration-none" target="_blank">View Invoice</a> -
                                    <span className="text-primary">Reg Form</span>
                                </span>
                                <span className="text-danger fw-bold cursor-pointer">
                                    <a href={`https://pms-api.gotra.my.id/api/invoice/55491f8d-4ed9-4d7a-9cb0-971662d3482a`} className="text-reset text-decoration-none" target="_blank">Download PDF Invoice</a> -
                                    <span className="text-secondary">Guest Details</span>
                                </span>
                            </>
                        )}
                    </div>
                    <div className="col-sm-4">
                        <div className="col-12">
                            <label className="col-form-label" htmlFor="custom_invoice_nb">Custom Invoice Nb:</label>
                            <input
                                type="text"
                                className="form-control app-form-control rounded-1"
                                id="custom_invoice_nb"
                                placeholder="Custom Invoice Nb"
                                style={{ fontSize: '14px', height: '35px' }}
                                value={invoiceForm.custom_invoice_nb}
                                onChange={(e) => setInvoiceForm((prevInvoiceForm) => ({ ...prevInvoiceForm, custom_invoice_nb: e.target.value }))}
                            />
                        </div>
                        <div className="col-12">
                            <label className="col-form-label" htmlFor="invoice_date">Invoice Date:</label>
                            <input
                                type="text"
                                className="form-control app-form-control rounded-1"
                                id="invoice_date"
                                placeholder="Invoice Date"
                                style={{ fontSize: '14px', height: '35px' }}
                                value={invoiceForm.invoice_date}
                                onChange={(e) => setInvoiceForm((prevInvoiceForm) => ({ ...prevInvoiceForm, invoice_date: e.target.value }))}
                            />
                        </div>
                        {/* Checkbox new guest & guest no */}
                        {/* <div className="col-12 d-flex gap-2">
                            <div className="col-sm-4" style={{ gap: '8px' }}>
                                <label className="col-form-label d-flex" htmlFor="new_guest">New Guest</label>
                                <div className="d-flex gap-2">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="new_guest"
                                        name="guestOption"
                                        checked={invoiceForm.new_guest === true}
                                        onChange={() => setInvoiceForm((prevInvoiceForm) => ({
                                            ...prevInvoiceForm,
                                            new_guest: true,
                                            guest_no: '' // Reset guest_no when new guest is selected
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-8" style={{ gap: '8px' }}>
                                <label className="col-form-label d-flex" htmlFor="guest_no">Guest No</label>
                                <div className="d-flex gap-2">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="guest_no"
                                        name="guestOption"
                                        checked={invoiceForm.new_guest === false}
                                        onChange={() => setInvoiceForm((prevInvoiceForm) => ({
                                            ...prevInvoiceForm,
                                            new_guest: false
                                        }))}
                                    />
                                    <input
                                        type="text"
                                        className="form-control app-form-control rounded-1"
                                        id="guest_no_input"
                                        placeholder="Guest No"
                                        style={{ fontSize: '14px', height: '35px' }}
                                        value={invoiceForm.guest_no}
                                        onChange={(e) => setInvoiceForm((prevInvoiceForm) => ({
                                            ...prevInvoiceForm,
                                            guest_no: e.target.value
                                        }))}
                                        disabled={invoiceForm.new_guest} // Input disabled if new_guest is true
                                    />
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-sm-4 ps-4">
                        <div className="col-12">
                            {/* Booking Date using input date */}
                            <label className="col-form-label" htmlFor="booking_date">Booking Date:</label>
                            <input
                                type="date"
                                className="form-control app-form-control rounded-1"
                                id="booking_date"
                                style={{ fontSize: '14px', height: '35px' }}
                                value={invoiceForm.booking_date}
                                onChange={(e) => setInvoiceForm((prevInvoiceForm) => ({ ...prevInvoiceForm, booking_date: e.target.value }))}
                            />
                        </div>
                        <div className="col-12">
                            {/* Status select */}
                            <label className="col-form-label" htmlFor="status">Status:</label>
                            <Select
                                options={optionsStatus}
                                value={defaultStatusOption()}
                                onChange={(selectedOption) => setInvoiceForm((prevInvoiceForm) => ({ ...prevInvoiceForm, status: selectedOption.value }))}
                            />
                        </div>
                        <div className="col-12">
                            {/* Mark as select */}
                            <label className="col-form-label" htmlFor="mark">Mark as:</label>
                            <Select
                                options={optionsMark}
                                value={defaultMarkOption()}
                                onChange={(selectedOption) => setInvoiceForm((prevInvoiceForm) => ({ ...prevInvoiceForm, mark: selectedOption.value }))}
                            />
                        </div>
                    </div>
                </div>

                {/* Room Rates loops listRates.room_count */}
                {rooms.map((room, index) => (
                    <RoomRates
                        key={index}
                        room={room}
                        setRooms={setRooms}
                        index={index}
                        invoiceForm={invoiceForm}
                        setInvoiceForm={setInvoiceForm}
                        optionsPaymentType={optionsPaymentType}
                        defaultPaymentTypeOption={defaultPaymentTypeOption}
                        removeRoom={removeRoom}
                        defaultRoomTypeOption={defaultRoomTypeOption}
                        selectRoomType={selectRoomType}
                        defaultRoomNumberOption={defaultRoomNumberOption}
                        defaultRateplanOption={defaultRateplanOption}
                        optionsAdults={optionsAdults}
                        defaultAdultsOption={defaultAdultsOption}
                        optionsChildren={optionsChildren}
                        defaultChildrenOption={defaultChildrenOption}
                        fetchRateplansDays={fetchRateplansDays}
                        getRoomTotal={getRoomTotal}
                        selectRoomNumber={selectRoomNumber}
                        fetchRoomTypes={fetchRoomTypes}
                        fetchRoomTypesInUpdate={fetchRoomTypesInUpdate}
                    />
                ))}
            </div>
        </div>
    );
};

export default InvoiceTab;