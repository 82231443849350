import { usePropertyManagement } from "contexts/v2/pages/property-setting/PropertyManagementContext";
import { useEffect } from "react";
import AsyncSelect from "react-select/async";

const AssignUserProperty = ({ initialData, onSuccess, onError }) => {
    const { userAssigned, setUserAssigned, loadOptionsUser, requestAssignUser } = usePropertyManagement();

    const handleChange = (selected) => {
        setUserAssigned((prevState) => ({
            ...prevState,
            form: {
                ...prevState.form,
                users: selected,
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await requestAssignUser(initialData.id, userAssigned.form.users);
            if (response.success) {
                onSuccess(response.message);
            } else {
                onError(response.message);
            }
        } catch (error) {
            onError(error.message);
        }
    };

    useEffect(() => {
        if (initialData) {
            setUserAssigned((prevState) => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    users: initialData.users.map((item) => ({
                        value: item.id,
                        label: item.name,
                    })),
                },
            }));
        }
    }, [initialData]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3 row align-items-center">
                <label className="col-sm-2 col-form-label">Property</label>
                <div className="col-sm-10">
                    <input type="text" className="form-control" value={initialData.name} readOnly />
                    <span className="text-muted">Users will be assigned to this property.</span>
                </div>
            </div>
            <div className="mb-3 row align-items-center">
                <label className="col-sm-2 col-form-label">User</label>
                <div className="col-sm-10">
                    <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions={userAssigned.data}
                        loadOptions={loadOptionsUser}
                        value={userAssigned.form.users}
                        onChange={handleChange}
                        placeholder="Search and select users..."
                    />
                </div>
            </div>

            {/* Button Assing */}
            <div className="mb-3 row align-items-center">
                <div className="col-sm-10 offset-sm-2">
                    <button type="submit" className="btn btn-primary">Assign</button>
                </div>
            </div>
        </form>
    );
};

export default AssignUserProperty;