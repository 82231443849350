import Select from "react-select";

const GuestDetails = ({
    guestDetailsForm,
    setGuestDetailsForm,
    countryOptions,
    defaultOptionsCountry,
    currencyOptions,
    defaultOptionsCurrency,
    agentOptions,
    defaultOptionsAgent,
    birthdayOptions,
    defaultOptionsBirthday
}) => {
    return (
        <>
            <div className="row mb-3 align-items-start">
                <div className="col-sm-4">
                    <label htmlFor="phone" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>Phone:</label>
                    <input
                        type="text"
                        className="form-control app-form-control rounded-1"
                        id="phone"
                        placeholder="Phone"
                        style={{ fontSize: '14px', height: '35px' }}
                        value={guestDetailsForm.phone}
                        onChange={(e) => {
                            setGuestDetailsForm((prevGuestDetailsForm) => ({
                                ...prevGuestDetailsForm,
                                phone: e.target.value,
                            }));
                        }}
                    />
                </div>

                {/* Address */}
                <div className="col-sm-4">
                    <label htmlFor="address" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>Address:</label>
                    <input
                        type="text"
                        className="form-control app-form-control rounded-1"
                        id="address"
                        placeholder="Address"
                        style={{ fontSize: '14px', height: '35px' }}
                        value={guestDetailsForm.address}
                        onChange={(e) => {
                            setGuestDetailsForm((prevGuestDetailsForm) => ({
                                ...prevGuestDetailsForm,
                                address: e.target.value,
                            }));
                        }}
                    />
                </div>

                {/* Select Country */}
                <div className="col-sm-4">
                    <label htmlFor="country" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>Country:</label>
                    <Select
                        isLoading={countryOptions.isLoading}
                        options={countryOptions.data}
                        value={defaultOptionsCountry()}
                        onChange={(selected) => {
                            setGuestDetailsForm((prevGuestDetailsForm) => ({
                                ...prevGuestDetailsForm,
                                country: selected.value,
                            }));
                        }}
                    />
                </div>
            </div>

            <div className="row mb-3 align-items-start">
                {/* State */}
                <div className="col-sm-3">
                    <label htmlFor="state" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>State:</label>
                    <input
                        type="text"
                        className="form-control app-form-control rounded-1"
                        id="state"
                        placeholder="State"
                        style={{ fontSize: '14px', height: '35px' }}
                        value={guestDetailsForm.state}
                        onChange={(e) => {
                            setGuestDetailsForm((prevGuestDetailsForm) => ({
                                ...prevGuestDetailsForm,
                                state: e.target.value,
                            }));
                        }}
                    />
                </div>
                {/* City */}
                <div className="col-sm-3">
                    <label htmlFor="city" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>City:</label>
                    <input
                        type="text"
                        className="form-control app-form-control rounded-1"
                        id="city"
                        placeholder="City"
                        style={{ fontSize: '14px', height: '35px' }}
                        value={guestDetailsForm.city}
                        onChange={(e) => {
                            setGuestDetailsForm((prevGuestDetailsForm) => ({
                                ...prevGuestDetailsForm,
                                city: e.target.value,
                            }));
                        }}
                    />
                </div>
                {/* Postal Code */}
                <div className="col-sm-3">
                    <label htmlFor="postal_code" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>Postal Code:</label>
                    <input
                        type="text"
                        className="form-control app-form-control rounded-1"
                        id="postal_code"
                        placeholder="Postal Code"
                        style={{ fontSize: '14px', height: '35px' }}
                        value={guestDetailsForm.postal_code}
                        onChange={(e) => {
                            setGuestDetailsForm((prevGuestDetailsForm) => ({
                                ...prevGuestDetailsForm,
                                postal_code: e.target.value,
                            }));
                        }}
                    />
                </div>
                {/* ID Number */}
                <div className="col-sm-3">
                    <label htmlFor="id_number" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>ID Number:</label>
                    <input
                        type="text"
                        className="form-control app-form-control rounded-1"
                        id="id_number"
                        placeholder="ID Number"
                        style={{ fontSize: '14px', height: '35px' }}
                        value={guestDetailsForm.id_number}
                        onChange={(e) => {
                            setGuestDetailsForm((prevGuestDetailsForm) => ({
                                ...prevGuestDetailsForm,
                                id_number: e.target.value,
                            }));
                        }}
                    />
                </div>
            </div>

            <div className="row mb-3 align-items-start">
                {/* Currency Select */}
                <div className="col-sm-2">
                    <label htmlFor="currency" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>Currency:</label>
                    <Select
                        isLoading={currencyOptions.isLoading}
                        options={currencyOptions.data}
                        value={defaultOptionsCurrency()}
                        onChange={(selected) => {
                            setGuestDetailsForm((prevGuestDetailsForm) => ({
                                ...prevGuestDetailsForm,
                                currency: selected.value,
                            }));
                        }}
                    />
                </div>

                <div className="col-sm-2 d-flex gap-2 px-5">
                    {/* Non Refund Checkbox */}
                    <div className="col-sm-6" style={{ gap: '8px' }}>
                        <label className="col-form-label d-flex" htmlFor="non_refund">Non Refund</label>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="non_refund"
                            checked={guestDetailsForm.non_refund}
                            onChange={(e) => {
                                setGuestDetailsForm((prevGuestDetailsForm) => ({
                                    ...prevGuestDetailsForm,
                                    non_refund: e.target.checked,
                                }));
                            }}
                        />
                    </div>
                    {/* Checkbox Maintenance */}
                    <div className="col-sm-6" style={{ gap: '8px' }}>
                        <label className="col-form-label d-flex" htmlFor="maintenance">Maintenance</label>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="maintenance"
                            checked={guestDetailsForm.maintenance}
                            onChange={(e) => {
                                setGuestDetailsForm((prevGuestDetailsForm) => ({
                                    ...prevGuestDetailsForm,
                                    maintenance: e.target.checked,
                                }));
                            }}
                        />
                    </div>
                </div>

                <div className="col-sm-2">
                    {/* Agency Select */}
                    <label htmlFor="agency" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>Agency:</label>
                    <Select
                        isLoading={agentOptions.isLoading}
                        options={agentOptions.data}
                        value={defaultOptionsAgent()}
                        onChange={(selected) => {
                            setGuestDetailsForm((prevGuestDetailsForm) => ({
                                ...prevGuestDetailsForm,
                                agent_id: selected.value,
                            }));
                        }}
                    />
                </div>

                <div className="col-sm-2">
                    {/* Arrival Time */}
                    <label htmlFor="arrival_time" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>Arrival Time:</label>
                    <input
                        type="time"
                        className="form-control app-form-control rounded-1"
                        id="arrival_time"
                        style={{ fontSize: '14px', height: '35px' }}
                        value={guestDetailsForm.arrival_time}
                        onChange={(e) => {
                            setGuestDetailsForm((prevGuestDetailsForm) => ({
                                ...prevGuestDetailsForm,
                                arrival_time: e.target.value,
                            }));
                        }}
                    />
                </div>

                <div className="col-sm-4">
                    <label htmlFor="birthday" className="col-sm-4 col-form-label d-flex" style={{ fontSize: '14px' }}>Birthday:</label>
                    <div className="row">
                        <div className="col-4">
                            <Select
                                options={birthdayOptions.day.map((day) => ({ value: day, label: day }))}
                                value={defaultOptionsBirthday().day}
                                onChange={(selected) => {
                                    setGuestDetailsForm((prevGuestDetailsForm) => ({
                                        ...prevGuestDetailsForm,
                                        birthday_day: selected.value,
                                    }));
                                }}
                            />
                        </div>
                        <div className="col-4">
                            <Select
                                options={birthdayOptions.month.map((month) => ({ value: month.value, label: month.label }))}
                                value={defaultOptionsBirthday().month}
                                onChange={(selected) => {
                                    setGuestDetailsForm((prevGuestDetailsForm) => ({
                                        ...prevGuestDetailsForm,
                                        birthday_month: selected.value,
                                    }));
                                }}
                            />
                        </div>
                        <div className="col-4">
                            <Select
                                options={birthdayOptions.year.map((year) => ({ value: year, label: year }))}
                                value={defaultOptionsBirthday().year}
                                onChange={(selected) => {
                                    setGuestDetailsForm((prevGuestDetailsForm) => ({
                                        ...prevGuestDetailsForm,
                                        birthday_year: selected.value,
                                    }));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GuestDetails;