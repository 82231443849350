import React, { useState } from "react";

const FilterCanvas = ({ calendarSettings, handleFilterCalendar, onSuccess, onFailed, onSameFilter }) => {
    const [filter, setFilter] = useState({
        unsigned_rooms: calendarSettings.unsigned || false,
    });

    const handleShowUnsignedRooms = (e) => {
        setFilter((prev) => ({ ...prev, unsigned_rooms: e.target.checked }));
    }

    const handleFilter = (e) => {
        e.preventDefault();
        if (filter.unsigned_rooms === calendarSettings.unsigned) {
            onSameFilter();
            return;
        }
        handleFilterCalendar(filter);
        onSuccess('Filter apply successfully');
    };

    return (
        <form className="d-flex flex-column" style={{ height: '100%' }} onSubmit={(e) => handleFilter(e)}>

            <div className="action-sheet-content flex-grow-1 mb-3">
                <div className="row align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">Show Unsigned Rooms:</label>
                    <div className="col-sm-8 d-flex align-items-center">
                        <div className="form-check form-switch mb-0">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="showUnsignedRooms"
                                checked={filter.unsigned_rooms}
                                onChange={handleShowUnsignedRooms}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Submit Button */}
            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block mb-3">
                    Apply Filter
                </button>
            </div>
        </form>
    );
};

export default FilterCanvas;
