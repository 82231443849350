import { useState } from "react";
import { getRequest, postRequest } from "services/api";

const useCreateLinkRateplan = () => {
    const [rateplanIdWillLink, setRateplanIdWillLink] = useState(null);

    const getRateplanForLink = async () => {
        try {
            const response = await getRequest(`/rate-plan`);
            if (response.success) {
                return { success: true, data: response.data, message: response.message };
            } else {
                return { success: false, message: response.message };
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    const createLinkRateplan = async (data) => {
        try {
            const response = await postRequest("/link-rate-plan", data);
            if (response.success) {
                return { success: true, message: response.message };
            }
        } catch (error) {
            if (error.code === 422) {
                return { success: false, message: error.message, formError: error.errors };
            } else {
                return { success: false, message: error.message };
            }
        }
    };

    return {
        rateplanIdWillLink,
        setRateplanIdWillLink,
        createLinkRateplan,
        getRateplanForLink
    };
};

export default useCreateLinkRateplan;