import { useEffect, useState } from "react";
import { getRequest } from "services/api";
import { API_VERSION } from "utils/constants";

const defaultReservationTooltip = {
    data: null,
    loading: false,
    error: null,
    id: null,
};

const useTooltipReservationHooks = () => {
    const [reservationTooltip, setReservationTooltip] = useState(defaultReservationTooltip);

    const clearTooltip = () => {
        setReservationTooltip(defaultReservationTooltip);
    };

    const fetchReservationDetails = async () => {
        try {
            const response = await getRequest(`/${API_VERSION}/booking/${reservationTooltip.id}`);
            if (response.success) {
                const data = response.data;
                setReservationTooltip((prevState) => ({
                    ...prevState,
                    data,
                    loading: false,
                    error: null,
                }));
            }
        } catch (error) {
            setReservationTooltip((prevState) => ({
                ...prevState,
                data: null,
                loading: false,
                error,
            }));
        }
    };

    useEffect(() => {
        if (reservationTooltip.id) {
            fetchReservationDetails();
        }
    }, [reservationTooltip.id]);

    return {
        clearTooltip,
        reservationTooltip,
        setReservationTooltip,
    };
};

export default useTooltipReservationHooks;