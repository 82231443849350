import { deleteRequest } from "services/api";

const useRemoveRoom = () => {
    const removeRoom = async (roomId) => {
        try {
            const response = await deleteRequest(`/room-type/${roomId}`);
            if (response.success) {
                return { success: true, message: response.message };
            }
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    return {
        removeRoom,
    };
};

export default useRemoveRoom;