import { useAuth } from "hooks/useAuth";
import { usePaginationWithComponent } from "hooks/usePaginationWithComponent";
import { createContext, useContext, useEffect, useState } from "react";
import { getRequest } from "services/api";
import { API_VERSION } from "utils/constants";
import { buildQueryString } from "utils/queryHelpers";

const defaultOptionsBadge = [
    // Confirmed, Modified, Inquiry / Unconfirm, Cancel
    { value: 'confirmed', label: 'Confirmed', color: 'success' },
    { value: 'modified', label: 'Modified', color: 'unconfirmed' },
    { value: 'unconfirm', label: 'Inquiry / Unconfirm', color: 'warning' },
    { value: 'cancelled', label: 'Cancel', color: 'danger' },
];

const defaultOptionsFilter = {
    search: '',
    date_type: '',
    all_properties: true,
    property_ids: [],
    from: '',
    to: '',
    status: 'all',
    agent_id: 'all',
    is_cancelled: null
};

const defaultPaginationValues = {
    currentPage: 1,
    totalPages: 1
};

const defaultAgentValues = {
    data: [],
    error: null,
    isLoading: false
}

const defaultOptionsDateType = [
    // Created, Arrival Date, Departure Date
    { value: 'created', label: 'Created' },
    { value: 'arrival', label: 'Arrival Date' },
    { value: 'departure', label: 'Departure Date' }
];

const defaultOptionsStatus = [
    { value: 'all', label: 'All' },
    ...defaultOptionsBadge
];

const ReservationContext = createContext();

export const ReservationProvider = ({ children }) => {
    const { user } = useAuth();

    const [reservation, setReservation] = useState({
        data: [],
        pagination: {},
        error: null,
        isLoading: false,
        filterLoading: false,
        refetch: () => fetchReservations()
    });
    const [agents, setAgents] = useState(defaultAgentValues);
    const [pagination, setPagination] = useState(defaultPaginationValues);
    const [filterOption, setFilterOption] = useState(defaultOptionsFilter);
    const [badgeOptions, setBadgeOptions] = useState(defaultOptionsBadge);
    const [dateTypeOptions, setDateTypeOptions] = useState(defaultOptionsDateType);
    const [statusOptions, setStatusOptions] = useState(defaultOptionsStatus);

    const [propertyOptions, setPropertyOptions] = useState(() => {
        return user?.properties
            ? Object.entries(user.properties).map(([id, name]) => ({
                label: name || "Unknown", // Fallback if name is missing
                value: id || null,       // Fallback if id is missing
            }))
            : [];
    });

    const queryString = buildQueryString({
        page: pagination.currentPage,
        ...(filterOption.search && { search: filterOption.search }),
        ...(filterOption.status !== 'all' && { status: filterOption.status }),
        ...(filterOption.agent_id !== 'all' && { agent_id: filterOption.agent_id }),
        ...(filterOption.date_type && { date_type: filterOption.date_type }),
        ...(filterOption.from && { from: filterOption.from }),
        ...(filterOption.to && { to: filterOption.to }),
        ...(filterOption.property_ids.length > 0 && { property_ids: filterOption.property_ids }),
        ...(filterOption.is_cancelled !== null && { is_cancelled: filterOption.is_cancelled ? 1 : 0 }),
    });


    const fetchReservations = async () => {
        try {
            const response = await getRequest(`/${API_VERSION}/booking`);
            if (response.success) {
                setReservation((prev) => ({
                    ...prev,
                    data: response.data,
                    pagination: response.pagination,
                    error: null
                }));

                setPagination((prev) => ({
                    ...prev,
                    totalPages: response.pagination.last_page
                }));
            }
        } catch (error) {
            setReservation((prev) => ({ ...prev, error: error }));
        }
    }

    const fetchReservationsByFilter = async () => {
        setReservation({ ...reservation, filterLoading: true });
        try {
            const response = await getRequest(`/${API_VERSION}/booking${queryString}`);
            if (response.success) {
                setReservation((prev) => ({
                    ...prev,
                    data: response.data,
                    pagination: response.pagination,
                    error: null
                }));

                setPagination((prev) => ({
                    ...prev,
                    totalPages: response.pagination.last_page
                }));
            }
        } catch (error) {
            setReservation((prev) => ({ ...prev, error: error }));
        } finally {
            setReservation((prev) => ({ ...prev, filterLoading: false }));
        }
    };

    const handlePageChange = (page) => {
        setPagination((prev) => ({ ...prev, currentPage: page }));
    };

    const PaginationComponent = usePaginationWithComponent({
        totalPages: pagination.totalPages,
        currentPage: pagination.currentPage,
        onPageChange: handlePageChange,
        visiblePages: 5,
    });

    const handleFilterChange = (e) => {
        e.preventDefault();
        fetchReservationsByFilter();
    };

    const handleFilterReset = (e) => {
        e.preventDefault();
        setFilterOption(defaultOptionsFilter);
        fetchReservationsByFilter();
    };

    const fetchAgents = async () => {
        try {
            const response = await getRequest('/agent?all=1');
            console.log('response', response.data);
            if (response.success) {
                setAgents((prev) => ({
                    ...prev,
                    data: [
                        { label: "All", value: "all", originalData: null },
                        ...response.data.map((agent) => ({
                            label: agent.name,
                            value: agent.id,
                            originalData: agent
                        })),
                    ],
                    error: null
                }));
            }
        } catch (error) {
            setAgents((prev) => ({ ...prev, error }));
        }
    };

    const fetchAll = async () => {
        setReservation((prev) => ({ ...prev, isLoading: true }));
        try {
            await fetchReservations();
            await fetchAgents();
        } catch (error) {
            setReservation((prev) => ({ ...prev, error: error }));
        } finally {
            setReservation((prev) => ({ ...prev, isLoading: false }));
        }
    }

    useEffect(() => {
        fetchAll();
    }, []);

    useEffect(() => {
        fetchReservationsByFilter();
    }, [pagination.currentPage]);

    return (
        <ReservationContext.Provider value={{
            badgeOptions,
            reservation,
            setReservation,
            PaginationComponent,
            filterOption,
            setFilterOption,
            handleFilterChange,
            handleFilterReset,
            agents,
            dateTypeOptions,
            propertyOptions,
            statusOptions
        }}>
            {children}
        </ReservationContext.Provider>
    );
}

export const useReservation = () => {
    const context = useContext(ReservationContext);
    if (!context) {
        throw new Error('useReservation must be used within a ReservationProvider');
    }
    return context;
}