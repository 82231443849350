import { useState } from 'react';

const defaultCommnetsForm = {
    public_notes: '',
    private_notes: '',
};

const useCommentsTab = () => {
    const [commentsForm, setCommentsForm] = useState(defaultCommnetsForm);

    const clearCommentsTab = () => {
        setCommentsForm(defaultCommnetsForm);
    };

    return {
        clearCommentsTab,
        commentsForm,
        setCommentsForm,
    };
};

export default useCommentsTab;